
.unitBar{

width: 93%;
height: calc( 0.8 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
border-color: grey;

}
