.notices {
}

.searchbar {
  margin-top: 100px;
  margin-bottom: 34px;
  width: 700px;
  border: 1px solid var(--cardBorderColor);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.serchIcon {
  margin-left: 10px;
  color: var(--lightText);
}

.searchInput {
  width: 94%;
  margin-right: 5px;
  background-color: white;
  height: 30px;
  font-size: 14px;
  font-size: 18px;
  border: none;
}

@media (max-width: 1300px) {
  .searchbar {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.mainContainer {
  margin-top: 20px;
  width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  row-gap: 10px;
  column-gap: 20px;
}

.leftContainer {
}

.title {
  font-weight: bold;
  color: black;

  margin-left: 10px;
}

.topBar {
  width: 100%;
  height: 35px;
  background-color: var(--cardBorderColorLight);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  font-size: 18px;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid var(--cardBorderColor);
}

.mainImg {
  width: 100%;
}

.pic {
  width: 100%;
  height: 100%;
}

.docBox {
  height: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.law {
  margin: 10px;
  line-height: 30px;
}

.uploadDocument {
  margin-top: 10px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border: 1px solid var(--themeColor);
  border-radius: 6px;
}

.rightContainer {
}

.changesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.circleContainer {
  width: 130px;
  height: 130px;
  margin-top: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: var(--themeColor);
  border: 2px solid var(--themeColor);
}

.changesTitle {
  font-weight: bolder;
  font-size: 22px;
}

.arrowIcon {
  margin-top: 10px;
  color: var(--themeColor);
  height: 30px;
  width: 30px;
}

.mainChangesDetailsContainer {
  margin-top: 50px;
  width: 45%;
  text-align: justify;
  height: auto;
  padding: 10px;
  line-height: 30px;
  border: 1px solid var(--themeColor);
  border-radius: 10px;
}


@media (max-width: 1300px) {

  .mainContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .leftContainer{
    width: 97%;
  }

  .rightContainer{
    margin-top: 40px;
    width: 97%;
  }

  .mainChangesDetailsContainer{
    width: 85%;
  }
}