/* General App Styles */
.app {
    text-align: center;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
  }
  
  .pagetitle {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 40px;
  }
  
  .sectiontitle {
    font-size: 1.8rem;
    font-weight: bold;
    color: #34495e;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  
  /* Package Container */
  .packagecontainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  /* Package Card */
  .packagecard {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 20px;
    width: 300px;
    height: 400px; /* Fixed height for consistency */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex; /* Use Flexbox for layout */
    flex-direction: column; /* Stack elements vertically */
    justify-content: space-between; /* Distribute space between elements */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .packagecard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .packagetitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #2c3e50;
  }
  
  .packageprice {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #e74c3c;
  }
  
  .packagefeatures {
    text-align: left;
    color: #7f8c8d;
    margin-bottom: 20px;
    flex-grow: 1; 
    overflow-y: auto; 
  }
  
  .featureitem {
    font-size: 0.95rem;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  
  .featureicon {
    color: var(--themeColor);
    font-weight: bold;
    margin-right: 8px;
  }
  
  /* Get Started Button */
  .getstartedbtn {
    background: var(--themeColor);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.2s ease;
    align-self: center; /* Center the button horizontally */
  }
  
  .getstartedbtn:hover {
    background: #2980b9;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .packagecontainer {
      flex-direction: column;
      align-items: center;
    }
  
    .packagecard {
      width: 90%;
    }
  }
  