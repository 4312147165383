
.profileCardDetails {
    margin-top: 20px;
    width: 100%;
    display: flex;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  
  .leftSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .rightSide {
    width: 100%;
  }
  
  .profilePic {
    width: 120px;
    height: 120px;
    border: 10px;
    margin: 20px;
  }
  
  .mainprofilePic {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 20px;
  }
  
  .bookbtn {
    width: 120px;
    height: 30px;
    background-color: var(--themeColor);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
  
  .bookbtn:hover {
    border: 1px solid var(--themeColor);
    cursor: pointer;
    background-color: white;
    color: var(--themeColor);
  }
 
  .bookbtn1{
  
    width: 120px;
    height: 30px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-weight: bold;

  }





  .topContainer {
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .NameContainer {
    font-size: 30px;
    font-weight: bold;
  }
  .rattingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .rattingIcon {
    height: 30px;
    width: 30px;
    color: sandybrown;
  }
  
  .ratingNumber {
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
    color: var(--greyTextColor);
  }
  
  .HeartBtn {
    height: 30px;
    width: 30px;
    color: var(--greyTextColor);
  }
  
  .midContainer {
    margin: 20px;
    display: flex;
    align-items: center;
  }
  .location {
    height: 30px;
    width: 30px;
    color: var(--greyTextColor);
  }
  
  .locationName {
    margin-left: 10px;
    color: var(--themeColor);
  }
  
  .bottomContainer {
    margin: 20px;
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
  
  .teachesContainer {
    display: flex;
    align-items: center;
    padding-top: 8px;
    margin-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: var(--cardBorderColorLight);
    border-radius: 10px;
    border: 1px solid var(--cardBorderColor);
    border-style: none;
  }
  
  .icon1 {
    height: 30px;
    width: 30px;
    border-radius: 10px;
  }
  
  .detailsContainer {
    margin-left: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  
  .heading {
   font-weight: bold;
  }
  
  .headingDetails {
    margin-top: 6px;
  }
  
  .languageContainer {
    display: flex;
    align-items: center;
    margin: 20px;
  }
  
  .languageTitle {
    font-size: 18px;
    font-weight: bold;
  }
  
  .lang {
    margin-left: 10px;
  }


@media (max-width: 1000px) {
  .profileParent{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
  
  
  }
  
  .mainContainer{
    width: 98%;
    padding: 0px;
  }
  
  .profileCardDetails{
    display: flex;
    flex-direction: column;
  }
  
  /* .topContainer{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  
  .ratingContainer{
    margin-top: 10px;
  
  }
  .HeartBtn{
    margin-top: 10px;
  
  }
  
  .bottomContainer{
    display: flex;
    flex-direction: column;
  } */
  
  }
