.marketingpage {
    text-align: center;
    padding: 30px;
    font-family: Roboto;
    background-color: var(--chatCommentBkgColor);
    width: 100%;
    box-sizing: border-box; 
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333; 
}

.subtitle {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #555;
    line-height: 1.5; 
}

.cardscontainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; 
    gap: 20px;
    width: 100%; 
    max-width: 1200px; 
    margin: 0 auto; 
    padding: 0 10px; 
    box-sizing: border-box;
}

.card {
    background-color: #ffffff; 
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 100%; 
    max-width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
}

.cardicon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.cardimage {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 50%;
    background-color: #f0f0f0;
    padding: 10px;
}

.cardtitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #222;
}

.carddescription {
    font-size: 1rem;
    color: #666;
    line-height: 1.5; 
}

.explorebutton {
    background-color: var(--themeColor); 
    color: white;
    border: none;
    padding: 12px 25px; 
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s, transform 0.3s;
    text-decoration: none; 
    display: inline-block; 
}

.explorebutton:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .cardscontainer {
        flex-direction: column; 
        align-items: center;
    }

    .card {
        width: calc(100% - 40px); 
        margin-bottom: 20px; 
    }

    .title {
        font-size: 2rem; 
    }

    .subtitle {
        font-size: 1rem; 
    }

    .explorebutton {
        font-size: 1rem; 
        padding: 10px 20px; 
    }
}

/* Optional for better content centering */
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll on the entire page */
    box-sizing: border-box;
}
