

.headerRight{

overflow: hidden;
text-align: center;
padding-right: 60px;
display: flex;
align-items: center;
border-style: none;
}



.tmpBtn{
height: calc( 0.7 * var(--headerHeight));
width: calc( 0.7 * var(--headerHeight));
border-radius: 50%;
margin-right: 20px;
background-color: var(--themeColor);
border-style: solid;
border-color: white;
border-width: initial;
}

.tmpIcon{
font-size: calc( 0.30 * var(--headerHeight) );
color: white;
margin-left: auto;
margin-right: auto;
display: block !important;
}


