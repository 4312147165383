.parentBox {
  width: 270px;

  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
}

.parentBox:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.logo {
  width: 100%;
  height: 250px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagesExam {
  width: 100%;
  height: 100%;
}

.title {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}

.description1 {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.description2 {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ViewToppersBtn {
  margin-top: 20px;
  width: 100px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border-radius: 20px;
  border: none;
  margin-bottom: 20px;
}

.ViewToppersBtn:hover {
  border: 1px solid var(--themeColor);
  background-color: white;
  cursor: pointer;
  color: var(--themeColor);
}
