
.contentDivUserProfile{
display: flex;
justify-content: space-between;
border-style: none;
border-color: orange;
padding-bottom: 20px;
/*
display: grid;
grid-template-columns: 1fr 2.3fr;
grid-template-rows: 1fr 1fr ;
grid-gap: 10px;
padding-bottom: 50px;
padding-right:20px;
*/




}



@media only screen and (max-width: 1100px) {
 .contentDivUserProfile{
     display: flex;
     flex-direction: column;
 }
}




