.getintouch {
  font-family: 'Arial', sans-serif;
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  text-align: center;
  color: #333;
  line-height: 1.6;
}

.title {
  font-size: 2rem;
  color: #003366;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.5rem;
  color: var(--themeColor);
  margin-bottom: 30px;
}

.ctatext {
  font-size: 1.2rem;
  color: #666;
  margin-top: 20px;
}

.ctalink {
  color: white;
  text-decoration: none;
  font-weight: bold;
  background-color: var(--themeColor);
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.ctalink:hover {
  text-decoration:wavy;
}

@media (max-width: 600px) {
  .title {
      font-size: 1.8rem;
  }

  .subtitle {
      font-size: 1.3rem;
  }

  .ctatext {
      font-size: 1rem;
  }
}
