

.quickMeetingPage{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1);
display: flex;
align-items: center;
justify-content: center;
}


.meetingInfoPage{

position: relative;
width: 40vw;
height:60vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
overflow: hidden;


}

.closeButtonDiv{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.closeFormButton{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.closeButtonIcon{

font-size: 25px;

}

.meetingLink{

color: var(--themeColor);
text-decoration: underline;
background-color:var(--lightThemeColor1);
padding-left: 20px;
padding-right: 20px;
border-radius: 5px;
border-style: none;
margin-top: 20px;
font-size: 17px;
padding: 20px;
cursor: pointer;

white-space: pre-wrap; /* CSS3 */    
white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
white-space: -pre-wrap; /* Opera 4-6 */    
white-space: -o-pre-wrap; /* Opera 7 */    
word-wrap: break-word; /* Internet Explorer 5.5+ */
width: 80%;

}



.copyButton{

margin-left: 10px;
border-style: none;
background-color: lightgrey;
padding: 5px;
border-radius: 4px;
cursor: pointer;
}


.startMeetingNowButton{
margin-top: 50px;
border-style: none;
padding: 10px;
color: white;
background-color: var(--themeColor);
font-weight: bold;
border-radius: 5px;
cursor: pointer;
}









