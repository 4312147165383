.parentNews {
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1200px;
  background: #f9f9f9; /* Light gray background */
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.imgAndHeading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #e7f3ff; /* Light blue background */
  border-radius: 8px;
}

.mainImg {
  width: 180px;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.contentDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 20px;
}

.mainTitle {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.newsDetails {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  max-width: 800px;
  text-align: justify;
}

.featureList {
  margin-top: 20px;
  padding: 0;
  list-style: none;
}

.featureItem {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #444;
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 5px 0;
  width: 90%;
  max-width: 600px;
}

.featureItem::before {
  content: "✔"; /* Checkmark icon */
  color: green;
  font-weight: bold;
  margin-right: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .parentNews {
    width: 95%;
    padding: 16px;
  }

  .mainImg {
    width: 150px;
  }

  .mainTitle {
    font-size: 24px;
  }

  .newsDetails {
    font-size: 16px;
    text-align: left;
  }

  .featureItem {
    font-size: 14px;
    padding: 8px;
    text-align: left;
  }
}
