/* Modal Background Overlay */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Prevents content from sticking to edges */
    z-index: 1000;
    overflow-y: auto; /* Allows scrolling if content overflows */
}

/* Modal Box */
.modalContent {
    height:90dvh;
    background: white;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 500px;
    width: 100%;
    position: relative;
    overflow-y: auto;
    animation: fadeIn 0.3s ease-in-out;
}

/* Close Button */
.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    background: rgba(0, 0, 0, 0.2);
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;
}

.closeButton:hover {
    background: rgba(0, 0, 0, 0.5);
}

/* Profile Image */
.modalImage {
    width: 100%;
    min-height: 300px;
    object-fit: cover;
    object-position: top;
    border-radius: 12px;
    margin-bottom: 15px;
}

/* Role Styling */
.modalRole {
    color: var(--themeColor);
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px;
}

/* Description */
.modalDescription {
    font-size: 14px;
    font-weight: 500;
    color: #444;
    text-align: justify;
    margin-top: 10px;
    line-height: 1.6;
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 600px) {
    .modalOverlay {
    padding: 8px;}
    .modalContent {
        max-width: 80%;
        padding: 8px;
        max-height: 90vh; 
        overflow-y: auto; 
    }

    .modalImage {
        max-height: 200px;
    }

    .modalRole {
        font-size: 16px;
    }

    .modalDescription {
        font-size: 13px;
    }
}