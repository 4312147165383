.centerImageLeftRightGrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  margin-top: 40px;
  color: blue;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.subTitle {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.details {
  margin-top: 40px;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: var(--lightText);
}

.serviceSection {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  row-gap: 10px;
  column-gap: 10px;
}

.leftSideContent {
  width: 100%;

}

.features {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--themeColor);
  font-weight: bold;
}

/* .a1box {
  max-height: 2.4em;
  overflow: hidden;
  transition: max-height 0.3s ease; 
}

.a1box:hover {
  max-height: none; 
} */

.serviceImage {
  height: 60px;
  width: 60px;
  background-color: white;
  visibility: hidden;
}

.a1box {
  height: auto;
  margin: 12px;
  padding: 8px;
  border: 1px solid var(--cardBorderColor);
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  overflow: hidden;
}

.serviceHeading1 {
  font-size: 18px;
  font-weight: 500;
  font-weight: bold;
  color: #08203d;
}

.serviceHeading2 {
  margin-top: 6px;
  font-size: 14px;
  color: var(--lightText);
  line-height: 23px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  transition: max-height 0.3s ease;
}

.serviceHeading2:hover {
  height: auto;
  max-height: none;
  cursor: pointer;
}


.a1box:hover .serviceHeading2{
  -webkit-line-clamp: 8;
  transition: max-height 0.3s ease;
}

.centerImage {
  margin-top: 35px;
  width: 100%;
  height: 620px;
  min-height: 620px;
  max-height: 620px;
  background-color: white;
  object-fit: cover;
  border-style: none;
  border-radius: 10px;
  display: flex;


}

.rightSideContent {
  width: 100%;

}

@media (max-width: 1300px) {
  .title {
    font-size: 20px;
  }

  .subTitle {
    margin-top: 10px;
    font-size: 30px;
  }
  .a1box {
    width: auto;
    padding: 10px;
    margin-top: 10px;
  }

  .centerImage {
    width: 96%;
    display: flex;
    justify-self: center;
    align-self: center;
    
  }

  .serviceSection {
    margin-top: 20px;
    width: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-auto-rows: auto;
    row-gap: 10px;
    column-gap: 10px;
  }
}
