.testimonials {
    font-family: 'Arial', sans-serif;
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    color: #333;
  }
  
  .title {
    font-size: 2.5rem;
    color: #000;
    margin-bottom: 40px;
  }
  
  .testimonialGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .testimonialCard {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .testimonialCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .stars {
    font-size: 1.2rem;
    color: #f4c430;
    margin-bottom: 10px;
  }
  
  .text {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  .profile {
    margin-right: 15px;
  }
  
  .profileImage {
    width: 50px; /* Adjust the size of the image */
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  .author {
    font-size: 1rem;
    color: #333;
    font-weight: bold;
    text-align: right;
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
  
    .testimonialCard {
      padding: 15px;
    }
  
    .text {
      font-size: 0.9rem;
    }
  
    .author {
      font-size: 0.9rem;
    }
  }
  