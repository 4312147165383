.subscriptionDetails {
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.PlanTextTitle {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.PlanTextsubTitle {
  margin-top: 20px;
  font-weight: bold;
  color: #08203D;
  line-height: 55px;
  font-size: 45px;
  
}

.planContainer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.basicPlan {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 8px;
}

.basicPlanContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pic {
  margin-top: 20px;
  height: 60px;
  width: 60px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColorLight);
  display: flex;
  justify-content: center;
  align-items: center;
}


.rupees_icon{
  height: 30px;
  width: 30px;
  color: var(--lightText);
}

.planTitle {
  margin-top: 20px;
  font-size: 25px;
  color: var(--darkText);
  letter-spacing: 5px;
  font-weight: bold;
}

.aboutPrice {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  align-items: end;
}

.amount {
  font-size: 40px;
  font-weight: bold;
  color: var(--themeColor);
}

.amountMonth {
  font-size: 20px;
  display: flex;
  align-items: end;
  font-weight: bold;
  color: var(--lightText);
}

.horizontalLine {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  background-color: var(--darkText);
  border-bottom: 1px solid var(--cardBorderColor);
}

.features {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.checkIcon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: gray;
}

.textplanDetails {
  margin-left: 10px;
  color: var(--lightText);
}

.basicPlanBtn {
  height: 24px;
  width: fit-content;
  background-color: white;
  color: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border-radius: 30px;
  margin-bottom: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid var(--themeColor);
}

.standardPlan {
  width: 33.33%;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.unlimitedPlan {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 8px;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
