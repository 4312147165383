.slider {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  
  .cards {
    width: 100%;
    height: auto;
    margin-top: 30px;
    overflow: hidden;
    position: relative;
  }
  
  .wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 200%; 
  }
  
  .wrapperMobile {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 550%; 
    visibility: hidden;
    height: 0;
  }
  
  .child {
    width: 16.6%;
    box-sizing: border-box;
  }
  
  .childMobile {
    width: 16.6%;
    box-sizing: border-box;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: gray;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .activeDot {
    background-color: var(--themeColor);
  }
  
  @media (max-width: 600px) {
    .wrapper {
      visibility: hidden;
      height: 0;
    }
  
    .dot {
      width: 30px;
      height: 20px;
      background-color: gray;
      border-radius: 5px;
    }
  
    .wrapperMobile {
      visibility: visible;
      height: auto;
    }
  }

  .card {
    width: 95%;
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;  
    border-radius: 7px 7px 0 0;
  }
  
  @media (max-width: 768px) {
    .img {
      height: 180px; 
    }
  }
  
  @media (max-width: 600px) {
    .img {
      height: 150px; 
    }
  }
  
  .title {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  .description1 {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
  }
  
  .ViewToppersBtn {
    margin-top: 20px;
    width: 150px;
    height: 30px;
    background-color: var(--themeColor);
    color: white;
    border-radius: 20px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ViewToppersBtn:hover {
    background-color: white;
    border: 1px solid var(--themeColor);
    color: var(--themeColor);
  }
  