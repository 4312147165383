

.DropDown{

border-radius: 3px;
position: absolute;
top: var(--headerHeight);
left: 150px;
border-style: none;
box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
align-items: center;
vertical-align: middle;
z-index: 5;
width:250px;
background-color: white;
transition: height 1s;
padding-bottom: 50px;
/*max-height: 50px;
overflow-y: auto;*/
margin:auto;
padding:20px;

/*overflow-x: hidden;
overflow-y:auto;*/
}


.DropDown::before {
  content: "";
  position: absolute;
  width: 0; 
  height: 0; 
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white ; 
  z-index: 9999;
  left: 50px;
}








.DropDown i{
display: block;
background-color: #f1f1f1;
font-weight: lighter;
font-style: normal;
text-align: left;
font-size: 1.2rem;
border-style: none;
height: 3rem;
vertical-align: middle;
line-height: 3rem;
width: 100%;
}



.DropDown i:hover{
display: block;
border-style: none;
background-color: #ddd;
font-style: normal;
font-weight: lighter;
}

.courseSelectText{
width: 100%;
height: 50px;
font-size: calc( 0.3 * var(--headerHeight) );
background: white;
color: var(--themeColor);
}


.courseBox{
width: 90%;
height: 50px;
background: #b8d1ff;
border-radius: 4px;
border-style: none;
border-width: 1px;
border-color: #b8d1ff;
color: var(--themeColor);
display: flex;
flex-direction: column;
align-items: center;
margin: auto;
justify-content: center;
margin-bottom: 10px;
}


.courseNameBox{
width: 70%;
text-overflow: ellipsis;
white-space: nowrap;
overflow:hidden;
}




.courseBox:hover{
color: #ff5349;	
background: #ffd4d1;
border-style: none;
cursor: pointer;
}




.topGapBox{
height: 50px;
width: 100%;
background-color: white;
color: grey;
font-weight: bold;
margin-bottom: 10px;

}
