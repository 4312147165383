

.createTicketFormDivParent{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;
}

.createTicketForm{

position: relative;
width: 100%;
max-width:700px;
max-height: 90vh;
background-color: white;
border-radius: var(--cardBorderRadius);
border-style: none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
align-items: center;
overflow-y: scroll;
}


.input_field{
height: 40px;
margin:auto;
margin-left: 50px;
}




.dateFields{
display: flex;
width: 75%;
margin: auto;
}




.createTicketFormLoading{

position: relative;
width: 60vw;
height: 80vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style: none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}


.submittingDiv{
padding-top: 100px;	
color: var(--themeColor);

}









.closeButtonDiv{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.closeFormButton{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.closeButtonIcon{

font-size: 25px;

}


.logoAndTitleContainer{


height:150px;
width:80%;
background-color: white;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-style: none;
margin: auto;
}

.bwLogo{

height: 75px;
width: 75px;

}

.formTitleDiv{
width: 100%;
display:flex;
justify-content:flex-start;
color: #585858;
font-size: 30px;
margin: auto;
}

.formTitleDiv i{

font-size: 25px;
font-style: normal;
}



.submitButtonDiv{
width: 80%;	
display: flex;
justify-content: center;
background-color: white;
padding-top: 50px;
justify-content: center;
border-style: none;
margin: auto;
margin-bottom: 100px;
}


.submit_button{
width: 40%;
border-style: none;
background-color: var(--themeColor);
color: white;
height:40px;
width:200px;
border-radius: 10px;
cursor: pointer;
}


.emptyDiv{
width: 80%;
border-style: solid;
height: 100px;
color: white;

}





