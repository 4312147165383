.services {
    font-family: 'Arial', sans-serif;
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    color: #333;
  }
  
  .servicestitle {
    font-size: 2.5rem;
    color: #000;
    margin-bottom: 40px;
  }
  .serviceicon img {
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  margin-bottom: 15px;
}

  .servicesgrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .servicecard {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .servicecard:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .serviceicon {
    font-size: 2.5rem;
    color: #007acc;
    margin-bottom: 15px;
  }
  
  .servicetitle {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .servicedescription {
    font-size: 1rem;
    color: #666;
    text-align: left;
    padding: 15px;
    line-height: 1.6;
  }
  
  .servicepoint {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .pointbullet {
    width: 8px;
    height: 8px;
    background-color: #007acc;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  @media (max-width: 768px) {
    .servicestitle {
      font-size: 2rem;
    }
  
    .servicecard {
      padding: 15px;
    }
  
    .servicetitle {
      font-size: 1.2rem;
    }
  
    .servicedescription {
      font-size: 0.9rem;
    }
  }
  