
.ratingContainer {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
  
  .finalRating {
    font-size: 30px;
    font-weight: bold;
  }
  
  .stars {
    margin-left: 20px;
    display: flex;
    width: 200px;
    justify-content: space-between;
  }
  
  .r1 {
    height: 30px;
    width: 30px;
    color: sandybrown;
  }
  
  .r2 {
    height: 30px;
    width: 30px;
    color: sandybrown;
  }
  
  .r3 {
    height: 30px;
    width: 30px;
    color: sandybrown;
  }
  
  .r4 {
    height: 30px;
    width: 30px;
    color: sandybrown;
  }
  
  .r5 {
    height: 30px;
    width: 30px;
    color: sandybrown;
  }
  
  .rateCards {
    margin-top: 50px;
  }
  
  .star5Container {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .stars5all {
    width: 200px;
    margin-left: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
  }
  
  .star4Container {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .star4 {
  }
  
  .stars4all {
    width: 160px;
    margin-left: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
  }
  
  .star3Container {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .star4 {
  }
  
  .stars3all {
    width: 120px;
    margin-left: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
  }
  
  .star2Container {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .star4 {
  }
  
  .stars2all {
    width: 80px;
    margin-left: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
  }
  
  .star1Container {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .star4 {
  }
  
  .stars1all {
    width: 40px;
    margin-left: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
  }
  
  .starNu {
    margin-left: 10px;
    font-weight: bold;
  }

  .TotalRating{
    margin-left: 20px;
    font-size: 20px;
    color: black;
  }