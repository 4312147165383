


.plcDataDiv{


height: 100vh;
width: 100vw;



}


.plcTitleDiv{
width: 100%;
height: 70px;
display: flex;
justify-content: center;
align-items: center;
font-size: 30px;
font-weight: bold;
background-color: lightgrey;
}


.TableDiv{

border-style: solid;
width: 90%;
min-height: 100px;
margin: auto;
border-color: lightgrey;
border-width: 1px;
margin-top:10px;
}


.oneRow{
width:90%;
height: 50px;
background-color: lightgrey;
display: flex;
margin: auto;
}




.oneRow1{
width:90%;
height: 50px;
background-color: white;
display: flex;
margin: auto;
border-style: solid;
border-color: lightgrey;
border-width: 1px;
}





.oneRowValue{
width:100%;
height: 50px;
background-color: white;
display: flex;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
}




.leftDiv{

width: 33%;	
padding-left: 20px;
align-items: center;
height: 100%;
display: flex;
}

.middleDiv{
width: 33%;
padding-left: 20px;
align-items: center;
height: 100%;
display: flex;


}


.rightDiv{
height: 100%;
width: 33%;
display: flex;
align-items: center;

}






