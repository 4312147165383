/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');
*/

.mainButtonContainer{


border-style: none;

}

.subOptions{

position: absolute;
left: 100px;
border-style: solid;
width:300px;
z-index:999999999;
}






 .sideNavBarButton {
      padding: calc( 0.20 * var(--headerHeight) );
      text-decoration: none;
      font-size: var(--sideNavBarFontSize);;
      color: var(--sideNavBarIconColor);
      background-color:#c2c3c4;
      border-style: solid;
      border-color:#757575;
      border-width: thin;
      display: flex;
      flex-direction:column;
      transition: 0.3s;
      font-weight: 100;
      margin:5px;
      width: var(--sideNavBarWidth);
      text-align: left;
      cursor: pointer;
      position:relative;
      align-items: center;
      font-family: Roboto;
      border-style: solid;
      background: red;
      /*top: 150px;*/
      width:90%;
      margin: auto;
      height:100%;
      border-radius: 10px;
      margin-bottom:10px;
      margin-top: 10px;
    }


.sideNavBarButton:hover{

/*background: linear-gradient(to right, var(--sideNavBarBtnhoverColor) 90%, var(--themeColor) 0%) !important;*/

}



.sideNavBarButton i {
      font-family: sans-serif;
      padding-left: 0px;
      font-size: calc( 0.9 * var(--sideNavBarFontSize) );
    }
    


.buttonText{
color: var(--themeColor);
vertical-align: middle;
font-size: var(--sideNavBarFontSize);
font-family: Roboto;
}


.dashIcon{
vertical-align: middle;
padding-right: 5px;
font-size:var(--sideNavBarFontSize);
color: var(--themeColor);
}










