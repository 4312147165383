.blogcontainer {
  font-family: 'Arial', sans-serif;
  color: #333;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.blogheader {
  text-align: center;
  margin-bottom: 40px;
}

.blogheader h1 {
  font-size: 2.5rem;
  color: #000;
}

.blogheader p {
  font-size: 1.2rem;
  color: #555;
}

.blogposts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.blogpost {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s, box-shadow 0.3s;
}

.blogpost:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.blogimage {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blogcontent {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.blogtitle {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.blogdate {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 15px;
}

.blogexcerpt, .blogdetails {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: auto;
}

.readmoreContainer {
  margin-top: 20px;
  text-align: right;
}

.readmoreButton {
  background-color: var(--themecolor, #007bff);
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.readmoreButton:hover {
  background-color: #0056b3;
}

/* Detailed Section Styles */
.detailsSection {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  animation: fadeIn 0.3s ease-in-out;
}

.detailsImage {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 8px;
}

.detailsTitle {
  font-size: 2rem;
  color: #000;
  margin-bottom: 15px;
}

.detailsDate {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 20px;
}

.detailsContent {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #555;
}

.backButton {
  background-color: #3c36f4;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 20px;
  transition: background-color 0.3s;
}

.backButton:hover {
  background-color: #2f55d3;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .blogposts {
    grid-template-columns: 1fr;
  }

  .detailsImage {
    height: 00px;
  }
}

/* Keyframes for Fade-in Effect */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.additionalImages {
  display: flex;
  gap: 10px;
  margin: 20px 0;
  flex-wrap: wrap;
}

.additionalImage {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
}

.videoContainer {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.video {
  width: 100%;
  max-width: 560px;
  height: 315px;
  border-radius: 8px;
}

.paragraphImage {
  width: 300px;
  height: 300px;
  margin-bottom: 10px;
}

.detailParagraph {
  margin-bottom: 20px;
}

.detailsContent p {
  text-align: justify;
}