

.oneSlot{

width: 300px;
height: 60px;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
display: flex;
align-items: center;
justify-content: space-between;
border-radius:10px;
margin-top:10px;
border-color: var(--themeColor);
background-color:var(--chatCommentBkgColor);
cursor: pointer;
margin-top: 20px;
font-size: 20px;
display: flex;
justify-content: center;
border-color: #b4cdfa;
}


.oneSlot:hover{

background-color: var(--themeColor);
color: white;
}


.oneSlot:hover .timeDiv{
color: white;

}


.bookNowButton{
width: 30%;
min-width: 120px;
border-radius: 0px 10px 10px 0px;
border-style: solid;
background-color: var(--themeColor);
color: white;
cursor: pointer;
border-color: var(--themeColor);
}


.timeDiv{

display: flex;
align-items: center;
padding-left: 10px;
color: var(--themeColor);
font-weight: bold;
}


.timeSpan{


}


.loginDiv{
position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.closeLoginButton{
border-radius: 5px;
border-style: solid;
width: 120px;
height: 70px;
background-color: white;
cursor: pointer;
border-width: 1px;
}



@media only screen and (max-width: 600px) {

.oneSlot{

height:40px;
width: 250px;
font-size: 17px;
}


}









