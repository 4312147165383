.slider {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style:none;
  margin: auto;
}

.cards {
  width: 100%;
  margin-top: 30px;
  display: flex;
  background-color: white;
}

.card {
  width: 30%;
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
  border: 1px solid var(--cardBorderColor);
}

.card:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.img {
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}

.description1 {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.description2 {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ViewToppersBtn {
  margin-top: 20px;
  width: 150px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border-radius: 20px;
  border: none;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ViewToppersBtn:hover {
  border: 1px solid var(--themeColor);
  background-color: white;
  cursor: pointer;
  color: var(--themeColor);
}

.indicatorDots {
  margin-top: 40px;
  display: flex; /* Make the dots container a flex container */
  justify-content: center; /* Center the dots horizontally */
}

.dot {
  /* Styles for regular dots */
  width: 10px;
  height: 10px;
  background-color: gray;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
}

.activeDot {
  /* Styles for active dot */
  background-color: black;
}
