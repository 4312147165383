.actionItemsParentDiv{ 
 background-color: var(--headerBkgColor);
 border-style: none;
 z-index: 9999;
}


.test{
position: absolute;
background-color: red;
top: 10px;
height:200px;
z-index: 99999;
width: 100px;
}



.UsersIcon{
    font-size: calc(  0.40 * var(--headerHeight) );
    color: var(--headerRightIconsColor);
    vertical-align:middle;
    border-style: none;
    flex-shrink: 0;
    }


.UsersIcon:hover{
   color: var(--themeColor)

}    




.ActionItemsButton{
    position: relative;
    height: calc( 0.7 * var(--headerHeight) );
    width: calc( 0.7 * var(--headerHeight) );
    border-style: none ;
    border-color: var(--headerRightIconsColor);
    border-width:calc( 0.06 * var(--headerHeight) );
    border-radius: 50%;
    background-color: var(--headerBkgColor);
    vertical-align: middle;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    }


@media only screen and (max-width: 700px) {

.ActionItemsButton{

border-style:none;
}

}






.ActionItemsButton:hover{

background-color:var(--headerRightIconsColor);

}



.alertMessage{
position: absolute;
top: calc( -0.15 * var(--headerHeight) );
left:calc( 0.3 * var(--headerHeight) );
min-width: calc( 0.244 * var(--headerHeight) );
height: calc( 0.244 * var(--headerHeight) );
font-size: calc( 0.244 * var(--headerHeight) );
background-color: #E34234;
border-radius: 50%;
border-color: var(--headerRightIconsColor);
color: white;
padding: 3px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
font-style: normal;
padding: 4px;
}








