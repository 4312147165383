
.profileParent {
  width: 100%;
}

.mainContainer {
  max-width: 1000px;
  padding: 20px;
  background-color: white;
  margin: auto; 
}

.videoSection {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 10px;
}

.mainImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.profileCardDetails {
  margin-top: 20px;
  width: 100%;
  display: flex;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rightSide {
  width: 100%;
}

.profilePic {
  width: 120px;
  height: 120px;
  border: 10px;
  margin: 20px;
}

.mainprofilePic {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 20px;
}

.bookbtn {
  width: 120px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.bookbtn:hover {
  border: 1px solid var(--themeColor);
  cursor: pointer;
  background-color: white;
  color: var(--themeColor);
}

.topContainer {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NameContainer {
  font-size: 30px;
  font-weight: bold;
}
.rattingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rattingIcon {
  height: 30px;
  width: 30px;
  color: sandybrown;
}

.ratingNumber {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
  color: var(--greyTextColor);
}

.HeartBtn {
  height: 30px;
  width: 30px;
  color: var(--greyTextColor);
}

.midContainer {
  margin: 20px;
  display: flex;
  align-items: center;
}
.location {
  height: 30px;
  width: 30px;
  color: var(--greyTextColor);
}

.locationName {
  margin-left: 10px;
  color: var(--deepDarkThemeTextColor);
}

.bottomContainer {
  margin: 20px;
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.teachesContainer {
  display: flex;
  align-items: center;
  padding-top: 8px;
  margin-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: var(--cardBorderColorLight);
  border-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.icon1 {
  height: 30px;
  width: 30px;
  border-radius: 10px;
}

.detailsContainer {
  margin-left: 10px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.heading {
  font-weight: bold;
}

.headingDetails {
  margin-top: 6px;
}

.languageContainer {
  display: flex;
  align-items: center;
  margin: 20px;
}

.languageTitle {
  font-size: 18px;
  font-weight: bold;
}

.lang {
  margin-left: 10px;
}

.aboutmeContainer {
  margin: 20px;
}

.aboutmeTitle {
  font-size: 30px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
}

.helloTitle {
  margin-top: 20px;
}

.detailsaboutme {
  margin-top: 30px;
  line-height: 26px;
}

.readmore {
  margin-top: 30px;
  font-weight: bold;
  text-decoration: underline;
  color: var(--themeColor);
}

.ratingContainer {
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.finalRating {
  font-size: 30px;
  font-weight: bold;
}

.stars {
  margin-top: 10px;
  display: flex;
  width: 200px;
  justify-content: space-between;
}

.r1 {
  height: 30px;
  width: 30px;
  color: sandybrown;
}

.r2 {
  height: 30px;
  width: 30px;
  color: sandybrown;
}

.r3 {
  height: 30px;
  width: 30px;
  color: sandybrown;
}

.r4 {
  height: 30px;
  width: 30px;
  color: sandybrown;
}

.r5 {
  height: 30px;
  width: 30px;
  color: sandybrown;
}

.rateCards {
  margin-top: 50px;
}

.star5Container {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.stars5all {
  width: 200px;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
}

.star4Container {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.star4 {
}

.stars4all {
  width: 160px;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
}

.star3Container {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.star4 {
}

.stars3all {
  width: 120px;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
}

.star2Container {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.star4 {
}

.stars2all {
  width: 80px;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
}

.star1Container {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.star4 {
}

.stars1all {
  width: 40px;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
}

.starNu {
  margin-left: 10px;
  font-weight: bold;
}


@media (max-width: 1000px) {
.profileParent{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;


}

.mainContainer{
  width: 98%;
  padding: 0px;
}

.profileCardDetails{
  display: flex;
  flex-direction: column;
}

/* .topContainer{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.ratingContainer{
  margin-top: 10px;

}
.HeartBtn{
  margin-top: 10px;

}

.bottomContainer{
  display: flex;
  flex-direction: column;
} */

}
