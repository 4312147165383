.MainIntroductionBlockParent {
  width: 100%;
  display: flex;
  background-color: var(--chatCommentBkgColor);
  background-color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.IntroductionBlockParent {
  border-style: none;
  width: 1200px;
  height: 500px;
  display: flex;
  border: 1px solid lightgray;
  padding: 20px;
  border-radius: 10px;
}

.leftBox {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  border-style: none;
}

.rightBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.img1 {
  width: 250px;
  height: 350px;
  object-fit: fill;
  margin-right: 10px;
  margin-bottom: 100px;
}

.img2 {
  object-fit: fill;

  width: 350px;
  height: 450px;
}



.imgOneBig{
  object-fit: cover; /* Ensures the image is cropped to fit its container */
  width: 100%; /* Image takes the full width of its container */
  max-width: 350px; /* Restrict maximum width */
  height: auto; /* Maintain aspect ratio */
  margin-right: 1rem; /* Add spacing for better aesthetics */
}





.mainCard {
  min-width: 350px;
  width: 100%;
  height: 350px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  border-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
}

.mainheadings{
  font-size: 65px;
  font-weight: 800;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: var(--darkTextColorDi);
  border-style: none;
  width: 100%;
  text-align: center;
}

.searchboxmaterial {
  margin-top: 20px;
  width: 90%;

  background-color: white;
  border-radius: 10px;
  height: 35px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-style: none;
}

.searchbyexamEditBox {
  border: none;
  width: 80%;
  height: 30px;
  border-radius: 10px;
  margin-left: 10px;
}

.searchbyexamEditBox:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border: none;
}

.dropdownContainer {
  width: 20%;
  height: 37px;
  background-color: var(--themeColor);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.downArrow {
  color: white;
  height: 20px;
  width: 20px;
}

.dropdownContainer:hover {
  cursor: pointer;
}

.btnTitle {
  margin-top: 20px;
  width: 350px;
  color: white;
  background-color: var(--themeColor);
  border-radius: 4px;
  border: none;
  height: 50px;
  font-size: 16px;
  /* margin-left: 100px; */
  
}

.BtnContainer{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropDownListitemmenu {
  width: 200px;
}

.btnTitle:hover {
  cursor: pointer;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  background-color: white;
}

.websiteContent {
  width: 100%;
  display: flex;
  border-style: none;
  border-color: red;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-style: none;
  color: green;
  width: 50%;
  padding-top: 50px;
}

.HomeImageContainer1 {
  width: 50%;
  display: flex;
  justify-content: start;
  margin-top: 50px;
  border-style: none;
  border-color: cyan;
}

.rightImage {
  border-style: none;
  color: orange;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 10px;
}

.instructionInfo {
  border-style: none;
  color: black;
  font-size: 18px;
  text-align: left;
  width: 100%;
}

.btnContainer {
  margin-right: 40px;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 20px;
}

.btn1 {
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.btn1:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.btn2 {
  margin-top: 16px;
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.btn2:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.btn3 {
  margin-top: 16px;
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.btn3:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.btn4 {
  font-weight: bold;
  margin-top: 16px;
  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.btn4:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.detailContainer1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-style: none;
  width: 100%;
  border-color: orange;
}

.detailContainer2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-style: none;
  width: 100%;
  border-color: orange;
}

.odisaMap {
  width: 100%;
}

.textHeading1 {
  padding-top: 90px;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* margin-top: 100px;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase; */
  background-color: transparent;
  fill: var(--themeColor);
}

.textHeading2 {
  width: 100%;
  margin-top: 50px;
  font-weight: bold;
  color: white;
  line-height: 55px;
  font-size: 40px;
  border-radius: 10px;
  text-align: left;
  color: black;
}

.textHeading3 {
  display: flex;
  justify-content: flex-start;
  border-style: none;
  width: 100%;
  border-color: red;
}

.findTopperButton {
  width: 353px;
  height: 66px;
  border-radius: 5px;
  border-style: none;
  cursor: pointer;
  color: var(--themeColor);
  font-size: 20px;
}

.findTopperButton:hover {
  color: white;
  background-color: var(--themeColor);
}

.leftTopHeadingText {
  text-align: left;
}

.textDetails {
  visibility: hidden;
  margin-top: 30px;
  color: var(--darkText);
  font-size: 18px;
  text-align: justify;
  line-height: 34px;
  font-weight: bolder;
  background-color: #e7efeb;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media (max-width: 800px) {
  .websiteContent {
    flex-direction: column;
  }
  .detailContainer {
    width: 100%;
  }

  .HomeImageContainer1 {
    width: 100%;
  }

  .textHeading2 {
    font-size: 20px;
  }

  .imgOneBig{
  width: 100%;
  margin: auto;
  display: block; 
  }

  .img1{
  display: none;
  }

  .img2{
  display: none;
  }


  .btnTitle{

  color: white;
  background-color: var(--themeColor);
  border-radius: 4px;
  border: none;
  height: 50px;
  font-size: 16px;
  margin: 0;
  }

}

.subHead{
font-weight: bold;
font-size: 20px;
color: grey;
border-style: none;
text-align: center;
margin-bottom: 20px;
}


@media (max-width: 1300px) {

.IntroductionBlockParent {
width:80%;
}	
}


@media (max-width: 1000px) {

  .MainIntroductionBlockParent{
    width: 98%;
    padding: 10px;
  }
  .IntroductionBlockParent {
  width: 100%;
  height: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .rightBox {
    width: 95%;
    display: flex;

  }

  .img1{
    padding: 20px;
    width: 130px;
    height: 220px;

  }

  .img2{
  
    width: 130px;
    height: 220px;

  }

  .leftBox{
    width: 100%;
    display: flex;
    justify-self: center;
    margin-bottom: 40px;
  }

  .mainCard{
width: 100%;
height: 100%;
min-width: 0;
  }

  .mainheadings{
   font-size: 25px;
   text-align: center;
  }

  .subHead{
    margin-top: 10px;
    text-align: center;
  }

}


@media (max-width: 787px) {
.imgOneBig{
 
}
}

@media (max-width: 768px) { 
  .imgOneBig {
    max-width: 250px;  
  }
}

@media (max-width: 480px) {  
  .imgOneBig {
    max-width: 150px;  
  }

  .btnTitle{
    width: 100%;
    height: 40px;
  }
  .leftBox{
    margin-bottom: 10px;
  }
}

