
.container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 0 15px;
}
.banner{
  display: grid;
  justify-content: center;
 
  align-items: center;
 
}

.bannerimg{
  width: 100%;
  display: flex;
  justify-content: center;
}
.bannerhead{
  font-weight: bolder;
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
}

.bannersubhead{
  text-align: justify;
  margin-left: 100px;
  margin-right: 100px;
 
}

.header {
  text-align: center;
  padding: 50px 0;
  background: #f8f9fa;
}

.para{
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--themeColor);
}

.subtitle {
  font-size: 1.25rem;
  margin-top: 10px;
  color: #7f8c8d;
}

.section {
  margin: 50px 0;
}

.sectionTitle {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #34495e;
}

.aboutContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.aboutImage {
  max-width: 100%;
  border-radius: 10px;
  width: 300px;
}

.text {
  flex: 1;
}

.cards, .serviceList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.card {
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: calc(33.33% - 20px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card img, .service img {
  max-width: 20%;
  height: auto;
  border-radius: 10px;
}

.cardTitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 10px 0;
  color: #2c3e50;
}

.cardText, .packageText {
  font-size: 0.95rem;
  color: #7f8c8d;
  margin-top: 10px;
  text-align: left;
}



/* Our Services Section */
.serviceList {
  display: flex;
  flex-wrap: nowrap;
  gap: 15px;
  overflow-x: auto;
}

.service {
  flex: 1 1 auto;
  min-width: 300px;
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
}

.service img {
  max-width: 50px;
  height: auto;
}

.serviceTitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 10px 0;
  color: #2c3e50;
}

.serviceText {
  font-size: 0.95rem;
  color: #7f8c8d;
  margin-top: 10px;
  text-align: left;
}



.package {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  
  
}
.packagebox{
  display: grid;
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 10px;
  max-width: 300px;
  color: black
}

.packageTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000000;
  text-align: center;
}

.addonText, .footerText {
  text-align: center;
  color: #434242;
  font-size: 1rem;
}

.footer {
  padding: 30px 15px;
  text-align: center;
  /* background: #000000; */
  color: #000000;
}

.footer a {
  color: #ecf0f1;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .card, .service {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .aboutContent {
    flex-direction: column;
    text-align: center;
  }

  .card, .service {
    width: 100%;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .sectionTitle {
    font-size: 1.75rem;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.75rem;
  }

  .subtitle {
    font-size: 0.9rem;
  }

  .sectionTitle {
    font-size: 1.5rem;
  }

  .footerText {
    font-size: 0.9rem;
  }

  .addonText {
    font-size: 0.9rem;
  }
}
