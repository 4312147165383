/* Parent Container */
.teamBlockParent {
  width: 100%;
  background-color: #f9f9f9;
}

/* About Section */
.topBlockAbout {
  text-align: start;
  padding: 50px 5%;
  background-color: var(--chatCommentBkgColor);
  border-radius: 12px;
}

.topBlockAbout h1 {
  font-size: 32px;
  color: var(--darkText);
}

.topBlockAbout p {
  font-size: 18px;
  color: gray;
  max-width: 800px;
  margin: auto;
}

/* Team Section */
.parentOurTeam {
  text-align: center;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 50px 5%;
}

.ourTeamTitle {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.ourTeamSubTitle {
  margin-top: 40px;
  font-weight: bold;
  color: #08203d;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

/* Team Grid - Ensures 4 cards per row on large screens */
.teamGrid {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: auto;
}

/* Highlight the important leader */
.highlightedLeader {
  margin-left: 280px;
  
  color: white;
  transform: scale(1.08);
}

.highlightedLeader h3,
.highlightedLeader p {
  color: white;
}

.highlightedLeader:hover {
  transform: scale(1.1);
}


/* Center small sections (2 or 3 members) */
.teamGrid.centered {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 300px;
  max-width: 800px;
  gap: 30px;
}

/* Team Member Card */
.teamMemberCard {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
  width: 250px;  /* Ensures cards are aligned in the center */
}

.teamMemberCard:hover {
  cursor: pointer;
  transform: scale(1.05);
}

/* Team Member Image - Crop from top & bottom */
.teamMemberCard img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center ;  
  border-radius: 12px;
}

.teamMemberCard h3 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.teamMemberCard p {
  font-size: 14px;
  color: gray;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .teamGrid {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
}

@media (max-width: 768px) {
  .teamGrid {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .teamGrid {
    grid-template-columns: 1fr;
  }
  .highlightedLeader {
    margin-left: 0px;
    
    color: white;
    transform: scale(1.08);
  }
}
