


.name_div{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-style: none;
margin-top: 10px;
position: relative;
width: 100%;
border-width: 1px;
height: 35px;
}

.input_field{
position: relative;
width: 100%;
height: 100%;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 10px 10px 10px 10px;
padding-left: 10px;
padding-right: 10px;
font-size: 16px;

}
.input_field:focus {
    border-style: solid;
    border-width: 1px;
    outline: none !important;
    border-color: var(--themeColor);
    border-radius:10px 10px 10px 10px;
}





