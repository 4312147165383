.projectMain{

    margin-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    row-gap: 20px;
    column-gap: 20px;
}


@media (max-width: 1300px) {
 
    .projectMain{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

    }
  }