.paarent {
  width: 300px;

  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 20px;
}

.logo {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.description {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}
