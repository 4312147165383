

.profileInformationAndSetting{

  background: white;
  /*display: flex;
  justify-content: space-between;
  margin-left: 30px;
  border-radius:3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  */
  /*grid-row-start:1;
  grid-row-end:4;*/
  border-style:none;
  width: 100%;
  border-radius: var(--cardBorderRadius);
}
