.appContentDiv{
/*background-color: white; /*var(--mainAppContainerBkgColor);*/
border-style: none;
border-width: 2px;
border-color: red;
width: 100% ;
height: 100%;
position: relative;
background-color: var(--bodyBkgColor);/*var(--lightThemeColor2);*/
overflow-x: hidden;
overflow-y: hidden;
border-radius: var(--cardBorderRadius) 0px 0px 0px;
}

.pwdAppDirectory{
display: flex;
align-items: center;
border-style: none;
height: calc( 0.7 * var(--headerHeight) );
}

.pwdAppDirectoryText{
font-style: normal;
margin-left: 50px;
color: grey;
font-size: 10px;
}
