
.singleContainer {
    margin: 10px;
    height: fit-content;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  
  .newsImage {
    width: 100%;
    height: 200px;
    max-height: 200px;
    min-height: 200px;
    background-color: white;
    object-fit: cover;
  }
  
  .newsTitle {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #08203d;
  }
  
  .newsDesc {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 15px;
    color: var(--lightText);
    text-align: justify;
    margin-right: 10px;
    line-height: 19px;
    height: auto;
    /* max-height: 180px;
    min-height: 180px; */
  }
  
  .readMoreBtn {
    margin-top: 20px;
    font-weight: bold;
    margin-left: 10px;
    font-size: 16px;
    color: var(--lightText);
    margin-bottom: 20px;
    color: var(--themeColor);
    padding: 6px;
    border-radius: 2px;
    border: none;
    background-color: transparent;
  }
  .readMoreBtn:hover{
    cursor: pointer;
  }
  


  
  @media (max-width: 375px) {
.newsImage{
  
  width: 100%;
  object-fit: fill;
}

  }

@media (max-width: 320px) {
.singleContainer{
  margin-left: 0;
  margin-right: 20px;
}

.newsImage{
  width: 100%;
  object-fit: fill;
  
  /* max-height: 150px; */
}
}