.card {
    width: 100%; 
    max-width: 350px; 
    height: auto; 
    padding: 10px 0px;
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start; 
    align-items: center;
    margin: 10px; 
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); 
    overflow: hidden; 

}

.img {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    border-radius: 7px 7px 0 0;
    aspect-ratio: 1/2;
}

.description1 {
    margin: 10px 0; 
    font-size: 14px; 
    text-align: center;
    
}

.title {
   
    font-size: 16px; 
    font-weight: bold;
    text-align: center;
}

.ViewToppersBtn {
    
    width: 80%;
    height: 30px; 
    background-color: var(--themeColor);
    color: white;
    border-radius: 20px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; 
}

.ViewToppersBtn:hover {
    background-color: white;
    border: 1px solid var(--themeColor);
    color: var(--themeColor);
}
