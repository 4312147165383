/* File: src/components/AddonServices.module.css */

.addonservices {
    font-family: 'Arial', sans-serif;
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    color: #333;
    /* background-color: #f9f9f9; */
  }
  
  .title {
    font-size: 2.5rem;
    color: #000;
    margin-bottom: 10px;
  }
  
  .subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 30px;
  }
  
  .servicesgrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .servicecard {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .servicecard:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .servicetitle {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .servicedescription {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
  
    .servicecard {
      padding: 15px;
    }
  
    .servicetitle {
      font-size: 1.2rem;
    }
  
    .servicedescription {
      font-size: 0.9rem;
    }
  }
  