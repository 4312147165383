.Container {
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    margin: 10px;
  }
  
  .mainImage {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 8px;
    margin-top: 10px;
    padding: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    border: 2px solid lightgray;
  }
  
  .gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .galleryitem {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    padding: 10px;
  }
  
  .galleryitem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .galleryitem:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .middleRow {
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
  }
  
  .middleRow img {
    width: calc(50% - 10px);
    height: 100%;
    object-fit: cover;
  }
  
  .circularRow {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    /* border:1px solid lightgray; */
    border-radius: 10px;
    padding: 10px;
    /* background-color: rgb(224, 225, 226); */
  }
  
  .circularFrame {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .circularFrame img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .circularFrame:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  

  


@media (max-width: 471px) {
  .circularRow{
flex-direction: column;
align-items: center;
  }

   .circularFrame img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .gallery {
    grid-template-columns: 0.5fr; 
  }
  .middleRow{
    grid-column: span 2; 
    display: flex;
    flex-direction: column;  
    justify-content: flex-start;  
    gap: 20px;  
  
  }
  .middleRow img {
    width: 100%;
    height: auto;
    object-fit: contain; 
  }
  
  .mainImage {
  height: auto;
    width: 100%;
  }
  
  .Container{
  margin-left: 0;
  padding-left: 0;
  }
  .gallery{
    padding-right: 0;
  }
  }