.mainContainer {
  
  padding: 20px;
  max-width: 1200px;
  text-align: center;
}

.title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.serviceList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.serviceItem {
  display: flex;
  align-items: center;
  background: #fff;
  margin: 20px 0;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  width: 85%;
  max-width: 900px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.serviceItem::before {
  content: "";
  position: absolute;
  top: -30px;
  left: -30px;
  width: 180px;
  height: 180px;
  background: rgba(0, 68, 255, 0.3);
  border-radius: 50%;
  z-index: 0;
}

.serviceItem::after {
  content: "";
  position: absolute;
  bottom: -40px;
  right: -40px;
  width: 200px;
  height: 200px;
  background: rgba(0, 153, 255, 0.6);
  border-radius: 50%;
  z-index: 0;
}

.serviceItem:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.serviceImage {
  width: 30%;
  height: auto;
  border-radius: 15px;
  margin-right: 25px;
  object-fit: cover;
  position: relative;
  z-index: 1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.serviceContent {
  text-align: left;
  position: relative;
  z-index: 1;
  flex-grow: 1;
}

.serviceTitle {
  font-size: 24px;
  font-weight: bold;
  color: #444;
}

.serviceDescription {
  font-size: 16px;
  font-weight: 500;
  color: #2c2b2b;
  margin-bottom: 10px;
}

.featureList {
  list-style-type: none;
  padding-left: 0;
  margin-top: 5px;
}

.featureItem {
  font-size: 16px;
  color: #2c2b2b;
  padding-left: 15px;
  position: relative;
}

.featureItem::before {
  content: "•";
  color: #007bff;
  position: absolute;
  left: 0;
  font-size: 16px;
}

@media (max-width: 768px) {
  .serviceItem {
    margin-inline-start: 32px;
    margin-inline-end: 32px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px;
    width: 95%;
  }

  .serviceImage {
    width: 40%;
    margin-bottom: 15px;
    margin-right: 0;
  }

  .serviceContent {
    text-align: center;
  }

  .serviceTitle {
    font-size: 22px;
  }

  .serviceDescription {
    font-size: 14px;
  }

  .featureItem {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .serviceItem {
    width: 90%;
    padding: 0px;
  }

  .serviceImage {
    width: 50%;
  }

  .serviceTitle {
    font-size: 20px;
  }

  .serviceDescription {
    font-size: 13px;
  }

  .featureItem {
    font-size: 12px;
  }
}
