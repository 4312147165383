
.buttonStyle2{
height: calc( 0.7 * var(--headerHeight) );
border-style: solid;
font-size: calc( 0.3 * var(--headerHeight) );
cursor: pointer;
border-width: calc( 0.04 * var(--headerHeight) );
border-color: var(--cardBorderColor);
background-color: white;
color: var(--themeColor);
font-family: Roboto;
border-radius: calc( 0.6 * var(--cardBorderRadius) );
padding-left: calc( 0.5 * var(--headerHeight) );
padding-right: calc( 0.5 * var(--headerHeight) );
border-width: 1px;
}

