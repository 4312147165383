
.contentDiv{
position: relative;
background-color: var(--bodyBkgColor);
width: 100%;
border-style: none;
border-color: orange;
height: 100%;
overflow-y: auto;
overflow-x:hidden;
}


.createSlotButton{

height: 40px;
margin-left: 30px;
margin-top: 30px;
width: 150px;
border-radius: 5px;
border-color: lightgrey;
border-style: solid;
border-width: 1px;
background-color: white;
color: var(--themeColor);
cursor: pointer;
}



.slotsContainer{

border-style: none;
width: 100%;
height: 2000px;
margin-top:30px;
}


.buttonContainer{
width: 100%;
border-style: none;
}

.slotsTitle{
border-style: none;	
font-size: 20px;
max-width: 500px;
margin: auto;
margin-bottom: 30px;

}



