.homeParent {
  width: 100%;
}

.topBar {
  width: 100%;
  height: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.National {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

.State {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

.newsBlock {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  row-gap: 10px;
  column-gap: 10px;
}

.latest {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}


.explorebutton {
  background-color: var(--themeColor); 
  color: white;
  border: none;
  padding: 12px 25px; 
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s, transform 0.3s;
  text-decoration: none; 
  display: inline-block; 
}

.explorebutton:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.dashoAppLink{
border-style: none;
background-color: white;
color: var(--themeColor);
cursor: pointer;
font-size:16px;

}


.productLinkDiv{
width: 100%;
border-style: none;
display: flex;
justify-content: center;
}
.ExamBoxSlide_img__d8kyu{
  object-fit: cover;
}
.productLinkDiv>img{
  object-fit: cover;
}



.latestTitle {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.latestsubTitle {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.panImageBkg {
  background-color: #e7efeb;
  background-repeat: no-repeat;
  height: auto;
  background-image: url("https://images.unsplash.com/photo-1523192193543-6e7296d960e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}

.newsBlock {
  width: 100%;
}

.stateNewsBlock {
  width: 100%;
}

.mainExamTitle {
  width: 100%;
  margin-top: 50px;
  font-size: 40px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--deepDarkThemeTextColor);
}

.HowitWorksContainer {
  margin-top: 50px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.HowitworksTitle {
  font-size: 40px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
}

.helloTopContainer {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: auto;
  row-gap: 30px;
  column-gap: 10px;
  border-style: none;
}

.mainWhytoppersContainer {
  margin-top: 30px;
  margin-bottom: 20px;
  background-color: var(--chatCommentBkgColor);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.whyToppersTitle {
  font-size: 40px;
  font-weight: bold;
  background-color: var(--chatCommentBkgColor);
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
}

.whyToppersContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  row-gap: 30px;
  column-gap: 30px;
  background-color: var(--chatCommentBkgColor);
}

.ExamContainer {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  row-gap: 30px;
  column-gap: 30px;
}

.SolvingItChanllengesBlockTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  font-size: 40px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
}

.bottombox {
  margin-top: 50px;
  padding-bottom: 30px;
  padding-top: 50px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--chatCommentBkgColor);
  border-style: none;

  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
}

.marginDivBottom {
  border-style: solid;
  width: 80%;
}

.bTitle {
  margin-top: 15px;
  font-size: 30px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
}

.bdetails {
  margin-top: 15px;
  font-size: 20px;
}

.bbutton {
  margin-top: 25px;
  width: auto;
  height: 40px;
  border-radius: 4px;
  background-color: var(--themeColor);
  color: white;
  font-size: 16px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;

}

.bbutton:hover {
  border: 1px solid var(--themeColor);
  background-color: white;
  cursor: pointer;
  color: var(--themeColor);
}

.paarent {
  width: 350px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 20px;
  font-family: Roboto;
  border-style: none;
  cursor: pointer;
}

.paarent:hover {
 border-style: solid;
 border-width: 1px;
 border-color: var(--themeColor);
}

.logo {
  margin-top: 30px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagesExam {
  width: 80px;
  height: 80px;
}


.imagesSAP{
width:80px;
height:50px;

}

.title {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.description {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  text-align: justify;
}


@media (max-width: 1300px) {
  .latestTitle {
    font-size: 20px;
  }

  .latestsubTitle {
    margin-top: 10px;
    font-size: 30px;
  }

  .homeParent {
    width: 98%;
  }

  .mainExamTitle {
    width: 100%;
    text-align: center;
    font-size: 25px;
  }

  .HowitworksTitle {
    width: 100%;
    text-align: center;
    font-size: 25px;
  }

  .HowitWorksContainer {
  }

  .paarent {
    width: 100%;
  }

  .whyToppersTitle {
    width: 100%;
    text-align: center;
    font-size: 25px;
  }

  .whyToppersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bottombox {
    width: 100%;
  }

  .bTitle {
    font-size: 22px;
    text-align: center;
  }
  .bdetails {
    font-size: 18px;
    text-align: justify;
  }
}

.productLinkDiv{
  
  display: flex;
  align-items: center;
}


.dasho {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.titleText {
  font-size: 40px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
  margin-bottom: 26px; 
  text-align: center;
}

.dashoAppLink {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--themeColor);
  color: white; 
  font-size: 16px; 
  font-weight: bold;
  border-radius: 5px; 
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease; 
}

.dashoAppLink:hover {
  background-color: var(--themeColor);
  color: white;
  transform: translateY(-2px); 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.dashoAppLink:active {
  transform: translateY(0); 
  box-shadow: none; 
}

.dasho img {
  width: 100%;
  max-width: 1200px; 
  height: auto; 
  object-fit: cover;
  margin-bottom: 20px; 
}

.dashoAppLink {
  border-style: none;
  background-color: white;
  color: var(--themeColor);
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
}

.dashoAppLink:hover {
  color: var(--hoverThemeColor);
  /* text-decoration: underline; */
  color: white;
}

@media only screen and (max-width: 600px) {
  .dasho img {
    width: 100%; 
  }
}


.dashoAppLink:hover {
  color: var(--hoverThemeColor);
  /* text-decoration: underline; */
  color: white;
}
@media only screen and (max-width: 600px) {
    .newsBlock {
        grid-template-columns: repeat(auto-fit, minmax(150px, auto));
    }
    .productLinkDiv {
        flex-direction: column;
        align-items: center;
    }


    .titleText{
      font-size: 20px;
    }
    .SolvingItChanllengesBlockTitle{
      font-size: 20px;
      text-align: center;

    }
}



@media (max-width: 471px) {
.homeParent{
width: 100%;
}

.IntroductionBlockParent{
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column-reverse;
}

.bdetails{
  width: 88%;
  padding: 20px;
}

}