
.whychooseus {
    font-family: 'Arial', sans-serif;
    margin: 0 auto;
    padding: 20px;
    
    width: 100%;
    color: #333;
    line-height: 1.6;
    background-color: #f4f4f9;
    
  }
  
  .headertitle {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 40px;
    background-color: #f4f4f9;
  }
  
  .highlight {
    color: var(--themeColor);
  }
  
  .features {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
    background-color: #f4f4f9;
    padding: 10px;
    
  }
  
  .featurecard {
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    flex: 1;
    max-width: 300px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .featurecard .icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .featurecard h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .featurecard p {
    font-size: 0.9rem;
    color: #666;
  }
  
  .ctasection {
    background: #003366;
    color: #fff;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
  }
  
  .ctacontent h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .ctacontent p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .ctabutton {
    background: #00c853;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .ctabutton:hover {
    background: #00b341;
  }

  @media (max-width: 768px) {

    .features {
        display: grid;
      flex-direction: column; /* Stack the feature cards */
      gap: 10px;
    }
  
    .featurecard {
      padding: 10px;
    }
  
    .headertitle {
      font-size: 1.2rem;
    }
  
    .ctacontent h3 {
      font-size: 1rem;
    }
  
    .ctacontent p {
      font-size: 0.8rem;
    }
  
    .ctabutton {
      font-size: 0.8rem;
      padding: 6px 12px;
    }
  }
  
  @media (max-width: 400px) {
    .features {
        display: grid;
      flex-direction: column; /* Stack the feature cards */
      gap: 10px;
    }
  
    .featurecard {
      padding: 10px;
    }
  
    .headertitle {
      font-size: 1.2rem;
    }
  
    .ctacontent h3 {
      font-size: 1rem;
    }
  
    .ctacontent p {
      font-size: 0.8rem;
    }
  
    .ctabutton {
      font-size: 0.8rem;
      padding: 6px 12px;
    }
  }
  