.name_div{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-style: none;
margin-top: 10px;
position: relative;
width: 100%;
}

.name_txt{
width: 100%;
position: relative;
background-color:white;
font-size: 16px;
color: grey;
font-weight: thin;
}

.name_inputDiv{
width: 100%;
border-style: none;
margin-top: 5px;
height: 35px;
}


.name_inputDiv_Paragraph{
width: 100%;
border-style: none;
margin-top: 5px;
}




.input_field  {
position: relative;
width: 100%;
height: 100%;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
font-size: 16px;
}


.input_field:focus {
    border-style: solid;
    border-width: 1px;    
    outline: none !important;
    border-color: var(--themeColor);
    border-radius:5px;
}

/*
.name_inputDivTimeField{
border-style: solid;
display: flex;
width: 100%;
margin-top: 10px;
border-width: 1px;
border-color: lightgrey;
border-radius: 5px;
height: 35px;
}
*/

.name_inputDivTimeField{
width: 100%;
border-style: none;
margin-top: 5px;
height: 35px;
display: flex;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
border-radius: 5px;
}

.name_inputDivTimeField:focus{
  border-style: solid;
  border-width: 2px;
  border-color: var(--themeColor);
  border-radius:5px;
}




.input_field_time  {
position: relative;
width: 100%;
height:35px;
font-size:18px;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
border-style: none;
-webkit-appearance: none;
-moz-appearance: none;
text-indent: 1px;
text-overflow: '';
}


.input_field_time:focus {
  border-style: none;
  border-width: 2px;
  outline: none !important;
  border-color: #719ECE;
  border-radius:5px;
}



.input_field_paragraph{
position: relative;
width: 100%;
height: 150px;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding: 10px;
font-size: 20px;
font-style: normal;
font-family: Roboto;
}

.input_field_paragraph:focus{
border-style: solid;
border-width: 1px;
outline: none !important;
border-color: var(--themeColor);
border-radius:5px;

}



.input_field_date  {
position: relative;
width: 100%;
max-width:300px;
height: 30px;
font-size:20px;
box-sizing: border-box;
}


.input_field_date:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}


.inputText_field  {
position: relative;
width: 100%;
height: 200px;
font-size:20px;
box-sizing: border-box;
}


.inputText_field:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}


.name_inputDivDayOptions{
border-style: none;
display: flex;
width: 100%;
margin-top: 10px;
}



.searchDropDown{
display: flex;
flex-direction: column;
position: absolute;
border-style: none;
border-width: 1px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
width: 100%;
top: 75px;
background-color: white;
z-index:2;
border-radius: 5px;
}


.oneSearchOptionButton{
width: 100%;
background-color: white;
height: 40px;
border-style: none;
display: flex;
justify-content: flex-start;
align-items: center;
cursor: pointer;
}


.oneSearchOptionButton:hover{

background-color: var(--cardBorderColor);

}

.input_field_searchnInsert{
position: relative;
width: 100%;
height: 40px;
font-size:20px;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
}


.input_field_searchnInsert:focus{
    border-style: solid;
    border-width: 2px;
    outline: none !important;
    border-color: #719ECE;
    border-radius:5px;



}



{/*  */}

.customTimePicker{
border-style: solid;
height: 33px;
width:100%;
border-width: 1px;
border-color: lightgrey;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
color: grey;
position: relative;
font-size: 16px;
}

/*
position: relative;
width: 100%;
height: 100%;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
*/








.customTimePicker:hover{

border-color: var(--themeColor)

}

.timeDropDownOptions{
height: 400px;
width: 100%;
background-color: white;
position: absolute;
top: 35px;
z-index: 22;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
border-radius: 0px 0px 5px 5px;
display: flex;
visibility: hidden;
}


.customTimePicker:hover .timeDropDownOptions{

visibility: visible;
}


.hourDiv{
width: 33%;
height:100%;
border-style: none;
overflow-y: scroll;
overflow-x:hidden;
}

.minDiv{
width:33%;
height:100%;
border-style: none;
overflow-y: scroll;
overflow-x:hidden;
}

.ampmDiv{
height:100%;
width: 33%;
border-style: none;
}


.oneTimeDropDownBox{
height: 30px;
display: flex;
align-items: center;
justify-content: center;
border-style:none;
width: 100%;
background-color: white;
color: var(--greyTextColor);
cursor: pointer;
}



.oneTimeDropDownBox1{
height: 30px;
display: flex;
align-items: center;
justify-content: center;
border-style:none;
width: 100%;
background-color: white;
color: var(--greyTextColor);
}


.name_txt_search{



}


.selectedSpeaker{
  border-style: none;
  width: 100%;
  color: var(--themeColor);
  margin-top: 5px;
  margin-bottom:20px;
}



.searchResultButton{
padding: 5px;
border-style: solid;
border-radius: 5px;
border-width: 1px;
border-color: lightgrey;
cursor: pointer;
}

.searchResultButton:hover{

background-color: var(--themeColor);
color: white;
border-style: none;
}



.searchResultButton:hover i{
color: white;

}


