@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap);



/*
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
*/

body {
  margin: 0;
  font-family: 'Roboto',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow-y: scroll;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}





















html{
   
     /*Core color codes------global color codes #224C98 */
     --themeColor: #094fb5;/* #224C98;/*#0c4dc4;/*#0956e3;/*#207ef5;/*#207ef5;*/
    /* #224C98;/*#207ef5;/* #224C98;/*#263c63;/* #1b3d7a;#4285F4*/
     --deepDarkThemeTextColor: #1b1e24;/*#29303d;/*#132545;/*#0e1c36;*/
     --greyTextColor: #5e5d5d;
     --cardBorderColor: #D3D3D3;
     --cardBorderColorLight : #E8E8E8;
     --greenColor1: #65c394;
     --redColor1: #ff726f;
     --chatCommentBkgColor:#E3EFFE ;

     --darkTextColorDi: #3b3838;

     
     --lightThemeColor1: #edf3fc;
     --lightThemeColor2: #f2f4f7;
     --lightThemeColor3: #dae0eb;
     --lightThemeColor4: #e9edf5;
     --tintGreyColor1: #5e5d5d;
     --tintGreyColor2: #cbd0d6;
     --tintGreyColor3: #dee0e3;
     --tintGreyColor4: #f0f2f5;
     --lightGreyTintTextColor: var(--tintGreyColor1);
     

     /*Header Colors*/
     --headerBkgColor: white;
     --headerIconColor: #8496b5;/*#9fbaed;*/
     --headerRightIconsColor: var(--deepDarkThemeTextColor);/* #9fbaed;/*#99add1;/* #9fbaed;*/
     --headerIconColorOnhover: white;
     --headerExpConBtnBkgColor: white;/*#1e458a;*/

     /*Side Nav Bar Colors*/
     --matchingTextColor1: #444655;/*use this color for all text in place of black*/
     --sideNavBarBtnhoverColor: lightgrey;/* var(--lightThemeColor3);*/

     --sideNavBarButtonTextColor: var(--deepDarkThemeTextColor);
     --sideNavBarIconColor: var(--deepDarkThemeTextColor);

     --sideNavBarBkgColor: white;/*var(--lightThemeColor3);/* #505050;*/

     --sideToolBarBkgColor: white;/*var(--lightThemeColor4);*/
     --sideToolBarBkgColor2: var(--sideNavBarBkgColor);

     /* Dashboard side nav Bar colors */
     --sideNavBarDashBoardBkgColor: var(--sideToolBarBkgColor);/* #f0f3fa;*/
     --sideNavBarDashBoardBtnColor: var(--deepDarkThemeTextColor);/*var(--darkThemeTextColor);*/
     --sideNavBarDashBoardBtnBkgColor: #f0f3fa;/*#dae0eb;*/
     --sideNavBarDashBoardOnClickBtnColor: white;
     --sideNavBarDashBoardOnClickBtnBkgColor: var(--themeColor);



     --userProfileInfoBoxBkgColor: white;   
     --mainAppContainerBkgColor: #E8E8E8;
     --ticketTextColor: #364a61;
     --TextColorDarkBlue1: #364a61;
     --clickBtnColorDash:read;/* #ff5349;*/
     --clickBtnColorDashBackground: #ffd4d1;

     /*body background color*/
     --bodyBkgColor: #f2f2f2;

     /*height, width, size settings*/
     --headerHeight:3rem;/* max( 2.5rem, calc( 0.065 * 100vh ) );*/
     --headerFontSize: calc( 0.35 * var(--headerHeight) );
     --headerLogoWidth: calc( 0.80 * var(--headerHeight));
     --headerLogoWidth: calc( 0.80 * var(--headerHeight)); 
     --headerTopMargin: 10px;
     --headerLeftMargin: 0.5%;
     --headerRightMargin: 0.5%;
     --headerTopMargin: calc( 0.17 * var(--headerHeight) ); 



     --sideNavBarWidth: calc( 4.0 * var(--headerHeight)) ;
     --sideNavBarWidthOnContract: 0px;
     --sideNavBarWidthMobile: 3.4rem;
     --sideNavBarFontSize: calc( 0.30 * var(--headerHeight) );
     --sideNavBarIconSize: calc( 0.35 * var(--headerHeight) );


     --sideToolBarHeight: calc(0.77 * var(--headerHeight));
     --topInfoBarInstructorWidth:calc( 7.0 * var(--headerHeight)); 
     --sideBarHidePoint: 850px;

      /*Top tool bar common style parameters inside the course */

      --topRightButtonWidth_Course: 6vw;
      --topRightButtonBkgColorInActive_Course: white;
      --topRightButtonTextColorInActive_Course: var(--deepDarkThemeTextColor);
      --topRightButtonBkgColorActive_Course:lightgrey;
      --topRightButtonTextColorActive_Course:var(--themeColor);
      --topRightButtonIconColorInActive:grey;
      --topRightButtonIconColorActive:var(--themeColor);
      --topRightButtonUnderlineColorActive: var(--bodyBkgColor);
      --topRightButtonUnderlineColorInActive: var(--bodyBkgColor);
      --topRightButtonTextFontSize_Course: calc(  1vw );/*calc( 0.25 * var(--headerHeight) );*/
      --topRightButtonIconFontSize_Course: calc( 1vw );/*calc( 0.30 * var(--headerHeight) );*/
      --topInnerBarWidth:80%;
      --topBarLeftTitleFontSize: calc( 0.4 * var(--headerHeight) );

      /*Top bar common style parameters in the dashboard/home*/
      --toolButtonDashBkgColorInActive: white;/*var(--lightThemeColor2);*/
      --toolButtonDashIconColorInActive: var(--deepDarkThemeTextColor);
      --toolButtonDashTextColorInActive: var(--deepDarkThemeTextColor);
      --toolButtonDashBorderColorInActive:lightgrey; 
      --toolButtonDashBoxShadowInActive:rgba(0, 0, 0, 0) 0px 0px 0px;

      --toolButtonDashBkgColorActive: white;
      --toolButtonDashIconColorActive: var(--deepDarkThemeTextColor);
      --toolButtonDashTextColorActive: var(--deepDarkThemeTextColor);
      --toolButtonDashBorderColorActive:grey; 
      --toolButtonDashBoxShadowActive:rgba(0, 0, 0, 0.16) 0px 1px 4px;
      --topRightButtonWidth_home: var(--topRightButtonWidth_Course);
      --topRightButtonTextFontSize_home: var(--topRightButtonTextFontSize_Course);
      --topRightButtonIconFontSize_home: var(--topRightButtonIconFontSize_Course);
      --toolButtonDashUnderlineColorInActive: var(--bodyBkgColor);/*var(--lightThemeColor2);*/
      --toolButtonDashUnderlineColorActive: var(--greyTextColor);

      --standardBoxShadow1: rgba(0, 0, 0, 0) 0px 0px 0px;
      --standardBoxShadow2: rgba(0, 0, 0, 0.16) 0px 1px 4px;

      /*Card specifications*/
      --cardBorderRadius:calc( 0.2 * var(--headerHeight) );


}




/*
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');

*/

/*
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Quicksand&display=swap");
*/

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}




.edrapp{

     width:100%;
     background-color: #f2f4f7;
     background-color: var(--lightThemeColor2);
     
     -ms-overflow-style: none; 
     scrollbar-width: none;
}

.edrapp::-webkit-scrollbar {
    display: none;
}



@media screen and (max-width: 1000px) {
html{
   --topRightButtonTextFontSize_Course:calc( 0.25 * var(--headerHeight) );
   --topRightButtonIconFontSize_Course: calc( 0.25 * var(--headerHeight) );
   --topRightButtonWidth_Course: 15vw;
 }

}



@media screen  and (max-width: 1400px)  {
html{
--headerHeight: 2.6rem; 

}


}





input::file-selector-button {
                 background-color: #094fb5;
                 background-color: var(--themeColor);
                 background-position-x: 0%;
                 background-size: 100%;
                 border: 0;
                 border-radius: 8px;
                 color: #fff;
                 padding: 10px;

}


@media (max-width: 471px) {
  html{
  width: 100vw !important;
  }

  .edrapp{
    width: 100%;
  }
  }





.Header_styleDivHeader__O22e7{
height: 0px;/*calc( 0.5 * var(--headerTopMargin) );*/
width:96%;
background-color: var(--headerRightIconsColor);
margin-left: 2%;
margin-top: calc( 0.5 * 0.17 * var(--headerHeight) );
position: fixed;
border-radius:  var(--headerTopMargin)  var(--headerTopMargin) 0px 0px;
}





.Header_header__1VCKf{

     background-color: var(--headerBkgColor);
     height: var(--headerHeight);
     text-align: center;
     display: flex;
     justify-content: space-between;
     margin: auto;
     line-height:var(--headerHeight);
     font-size: var(--headerFontSize);
     box-shadow:none;
     position: fixed;
     width: 100%;
     z-index:2;
     /*border-radius: var(--headerTopMargin);
     margin-left: 0.5%;
     margin-right: 0.5%;
     top:  var(--headerTopMargin);*/
     border-style: none;
     border-color: lightgrey;
     border-width: 1px;
}








.HeaderLeft_headerLeft__20zk0{

    text-align: center;
    padding-left: 20px;
    display:flex;
    justify-content: flex-start;
    align-items: center; 
    overflow: hidden;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: var(--cardBorderColor);
    }


.HeaderLeft_logo__2KFAj{/*
    width: var(--headerLogoWidth);
    height: var(--headerLogoWidth);
    border-radius: 50%;
    position: relative;
    top: 50%;
    -webkit-transform: perspective(1px) translateY(-50%);
    -ms-transform: perspective(1px) translateY(-50%);
    transform: perspective(1px) translateY(-50%);
    */}


.HeaderLeft_logoText__12JCx{
color: var(--themeColor);
font-size: calc( 0.40 * var(--headerHeight) );
margin: auto;
border-style: none;
display: flex;
justify-content: space-between;
align-items: center;
color: white;
height: calc( 0.40 * var(--headerHeight) );
border-radius: calc( 0.1 * var(--headerHeight) );
padding: calc( 0.16 * var(--headerHeight) );
position: relative;
cursor: pointer;

}   


.HeaderLeft_styleBar__38ffT{
width: 5px;
height: 3px;
background-color: var(--themeColor);
position: absolute;
top:18px;
}



.HeaderLeft_logoText__12JCx span{
margin-left: 10px;
}


@media screen and (max-width: 1000px) {
.HeaderLeft_logoText__12JCx span{
display: none;
}

}







@media only screen and (max-width: 700px) {

.HeaderLeft_logo__2KFAj{

display:none;
}

}









.HeaderLeft_courseNameButton__1tloe{
    margin-left: 20px;
    text-decoration: none;
    color: white;
    font-weight: lighter !important;
    font-style: normal;
    background-color: var(--headerBkgColor);
    /*border-style: solid;*/
    overflow: hidden;
    border-style: none;
    line-height: var(--headerHeight);
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 20px;
    }

.HeaderLeft_courseNameButton__1tloe i{
font-size: calc( 0.30 * var(--headerHeight) );
font-style: normal;
font-weight: lighter !important;
font-family: Roboto;
color: var(--deepDarkThemeTextColor);
}




.HeaderLeft_superScript__2b4Ov{
    position: relative;	
    vertical-align: top; 
    font-size: calc( 0.23 * var(--headerHeight) );
    top: -0.8em;
    font-family: Roboto; 
    }



.HeaderLeft_ExpConButton__2AUww{
height: calc( 0.7 * var(--headerHeight));
border-style: none;
background-color: var(--headerExpConBtnBkgColor);/* #2f4975;/*#294473;/*var(--headerExpConBtnBkgColor);*/ 
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
margin-left: 20px;
float: left;
border-radius: calc( 0.1 * var(--headerHeight) );
}


.HeaderLeft_ExpConButton__2AUww:hover{
background-color: lightgrey;
}


.HeaderLeft_ExpConIcon__amM7G{

font-size: calc( 0.35 * var(--headerHeight));
color: var(--deepDarkThemeTextColor);

}



.HeaderLeft_clickDash__h515Q{
color: var(--deepDarkThemeTextColor);

}

.HeaderLeft_clickDash__h515Q:hover{
cursor:pointer;
}




.HeaderLeft_backButton__3jRS_{
background-color: var(--headerBkgColor);
border-style: none;
color: var(--deepDarkThemeTextColor);
cursor: pointer;
padding-right: 20px;
}


.HeaderLeft_BackIcon__3nsU9{
font-size:calc( 0.5 * var(--headerHeight) );
}

.HeaderLeft_topperLogo__3PUgc{
width: 100px;
height:40px;

}






.CourseDropDown_DropDown__3i8Bo{

border-radius: 3px;
position: absolute;
top: var(--headerHeight);
left: 150px;
border-style: none;
box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
align-items: center;
vertical-align: middle;
z-index: 5;
width:250px;
background-color: white;
transition: height 1s;
padding-bottom: 50px;
/*max-height: 50px;
overflow-y: auto;*/
margin:auto;
padding:20px;

/*overflow-x: hidden;
overflow-y:auto;*/
}


.CourseDropDown_DropDown__3i8Bo::before {
  content: "";
  position: absolute;
  width: 0; 
  height: 0; 
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white ; 
  z-index: 9999;
  left: 50px;
}








.CourseDropDown_DropDown__3i8Bo i{
display: block;
background-color: #f1f1f1;
font-weight: lighter;
font-style: normal;
text-align: left;
font-size: 1.2rem;
border-style: none;
height: 3rem;
vertical-align: middle;
line-height: 3rem;
width: 100%;
}



.CourseDropDown_DropDown__3i8Bo i:hover{
display: block;
border-style: none;
background-color: #ddd;
font-style: normal;
font-weight: lighter;
}

.CourseDropDown_courseSelectText__1O_TE{
width: 100%;
height: 50px;
font-size: calc( 0.3 * var(--headerHeight) );
background: white;
color: var(--themeColor);
}


.CourseDropDown_courseBox__11mja{
width: 90%;
height: 50px;
background: #b8d1ff;
border-radius: 4px;
border-style: none;
border-width: 1px;
border-color: #b8d1ff;
color: var(--themeColor);
display: flex;
flex-direction: column;
align-items: center;
margin: auto;
justify-content: center;
margin-bottom: 10px;
}


.CourseDropDown_courseNameBox__2v845{
width: 70%;
text-overflow: ellipsis;
white-space: nowrap;
overflow:hidden;
}




.CourseDropDown_courseBox__11mja:hover{
color: #ff5349;	
background: #ffd4d1;
border-style: none;
cursor: pointer;
}




.CourseDropDown_topGapBox__3Jxfk{
height: 50px;
width: 100%;
background-color: white;
color: grey;
font-weight: bold;
margin-bottom: 10px;

}



.HeaderRight_headerRight__3DGSn{

overflow: hidden;
text-align: center;
padding-right: 60px;
display: flex;
align-items: center;
border-style: none;
}



.HeaderRight_tmpBtn__3zkdf{
height: calc( 0.7 * var(--headerHeight));
width: calc( 0.7 * var(--headerHeight));
border-radius: 50%;
margin-right: 20px;
background-color: var(--themeColor);
border-style: solid;
border-color: white;
border-width: initial;
}

.HeaderRight_tmpIcon__pKKD-{
font-size: calc( 0.30 * var(--headerHeight) );
color: white;
margin-left: auto;
margin-right: auto;
display: block !important;
}



.ActionItemsHead_actionItemsParentDiv__30Qjh{ 
 background-color: var(--headerBkgColor);
 border-style: none;
 z-index: 9999;
}


.ActionItemsHead_test__2tvY1{
position: absolute;
background-color: red;
top: 10px;
height:200px;
z-index: 99999;
width: 100px;
}



.ActionItemsHead_UsersIcon__3L1Ze{
    font-size: calc(  0.40 * var(--headerHeight) );
    color: var(--headerRightIconsColor);
    vertical-align:middle;
    border-style: none;
    flex-shrink: 0;
    }


.ActionItemsHead_UsersIcon__3L1Ze:hover{
   color: var(--themeColor)

}    




.ActionItemsHead_ActionItemsButton__3OKFT{
    position: relative;
    height: calc( 0.7 * var(--headerHeight) );
    width: calc( 0.7 * var(--headerHeight) );
    border-style: none ;
    border-color: var(--headerRightIconsColor);
    border-width:calc( 0.06 * var(--headerHeight) );
    border-radius: 50%;
    background-color: var(--headerBkgColor);
    vertical-align: middle;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    }


@media only screen and (max-width: 700px) {

.ActionItemsHead_ActionItemsButton__3OKFT{

border-style:none;
}

}






.ActionItemsHead_ActionItemsButton__3OKFT:hover{

background-color:var(--headerRightIconsColor);

}



.ActionItemsHead_alertMessage__2cW8j{
position: absolute;
top: calc( -0.15 * var(--headerHeight) );
left:calc( 0.3 * var(--headerHeight) );
min-width: calc( 0.244 * var(--headerHeight) );
height: calc( 0.244 * var(--headerHeight) );
font-size: calc( 0.244 * var(--headerHeight) );
background-color: #E34234;
border-radius: 50%;
border-color: var(--headerRightIconsColor);
color: white;
padding: 3px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
font-style: normal;
padding: 4px;
}











.CourseEnrollmentRequest_courseEnrollmentRequest__3aKUr{

background-color: white;
position: absolute;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding: 20px;
z-index: 99999000;
width: 200px;
top: var(--headerHeight);
border-radius: 4px;
height: 300px;
/*overflow-y: auto;
overflow-x: hidden;*/
line-height: normal;
}

.CourseEnrollmentRequest_courseEnrollmentRequest__3aKUr::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white ;
  z-index: 9999999;
  left: 7px;
}


.CourseEnrollmentRequest_allRequests__17jFD{
border-style: none;
height: 100%;
overflow-y: auto;
overflow-x: hidden;
}






.CourseEnrollmentRequest_ApproveRejectBtnDiv__Ek-tB{
display: flex;
flex-direction: column;
border-style: none;
justify-content: space-between;
height: 70px;
}



.CourseEnrollmentRequest_approveButton__1Hbz5{
border-style: none;
background-color: #65c394;
border-radius: 4px;
color: white;
padding:5px;
cursor: pointer;
}

.CourseEnrollmentRequest_approveButton__1Hbz5:hover{
background-color: #45966e;
}



.CourseEnrollmentRequest_rejectButton__3N9Kx{
border-style: none;
background-color: #ff726f;
border-radius: 4px;
color: white;
padding:5px;
cursor: pointer;
}



.CourseEnrollmentRequest_rejectButton__3N9Kx:hover{
background-color: #ad4442;
}

.CourseEnrollmentRequest_oneRequest__3IFKb{
border-style: solid;
border-width: 1px;
border-color: lightgrey;
margin-bottom: 20px;
padding: 20px;
line-height: normal;
}


.CourseEnrollmentRequest_textBox1__1566w{
font-size: 1rem;
color: grey;
}


.CourseEnrollmentRequest_textBox2__15SAP{
font-size: 1rem;
color: grey;
}


.CourseEnrollmentRequest_requesterName__2VWLD{
color: var(--themeColor);
}



.NotificationsHead_notificationItemsParentDiv__2Rmzv{
 background-color: var(--headerBkgColor);
 border-style: none;
 z-index: 9999;
 margin-left: 20px;
}






.NotificationsHead_UsersIcon__2Si8K{

    font-size: calc( 0.40 * var(--headerHeight) );
    color: var(--headerRightIconsColor);
    vertical-align:middle;
    flex-shrink: 0;
    }


.NotificationsHead_UsersIcon__2Si8K:hover{
   color: var(--themeColor)

}



.NotificationsHead_ActionItemsButton__3cXsB{
    position: relative;
    height: calc( 0.70 * var(--headerHeight) );
    width: calc( 0.70 * var(--headerHeight) );
    border-style: none;
    border-width:calc( 0.06 * var(--headerHeight) );
    border-color: var(--headerRightIconsColor);
    border-radius: 50%;
    background-color: var(--themeColor);
    vertical-align: middle;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center; 
    }



@media only screen and (max-width: 700px) {

.NotificationsHead_ActionItemsButton__3cXsB{

border-style:none;
}

}





.NotificationsHead_ActionItemsButton__3cXsB:hover{

background-color:white;

}

.NotificationsHead_alertMessage__Grz51{
position: absolute;
top: calc( -0.15 * var(--headerHeight) );
left:calc( 0.3 * var(--headerHeight) );
min-width: calc( 0.244 * var(--headerHeight) );
height: calc( 0.244 * var(--headerHeight) );
font-size: calc( 0.244 * var(--headerHeight) );
background-color: #E34234;
border-radius: 50%;
border-color: var(--headerRightIconsColor);
color: white;
padding: 3px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
font-style: normal;
padding: 4px;
}




.NotificationContainer_notificationContainer__3PcPy{
background-color: white;
position: absolute;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding: 20px;
z-index: 99999000;
width: 200px;
top: var(--headerHeight);
border-radius: 4px;
height: 150px;
/*overflow-y: auto;
overflow-x: hidden;*/
line-height: normal;
}



.NotificationContainer_notificationContainer__3PcPy::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white ;
  z-index: 9999999;
  left: 30px;
}







.NotificationContainer_oneNotification__He2Fl{



}

.MessagesHead_messagesItemsParentDiv__2p96F{
 background-color: var(--headerBkgColor);
 border-style: none;
 z-index: 9999;
 margin-left: 20px;
}



.MessagesHead_UsersIcon__LHnmt{

    font-size: calc( 0.40 * var(--headerHeight) );
    color: red;
    color: var(--headerRightIconsColor);
    vertical-align:middle;
    flex-shrink: 0;
    }


.MessagesHead_UsersIcon__LHnmt:hover{
   color: var(--themeColor)

}



.MessagesHead_ActionItemsButton__2rGcB{
    position: relative;
    height: calc( 0.7 * var(--headerHeight) );
    width: calc( 0.7 * var(--headerHeight) );
    border-style: none;
    border-width:calc( 0.06 * var(--headerHeight) );
    border-color: var(--headerRightIconsColor);
    border-radius: 50%;
    background-color: var(--themeColor);
    vertical-align: middle;
    cursor:pointer;
    display:flex;
    align-items: center;
    justify-content: center;
    }



@media only screen and (max-width: 700px) {

.MessagesHead_ActionItemsButton__2rGcB{

border-style:none;
}

}









.MessagesHead_ActionItemsButton__2rGcB:hover{

background-color:white;

}



.MessagesHead_alertMessage__15OZj{
position: absolute;
top: calc( -0.15 * var(--headerHeight) );
left:calc( 0.3 * var(--headerHeight) );
min-width: calc( 0.244 * var(--headerHeight) );
height: calc( 0.244 * var(--headerHeight) );
font-size: calc( 0.244 * var(--headerHeight) );
background-color: #E34234;
border-radius: 50%;
border-color: var(--headerRightIconsColor);
color: white;
padding: 3px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
font-style: normal;
padding: 4px;
}






.MessagesContainer_notificationContainer__2EXAW{
background-color: white;
position: absolute;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding: 20px;
z-index: 99999000;
width: 200px;
top: var(--headerHeight);
border-radius: 4px;
height: 150px;
/*overflow-y: auto;
overflow-x: hidden;*/
line-height: normal;
}



.MessagesContainer_notificationContainer__2EXAW::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white ;
  z-index: 9999999;
  left: 30px;
}







.MessagesContainer_oneNotification__2XbNk{



}

.UserHead_UserHeadDiv__3keSx{

 background-color:var(--headerBkgColor);
 display: flex;
 justify-content: space-between;
 align-items: center;
}



.UserHead_UsersIconHead__1r0zC{

    font-size: calc( 0.40 * var(--headerHeight) );
    color:white; 
    vertical-align:middle;
    }


.UserHead_UsersIconHead__1r0zC:hover{

}    



.UserHead_UserHeadButton__3ZRmg{

    /*height: calc( 0.2 * var(--headerHeight) );
    width: calc( 0.2 * var(--headerHeight) );*/
    position: relative;	
    border-style: none ;
    border-color: var(--headerRightIconsColor);
    border-width:medium;
    background-color: var(--headerBkgColor); 
    vertical-align: middle;
    cursor:pointer;
    margin-left: 40px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: space-between;
    font-family: Roboto;
    color: var(--deepDarkThemeTextColor);
    }

.UserHead_UserHeadButton__3ZRmg:hover{


}


.UserHead_userImage__DomD8{

    width: calc( 0.7 *  var(--headerLogoWidth) );
    height: calc( 0.7 * var(--headerLogoWidth) );
    border-radius: 50%;
    position: relative;
    /*following lines are to align the image vertically in the middle*/
    /*top: 50%;
    -webkit-transform: perspective(1px) translateY(-50%);
    -ms-transform: perspective(1px) translateY(-50%);
    transform: perspective(1px) translateY(-50%);
    */



}


.UserHead_UserFirstName__1kaB4{

font-style: normal;
font-weight: 400;
color: var(--deepDarkThemeTextColor);
font-size:calc( 0.7 * var(--headerFontSize) );
overflow: hidden;
}



.UserHead_userType__3V-ST{
position: absolute;
width: calc( 0.16 * var(--headerHeight) ) ;
height: calc( 0.16 * var(--headerHeight) );
top: 0px;
right:  calc( -0.13 * var(--headerHeight) );
font-size: calc( 0.23 * var(--headerHeight) );
background-color: var(--headerBkgColor);
border-radius: 50%;
color: var(--deepDarkThemeTextColor);
padding: calc( 0.05 * var(--headerHeight) );
border-style: solid;
border-width: calc( 0.04 * var(--headerHeight) );
display: flex;
align-items: center;
justify-content: center;
}


.UserHead_userType__3V-ST i{
font-style: normal;
}





@media screen and (max-width: 1000px) {

.UserHead_UserFirstName__1kaB4{
display: none;
}
}









.UserHeadDropDown_DropDown__2Z6rE{

border-radius: 3px;
position: absolute;
top: var(--headerHeight);
right: 100px;
border-style: none;
box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
align-items: center;
vertical-align: middle;
}


.UserHeadDropDown_DropDown__2Z6rE::before {
  content: "";
  position: absolute;
  width: 0; 
  height: 0; 
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid grey ; 
  z-index: 9999;
  left: 130px;
}








.UserHeadDropDown_DropDown__2Z6rE i{
display: block;
background-color: #f1f1f1;
font-weight: lighter;
font-style: normal;
text-align: left;
font-size: 1.2rem;
border-style: none;
height: 3rem;
vertical-align: middle;
line-height: 3rem;
width: 170px;
}



.UserHeadDropDown_DropDown__2Z6rE i:hover{
display: block;
border-style: none;
background-color: #ddd;
font-style: normal;
font-weight: lighter;
cursor: pointer;
}


.UserHeadDropDown_profile__1f15e{

background: linear-gradient(to top, #f1f1f1 90%, grey 0%);

}


.UserHeadDropDown_profile__1f15e:hover{
background: linear-gradient(to top, #ddd 90%, grey 0%);

}

.UserHeadDropDown_icons__2RNDE{

font-size: 1rem;
padding-left: 0.5rem;
padding-right: 0.5rem;
}









.SideBarDashboard_sideBarDashboard__2LEOr{
      height: calc( 100vh - var(--headerHeight) - var(--sideToolBarHeight)  );
      width: var(--sideNavBarWidth);
      position: fixed;
      z-index: 1;
      top:calc( var(--headerHeight) );
      left: 0.5%;
      background-color: var(--sideNavBarDashBoardBkgColor);/*var(--sideNavBarDashBoardBkgColor);*/
      /*#c2c3c4;*/
      overflow-x: hidden;
      transition: 0.5s;
      padding-top: 0px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-y: hidden;
      padding-top: 20px;
}


.SideBarDashboard_sideBarDashboard__2LEOr:hover{

overflow-y:auto;
}




/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');
*/

.SideNavBarButtonDashboard_mainButtonContainer__2Eu-B{


border-style: none;

}

.SideNavBarButtonDashboard_subOptions__3r1Zh{

position: absolute;
left: 100px;
border-style: solid;
width:300px;
z-index:999999999;
}






 .SideNavBarButtonDashboard_sideNavBarButton__M-FQB {
      padding: calc( 0.16 * var(--headerHeight) );
      text-decoration: none;
      font-size: var(--sideNavBarFontSize);
      color: var(--sideNavBarIconColor);
      /*background-color:#c2c3c4;*/
      border-style: none;
      border-color:#757575;
      border-width: thin;
      display: block;
      transition: 0.3s;
      font-weight: 100;
      margin:calc( 0.2 * var(--headerHeight) );
      text-align: left;
      cursor: pointer;
      position:relative;
      align-items: center;
      border-style: none;
      background: red;
      width:100%;
      width: calc( var(--sideNavBarWidth) - calc( 0.3 * var(--headerHeight) )  );
      height:100%;
      padding-top: calc( 0.2 * var(--headerHeight) );
      padding-bottom: calc( 0.2 * var(--headerHeight) );
      padding-left: calc( 0.2 * var(--headerHeight) );
      font-family: Roboto;
    }


.SideNavBarButtonDashboard_sideNavBarButton__M-FQB:hover{

/*background: linear-gradient(to right, var(--sideNavBarBtnhoverColor) 90%, var(--themeColor) 0%) !important;*/

}


/*
.sideNavBarButton i {
      font-style: normal;
      font-family: sans-serif;
      padding-left: 0px;
      font-size: calc( 0.9 * var(--sideNavBarFontSize) );
    }
   
*/

.SideNavBarButtonDashboard_buttonText__3fHlp{
color: var(--themeColor);
vertical-align: middle;
}


.SideNavBarButtonDashboard_dashIcon__AN3Hr{
vertical-align: middle;
padding-right: 5px;
font-size:var(--sideNavBarIconSize);
color: var(--themeColor);
margin-left: calc( 0.14 * var(--headerHeight) );
}





.QuickMeetingPage_quickMeetingPage__3dwIv{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1);
display: flex;
align-items: center;
justify-content: center;
}


.QuickMeetingPage_meetingInfoPage__2hetE{

position: relative;
width: 40vw;
height:60vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
overflow: hidden;


}

.QuickMeetingPage_closeButtonDiv__4_wBW{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.QuickMeetingPage_closeFormButton__17nm7{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.QuickMeetingPage_closeButtonIcon__35lRH{

font-size: 25px;

}

.QuickMeetingPage_meetingLink__3GcYj{

color: var(--themeColor);
text-decoration: underline;
background-color:var(--lightThemeColor1);
padding-left: 20px;
padding-right: 20px;
border-radius: 5px;
border-style: none;
margin-top: 20px;
font-size: 17px;
padding: 20px;
cursor: pointer;

white-space: pre-wrap; /* CSS3 */    
white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
white-space: -pre-wrap; /* Opera 4-6 */    
white-space: -o-pre-wrap; /* Opera 7 */    
word-wrap: break-word; /* Internet Explorer 5.5+ */
width: 80%;

}



.QuickMeetingPage_copyButton__24EGR{

margin-left: 10px;
border-style: none;
background-color: lightgrey;
padding: 5px;
border-radius: 4px;
cursor: pointer;
}


.QuickMeetingPage_startMeetingNowButton__1_yRf{
margin-top: 50px;
border-style: none;
padding: 10px;
color: white;
background-color: var(--themeColor);
font-weight: bold;
border-radius: 5px;
cursor: pointer;
}










/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');
*/

.SideNavBarButtonQMeeting_mainButtonContainer__1OwD2{


border-style: none;

}

.SideNavBarButtonQMeeting_subOptions__2EDqG{

position: absolute;
left: 100px;
border-style: solid;
width:300px;
z-index:999999999;
}






 .SideNavBarButtonQMeeting_sideNavBarButton__1mTQZ {
      padding: calc( 0.20 * var(--headerHeight) );
      text-decoration: none;
      font-size: var(--sideNavBarFontSize);;
      color: var(--sideNavBarIconColor);
      background-color:#c2c3c4;
      border-style: solid;
      border-color:#757575;
      border-width: thin;
      display: flex;
      flex-direction:column;
      transition: 0.3s;
      font-weight: 100;
      margin:5px;
      width: var(--sideNavBarWidth);
      text-align: left;
      cursor: pointer;
      position:relative;
      align-items: center;
      font-family: Roboto;
      border-style: solid;
      background: red;
      /*top: 150px;*/
      width:90%;
      margin: auto;
      height:100%;
      border-radius: 10px;
      margin-bottom:10px;
      margin-top: 10px;
    }


.SideNavBarButtonQMeeting_sideNavBarButton__1mTQZ:hover{

/*background: linear-gradient(to right, var(--sideNavBarBtnhoverColor) 90%, var(--themeColor) 0%) !important;*/

}



.SideNavBarButtonQMeeting_sideNavBarButton__1mTQZ i {
      font-family: sans-serif;
      padding-left: 0px;
      font-size: calc( 0.9 * var(--sideNavBarFontSize) );
    }
    


.SideNavBarButtonQMeeting_buttonText__30D19{
color: var(--themeColor);
vertical-align: middle;
font-size: var(--sideNavBarFontSize);
font-family: Roboto;
}


.SideNavBarButtonQMeeting_dashIcon__1b7oe{
vertical-align: middle;
padding-right: 5px;
font-size:var(--sideNavBarFontSize);
color: var(--themeColor);
}














.MainAppContainer_mainAppContainer2__2tu_5{
position: relative;
width: calc( 100% - var(--sideNavBarWidth) );
top:  calc( 1 * var(--headerHeight) );
left: var(--sideNavBarWidth);
display: grid;
grid-template-columns: 1fr;
grid-template-rows: calc(0.5 * var(--headerHeight)) calc(0.9 * var(--headerHeight)) 1fr;
grid-row-gap: 15px;
row-gap: 15px;
grid-column-gap: 15px;
-webkit-column-gap: 15px;
        column-gap: 15px;
transition: 0.5s;
border-style: solid;
}



.MainAppContainer_mainAppContainer__1_LfW{
position: relative;
/*background-color: red;#E8E8E8;*/
width: calc( 99% - var(--sideNavBarWidth)  );
top: var(--headerHeight);/* calc( var(--headerHeight) + var(--headerTopMargin) );*/
left: calc( var(--sideNavBarWidth) + 0.5% );
/*display: grid;
grid-template-columns: 1fr;
grid-template-rows: calc(0.5 * var(--headerHeight)) calc(0.9 * var(--headerHeight)) 1fr;
grid-row-gap: calc( 0.20 * var(--headerHeight) );*/
transition: 0.5s;
border-style: none;
height: calc( 100vh - var(--headerHeight) );
border-style: none;
border-color: cyan;
border-width: 1px;
overflow-x: hidden;
overflow-y: auto;
background-color: var(--bodyBkgColor);
}


.TopInfoBarUserProfile_topInfoBar__1ytIM{
background-color: #c2c3c4;
box-shadow: none;
font-family:'Roboto','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-size: large;
text-align: left;
background-color: #c2c3c4;
display: flex;
height:calc( 0.5 * var(--headerHeight) );
align-items: center;
}









.TopInfoBarUserProfile_topInfoBar__instructor__-FMIH{
position: relative;
left: calc( 1.5 * 0.50 * 0.18 * 0.5 * var(--headerHeight) );
border-bottom: calc( 0.82 * 0.5 * var(--headerHeight) ) solid var(--themeColor);
border-right: 50px solid transparent;
height: 0;
width: 300px;
color: #c2c3c4;
}



.TopInfoBarUserProfile_topInfoBar__instructor__-FMIH i{
font-style: normal;
margin-left: 50px;
font-size: calc( 0.65 * 0.82 * 0.5 * var(--headerHeight) );
}



.TopInfoBarButton_topInfoBar__profilebtn__3u_Vq{

display: flex;
position:relative;
left: calc(0.05 * 0.5 * var(--headerHeight));
min-width: calc( 2.77 * var(--headerHeight) );
text-align: center;
color: var(--sideNavBarBtnhoverColor);
border-radius: 0 50px 0 50px;
background-color:#E8E8E8;
border-style: solid;
border-width: thin;
height:80%;
font-weight: bold;
border-color: grey;
align-items: center;
justify-content: center;
font-size: calc( 0.65 * 0.82 * 0.5 * var(--headerHeight) )
}

.TopInfoBarButton_topInfoBar__profilebtn__3u_Vq:hover{

cursor: pointer;
}





.TopInfoBarInstructor_topInfoBar__instructorr__2QzU3{

 position: relative;
 height: calc( 0.82 * 0.5 * var(--headerHeight) );
 left: calc( 0.50 * 0.18 * 0.5 * var(--headerHeight) );
 width: var(--topInfoBarInstructorWidth);
 text-align: center;
 color: #c2c3c4;
 vertical-align: middle;
 background-color:var(--themeColor);
 display:flex;
 border-radius: 0 50px 0 0;
 border-style: solid;
 border-width: thin;
 border-color: var(--themeColor);
 align-items: center;
}




.TopInfoBarInstructor_topInfoBar__instructor__2jayx{
position: relative;	
left: calc( 1.5 * 0.50 * 0.18 * 0.5 * var(--headerHeight) );	
border-bottom: calc( 0.82 * 0.5 * var(--headerHeight) ) solid var(--themeColor);
border-right: 50px solid transparent;
height: 0;
width: 500px;
color: #c2c3c4;
}



.TopInfoBarInstructor_topInfoBar__instructorName__3kuz_{
overflow: hidden;
font-style: normal;
margin-left: 50px;
font-size: calc( 0.65 * 0.82 * 0.5 * var(--headerHeight) );
}

@media only screen and (max-width: 600px) {

.TopInfoBarInstructor_topInfoBar__instructor__2jayx{
display: none;
}

}








/*
.topInfoBar__instructor i{
font-style: normal;
margin-left: 50px;
font-size: calc( 0.65 * 0.82 * 0.5 * var(--headerHeight) );
}
*/



.TopTitleBar_topTitleBar__3Bu2c{

color: var(--greyTextColor);
box-shadow: none;
font-family:'Roboto','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-size: calc(0.45 * var(--headerHeight));
vertical-align: middle;
/*margin-left: 30px;*/
display: flex;
align-items: center;
height: calc( 1.2 * var(--headerHeight) );
border-style: none;
border-color: red;
}

.TopTitleBar_topTitleBar__Text__16Wv7{

margin-left: 30px;
font-style: normal;
font-family: Roboto;
}


.TopTitleBar_backButton__1F3Qs{

margin-right: 20px;
height: 20px;
border-style: none;
font-weight: bold;
color: var(--themeColor);
background-color: lightgrey;
}


.TopTitleBar_backButton__1F3Qs:hover{

cursor: pointer;
}



.TopTitleBar_backIcon__2TcRR{

font-size: 30px;

}



.ContentDivUserProfile_contentDivUserProfile__2w5IQ{
display: flex;
justify-content: space-between;
border-style: none;
border-color: orange;
padding-bottom: 20px;
/*
display: grid;
grid-template-columns: 1fr 2.3fr;
grid-template-rows: 1fr 1fr ;
grid-gap: 10px;
padding-bottom: 50px;
padding-right:20px;
*/




}



@media only screen and (max-width: 1100px) {
 .ContentDivUserProfile_contentDivUserProfile__2w5IQ{
     display: flex;
     flex-direction: column;
 }
}






.ProfileImageSec_profileImageSec__3DM82{


  display: flex;
  justify-content: flex-start;
  border-style: none;
  border-color: orange;
  border-width: 1px;
  width: 30%;
  margin-left: 30px;
  flex-direction: column;
  

}


.ProfileImageSec_unitColumn__2udlL{

width: 100%;
border-style: solid;
margin-bottom: 30px;
background-color: white;
border-color:var(--cardBorderColor);
border-width: 1px;
border-radius: var(--cardBorderRadius);
}


.ProfileImageSec_unitColumn__2udlL:before {/*
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(5/3 * 100%);
  background-color:var(--themeColor);
*/}






@media only screen and (max-width: 1100px) {

.ProfileImageSec_profileImageSec__3DM82{

width: 95%;
margin: auto;

}

.ProfileImageSec_unitColumn__2udlL{



}


}









.ProfileImageSec_profileImageSec__3DM82:before {/*
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(5/3 * 100%);
  background-color:var(--themeColor);
*/}


.ProfileImageSec_profileImageSec__pseudo__3v-Ga{

  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: var(--cardBorderRadius);
  display: flex;
  flex-direction: column;
}






.ProfileImageSec_innerColumn__1RKjr{

height: 80%;
width: 80%;
border-style: solid;
margin: auto;
margin-top: 10%;
border-width: 1px;
border-color: lightgrey;
}

.ProfileImageSec_ProfileInfo2_titleBar__2mVpb{


width: 100%;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
}

.ProfileImageSec_ProfileInfo2_titleBar__2mVpb i{
font-style: normal;
color: grey;
}



.ProfileImageSec_yourlinks__1ou3s{

width: 100%;
display: flex;
justify-content:center;
}

.ProfileImageSec_youtubeLink__1aga3{

color: red;
padding-right: 20px;
font-size: 25px;
}

.ProfileImageSec_linkedInLink__2Mp0g{
padding-right: 20px;
font-size: 25px;
color: #0072b1;
}


.ProfileImageSec_twitterLink__dPWZX{
font-size: 25px;
color: #00acee;
}









.ProfileImage_profileImageDiv__3pizm{

 position:relative;
 height:70%;
 padding: 10%;
 background-color:white;
 display: flex;
 flex-direction: column;
 border-radius: var(--cardBorderRadius);

}




.ProfileImage_userImage__1yoyd{
 position: relative;
 width: 100%;
 aspect-ratio:1/1; 
 border-radius: var(--cardBorderRadius);
}

.ProfileImage_changePicButton__1yxCV{
border-style: none;
height: 40px;
border-radius: var(--cardBorderRadius);
}


.ProfileImage_changePicButton__1yxCV:hover{
color: white;
background-color:var(--themeColor);
cursor: pointer;

}







.ProfileImage_imageUpload__3vHio{

width: 100%;
cursor:pointer;
width: 200px;
margin: auto;
}
.ProfileImage_image_field__3Rffp{
width:100%;
height: 100%;
text-decoration: none;
}


.ProfileImage_submit_button__3Duir{
width: 100%;
color: white;
background-color:var(--themeColor);
border-style: none;
margin-top:20px;
height:30px;
border-radius: var(--cardBorderRadius);
}

.ProfileImage_submit_button__3Duir:hover{
cursor: pointer;


}




.ProfileImageInfo_profileImageInfo__1PcDZ{
height: 40%;
padding:10%;
padding-top: 0px;
background-color: white;
text-align: center;
color: black;
display: flex;
flex-direction: column;
border-style: none;
border-radius: var(--cardBorderRadius);
}

.ProfileImageInfo_nameDiv__2QAq1{
height: 40%;
background-color: white;
}

.ProfileImageInfo_name_i__3SEsE{

font-style: normal;
color:var(--greyTextColor)
}

.ProfileImageInfo_profileImageInfo_position__2k4-Q{

height:40px;
background:linear-gradient(to top, white 98%, lightgrey 0%);
display: flex;
justify-content: space-between;
align-items: center;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}

.ProfileImageInfo_profileImageInfo_position__2k4-Q i{

font-style: normal;
position:relative;
left: 10px;
width: 100px;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}

.ProfileImageInfo_roleTxt__1clIE{

float: left;
}



.ProfileImageInfo_profileImageInfo_joiningDate__2lG62{

height:40px;
background:linear-gradient(to top, white 98%, lightgrey 0%);
display: flex;
justify-content: space-between;
align-items: center;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}


.ProfileImageInfo_profileImageInfo_joiningDate__2lG62 i{
font-style: normal;
position:relative;
left: 10px;
width: 100px;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}


.ProfileImageInfo_joinTxt__2R5YO{

float: left;

}




.ProfileImageInfo_profileImageInfo_status__18e3V{
height:40px;
background:linear-gradient(to top, white 98%, lightgrey 0%);
display: flex;
justify-content: space-between;
align-items: center;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}

.ProfileImageInfo_profileImageInfo_status__18e3V i{

font-style: normal;
color: white;
background-color: #4CBB17;
padding: 2px;
position:relative;
width: 100px;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}





.ProfileUnitBlock_profileImageSec__18YpO{


  background: white;
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  border-radius:var(--cardBorderRadius);
  /*box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
  width: 70%;
  margin-right: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--cardBorderColor);

}




@media only screen and (max-width: 1100px) {

.ProfileUnitBlock_profileImageSec__18YpO{

width: 95%;
margin: auto;
}


}









.ProfileUnitBlock_profileImageSec__18YpO:before {/*
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(5/3 * 100%);
  background-color:var(--themeColor);
*/}


.ProfileUnitBlock_profileImageSec__pseudo___9Z-Q{
/*
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
*/
}




.ProfileInformationAndSetting_profileInformationAndSetting__3nQOM{

  background: white;
  /*display: flex;
  justify-content: space-between;
  margin-left: 30px;
  border-radius:3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  */
  /*grid-row-start:1;
  grid-row-end:4;*/
  border-style:none;
  width: 100%;
  border-radius: var(--cardBorderRadius);
}



.UserProfileContentBasic_userProfileContentBasic__2DEOg{
width: 100%;
height: calc( 100% - calc( 0.6 * var(--headerHeight) ) );
border-style: none;
border-radius: var(--cardBorderRadius);
}




.AboutBasic_about_Basic__1JInm{


width: 100%;
background-color: white;
display: flex;
align-items: center;
flex-direction: column;
border-style: none;
border-color: red;
border-radius:var(--cardBorderRadius);

}


.AboutBasic_aboutInfoContainer__3z9MS{

width:93%;
display: flex;
align-items: center;
background-color: #E8E8E8;
background-color:var(--userProfileInfoBoxBkgColor);
border-style: none;
border-width: 1px;
border-color: #E8E8E8;
border-color:green;
border-radius:3px;

}



.AboutBasic_aboutInfoContainerEd__2qK-Z{

width:93%;
display: flex;
flex-direction: column;
align-items: center;
background-color: #E8E8E8;
background-color:var(--userProfileInfoBoxBkgColor);
border-style: none;
border-width: 1px;
border-color: #E8E8E8;
border-color:green;
border-radius:3px;


}












@media only screen and (max-width: 1100px) {
 .AboutBasic_aboutInfoContainer__3z9MS{
     display: flex;
     justify-content: space-between;
     flex-direction: column;
     width: 90%;
     margin: auto;
     border-style: none;
     border-color: cyan;
     background-color: cyan;
 }



}








.AboutBasic_InfoBoxLeft__xaS49{
width: 100%;
background-color: white;
}


.AboutBasic_InfoBoxRight__2ZW9f{
width: 100%;
border-style: none;
background-color: white;
}



.AboutBasic_fieldTitle__bvW3j{
color: grey;
width: 120px;
}

.AboutBasic_oneFieldInfo__3lS2A{
height: 35px;
display: flex;
justify-content: flex-start;
align-items: center;
}


.AboutBasic_fieldValue__9VyFq{
margin-left: 10px;
}









.UnitBar_unitBar__342j0{

width: 93%;
height: calc( 0.8 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
border-color: grey;

}


.UnitBarFirstName_unitBarFirstName__lvdHB{

width: 93%;
height: calc( 0.5 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
border-color: grey;
display: flex;
align-items: center;
margin-top:10px;
margin-bottom: 10px;
}


.UnitBarFirstName_firstNameTitle__412C8{

width:30%;
font-size:calc( 0.25 * var(--headerHeight) );
border-style: none;
border-width:1px;
height:100%;
display: flex;
align-items: center;
border-color: #E8E8E8;
border-color:lightgrey;
position: relative;
padding-left: 20px;
color: grey;
font-weight:bold;
}

.UnitBarFirstName_firstName__1P_Xf{
width:70%;
height: 100%;
font-size:calc( 0.25 * var(--headerHeight) );
text-align: left;
display: flex;
align-items: center;
background-color: var(--userProfileInfoBoxBkgColor);
border-style: none;
border-color:lightgrey;
border-width:1px;
position: relative;
padding-left:10px;
}



.UnitBarGenDOB_unitBarGenDOB__gK5fn{

width: 93%;
height: calc( 0.7 * var(--headerHeight) );
background-color:#E8E8E8;
background-color:var(--userProfileInfoBoxBkgColor);
border-style: none;
border-width: 1px;
border-color: grey;
display: flex;
align-items: center;
margin-top:10px;
margin-bottom: 10px;
justify-content: space-between;
}


.UnitBarGenDOB_genderDiv__2XT16{

width:45%;
font-size:calc( 0.25 * var(--headerHeight) );
border-style: none;
border-width:1px;
height:100%;
display: flex;
align-items: center;
border-color:#E8E8E8;
border-color:lightgrey;
position: relative;
padding-left: 20px;
color: grey;
background-color: white;
}

.UnitBarGenDOB_genderDiv__2XT16 i{

font-style: normal;
padding-left: 20px;
}



.UnitBarGenDOB_dobDiv__2542v{
width:45%;
height: 100%;
font-size:calc( 0.25 * var(--headerHeight) );
text-align: left;
display: flex;
align-items: center;
background-color: white;
border-style: solid;
border-color:#E8E8E8;
border-color:lightgrey;
border-width:1px;
color: #454444;
padding-left: 20px;
}

.UnitBarGenDOB_dobDiv__2542v i{
font-style: normal;
padding-left: 20px;
overflow: auto;

}










.UnitAboutIcon_unitAboutIcon__1aZSo{

width: 93%;
height: calc( 0.8 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
display: flex;
align-items: center;
font-size: calc( 0.6 * var(--headerHeight) );
justify-content: space-between;

}

.UnitAboutIcon_unitAboutIcon__1aZSo div{
display: flex;
align-items: center;
}


.UnitAboutIcon_aboutIcon__2qK4s{
left: calc( 0.3 * var(--headerHeight) );

}


.UnitAboutIcon_unitAboutIcon__1aZSo i{
font-size: calc( 0.3 * var(--headerHeight) );
font-style: normal;
margin-left: 10px;
}

.UnitAboutIcon_editButtonAbout__1sZ4l{
left: calc( 0.3 * var(--headerHeight) );
background-color: white;
border-style: solid;
border-width: 1px;
border-radius: 5px;
padding:5px;
padding-left: 10px;
padding-right: 10px;
cursor: pointer;
color: var(--themeColor);
border-color: var(--cardBorderColor);
}


.UnitAboutIcon_editButtonAbout__1sZ4l:hover{
color:white;
background-color:var(--themeColor);

}












.AboutEditForm_aboutEditFormDivParent__1s23T{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;
}

.AboutEditForm_aboutEditForm__15AlT{

position: relative;
width: 100%;
height:80vh;
max-width:500px;
background-color: white;
border-radius: 5px;
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
overflow: auto;
}















.AboutEditForm_closeButtonDiv__BwRpY{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.AboutEditForm_closeFormButton__aT539{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.AboutEditForm_closeButtonIcon__obepP{

font-size: 25px;

}


.AboutEditForm_logoAndTitleContainer__3C0V1{


height:150px;
width:80%;
background-color: white;
display: flex;
flex-direction: column;
margin-bottom: 50px;
}

.AboutEditForm_bwLogo__2ljgi{

height: 75px;
width: 75px;

}

.AboutEditForm_titleDiv__vULxh{
width: 100%;
display:flex;
justify-content:flex-start;
color: #585858;
}

.AboutEditForm_titleDiv__vULxh i{

font-size: 20px;
}


.AboutEditForm_name_div__kod4J{

display: flex;
flex-direction: column;
width:80%;
align-items: center;
justify-content: center;
margin: 10px;
}

.AboutEditForm_name_txt__2ewTD{
width: 100%;
position: relative;
background-color:white;
font-size: 20px;
color: grey;
font-weight: thin;
}

.AboutEditForm_name_inputDiv__1p0EX{
width: 100%;
}

.AboutEditForm_value_field__1fUqO{
height: 30px;
border-style: solid;
border-color: lightgrey;
width: 100%;
font-size: 18px;
border-radius: 10px;
padding-left: 10px;
}


.AboutEditForm_genpos_div__3BiMu{

display: flex;
flex-direction: row;
width:80%;
align-items: center;
justify-content: space-between;
margin: 20px;
}


.AboutEditForm_gender_div__2Ec8R{
display: flex;
flex-direction: column;
width: 45%;
background-color: red;
border-radius: 5px;
}


.AboutEditForm_position_div__wAw30{
display: flex;
flex-direction: column;
width: 45%;
background-color: green;
border-radius: 5px;
border-style: none;
}



.AboutEditForm_genpos_field__1d_ze{
width:100%;
height: 40px;
font-size: 20px;
padding: 5px;
}


.AboutEditForm_dob_div__3vnx5{
width: 80%;
height: 90px;
display: flex;
flex-direction: column;
background-color: white;
justify-content: flex-start;
align-items: flex-start;
}

.AboutEditForm_dob_txt__kk4Z8{
width: 100%;
position: relative;
background-color:white;
font-size: 20px;
color: grey;
font-weight: thin;

}

.AboutEditForm_dob_innerDiv__2Eo-d{
width: 100%;
position: relative;

}

.AboutEditForm_dob_innerDiv2__3wJvh{

position: relative;
width: 45%;

}


.AboutEditForm_dateofbirth_field__1LUyE{
position: relative;
width: 100%;
padding: 5px;
height: 30px;
font-size:20px;
min-width:200px;
}

.AboutEditForm_firstname_field__3QmwF , .AboutEditForm_lastname_field__aWN3y,.AboutEditForm_gender_field__l51CI,.AboutEditForm_position_field__1KRWz {
position: relative;
width: 100%;
padding: 5px;
height: 30px;
font-size:23px;
}


.AboutEditForm_submitButtonDiv__2AXNc{
width: 80%;	
display: flex;
justify-content: center;
background-color: white;
padding-top: 50px;
}


.AboutEditForm_submit_button__3skn3{
width: 300px;
border-style: none;
background-color: var(--themeColor);
color: white;
height:50px;
border-radius: 10px;
cursor: pointer;
margin-bottom: 50px;
font-size: 20px;
}






.EduDegreeForm_createTicketFormDivParent__2mtT6{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;
}

.EduDegreeForm_createTicketForm__18xRE{

position: relative;
width: 100%;
max-width:700px;
max-height: 90vh;
background-color: white;
border-radius: var(--cardBorderRadius);
border-style: none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
align-items: center;
overflow-y: scroll;
}


.EduDegreeForm_input_field__32TSc{
height: 40px;
margin:auto;
margin-left: 50px;
}




.EduDegreeForm_dateFields__3ZMe2{
display: flex;
width: 75%;
margin: auto;
}




.EduDegreeForm_createTicketFormLoading__Rs7Ag{

position: relative;
width: 60vw;
height: 80vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style: none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}


.EduDegreeForm_submittingDiv__1Kxks{
padding-top: 100px;	
color: var(--themeColor);

}









.EduDegreeForm_closeButtonDiv__yjaDD{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.EduDegreeForm_closeFormButton__29x7m{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.EduDegreeForm_closeButtonIcon__3g2UJ{

font-size: 25px;

}


.EduDegreeForm_logoAndTitleContainer__2ycSA{


height:150px;
width:80%;
background-color: white;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-style: none;
margin: auto;
}

.EduDegreeForm_bwLogo__1RQI7{

height: 75px;
width: 75px;

}

.EduDegreeForm_formTitleDiv__IYr7N{
width: 100%;
display:flex;
justify-content:flex-start;
color: #585858;
font-size: 30px;
margin: auto;
}

.EduDegreeForm_formTitleDiv__IYr7N i{

font-size: 25px;
font-style: normal;
}



.EduDegreeForm_submitButtonDiv__2gzFY{
width: 80%;	
display: flex;
justify-content: center;
background-color: white;
padding-top: 50px;
justify-content: center;
border-style: none;
margin: auto;
margin-bottom: 100px;
}


.EduDegreeForm_submit_button__3Hv9s{
width: 250px;
border-style: none;
background-color: var(--themeColor);
color: white;
height:50px;
border-radius: 10px;
cursor: pointer;
font-size: 20px;
}


.EduDegreeForm_emptyDiv__14X6c{
width: 80%;
border-style: solid;
height: 100px;
color: white;

}



.EduDegreeForm_name_div__256AX{

display: flex;
flex-direction: column;
width:80%;
align-items: center;
justify-content: center;
margin: auto;
margin-bottom: 20px;
}

.EduDegreeForm_name_txt__1PStr{
width: 100%;
position: relative;
background-color:white;
font-size: 20px;
color: grey;
font-weight: thin;
}

.EduDegreeForm_name_inputDiv__3LTLG{
width: 100%;
}

.EduDegreeForm_value_field__ziD-q{
height: 30px;
border-style: solid;
border-color: lightgrey;
width: 100%;
font-size: 18px;
border-radius: 10px;
padding-left: 10px;
}

.EduDegreeForm_yearsDiv__Rm1d7{
width: 80%;
margin: auto;
}



.UnitBarInstDegree_unitBarInstDegree__3RKJ5{
width: 90%;

border-style: solid;
border-width: 1px;
border-color: lightgrey;
display: flex;
margin-top: 20px;
padding-left: 20px;
padding-top:10px;
padding-bottom:10px;
border-radius: 10px;
justify-content: space-between;
align-items: center;
}


.UnitBarInstDegree_InstLogo__izn43{

width: 80px;
height: 80px;
margin-left: 30px;
}

.UnitBarInstDegree_InstInfo__2UQWX{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0px;

}


.UnitBarInstDegree_InstInfo__degree__1Pv_b{
  color: black;
  font-weight: bold;
  font-size:20px;
}

.UnitBarInstDegree_InstInfo__duration__gS49J{
 color: var(--themeColor);
}


.UnitBarInstDegree_InstInfo__location__36cQ-{
  color: grey;
}



.UnitBarInstDegree_logoImage__27it5{

width:60px;
height: 60px;

}


.UnitBarInstDegree_deleteButton__1BWbb{
height: 30px;
margin-right:10px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
}






.UnitBarInstDegree_unitBarInstDegree__1UfVW{
width: 100%;
border-style: none;
display: flex;
margin-top: 20px;
flex-direction: row;
justify-content: space-between;
}

.UnitBarInstDegree_degreeInfoall__1iicU{

display: flex;
}



.UnitBarInstDegree_InstLogo__er9Mh{

width: 80px;
height: 80px;
margin-left: 30px;
}

.UnitBarInstDegree_InstInfo__2pIoz{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0px;

}


.UnitBarInstDegree_InstInfo__degree__3V_lL{
  color: #4e4e52;
}

.UnitBarInstDegree_InstInfo__duration__OVj2c{
 color: grey;
}


.UnitBarInstDegree_InstInfo__location__n2anI{
  color: grey;
}



.UnitBarInstDegree_logoImage__1YzeM{

width:60px;
height: 60px;

}



.UnitBarInstDegree_addressType__1rewm{
width: 150px;
margin-left: 30px;

}


.UnitBarInstDegree_deleteDegree__20qny{
font-size: 25px;
color: grey;
cursor: pointer;
height: 30px;
border-style: none;
}


.UnitBarInstDegree_addMainContainer__25aJ3{

display: flex;

}





.UnitAboutIcon_unitAboutIcon__29QqN{

width: 93%;
height: calc( 0.8 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
display: flex;
align-items: center;
font-size: calc( 0.6 * var(--headerHeight) );
justify-content: space-between;

}

.UnitAboutIcon_unitAboutIcon__29QqN div{
display: flex;
align-items: center;
}


.UnitAboutIcon_aboutIcon__32OPT{
left: calc( 0.3 * var(--headerHeight) );

}


.UnitAboutIcon_unitAboutIcon__29QqN i{
font-size: calc( 0.3 * var(--headerHeight) );
font-style: normal;
margin-left: 10px;
}

.UnitAboutIcon_editButtonAbout__1GR8B{
left: calc( 0.3 * var(--headerHeight) );
background-color: white;
border-style: solid;
border-radius: 5px;
border-width: 1px;
padding:5px;
padding-left: 10px;
padding-right: 10px;
cursor: pointer;
color: var(--themeColor);
border-color: var(--cardBorderColor);
}


.UnitAboutIcon_editButtonAbout__1GR8B:hover{
background-color: var(--themeColor);
color: white;

}









.EduDegreeForm_createTicketFormDivParent__1k6Vi{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;
}

.EduDegreeForm_createTicketForm__350E5{

position: relative;
width: 100%;
max-width:700px;
max-height: 90vh;
background-color: white;
border-radius: var(--cardBorderRadius);
border-style: none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
align-items: center;
overflow-y: scroll;
}


.EduDegreeForm_input_field__3MGoj{
height: 40px;
margin:auto;
margin-left: 50px;
}




.EduDegreeForm_dateFields__2mYFt{
display: flex;
width: 75%;
margin: auto;
}




.EduDegreeForm_createTicketFormLoading__1NLSI{

position: relative;
width: 60vw;
height: 80vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style: none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}


.EduDegreeForm_submittingDiv__21a77{
padding-top: 100px;	
color: var(--themeColor);

}









.EduDegreeForm_closeButtonDiv__14-9F{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.EduDegreeForm_closeFormButton__2pK_u{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.EduDegreeForm_closeButtonIcon__1uBM0{

font-size: 25px;

}


.EduDegreeForm_logoAndTitleContainer__2tCSr{


height:150px;
width:80%;
background-color: white;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-style: none;
margin: auto;
}

.EduDegreeForm_bwLogo__30hRQ{

height: 75px;
width: 75px;

}

.EduDegreeForm_formTitleDiv__ZwbNi{
width: 100%;
display:flex;
justify-content:flex-start;
color: #585858;
font-size: 30px;
margin: auto;
}

.EduDegreeForm_formTitleDiv__ZwbNi i{

font-size: 25px;
font-style: normal;
}



.EduDegreeForm_submitButtonDiv__1w4F3{
width: 80%;	
display: flex;
justify-content: center;
background-color: white;
padding-top: 50px;
justify-content: center;
border-style: none;
margin: auto;
margin-bottom: 100px;
}


.EduDegreeForm_submit_button__2cmX5{
width: 40%;
border-style: none;
background-color: var(--themeColor);
color: white;
height:40px;
width:200px;
border-radius: 10px;
cursor: pointer;
}


.EduDegreeForm_emptyDiv__13Q1p{
width: 80%;
border-style: solid;
height: 100px;
color: white;

}






.FormInputObjects_name_div__lxQPN{

display: flex;
flex-direction: column;
width:80%;
align-items: center;
justify-content: center;
margin: 10px;
}

.FormInputObjects_name_txt__26qTD{
width: 100%;
position: relative;
background-color:white;
font-size: 23px;
color: grey;
font-weight: thin;
}

.FormInputObjects_name_inputDiv__JQ0xn{
width: 100%;
}

.FormInputObjects_input_field__26Tit  {
position: relative;
width: 100%;
height: 30px;
font-size:20px;
box-sizing: border-box;
}



.FormInputObjects_input_field__26Tit:focus {
    border-style: none;   	
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}









.FormInputObjects_input_field_date__1LR2s  {
position: relative;
width: 100%;
max-width:300px;
height: 30px;
font-size:20px;
box-sizing: border-box;
}


.FormInputObjects_input_field_date__1LR2s:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}






.FormInputObjects_inputText_field__3SUOc  {
position: relative;
width: 100%;
height: 200px;
font-size:20px;
box-sizing: border-box;
}



.FormInputObjects_inputText_field__3SUOc:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}













.MainAppContainer_mainAppContainer__2amSx{
position: relative;
/*background-color: red;#E8E8E8;*/
width: calc( 99% - var(--sideNavBarWidth)  );
top: var(--headerHeight);/* calc( var(--headerHeight) + var(--headerTopMargin) );*/
left: calc( var(--sideNavBarWidth) + 0.5% );
/*display: grid;
grid-template-columns: 1fr;
grid-template-rows: calc(0.5 * var(--headerHeight)) calc(0.9 * var(--headerHeight)) 1fr;
grid-row-gap: calc( 0.20 * var(--headerHeight) );*/
transition: 0.5s;
border-style: none;
height: calc( 100vh - var(--headerHeight) );
min-width: 400px;
border-style: none;
border-color: green;
border-width: 1px;
overflow-x: hidden;
overflow-y: hidden;
}




.GeneralContentDiv_contentDiv__3RQmf{
position: relative;
border-style: none;
border-width: 1px;
border-color: cyan;
width: 100% ;
height: 100%;
/*border-radius: 10px 0px 0px 0px;*/
overflow-x: hidden;
overflow-y: auto;
}


.GeneralContentDiv_createDocButton__KpwHW{

margin-top:30px;
margin-left: 30px;
padding: 10px;
border-radius: 5px;
border-style: solid;
border-color: lightgrey;
border-width: 1px;
width: 160px;
cursor: pointer;
background-color: white;
color: var(--themeColor);
}

.GeneralContentDiv_documentArea__1v7l3{


width:98%;
height:90%;
border-style: none;
margin: auto;
display: flex;
border-color: red;
}

.GeneralContentDiv_documentNames__2fTVZ{
width: 200px;
height: 100%;
border-style: solid;
border-color: grey;
}

.GeneralContentDiv_documentTextArea__1QzG3{

width: calc( 100% - 250px );
height: 100%;
border-style: none;
display: flex;
flex-direction: column;
}

.GeneralContentDiv_saveButton__3PihL{

height: 40px;
width: 150px;
background-color: var(--themeColor);
cursor:pointer;
}




.GeneralContentDiv_textAreaInput__2xbpA{
width: 100%;
height: 95%;
padding: 20px;
max-width: 1000px;
font-size: 20px;
}





.AppContentDiv_appContentDiv__1bhe2{
/*background-color: white; /*var(--mainAppContainerBkgColor);*/
border-style: none;
border-width: 2px;
border-color: red;
width: 100% ;
height: 100%;
position: relative;
background-color: var(--bodyBkgColor);/*var(--lightThemeColor2);*/
overflow-x: hidden;
overflow-y: hidden;
border-radius: var(--cardBorderRadius) 0px 0px 0px;
}

.AppContentDiv_pwdAppDirectory__2FivM{
display: flex;
align-items: center;
border-style: none;
height: calc( 0.7 * var(--headerHeight) );
}

.AppContentDiv_pwdAppDirectoryText__2cJTq{
font-style: normal;
margin-left: 50px;
color: grey;
font-size: 10px;
}


.SlotsContentDiv_contentDiv__3mtku{
position: relative;
background-color: var(--bodyBkgColor);
width: 100%;
border-style: none;
border-color: orange;
height: 100%;
overflow-y: auto;
overflow-x:hidden;
}


.SlotsContentDiv_createSlotButton__yPOqi{

height: 40px;
margin-left: 30px;
margin-top: 30px;
width: 150px;
border-radius: 5px;
border-color: lightgrey;
border-style: solid;
border-width: 1px;
background-color: white;
color: var(--themeColor);
cursor: pointer;
}



.SlotsContentDiv_slotsContainer__nWdh2{

border-style: none;
width: 100%;
height: 2000px;
margin-top:30px;
}


.SlotsContentDiv_buttonContainer__2Zds1{
width: 100%;
border-style: none;
}

.SlotsContentDiv_slotsTitle__1Hp20{
border-style: none;	
font-size: 20px;
max-width: 500px;
margin: auto;
margin-bottom: 30px;

}






.Classes_classes__2oqJx{


width: 100%;
margin: auto;
margin-top: 20px;
border-style: none;
height: 1000px;
}


.Classes_switchBar__3yjbc{
height: calc( 0.7 * var(--headerHeight) );
width:90%;
margin: auto;
border-color: lightgrey;
border-style: none;
display: flex;
justify-content: space-between;
}

.Classes_downArrow__14UKO{

border-style: none;
display: flex;
font-size: 12px;
margin-left: 20px;
}


.Classes_timeLine__1x_Eq{
height: 30px;
width: 90%;
border-style: none;
margin: auto;
align-items: center;
margin: auto;
display: flex;
color: var(--greyTextColor);
margin-top: 20px;
white-space: nowrap;
}


.Classes_dummyDiv__Ngt4U{
position: relative;
}


.Classes_dropDownDiv__2ostB{
border-style:solid;
background-color: white;
border-width: 1px;
border-color: lightgrey;
border-radius: 0px 0px calc( 0.6 * var(--cardBorderRadius) )  calc( 0.6 * var(--cardBorderRadius) );
position: absolute;
width: 100%;
z-index: 1000000;
padding-bottom: 20px;
}


.Classes_dropDownButton__1VHyH{
width: 100%;
background-color: white;
height: calc( 0.7 * var(--headerHeight) );
display: flex;
justify-content: flex-start;
align-items: center;
cursor: pointer;
border-style: none;

}


.Classes_dropDownButton__1VHyH span{

margin-left: 15px;
}


.Classes_dropDownButton__1VHyH:hover{

background-color: var(--cardBorderColor);
}



.Classes_navButton__1CJK2{
border-style: none;
border-width: 1px;
border-color: var(--cardBorderColor);
margin-left: 5px;
margin-right: 5px;
border-radius: 3px;
color: grey;
padding-left:10px;
padding-right: 10px;
display: flex;
align-items: center;
padding-top: 8px;
padding-bottom: 8px;
}

.Classes_navButton__1CJK2:hover{
background-color: lightgrey;
}



.Classes_infoText_i__1yKFh{
display: flex;
}


.Classes_classTimeLine__C7D_K{
width: 100%;
height: 1px;
background-color: var(--cardBorderColor);
display: flex;
align-items: center;
justify-content: space-between;
padding-right: 70px;
}


.Classes_oneClassBall__1Ganv{
height: 5px;
width: 5px;
border-radius: 50%;
background-color: grey;
margin-left: 5%;
position: relative
}


.Classes_oneClassExactTime__1L3gI{
position: absolute;
top: 5px;
}



.Classes_dateText__2nOE5{
border-style: none;
display: flex;
align-items: center;
}





.buttonStyle2_buttonStyle2__3Vw4E{
height: calc( 0.7 * var(--headerHeight) );
border-style: solid;
font-size: calc( 0.3 * var(--headerHeight) );
cursor: pointer;
border-width: calc( 0.04 * var(--headerHeight) );
border-color: var(--cardBorderColor);
background-color: white;
color: var(--themeColor);
font-family: Roboto;
border-radius: calc( 0.6 * var(--cardBorderRadius) );
padding-left: calc( 0.5 * var(--headerHeight) );
padding-right: calc( 0.5 * var(--headerHeight) );
border-width: 1px;
}




.CreateClassForm_createTicketFormDivParent__1NeUN{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;
}

.CreateClassForm_createTicketForm__3vbvw{

position: relative;
width: 60vw;
max-width: 700px;
height:90vh;
min-width:400px;
background-color: white;
border-radius: var(--cardBorderRadius);
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
/*display: flex;
flex-direction: column;*/
align-items: center;
overflow-y: scroll;
overflow-x: hidden;
}



.CreateClassForm_createTicketFormLoading__3kdjP{

position: relative;
width: 60vw;
height:80vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}


.CreateClassForm_submittingDiv__3ag2f{
padding-top: 100px;	
color: var(--themeColor);

}









.CreateClassForm_closeButtonDiv__HH6v_{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.CreateClassForm_closeFormButton__1Whcf{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.CreateClassForm_closeButtonIcon__3VuUx{

font-size: 25px;

}


.CreateClassForm_logoAndTitleContainer__1kbw8{


width:80%;
background-color: white;
display: flex;
flex-direction: column;
align-items: center;
margin: auto;
border-style: none;
margin-bottom: 30px;
}

.CreateClassForm_bwLogo__3NOi-{

height: 75px;
width: 75px;

}

.CreateClassForm_formTitleDiv__wge7i{
width: 100%;
display:flex;
justify-content:flex-start;
color: #585858;
margin-top: 20px;
}

.CreateClassForm_formTitleDiv__wge7i i{

font-size: 25px;
font-style: normal;
}



.CreateClassForm_submitButtonDiv__1Xxpa{
width: 100%;	
display: flex;
justify-content: center;
background-color: white;
padding-top: 50px;
margin-bottom: 50px;
border-style: none;
}


.CreateClassForm_submit_button__1bHPi{
width: 300px;
border-style: none;
background-color: var(--themeColor);
color: white;
height:50px;
width:200px;
border-radius: 10px;
cursor: pointer;
font-size: 18px;
}


.CreateClassForm_emptyDiv__n-I7_{
width: 80%;
border-style: solid;
height: 50px;
color: white;

}




.CreateClassForm_singleNMultiFormOption__3pnjA{

height: 40px;
width: 80%;
display: flex;
justify-content: space-between;
margin-top: 0px;
border-bottom: none;
border-color: lightgrey;
border-width: 1px;
margin: auto;
}
.CreateClassForm_singleClass__2S9gD{
width: 50%;
height: 40px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
color: grey;
border-radius: var(--cardBorderRadius) 0px 0px var(--cardBorderRadius);
}

.CreateClassForm_singleClass__2S9gD:hover{
cursor: pointer;
}

.CreateClassForm_multiClass__3Ihfy{
width: 50%;
height:40px;
border-style: none;
border-width: 1px;
border-color: lightgrey;
font-family: Roboto;
display: flex;
align-items: center;
justify-content: center;
color: grey;
border-radius: 0px var(--cardBorderRadius) var(--cardBorderRadius) 0px;
}

.CreateClassForm_multiClass__3Ihfy:hover{
cursor:pointer;
}



.CreateClassForm_noCourseWarning__2I4lt{
font-style: normal;
color: grey;
font-size: 20px;
margin: auto;
border-style: none;
width: 80%;
}

.CreateClassForm_formElement1__ib9bI{
width: 80%;
margin: auto;
border-style: none;
margin-top: 30px;
}



.CreateClassForm_multiClassInfo__EIzCr{
margin: 20px;
color: grey;
font-size: 20px;
}

.CreateClassForm_multiContainer__KFrsx{
width: 100%;
display: grid;
grid-template-columns: repeat(auto-fit, minmax(150px, 1fr) );
grid-auto-rows: 100px ;
border-style: none;
grid-column-gap: 50px;
grid-row-gap: 20px;
border-color: red;
margin: auto;

}


.CreateClassForm_dayFieldContainer__1TPpO{
display: grid;
grid-row-gap: 20px;

}



.CreateClassForm_customTimePicker__1qvzm{

border-style: solid;
height: 40px;
width: 200px;
border-width: 1px;
border-color: lightgrey;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
color: grey;
position: relative;
}


.CreateClassForm_customTimePicker__1qvzm:hover{

border-color: var(--themeColor)

}

.CreateClassForm_timeDropDownOptions__YjaeM{
height: 400px;
width: 100%;
background-color: white;
position: absolute;
top: 40px;
z-index: 22;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
border-radius: 0px 0px 5px 5px;
display: flex;
visibility: hidden;
}


.CreateClassForm_customTimePicker__1qvzm:hover .CreateClassForm_timeDropDownOptions__YjaeM{

visibility: visible;
}






.CreateClassForm_hourDiv__ybsTe{
width: 33%;
height:100%;
border-style: none;
overflow-y: scroll;
overflow-x:hidden;
}

.CreateClassForm_minDiv__2-Fex{
width:33%;
height:100%;
border-style: none;
overflow-y: scroll;
overflow-x:hidden;
}

.CreateClassForm_ampmDiv__2y4rP{
height:100%;
width: 33%;
border-style: none;
}


.CreateClassForm_oneTimeDropDownBox__hl-Qh{
height: 30px;
display: flex;
align-items: center;
justify-content: center;
border-style:none;
width: 100%;
background-color: white;
color: var(--greyTextColor);
cursor: pointer;
}



.CreateClassForm_oneTimeDropDownBox1__1tzyX{
height: 30px;
display: flex;
align-items: center;
justify-content: center;
border-style:none;
width: 100%;
background-color: white;
color: var(--greyTextColor);
}





.CreateClassForm_oneTimeDropDownBox__hl-Qh:hover{
background-color: lightgrey;
color: grey;
}


.CreateClassForm_firstFieldBlock__3Tji9{

width: 100%;
border-style: none;
display: flex;
justify-content: space-between;
margin-bottom: 20px;
}

.CreateClassForm_firstFieldBlockSmall__tZjVa{
width: 200px;
border-style: none;
display: flex;
justify-content: space-between;
margin-top: 20px;
}



.CreateClassForm_firstFieldBlockLong__1nvem{
width: 100%;
border-style: none;
display: flex;
margin-top:20px;
}


.CreateClassForm_optionalSettings__2Qdjq{

height: 2px;
margin-top:50px;
display: flex;
justify-content: space-between;
border-style: none;
position: relative;
height: 30px;
align-items: center;
width: 100%;
cursor: pointer;
background-color: white;
}




.CreateClassForm_optionSettingDiv__2SNyp{
position: relative;
background-color: white;
border-style: none;
color: var(--themeColor);
}


.CreateClassForm_optionSettingButton__3zKL2{
position: relative;
background-color: white;
border-style: none;
color: var(--themeColor);
}



.CreateClassForm_chooseTopicForClassDiv__OGZHH{

width:100%;
max-width:300px;
border-style: none;
height: 30px;
border-radius: 5px;
height: 33px;
cursor: pointer;
margin-top: 35px;
color: var(--greyTextColor);
font-family: Roboto;
}


.CreateClassForm_selTopicsParentDiv__vnOxf{
margin-top:20px;

}

.CreateClassForm_selectedTopicDiv__2omAZ{

color: var(--themeColor);
}

.CreateClassForm_selTopicTitle__1pEEA{
 margin-bottom: 15px;
 color: grey;
}



.CreateClassForm_optionalSettingDiv__2puRB{
display: flex;
justify-content: space-between;
z-index: 1;
}


.CreateClassForm_noTopicsSelMessage__3gNte{

font-size: 13px;
margin-left: 20px;
color: red;
}





.Logo_logoText__2Te4W{
font-size: 30px;
border-style: none;
display: flex;
justify-content: center;
align-items: center;
background-color: var(--themeColor);
color: white;
position: relative;
font-weight: 900;
height: 40px;
width: 40px;
border-radius: 5px;
} 


.Logo_styleBar__3avSA{
width: 15px;
height: 5px;
background-color: var(--themeColor);
position: absolute;
top: 20px;
left: 0px;
}


.FormInputObjects_name_div__wP-BI{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-style: none;
margin-top: 10px;
position: relative;
width: 100%;
}

.FormInputObjects_name_txt__27Fqg{
width: 100%;
position: relative;
background-color:white;
font-size: 16px;
color: grey;
font-weight: thin;
}

.FormInputObjects_name_inputDiv__LY6xv{
width: 100%;
border-style: none;
margin-top: 5px;
height: 35px;
}


.FormInputObjects_name_inputDiv_Paragraph__2Bti0{
width: 100%;
border-style: none;
margin-top: 5px;
}




.FormInputObjects_input_field__F5cnn  {
position: relative;
width: 100%;
height: 100%;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
font-size: 16px;
}


.FormInputObjects_input_field__F5cnn:focus {
    border-style: solid;
    border-width: 1px;    
    outline: none !important;
    border-color: var(--themeColor);
    border-radius:5px;
}

/*
.name_inputDivTimeField{
border-style: solid;
display: flex;
width: 100%;
margin-top: 10px;
border-width: 1px;
border-color: lightgrey;
border-radius: 5px;
height: 35px;
}
*/

.FormInputObjects_name_inputDivTimeField__297Az{
width: 100%;
border-style: none;
margin-top: 5px;
height: 35px;
display: flex;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
border-radius: 5px;
}

.FormInputObjects_name_inputDivTimeField__297Az:focus{
  border-style: solid;
  border-width: 2px;
  border-color: var(--themeColor);
  border-radius:5px;
}




.FormInputObjects_input_field_time__1N6m6  {
position: relative;
width: 100%;
height:35px;
font-size:18px;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
border-style: none;
-webkit-appearance: none;
-moz-appearance: none;
text-indent: 1px;
text-overflow: '';
}


.FormInputObjects_input_field_time__1N6m6:focus {
  border-style: none;
  border-width: 2px;
  outline: none !important;
  border-color: #719ECE;
  border-radius:5px;
}



.FormInputObjects_input_field_paragraph__1kinM{
position: relative;
width: 100%;
height: 150px;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding: 10px;
font-size: 20px;
font-style: normal;
font-family: Roboto;
}

.FormInputObjects_input_field_paragraph__1kinM:focus{
border-style: solid;
border-width: 1px;
outline: none !important;
border-color: var(--themeColor);
border-radius:5px;

}



.FormInputObjects_input_field_date__1UYIJ  {
position: relative;
width: 100%;
max-width:300px;
height: 30px;
font-size:20px;
box-sizing: border-box;
}


.FormInputObjects_input_field_date__1UYIJ:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}


.FormInputObjects_inputText_field__3B11j  {
position: relative;
width: 100%;
height: 200px;
font-size:20px;
box-sizing: border-box;
}


.FormInputObjects_inputText_field__3B11j:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}


.FormInputObjects_name_inputDivDayOptions__2MWCp{
border-style: none;
display: flex;
width: 100%;
margin-top: 10px;
}



.FormInputObjects_searchDropDown__3g_cX{
display: flex;
flex-direction: column;
position: absolute;
border-style: none;
border-width: 1px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
width: 100%;
top: 75px;
background-color: white;
z-index:2;
border-radius: 5px;
}


.FormInputObjects_oneSearchOptionButton__3c91-{
width: 100%;
background-color: white;
height: 40px;
border-style: none;
display: flex;
justify-content: flex-start;
align-items: center;
cursor: pointer;
}


.FormInputObjects_oneSearchOptionButton__3c91-:hover{

background-color: var(--cardBorderColor);

}

.FormInputObjects_input_field_searchnInsert__AU05u{
position: relative;
width: 100%;
height: 40px;
font-size:20px;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
}


.FormInputObjects_input_field_searchnInsert__AU05u:focus{
    border-style: solid;
    border-width: 2px;
    outline: none !important;
    border-color: #719ECE;
    border-radius:5px;



}



{/*  */}

.FormInputObjects_customTimePicker__2rqVV{
border-style: solid;
height: 33px;
width:100%;
border-width: 1px;
border-color: lightgrey;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
color: grey;
position: relative;
font-size: 16px;
}

/*
position: relative;
width: 100%;
height: 100%;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
*/








.FormInputObjects_customTimePicker__2rqVV:hover{

border-color: var(--themeColor)

}

.FormInputObjects_timeDropDownOptions__3Dz5c{
height: 400px;
width: 100%;
background-color: white;
position: absolute;
top: 35px;
z-index: 22;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
border-radius: 0px 0px 5px 5px;
display: flex;
visibility: hidden;
}


.FormInputObjects_customTimePicker__2rqVV:hover .FormInputObjects_timeDropDownOptions__3Dz5c{

visibility: visible;
}


.FormInputObjects_hourDiv__29j67{
width: 33%;
height:100%;
border-style: none;
overflow-y: scroll;
overflow-x:hidden;
}

.FormInputObjects_minDiv__1G7Ba{
width:33%;
height:100%;
border-style: none;
overflow-y: scroll;
overflow-x:hidden;
}

.FormInputObjects_ampmDiv__2sMkH{
height:100%;
width: 33%;
border-style: none;
}


.FormInputObjects_oneTimeDropDownBox__3sHa_{
height: 30px;
display: flex;
align-items: center;
justify-content: center;
border-style:none;
width: 100%;
background-color: white;
color: var(--greyTextColor);
cursor: pointer;
}



.FormInputObjects_oneTimeDropDownBox1__1M1oZ{
height: 30px;
display: flex;
align-items: center;
justify-content: center;
border-style:none;
width: 100%;
background-color: white;
color: var(--greyTextColor);
}


.FormInputObjects_name_txt_search__2y4Nu{



}


.FormInputObjects_selectedSpeaker__Mqcpu{
  border-style: none;
  width: 100%;
  color: var(--themeColor);
  margin-top: 5px;
  margin-bottom:20px;
}



.FormInputObjects_searchResultButton__3srCh{
padding: 5px;
border-style: solid;
border-radius: 5px;
border-width: 1px;
border-color: lightgrey;
cursor: pointer;
}

.FormInputObjects_searchResultButton__3srCh:hover{

background-color: var(--themeColor);
color: white;
border-style: none;
}



.FormInputObjects_searchResultButton__3srCh:hover i{
color: white;

}



.SyllabusTopics_syllabusTopics__1A3-D{


position: fixed;
top: 50px;
height: 700px;
min-width: 300px;
width: 80%;
max-width: 1000px;
border-style: none;
background-color: white;
border-radius: 10px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
margin: auto;
right: 30px;
z-index: 9999;
}


.SyllabusTopics_closeBarButton__2lFH6{

height: 40px;
border-style: solid;

}

.SyllabusTopics_closeButtonDiv___m9o6{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.SyllabusTopics_closeFormButton__1LaMp{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.SyllabusTopics_closeButtonIcon__3INbl{
font-size: 25px;
}

.SyllabusTopics_instructionTitle__213px{
  margin: auto;
  border-style: none;
  width: 80%;
  color: var(--themeColor);
}



.SyllabusTopics_syllabusTitle__qVSoc{
width: 90%;
border-style: none;
margin: auto;
color: var(--greyTextColor);
font-size: 20px;
margin-top:20px;
}



{/* Followin design is from sllabys standalone App  */}
.SyllabusTopics_courseSyllabus__3cehv{
width: 85%;
background-color: white;
display: flex;
align-items: center;
flex-direction: column;
align-items: flex-start;
margin-bottom: 200px;
padding-top:20px;
border-style: none;
margin:auto;
margin-top: 20px;
padding: 2.5%;
border-style: none;
border-width: 1px;
border-color: var(--cardBorderColor);
border-radius: var(--cardBorderRadius);
}

.SyllabusTopics_courseSyllabus__3cehv i{
font-style: normal;
font-size: calc( 0.30 * var(--headerHeight) );
color: grey;
display: flex;
}

.SyllabusTopics_courseSyllabusss__1TzBT button{
font-size:calc( 0.30 * var(--headerHeight) );
border-style: none;
background-color: white;
cursor: pointer;
color:var(--TextColorDarkBlue1);
display: flex;
align-items: center;
}

.SyllabusTopics_courseSyllabus__3cehv button:hover{
text-decoration: underline;
}


.SyllabusTopics_ExpandSyllIcon__2pTHl{
font-size:calc( 0.30 * var(--headerHeight) );
padding-left: 5px;
}


.SyllabusTopics_syllabusContent__ZQ6hZ{
position: relative;
width: 100%;
background-color: white;
font-size: calc( 0.30 * var(--headerHeight) );
color: grey;
}


.SyllabusTopics_topicCheck__1GZ6j{
font-size: calc( 0.35 * var(--headerHeight) );
color: green;
}


.SyllabusTopics_chapterNames__2pemw{
display: flex;
flex-direction: column;

}


.SyllabusTopics_completeIcon__1zFFv{
color: green;
}


.SyllabusTopics_numLectures__BwnRz{
color: white;
background-color: red;
float: right;
border-radius: 2px;
padding: 2px;
}

.SyllabusTopics_sectionHead__k6oAN{

border-style: solid;
border-width: 1px;
border-color: lightgrey;
margin: 10px;
}



.SyllabusTopics_sectionlist__3Xjmk{
counter-reset: item
}




ol { counter-reset: item }


li{ 
display: block; 
border-width: 1px;
border-color: lightgrey;
border-style: none;
padding: 10px;

}
li:before { content: counters(item, ".") " "; counter-increment: item }




.SyllabusTopics_topicsList__2TLyw{
display: flex;


}


.SyllabusTopics_sectionEditButton__3GPyO{
border-style: none;
float: right;
background-color: lightgrey;
border-radius: 3px;
color: grey;
padding: 2px;
padding-left: 5px;
padding-right: 5px;
font-size: 15px;
margin-left: 10px;
margin-right: 10px;
}

.SyllabusTopics_sectionEditButton__3GPyO:hover{
cursor: pointer;
}

.SyllabusTopics_editTopicUnit__58WdQ{
border-style: solid;
border-width: 1px;
border-radius: 3px;
background-color: #ebebeb;
border-color: lightgrey;
}



.SyllabusTopics_inputText_field__1gh49{
width: 100%;
height: 100%;

}


.SyllabusTopics_sectionEditBox__mZYF2{
min-height: 100px;
border-style: solid;
width: 100%;
border-style: solid;
border-width: 2px;
border-color:#e0ebff; 
}


.SyllabusTopics_sectionTextArea__3AvDA{
min-height: 100px;
border-style: solid;
width: 100%;
border-style: solid;
border-width: 2px;
border-color:#e0ebff;
padding: 20px;
}



.SyllabusTopics_sectionTextArea__3AvDA:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}



.SyllabusTopics_sectionSaveButton__2-LXK{
border-style: none;
background: var(--themeColor);
color: white;
border-radius: 3px;
padding: 3px;
}

.SyllabusTopics_sectionSaveButton__2-LXK:hover{
cursor: pointer;
text-decoration: none;
}



.SyllabusTopics_deleteChapterButton__3DBp0{
float: right;
border-style: none;
background-color: lightgrey;
padding: 5px;
border-radius: 3px;
font-size: 20px;
color: grey;
cursor: pointer;
margin-left: 10px;
}

.SyllabusTopics_topBarSyllabus__2Ad1a{
color: grey;
display: flex;
}

.SyllabusTopics_topBarSyllabus_button__2UOsL{
border-style: none;
color: white;
background-color: #00aff0;
background-color: var(--themeColor);
padding: 5px;
margin-left: 20px;
border-radius: 3px;
cursor: pointer;
text-decoration: none;

}

.SyllabusTopics_topBarSyllabus_button__2UOsL:hover{
text-decoration: none;
}


.SyllabusTopics_toggleSwitch__u9DL3{
 margin-left: 20px;
 display: flex;
 align-items: center;
}





.SectionIter_oneTopicSelect__2PbLL{
border-style: solid;
border-width: 1px;
border-color:lightgrey;
background-color: var(--bodyBkgColor);
padding: 3px;
margin: 3px;
display: inline;
border-radius: 5px;
cursor: pointer;
}

.SectionIter_oneTopicSelect__2PbLL:hover{

background-color: #c8defa;
border-color: var(--themeColor);
}



.ClassesContainer_parentContainer__3Z16p{

width: 90%;
border-style: none;
margin: auto;
height: 1000px;
margin-top: calc( 0.5 * var(--headerHeight) );
}





.ClassesContainer_daySection__L_tGJ{
min-height: 50px;
border-style: none;
border-width:1px;
border-color: var(--cardBorderColor);
border-radius: var(--cardBorderRadius);
display: flex;
align-items: center;
color: var(--greyTextColor);
align-items: stretch;
margin-bottom: 10px;
}

.ClassesContainer_dayName__3YxB7{
width: 100%;
border-style: none;
margin-left:-10px;
margin-right: 10px;
background-color: var(--bodyBkgColor);
padding-top: 5px;
padding-bottom: 5px;
height: 20px;
margin: auto;
margin-left:-10px;
margin-right: 10px;
}



.ClassesContainer_dayClassContainer__Ww4t2{

width: 90%;
border-style: none;
padding-top: 10px;
border-width: 5px;
border-color: var(--bodyBkgColor);
border-color: white;
border-radius: var(--cardBorderRadius);
display: flex;
flex-direction: column;
justify-content: center;
}


.ClassesContainer_leftDayNameContainer__1-454{
width: 10%;
border-style: none;
border-color: var(--cardBorderColor);
border-width: 1px;
display: flex;
align-items:center;
position: relative;
align-items: stretch;
border-radius: var(--cardBorderRadius);
border-left-style: solid;
}




.ClassesContainer_noClassDiv__WbxBH{
border-style: solid;
border-width: 1px;
border-color: var(--cardBorderColor);
padding: 20px;
border-radius: var(--cardBorderRadius);

}


.ClassesContainer_timeLine__3nyAI{
height: 30px;
width: 100%;
border-style: none;
margin: auto;
align-items: center;
margin: auto;
display: flex;
color: var(--greyTextColor);
margin-top: 20px;
margin-bottom: 10px;
white-space: nowrap;
}


.ClassesContainer_navButton__3_1-R{
border-style: none;
border-width: 1px;
border-color: var(--cardBorderColor);
margin-left: 5px;
margin-right: 5px;
border-radius: 3px;
color: grey;
padding-left:10px;
padding-right: 10px;
display: flex;
align-items: center;
padding-top: 8px;
padding-bottom: 8px;
cursor: pointer;
}

.ClassesContainer_navButton__3_1-R:hover{
background-color: lightgrey;
}

.ClassesContainer_infoText_i__16XaW{
display: flex;
}

.ClassesContainer_dateText__3J-5y{
border-style: none;
display: flex;
align-items: center;
}


.ClassesContainer_classTimeLine__wGtUt{
width: 100%;
height: 1px;
background-color: var(--cardBorderColor);
display: flex;
align-items: center;
justify-content: space-between;
padding-right: 70px;
}

.ClassesContainer_oneClassBall__1r01b{
height: 5px;
width: 5px;
border-radius: 50%;
background-color: grey;
margin-left: 5%;
position: relative
}


.ClassesContainer_oneClassExactTime__1GnIc{
position: absolute;
top: 5px;
}



.ClassViewShort_v2_courseViewDashboard__p3dir {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-style: solid;
    border-width: 1px;
    border-radius: var(--cardBorderRadius);
    padding: 15px;
    border-color: lightgrey;
    background-color: white;
    margin-bottom: 10px;
  }
  
  .ClassViewShort_v2_courseViewDashboard__p3dir:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  
  .ClassViewShort_v2_titleDiv__2VYs3 {
    width: 100%;
    border-style: none;
    margin-left: -0%;
    border-width: 1px;
    border-color: lightgrey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
  
  }
  
  .ClassViewShort_v2_classInfo__3pUG8 {
    /* background-color: var(--themeColor); */
    color: var(--greyTextColor);
    padding-right: 10px; 
    border-radius: 7px;
    font-size: 14px;
    display: flex;
    border-style: none;
    height: 30px;
    background-color: var(--themeColor);
  }
  
  .ClassViewShort_v2_time__2VOyh {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--themeColor);
    color: white;
  }
 


  .ClassViewShort_v2_timeAll__23Lpu{
   margin-left: 5px;
   font-size: 14px;
   font-weight: 600;
  }


  .ClassViewShort_v2_leftInfo__L6xRx {
    display: flex;
    align-items: center;
    border-style: none;
    border-color: green;
    height: 100%;
    width: calc(100% - 170px);
  }
  
  .ClassViewShort_v2_toprightBoxes__2_sz9 {
    display: flex;
    border-style: none;
    margin-right: 0px;
    width: 130px;
    justify-content: space-between;
    position: relative;
  }
 

 @media only screen and (max-width:600px){

    .ClassViewShort_v2_toprightBoxes__2_sz9{
  
       visibility: hidden;	    
  
    }

 }



  .ClassViewShort_v2_classId__3IpRx {
    background-color: white;
    color: var(--themeColor);
    border-radius: 2px;
    padding: 2px;
  }
  
  .ClassViewShort_v2_topicsTitle__fBWp_ {
   color: var(--deepDarkThemeTextColor);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}
 
  
  .ClassViewShort_v2_classData__FlhNk {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    display: flex;
    margin: auto;
    font-size: 12px;
    color: var(--themeColor);
    background-color: var(--themeColor);
    color: white;
  }
 

  .ClassViewShort_v2_classDataAll__gcVll{
  
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    display: flex;
    margin: auto;
    font-size: 12px;
    color: var(--themeColor);
  
  }







  .ClassViewShort_v2_clockIcon__3dEe9 {
    height: 16px;
    width: 16px;
    color: var(--themeColor);
  }
  
  .ClassViewShort_v2_dotsButton__3Cn4r {
    float: right;
    border-style: none;
    /* margin-left: 20px; */
    background-color: white;
    cursor: pointer;
    padding: 4px;
    /* border: 1px solid red; */
  }
  
  .ClassViewShort_v2_dotsButton__3Cn4r:hover {
    background-color: var(--bodyBkgColor);
    border-radius: 20%;
  }
  
  .ClassViewShort_v2_middleDiv__aO8CN {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    border-style: solid;
    border-width: 1px;
    border-color: red;
    margin: auto;
  }
  
  .ClassViewShort_v2_authorDiv__3Bv7f {
    border-style: none;
    align-items: center;
    color: var(--lightGreyTintTextColor);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
    width: 80%;
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  
  
  .ClassViewShort_v2_addressDiv__2YTho {
    margin-top: 10px;
    border-style: none;
    border-width: 1px;
    color: var(--lightGreyTintTextColor);
    height: 20px;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 80%;
  }
  
  .ClassViewShort_v2_redirButtons__34LRu {
    display: flex;
    justify-content: right;
    border-style: none;
    float: right;
    align-items: center;
    border-color: yellow;
  }
  
  .ClassViewShort_v2_joinClassButton__2RpJZ {
    margin-top: 10px;
    height: 80%;
    border-style: none;
    border-radius: 2px;
    padding-left: 15px;
    padding-right: 15px;
    /* background-color: #d67b1a;
      background-color: orange; */
    color: white;
    width: 150px;
    /* border-radius: var(--cardBorderRadius); */
    cursor: pointer;
    font-family: Roboto;
    border-radius: 6px;
    background-color: transparent;
    color: #4c8bf5;
    border-radius: 4px;
    border: 1px solid #4c8bf5;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: orange;
    color: white;
    border-color:white;
    color: white;
    border-radius: 7px;
    font-weight: bold;
  }
  
 
  .ClassViewShort_v2_joinClassButton__2RpJZ:hover{
  
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  }


  .ClassViewShort_v2_joinText__Poc7d {
    padding-left: 10px;
  }
  
  .ClassViewShort_v2_viewClassButton__3mUAO {
    margin-top: 10px;
    height: 70%;
    margin-left: 10%;
    border-style: none;
    align-items: center;
    display: flex;
    width: 120px;
    cursor: pointer;
    /* border-radius: var(--cardBorderRadius); */
    border-radius: 4px;
    padding-left: 15px;
    color: var(--tintGreyColor1);
    font-family: Roboto;
    border: 1px sol id var(--cardBorderColor);
  }
  
  .ClassViewShort_v2_viewClassButton__3mUAO:hover {
    background-color: white;
    border: 1px solid var(--cardBorderColor);
  }
  
  .ClassViewShort_v2_viewdetailIcon__27ckc {
    margin-left: 10px;
  }
  
  .ClassViewShort_v2_classAddress__3rafn {
    color: var(--tintGreyColor1);
    border-style: none;
    display: flex;
    align-items: center;
  }
  
  .ClassViewShort_v2_lowerDiv__3p-1Z {
    margin-top: 10px;
    height: 40px;
    border-style: none;
    width: 100%;
    border-width: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid blue; */
  }
  
  .ClassViewShort_v2_leftLowerDiv__1G-95 {
    height: 100%;
    width: calc(100% - 300px);
    /* border: 1px solid red; */
  }
  
  .ClassViewShort_v2_rightLowerDiv__2IhoP {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    /* border: 1px solid green; */
  }
  
  .ClassViewShort_v2_classStatus__35qLb {
    color: green;
    border-style: none;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }
  
  .ClassViewShort_v2_todayTopic__35p_q {
    font-size: 14px;
    color: var(--greyTextColor);
    font-weight: 600;
    margin-left:10px;
  }
  
  .ClassViewShort_v2_todayTopicDetail__dWixR {
    padding-left: 10px;
    font-size: 14px;
  }
  
  .ClassViewShort_v2_classTime__2tu6b {
    margin-right: 10px;
    border-width: 1px;
    padding: 5px;
    border-radius: 5px;
    border-color: var(--tintGreyColor2);
    color: var(--themeColor);
    color: var(--lightGreyTintTextColor);
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
  }
  
  .ClassViewShort_v2_classNum__qe-yY {
    margin-left: 10px;
    border-style: none;
    color: var(--deepDarkThemeTextColor);
    /* color: var(--themeColor); */
    padding: 5px;
    font-size: 14px;
    background-color: var(--bodyBkgColor);
    border-radius: 16%;
    color: grey;
  }



.ClassViewShort_v2_rightDotsDiv__3YrBz{


border-style: none;


}


.ClassViewShort_v2_dateText__2OmX5{
background-color: var(--themeColor);
color: white;
padding: 15px;
height: 30px;

}



.ClassViewShort_v2_minduration__2a3xX{
background-color: white;
border-radius: 5px;
padding-left: 5px;
padding-right: 5px;
}



.ClassViewShort_v2_studentNo__243Vv{
color: white;
background-color: #50C878;
border-radius: 5px;
height: 15px;
min-width: 15px;
border-style: none;
border-color: black;
display: inline-block;
font-weight: bold;
padding: 5px;
align-items: center;
display: flex;
justify-content: center;
margin-left: 10px;
}


.ClassViewShort_v2_paymentStatus__2xq1O{
margin-left: 10px;
}


.CourseViewDashboard_v2_courseViewDashboard__SU76g{
border-width: 1px;
background-color: white;
border-radius: var(--cardBorderRadius);
margin-bottom: 0px;
border-style: solid;
border-color: var(--headerRightIconsColor);
border-color: #e0ebff;
/*box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;*/
border-color: var(--tintGreyColor3);
}


.CourseViewDashboard_v2_courseViewDashboard__SU76g:hover{
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}





.CourseViewDashboard_v2_courseViewDashboard__SU76g:hover .CourseViewDashboard_v2_courseNameButton__1eH4E{

color: var(--themeColor);

}


.CourseViewDashboard_v2_courseViewDashboard__SU76g:hover .CourseViewDashboard_v2_arrowButton__oZ6Em{
color: var(--themeColor);

}


.CourseViewDashboard_v2_courseViewDashboard__SU76g:hover .CourseViewDashboard_v2_enrolldisplayButton1__40jvB{
visibility: visible;
}


.CourseViewDashboard_v2_courseViewDashboard__SU76g:hover .CourseViewDashboard_v2_courseGoToButton__242Id{
visibility: visible;
}



.CourseViewDashboard_v2_innerMarginDiv__1EXFD{
border-style: none;
margin: calc( 0.5 * var(--headerHeight) );
}



.CourseViewDashboard_v2_upperPart__3iRN9{
height: calc( 2 * var(--headerHeight) );
width: 100%;
border-style: none;
border-width: 1px;
display: flex;
}


.CourseViewDashboard_v2_upperInfoDiv__3-aa_{
background-color: white;
height: calc( 3 * var(--headerHeight) );
width: calc( 100% - calc( 2.3 * var(--headerHeight) ));
margin-left: calc( 0.3 * var(--headerHeight) );
border-style: none;
display: flex;
flex-direction: column;
justify-content: flex-start;
}



.CourseViewDashboard_v2_picDiv__1WeL5{
height: calc( 2 * var(--headerHeight) );
width: calc( 2 * var(--headerHeight) );
background-color:white;
border-radius: 5px;
border-style: none;
position: relative;
}


.CourseViewDashboard_v2_picStyle__26Xpl{
width: calc( 2 * var(--headerHeight) );
height: calc( 2 * var(--headerHeight) );
border-radius: 5px;
}







.CourseViewDashboard_v2_topBar1__2tGhv{
border-style: none;
color: grey;
display: flex;
justify-content: space-between;
}

.CourseViewDashboard_v2_topBar1_left__2XheM{
display: flex;
width: 50%;
border-style: none;
justify-content: space-between;
font-size: calc( 0.25 * var(--headerHeight) );
}





.CourseViewDashboard_v2_topBar1_right__2XbCk{
display: flex;
border-style: none;
font-size: calc( 0.25 * var(--headerHeight) );
}


.CourseViewDashboard_v2_courseCode__z6eND{

background-color:#dcf7ea;
padding: calc( 0.05 * var(--headerHeight) );
border-radius:calc( 0.05 * var(--headerHeight) );
color: #65c394;
font-size: calc( 0.25 * var(--headerHeight) );
}

.CourseViewDashboard_v2_notification__5RTN6{
margin-left:calc( 0.20 * var(--headerHeight) );
margin-right:calc( 0.20 * var(--headerHeight) );
border-style: none;
display: flex;
flex-direction: column;
justify-content: center;
}

.CourseViewDashboard_v2_notIcon__BIr6s{
font-size: calc( 0.30 * var(--headerHeight) );

}



.CourseViewDashboard_v2_dotsButton__e7yi1{
border-style: none;
background-color: white;
cursor: pointer;
display: flex;
justify-content: center;
flex-direction: column;
position: relative;
font-size: calc( 0.25 * var(--headerHeight) );

}

.CourseViewDashboard_v2_verticalDotsIcon__2D_aT{
font-size: calc( 0.25 * var(--headerHeight) );
}




.CourseViewDashboard_v2_courseNameDiv__RZidm{
font-size: calc( 0.25 * var(--headerHeight) );
display: flex;
justify-content: space-between;
}

.CourseViewDashboard_v2_courseNameDiv_left__1sQ6g{
display: flex;
border-style: none;
width: 80%;
}


.CourseViewDashboard_v2_courseNameButton__1eH4E{
max-width: 100%;
border-style: none;
background-color: white;
font-size: calc( 0.30 * var(--headerHeight) );
cursor: pointer;
padding: 0px;
font-family: Roboto;
color: var(--deepDarkThemeTextColor);
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}


.CourseViewDashboard_v2_arrowButton__oZ6Em{
border-style: none;
border-width: 1px;
background-color: white;
font-size: calc( 0.30 * var(--headerHeight) );
cursor: pointer;
padding: 0px;
font-family: Roboto;
color: var(--deepDarkThemeTextColor);
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
padding-left: 5%;
padding-right: 2%;
color: grey;
display: flex;
flex-direction: column;
justify-content: center;

}



.CourseViewDashboard_v2_middlePart__2JiQw{
border-style: none;
width: 100%;
border-width: 1px;
border-color: orange;
display: flex;
justify-content: space-between;
}


.CourseViewDashboard_v2_middlePart_left__2_Iza{
width: 100%;
height: 100%;
border-style: none;
border-width: 1px;
border-radius: 5px;
color: grey;
display: flex;
flex-direction: column;
justify-content: center;
font-size: calc( 0.25 * var(--headerHeight) );
}


.CourseViewDashboard_v2_middlePart_middle__36C-8{
height: 100%;
border-style: none;
border-width: 1px;

}






.CourseViewDashboard_v2_middlePart_right__yiYm3{
width: calc( 1.0 * var(--headerHeight) );
height: 100%;
border-style: none;
border-width: 1px;
display: flex;
flex-direction: column;
justify-content: center;

}


.CourseViewDashboard_v2_goToCourseButton__sOL0j{
height: 100%;
border-style: none;
color: grey;
cursor: pointer;
height: calc( 0.25 * var(--headerHeight) );
flex-shrink: 0;
}



.CourseViewDashboard_v2_goToCourseBtnIcon__1Y6JS{
font-size:calc( 0.25 * var(--headerHeight) );
flex-shrink: 0;
border-style: none;
border-width: 1px;
}

.CourseViewDashboard_v2_InfoDivLowest__137Je{
border-style: none;
height: 100%;
display: flex;
justify-content: space-between;
}

.CourseViewDashboard_v2_InfoDivLowest_left__1Ccgy{
height: 100%;
border-style: none;
width: calc( 3 * var(--headerHeight) );
display: flex;
flex-direction: column;
justify-content: flex-end;

}


.CourseViewDashboard_v2_courseGoToButton__242Id{

height: calc( 0.5 * var(--headerHeight) );
background-color: var(--greenColor1);
color: white;
border-style: none;
border-radius: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.30 * var(--headerHeight) );
margin-bottom: calc( 0.35 * var(--headerHeight) );
cursor: pointer;
visibility: hidden;
background-color: var(--themeColor);
/*background-color:white;
color: var(--themeColor);
border-style: solid;
border-width: 1px;
border-color:var(--themeColor);*/
}





.CourseViewDashboard_v2_courseGoToButton2__2qZjA{

height: calc( 0.5 * var(--headerHeight) );
background-color: var(--greenColor1);
color: white;
border-style: none;
border-radius: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.30 * var(--headerHeight) );
margin-bottom: calc( 0.35 * var(--headerHeight) );
cursor: pointer;
background-color: var(--themeColor);
/*background-color:white;
color: var(--themeColor);
border-style: solid;
border-width: 1px;
border-color:var(--themeColor);*/
}















.CourseViewDashboard_v2_InfoDivLowest_right__13qYL{
height: calc(2 * var(--headerHeight) );
border-style: none;
width: calc( 2 * var(--headerHeight) );
display: flex;
flex-direction: column;
justify-content: space-between;
border-left: 1px solid var(--tintGreyColor3);
padding-left: 10px;
}








.CourseViewDashboard_v2_lowerPart__NKvrr{
height: calc( 1 * var(--headerHeight) );
width: calc( 2 * var( --headerHeight) );
border-style: none;
border-width: 1px;
display: flex;
justify-content: space-between;
}

.CourseViewDashboard_v2_lowerPartTitle__gM0Ad{
color: var(--tintGreyColor1);
font-size: calc( 0.2 * var(--headerHeight) );
}


.CourseViewDashboard_v2_lowerPart_left__1Rmv9{
border-style: none;
height: 100%;
width: calc( 2 * var(--headerHeight) ) ;
display: flex;
flex-direction: column;
justify-content: center;
border-color: red;
}


.CourseViewDashboard_v2_lowerPartInfo1__2s9es{
margin-top: calc( 0.2 * var( --headerHeight) );	
width: 100%;
}



.CourseViewDashboard_v2_progressBar__1VGLi{
height:calc( 0.2 * var(--headerHeight) );
width: 100%;
background-color: #F0F0F0;
border-radius:5px;

}

.CourseViewDashboard_v2_progressWidth__XhTM_{
height:calc( 0.2 * var(--headerHeight) );
width:30%;
background-color: #AFE1AF;
border-radius:calc( 0.2 * var(--headerHeight) );
}




.CourseViewDashboard_v2_lowerPart_middle__3l79-{
height: calc( 1 * var(--headerHeight) );
border-style: none;
border-width: 2px;
display: flex;
justify-content: center;
width: calc( 3.0 * var(--headerHeight) );
display: flex;
flex-direction: column;
}






.CourseViewDashboard_v2_oneTool__38etM{
height: calc( 0.6 * var(--headerHeight) );
width: calc( 0.6 * var(--headerHeight) );
border-radius: calc( 0.2 * var(--headerHeight) );
border-style: solid;
border-color: lightgrey;
border-width: calc( 0.05 * var(--headerHeight) );
display: flex;
justify-content: center;
align-items: center;
color: lightgrey;
cursor: pointer;
font-size:calc( 0.3 * var(--headerHeight) );
}

.CourseViewDashboard_v2_oneTool__38etM:hover{
color: grey;
border-color:var(--tintGreyColor1);
}


.CourseViewDashboard_v2_lowerPartInfo2__33jbz{
display: flex;
width: 100%;
border-style: none;
justify-content: space-between;
height: calc( 1 * var(--headerHeight) );
align-items: center;
}




.CourseViewDashboard_v2_lowerPart_right__2v_AY{
border-style: solid;
width: calc( 2.5 * var(--headerHeight) );
display: flex;
flex-direction: column;
justify-content: center;
}



.CourseViewDashboard_v2_lowerPartInfo3__3fCs0{
color: var(--tintGreyColor1);
width: 50%;
border-style: none;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
font-size: calc( 0.25 * var(--headerHeight) );
margin-top: calc( 0.1 * var(--headerHeight) );
padding-bottom: calc( 0.1 * var(--headerHeight) );
}

























.CourseViewDashboard_v2_dropdownButtons__39Cy6{
width: 100px;
position: absolute;
border-style: solid;
z-index: 9999;
right: 2px;
top: 25px;
background-color: white;
border-width: 1px;
border-color: lightgrey;
border-radius: 3px;
display: flex;
flex-direction: column;
}

.CourseViewDashboard_v2_dropdownButton__3cRKR{

height: 35px;
border-style: none;
cursor: pointer;
}


.CourseViewDashboard_v2_dropdownButton__3cRKR:hover{

background-color: lightgrey;

}



.CourseViewDashboard_v2_upcomingClassTime__1IDmC{
margin-top: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.25 * var(--headerHeight) );
color: var(--tintGreyColor1);
}

.CourseViewDashboard_v2_upcomingClassTime__1IDmC span{
font-size: calc( 0.25 * var(--headerHeight) );
color: var(--tintGreyColor1);
}

.CourseViewDashboard_v2_courseAssociation__3FJR8{
margin-top: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.25 * var(--headerHeight) );
color: var(--themeColor);
border-style: solid;
border-width: 1px;
border-radius: 5px;
width:60px;
font-size: 10px;
padding: 3px;
}





.CourseViewDashboard_v2_enrolldisplayButton1__40jvB{

height: calc( 0.5 * var(--headerHeight) );
background-color: var(--greenColor1);
color: white;
border-style: none;
border-radius: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.30 * var(--headerHeight) );
margin-bottom: calc( 0.35 * var(--headerHeight) );
cursor: pointer;
visibility: hidden;
background-color: var(--themeColor);
background-color:white;
color: var(--themeColor);
border-style: solid;
border-width: 1px;
border-color:var(--themeColor);
}


.CourseViewDashboard_v2_uploadCourseImageButton__3y3Su{

position: absolute;
left:calc( 0.6 * var(--headerHeight) );
top: calc( 0.6 * var(--headerHeight) );
font-size: calc( 0.6 * var(--headerHeight) );
cursor: pointer;
visibility: hidden;
background: white;
border-style: none;
opacity:0.7;
border-radius: 5px;
}


.CourseViewDashboard_v2_picDiv__1WeL5:hover .CourseViewDashboard_v2_uploadCourseImageButton__3y3Su{
visibility: visible;
}

.CourseViewDashboard_v2_picDiv__1WeL5:hover{
cursor: inherit;
}





.Login_mainDiv__1hvpq {
  /* width: 100vw;
  height: 100%;

  width: 99%;
  height: 99%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 99vh;
  width: 99vw;
  margin: auto;
  border-style: none;
}

.Login_subMainDiv__G_Vwp {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;


  width: 800px;
  aspect-ratio:3/2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;
  border-style: none;
}


.Login_logoDiv__33Tr5{
border-style: none;

}

.Login_logoImage__2lmDh{
height: 80px;
width: 250px;
margin-top: 20px;
}



.Login_image__804er {

  width: 100%;
  height: 100%;
}
.Login_coverImg__12skX {
  /* width: 450px;
  height: 550px; */
  width: 100%;
  height: 100%;
}

.Login_parentdiv__27AGB {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  /* width: 450px;
  height: 550px; */
  width: 100%;
  height: 100%;
  background-color: white;
  justify-content: space-between;
}

.Login_topBar__Tov5J {
  border-bottom: 1px solid;
  border-bottom-color: var(--cardBorderColorLight);

  /* height: 15%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.Login_logoContainer__6N-R6 {
  background-color: var(--themeColor);
  width: 50px;
  height: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.Login_logoContainer__6N-R6:hover {
  cursor: pointer;
  border: none;
}

.Login_textDI__1I-pF {
  font-size: 24px;
  color: white;
  font-weight: 800;
}

.Login_loginTitle__3GxDX {
  color: black;
  margin-left: 10px;
  font-size: 26px;
  font-weight: bold;
  height: 60px;
  margin-top:20px;
}

.Login_midContent__59rBg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid red;
  border-style: none; 
}
.Login_loginTitleText__3rXcp {
  font-size: 20px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-decoration: underline; */
}

.Login_mainContainerA1__2HsnN{
  width: 60%;
  margin-top: 20px;
  display: flex;
  align-items: start;
  justify-content: start;
  /*border: 1px solid red;*/


}

.Login_mainContainer__23-Yo {
  margin-top: 16px;
  display: flex;
  align-items: center;
  position: relative;
  border-style: solid;
  width: 315px;
  border-color: var(--themeColor);
  border-radius: 5px;
  height: 45px;
}


.Login_flagcode__1qF5B{
display: flex;
height: 80%;
align-items: center;
}




.Login_fieldtype__3hT3U{
font-size: 15px;
margin-left:10px;
margin-top:5px;
color: red;
max-width: 70%;
}



.Login_infoAboutMobEmail__bX14f{
position: absolute;
top: 28px;
background-color: white;
color: grey;
z-index: 999;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
padding: 20px;
border-radius: 10px;
visibility: hidden;
height:200px;
}


.Login_infoLine__2Pcxy{

margin-top:10px;
}

.Login_mainContainer__23-Yo:hover .Login_infoAboutMobEmail__bX14f{

visibility: visible;
}





.Login_flagImg__1YxNN {
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 2px;
  margin-left: 10px;

}

.Login_countryCode__383up {
  margin-left: 10px;
  color: var(--greyTextColor);
  font-size: 20px;
  font-weight: bold;
  color: black;
  display: flex;
  border-style: none;
  height: 100%;
  align-items: center;
  padding-right:5px;
}
.Login_editmobileNuBox__LtNp9 {
  border-radius: 4px;
  /* background-color: #f0f2f5; */
  height: 40px;
  border: 2px solid var(--themeColor);
  font-size: 20px;
  margin: auto;
  border-style: none;
  font-weight: bold;
  width:50%;
}

.Login_editmobileNuBox__LtNp9:focus {
  padding-left: 8px;
  padding-right: 8px;
  border-style: none;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColorLight);
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_captcha__2Stx_ {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  /* border: 1px solid red; */
}
.Login_grecaptcha__BcVdo {

  /* height: 40px;
  width: 60%; */
  /* place-items: center; */
  display: flex;
  justify-content: center;
  /* align-self: center;
  transform: scale(0.69);
  -webkit-transform: scale(0.69);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0; */
  -webkit-transform:scale(0.6);
          transform:scale(0.6);
  -webkit-transform-origin:1 0;
          transform-origin:1 0;
}

.Login_sendOtpbtn__3l5P0 {
  margin-top: 20px;
  background-color: var(--themeColor);
  color: white;
  height: 45px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 700;
  border: none;
  /* border: 1px solid red; */
}

.Login_sendOtpbtn__3l5P0:hover {
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  cursor: pointer;
}



.Login_createBtn__12tlS {
  margin-top: 20px;
  background-color: var(--themeColor);
  color: white;
  height: 50px;
  width:320px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  border: none;
  /* border: 1px solid red; */
}

.Login_createBtn__12tlS:hover {
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  cursor: pointer;
}




.Login_horizontalContainer__3aXFd {
  margin-top: 30px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.Login_horizontalContainer2__ltRVI{
  margin-top: 30px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login_leftLine__21AtR {
  width: 50%;
  border-bottom: 1px solid var(--cardBorderColorLight);
  border-bottom-color: var(--cardBorderColorLight);
}

.Login_orText__QD3S4 {
  font-size: 10px;
  padding: 4px;
  font-weight: 700;
  border: 1px solid var(--cardBorderColorLight);
  border-radius: 50%;
  color: var(--cardBorderColorLight);
  background-color: var(--themeColor);
}
.Login_rightLine__bz1PQ {
  width: 50%;
  border-bottom: 1px solid var(--cardBorderColorLight);
  border-bottom-color: var(--cardBorderColorLight);
}

.Login_signInContainer__3jSMf {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 60%;
  /* min-width: 300px; */
}

.Login_googleSignINContainer__2rGRq {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid var(--cardBorderColorLight);
  border-radius: 4px;
}

.Login_googleSignINContainer__2rGRq:hover {
  background-color: white;
  cursor: pointer;
}

.Login_googleICON__3YHqP {
  margin-left: 10px;
}

.Login_signInGoogleTitle__3Zgyp {
  font-size: 13px;
  color: var(--deepDarkThemeTextColor);
  margin-left: 10px;
}

.Login_facebookICON__3Br47 {
  margin-left: 10px;
  color: #3b5998;
}

.Login_facebookSignINContainer__1qsVF {
  height: 30px;

  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid var(--cardBorderColorLight);
  border-radius: 4px;
}

.Login_facebookSignINContainer__1qsVF:hover {
  background-color: white;
  cursor: pointer;
}

.Login_signInFacebookTitle__2Lu2R {
  font-size: 13px;
  color: var(--deepDarkThemeTextColor);
  margin-left: 10px;
}

.Login_linkedinICON__e_OqP {
  margin-left: 10px;
  color: #0a66c2;
}

.Login_linkSignINContainer__NE2zV {
  height: 30px;

  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid var(--cardBorderColorLight);
  border-radius: 4px;
}

.Login_linkSignINContainer__NE2zV:hover {
  background-color: white;
  cursor: pointer;
}

.Login_signInLinkTitle__2rKJ6 {
  font-size: 13px;
  color: var(--deepDarkThemeTextColor);
  margin-left: 10px;
}

.Login_textContainer__119bN {
  height: 50px;
  width: 400px;
  margin-top: 20px;
  font-size: 14px;
  color: var(--greyTextColor);
  border: 1px solid red;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
}

.Login_hintText__2KKvj {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Login_termTitle__KxXd_ {
  color: var(--themeColor);
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
}

.Login_privacyTitle__pJRyX {
  color: var(--themeColor);
  font-weight: 500;
  margin-left: 10px;
}

.Login_bottomBar__34lUE {
  border-top: 1px solid;
  border-top-color: var(--cardBorderColorLight);
  /* height: 15%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 12%;

}

.Login_bottomTopBar__2SKye {
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login_textTitleBottom__iSmC0 {
  margin-top: 20px;
  font-size: 12px;
  color: var(--greyTextColor);
  padding-left: 10px;
  padding-right: 10px;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.Login_textTitleBottom1__2bYiD {
  /* margin-top: 20px; */
  font-size: 12px;
  color: var(--greyTextColor);
  padding-left: 10px;
  padding-right: 10px;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.Login_contactUS__2q2rd {
  color: var(--greyTextColor);

  border: none;
  background-color: transparent;
}
.Login_contactUS__2q2rd:hover {
  color: var(--themeColor);
  cursor: pointer;
  font-weight: 500;
}

.Login_termOfService__3vgfZ {
  color: var(--greyTextColor);

  border: none;
  background-color: transparent;
}
.Login_termOfService__3vgfZ:hover {
  color: var(--themeColor);
  cursor: pointer;
  font-weight: 500;
}

.Login_privacyText__2Ewny {
  color: var(--greyTextColor);

  border: none;
  background-color: transparent;
}

.Login_privacyText__2Ewny:hover {
  color: var(--themeColor);
  cursor: pointer;
  font-weight: 500;
}

.Login_texttitle__dkV_f {
  font-size: 14px;
  color: var(--deepDarkThemeTextColor);
  margin-right: 10px;
}

.Login_create__1wBkM {
  margin-left: 6px;
  font-size: 14px;
  font-weight: 600;
  color: var(--themeColor);
  background-color: transparent;
  border: none;
}

.Login_create__1wBkM:hover {
  cursor: pointer; 
  border-radius: 4px;
  text-decoration: underline;
}


/* ============================================================================= */

/* ACCOUNT SUCCESSFULLY CREATED PAGE CSS */

.Login_mainContainerOpt__WD3TU {
  margin-top: 50px;
  display: flex;
  width: 300px;
  max-width: 300px;
  justify-content: space-around;
}

.Login_num1Box__1d8u8 {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num1Box__1d8u8:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num2Box__MWtNE {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num2Box__MWtNE:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num3Box__1f7Wm {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num3Box__1f7Wm:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num4Box__29JVl {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num4Box__29JVl:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num5Box__1-Rtr {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num5Box__1-Rtr:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_procedToLogin__Yd9vu {
  background-color: var(--themeColor);
  color: white;
  height: 30px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  border: none;
}


.Login_procedToLogin__Yd9vu:hover{
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  cursor: pointer;
}


.Login_successIconDiv__2YGEa {
  height: 50px;
  width: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Login_iconSuccess__29FCr {
  height: 40px;
  width: 40px;
  color: var(--themeColor);
}

/* ============================================================================= */


/* OTP PAGE CSS */

.Login_mainContainerOpt__WD3TU {
  margin-top: 50px;
  display: flex;
  width: 300px;
  max-width: 300px;
  justify-content: space-around;
}

.Login_num1Box__1d8u8 {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num1Box__1d8u8:focus {

  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num2Box__MWtNE {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num2Box__MWtNE:focus {

  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num3Box__1f7Wm {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num3Box__1f7Wm:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num4Box__29JVl {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num4Box__29JVl:focus {

  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num5Box__1-Rtr {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num5Box__1-Rtr:focus {

  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}


.Login_submitOtpbtn__1weCo{
  margin-top: 50px;
  background-color: var(--themeColor);
  color: white;
  height: 45px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  border: none;
}

.Login_submitOtpbtn__1weCo:hover {
  margin-top: 50px;
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  cursor: pointer;
}



.Login_userExistSymbol__3APnD{
color: green;
}


.Login_userExistSymbol2__ffgqh{
color: red;
}




.Login_sendOTPButton__3JHfi{

background-color: var(--themeColor);
padding: 10px;
border-style: none;
border-radius: 5px;
color: white;
}



.Login_diracAIButton__1HgUj{
border-style: none;
background-color: white;
cursor: pointer;
}
.Login_logologin__2zeBH{

background-color:#224C98;
border-radius: 5px;
color: white;
font-weight: bold;
padding: 5px;
margin-right: 5px;
}



/* =====================================================================*/
@media (max-width: 900px) {
  .Login_mainDiv__1hvpq {
    /* width: 100%; */
    /* aspect-ratio: 45/60; */
  }


  .Login_subMainDiv__G_Vwp {
    width: 90%;
    max-width: 400px;
    height: 90%;
    padding: 10px;

    border-style: none;
  }

  .Login_image__804er {
    display: none;
  }

  .Login_parentdiv__27AGB {
    width: 100%;
    border-style: none;
  }

  /* .image {
    width: 100%;
    height: 300px;
  }

  .parentdiv {
    width: 100%;
    height: 100%;
    max-width: 500px;
  }

  .topBar {
    height: 10%;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .midContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40%;
  }

  .bottomBar {
    margin-top: 20px;
    padding-top: 20px;
    height: 10%;
  } */
}

@media (min-width: 1400px) {
  .Login_subMainDiv__G_Vwp {
    width: 900px;
  }

  .Login_grecaptcha__BcVdo{
    -webkit-transform:scale(0.8);
            transform:scale(0.8);
  -webkit-transform-origin:1 0;
          transform-origin:1 0;
  }
}

.Login_homeRedirectButton__3evqU{

margin-top: 20px;
margin-left: 50px;
padding: 20px;
border-style: none;
border-radius: 5px;
cursor: pointer;
}

.Login_homebuttonDiv__31KvX{
width: 100%;
}


.Login_alreadyRegisteredDiv__1qDGP{
margin-top:10px;
}

.Login_alreadyloginnow__2mqp6{
border-style: none;
background-color: white;
color: var(--themeColor);
font-size: 16px;
cursor: pointer;
}




.OTPinputUnit_otpUnit__2hz5Y{

height: 40px;
width: 30px;
font-size: 20px;
border-style: solid;
border-width: 1px;
border-radius: 5px ;
border-color: var(--cardBorderColor);
display: flex;
justify-content: center;
padding:5px;
overflow: hidden;

}

.OTPinputUnit_otpUnit__2hz5Y:focus{
border-style: solid;
border-width: 1px;
outline: none !important;
border-color: var(--themeColor);
border-radius:5px;
align-items: center;
padding:5px;
}




.LoginHeader_loginHeader__37kTg{

height: var(--headerHeight);
width: 100%;
display: flex;
align-items: center;
border-bottom: none;
border-color: lightgrey;
border-width: 1px;
/*box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
*/

}

.LoginHeader_homeIcon__2arrj{

height: calc( 0.6 * var(--headerHeight) );
width: calc( 0.6 * var(--headerHeight) );
margin-left: 50px;
color: var(--themeColor);
}


.LoginHeader_loginHeader__37kTg i{
font-style: normal;
color: var(--themeColor);
margin-left: 30px;
text-decoration: none;
}

.LoginHeader_goBackHome__1EASl{
border-style: none;
background-color: var(--lightThemeColor1) ;
cursor: pointer;


}

.Register_registerParent__Vi08B{
border-style: none;
height:100vh;
width:100vw;
align-items: center;
background-color: var(--bodyBkgColor);
}


.Register_registerDiv__3lmzt{
width: 100%;
max-width: 500px;
border-style: solid;
height: 85vh;
border-radius: var(--cardBorderRadius);
margin: auto;
background-color: white;
display: flex;
flex-direction: column;
margin-bottom: 30px;
padding-bottom: 30px;
border-color: var(--cardBorderColor);
border-width: 1px;
}


.Register_header__2Xg5e{

border-style: solid;
width: 100%;
height: 150px;
display: flex;
flex-direction: column;
justify-content: center;
color: var(--themeColor);
}



.Register_titleDiv__2gQBo{
width: 80%;
height:100px;
margin-top:100px;
color: var(--deepDarkThemeTextColor);
border-style: none;
margin: auto;
display: flex;
flex-direction: row;
justify-content: center;
display: flex;
align-items: center;
}

.Register_switchMethodDiv__1DbNq{
width: 80%;
height:30px;
border-style: none;
margin: auto;
}

.Register_phoneNumberDiv__15pp-{
width: 80%;
height:115px;
border-style: none;
margin: auto;

}

.Register_phonetitle__1C9d9{
font-size: 18px;
height:30px;
}

.Register_enterPhoneDiv__2G-Hj{
height: 50px;
border-style: none;
display: flex;
justify-content: space-between;

}


.Register_enterOTPDiv__10EGo{

height: 50px;
border-style: none;
display: flex;
justify-content: space-between;


}


.Register_otpUnit__chrFq{

height: 40px;
width: 30px;
font-size: 25px;
border-style: solid;
border-radius: calc( 0.5 * var(--cardBorderRadius) ) ;
border-color: var(--cardBorderColor);

}









.Register_phonetnc__YWKMh{
font-size: 12px;
height: 25px;
color: grey;
border-style:none;
display:flex;
justify-content:center;
flex-direction:column;
}




.Register_usertypeDiv__2wCT5{
width: 80%;
height:100px;
border-style: none;
margin: auto;
padding-top: 50px;

}




.Register_countryCodes__1DMg4{

width:150px;
display: flex;
align-items: center;

}


.Register_countryCodes__1DMg4 i{

font-style: normal;
font-size: 20px;
color: grey;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


.Register_usernameInput__3eCJh{
width: 100%;
height: 100%;
font-size: 22px;
box-sizing: border-box;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
border-radius: var(--cardBorderRadius);
padding-left: 5%;
}

.Register_usernameInput__3eCJh:focus{
outline: none !important;
border-style: solid;
border-width: 2px;
border-color:var(--themeColor);

}





.Register_inflag__3tiMY{
width:60px;
height:40px;

}


.Register_chooseUserTypeTitle__gJbIW{
height:30px;
display:flex;
flex-direction: column;
justify-content: center;
border-style: none;
font-size: 18px;
}



.Register_userTypeOptions__8dLBz{

height:70px;
border-style: none;
}



.Register_userTypeOptions__8dLBz{
display: flex;
flex-direction: column;
justify-content: center;

}


.Register_userTypeOptionsInner__X6YdO{
display: flex;
justify-content:space-between;
color: var(--themeColor);
font-weight: bold;

}





.Register_submitButtonDiv__3xtvF{
width: 80%;
height:50px;
border-style: none;
margin: auto;
margin-top: 50px;
display: flex;
justify-content: center;
}


.Register_sendOTPButton__1UEyT{

height: 40px;
width:60%;
border-style: none;
cursor: pointer;
border-radius: var(--cardBorderRadius);
}

.Register_radioButton__38flq{
cursor: pointer;
height:20px;
width: 20px;
}


.Register_linkToAccountCreation__2lvWU{
border-style: none;
background-color: white;
text-decoration: underline;
color: var(--themeColor);
cursor: pointer;
}




.Website_website__3ORt4 {
  align-items: center;
}

.Website_innerDiv__1ZsT8{
  width: 1200px;
  background-color: white;
  margin: 0 auto;
  border-style: none;
}

.Website_headrparent__2fKxK {
  border: 1px solid red;
}

.Website_backgroundPicDiv__3kmqw {
  border-style: solid;
}

@media (max-width: 1300px) {
  .Website_innerDiv__1ZsT8 {
    width: 80%;
  }
}

.Website_topbar__anUC0 {
  width: 100%;
  border: 1px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Website_rightContainer__2AE32 {
  display: flex;
}

.Website_firstTopContainer__2uY4n {
  display: flex;
}

.Website_emailIcon__19qs5 {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  background-color: #5e5d5d;
}

.Website_textEmail__16KuD {
  margin-left: 10px;
}

.Website_secTopContainer__2ET2l {
  margin-left: 20px;
  border: 1px solid green;
  display: flex;
}

.Website_mobileIcon__2O9WV {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  background-color: #5e5d5d;
}

.Website_mobileNumber__2w1sT {
  margin-left: 10px;
}

.Website_socialiconContainer__1h4ka {
  background-color: #207ef5;
  border: 1px solid red;
  display: flex;
  margin-right: 10px;
}

.Website_fbicon__3hwe_ {
  margin: 10px;
  background-color: grey;
  height: 26px;
  width: 26px;
}

.Website_topNavigationBar__3EW1U {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid red;
}

.Website_nFirstCotainer__2qiTx {
  display: flex;
  align-items: center;
}
.Website_logo__ynx4l {
  height: 50px;
  width: 100px;
  background-color: #207ef5;
}

.Website_Name__N44mm {
  margin-left: 10px;
  font-size: 26px;
  font-weight: bold;
}
.Website_navigationMenu__2OmLS {
  display: flex;
}

.Website_websiteContent2__WeWp- {
  margin-top: 50px;
  width: 100%;
  display: flex;
}

.Website_HomeImageContainer2__PqF-W {
  width: 50%;
  background-color: green;
}

.Website_detailContainer2__4wSGl {
  margin-left: 20px;
  width: 50%;
}

.Website_aboutHeading1__25fOC {
  color: var(--themeColor);
  font-size: 20px;
}

.Website_aboutHeading2__3EP-9 {
  margin-top: 20px;
  color: var(--darkText);
  font-size: 40px;
}

.Website_aboutDetails__11A4r {
  margin-top: 20px;
  width: 280px;
  font-size: 16px;
  color: var(--lightText);
}

.Website_footer__33Njg {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #207ef5;
  padding: 10px;
}

.Website_block1__12h2g {
  width: 30%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Website_logotextContainer__1JupW {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Website_logopic__21Cll {
  width: 100px;
  background-color: white;
  height: 50px;
}

.Website_websiteNametext__1SvRE {
  margin-left: 10px;
  font-size: 20px;
}

.Website_websiteDetail__1dq_v {
  margin-top: 10px;
  color: white;
  font-size: 14px;
}

.Website_block2__3nH00 {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Website_cont1__2uIM7 {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Website_cont1Icon__w33_1 {
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
}

.Website_cont1Text__I0T9g {
  margin-left: 10px;
  color: white;
}

.Website_block3__HGa8- {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Website_block4__2SeYR {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Website_cont4__2wGL3 {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Website_cont4Icon__3GNZb {
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 30px;
}

.Website_cont4textContainer__2x7AE {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

/*background-image: url("tbg.png");*/

.Website_backgroundImage__yo5OG {
/*   

  background-image: url("https://media.istockphoto.com/id/941562950/photo/hand-holding-another-hand.jpg?s=1024x1024&w=is&k=20&c=2olan6rtEiTXh8DskRG1QeZHRxijJJv_iYLAbfTXnRc=");

  background-image: url("https://media.istockphoto.com/id/625736338/photo/stack-of-hands-showing-unity.jpg?s=2048x2048&w=is&k=20&c=m0q5TaMHzRZEzojxhGIFsFMCwWdHdkFwqtAAi1Bf67Q="); */

  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Optional: This property ensures that the background image remains fixed when scrolling */
}




/* .backgroundImage::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 128, 0, 0.1); 

  } */
/* ================================================ */
/* .website {
  position: relative;

} */

.Website_carousel-content__3ReBK{
  transition-timing-function: ease-out;
  
}

.Website_mainContainer__3iHi- {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Website_arrow__1fjXT {
  top: 50%;
  font-size: 24px;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.Website_left-arrow__3YI_Q {
  visibility: hidden;

}

.Website_right-arrow__1OKf5 {
  visibility: hidden;

}



@media (max-width: 471px) {
  .Website_website__3ORt4{
  width: 100%;
  }

  .Website_innerDiv__1ZsT8{
    /* margin-left: 0; */
    /* margin-right: 0; */
  }

 
  }
.Header_topbar__dXW4p {
  width: 100%;
  display: flex;
  justify-content: center;
  border-style: none;
  height: 70px;
  background-color: white;
}

.Header_parentFooter__2QReY {
  width: 80%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.Header_inner_topbar__3UBnv {
  width: 1200px;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  display: grid;
  justify-self: center;
  align-self: center;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  background-color: white;
  border-style:none;
}

.Header_topNavigationBar__axKlu {
  padding-right: 10px;
  padding-left: 10px;
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
  border-color: red;
  align-items: center;
  /* background-color: var(--themeColor); */
  /* border: 1px solid var(--themeColor); */
  border-style: none;
  background-color: var(--themeColor);
  background-color: white;
  /* margin-top: 25px; */
  border-bottom-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  padding-bottom: 20px;
}

/* .inner_topbar {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  background-color: var(--themeColor);
  margin: auto;  
} */

.Header_rightContainer__1d4DV {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
  border-color: orange;
}

.Header_firstTopContainer__3ccc- {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: flex-end;
  align-items: center; */
}

.Header_helloToppersText__11ab3{
font-weight: bold;
margin-left: 10px;
font-size: 25px;
}



.Header_logoCSS__2nioK {
display: flex;
justify-content: center;
align-items: center;
}

.Header_companyName__1ev1Q{
font-size:30px;
font-weight: bold;
color: var(--darkTextColorDi);
margin-left: 6px;
font-size: 35px;
  font-weight: 700;
  font-family: "Roboto", serif;
  color: var(--darkTextColorDi);
}



.Header_textEmail__1mS9H {
  margin-left: 10px;
  color: var(--lightThemeColor1);
}

.Header_secTopContainer__2fOrM {
  margin-left: 20px;
  display: flex;
}

.Header_mobileIcon__39cAi {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  background-color: #5e5d5d;
}

.Header_mobileNumber__3tb6w {
  margin-left: 10px;
}

.Header_socialiconContainer__1xMNK {
  display: flex;
  justify-content: end;
  align-items: end;
  border-style: none;
  border-color: cyan;
}

.Header_innerSocialMediaContainer__1dBrB{
  display: flex;
  justify-content: end;
  align-items: end;
  border-color: cyan;
}



.Header_fbicon__Ol6I8 {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  border-style: none;
  background-color: var(--lightThemeColor1);
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.Header_parentFooter__2QReY {
  width: 100%;
  background-color: var(--themeColor);
  background-color: white;
}

.Header_nFirstCotainer__ola8h {
  display: flex;
  align-items: center;
  border-style: none;
  background-color: transparent;
}

.Header_nFirstCotainer__ola8h{
  cursor: pointer;
}

.Header_navigationMenu__1eXfn {
  display: flex;
  border-style: none;
}

.Header_navigationMenuMobile__3MX_j{
display: none;
border-style: solid;
border-color: red;

}



.Header_downButtonHeader__smbAI{

visibility: hidden;
}



@media (max-width: 800px) {
  .Header_inner_topbar__3UBnv {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: space-between;
  }

  .Header_topNavigationBar__axKlu {
    width: auto;
    border-style: none;
    border-color: var(--themeColor);
    border-width: 1px;
    background-color:var( --chatCommentBkgColor);
  }


  .Header_navigationMenu__1eXfn {
    flex-direction: column;
    border-style: none;
    height: 200px;
    display: none;
  }

  .Header_navigationMenuMobile__3MX_j{
  
   display: flex;
   flex-direction: column;
   border-style: none;
  }




  .Header_downButtonHeader__smbAI{
   visibility: visible;	
   border-style: none;
   width: 50px;
   color: var(--themeColor);
   background-color: white;
   cursor: pointer;
   }

   .Header_registrationButton__1wRs0{
   display: none;

   }


  .Header_socialiconContainer__1xMNK{
   width: 50%;

  }

  .Header_parentFooter__2QReY{
  
   border-radius: 20px 20px 0px 0px;

  }
  .Header_helloToppersText__11ab3{
  visibility: hidden;
  }



}

.Header_logo__2kbWP {
  height: 50px;
  width: 60px;
}

.Header_Name__2MHD5 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: var(--themeColor);
}

.Header_headerButtonDiv__1Ts3n {
  margin-left: 30px;
  border-style: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.Header_headerButton__10qwG {
  font-family: Roboto;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-style: none;
  cursor: pointer;
  background-color: transparent;
}

.Header_headerButton__10qwG:hover {
  color: white;
}

.Header_underlineDiv__2COYu {
  height: 3px;
  width: 90%;
  border-radius: 10px;
  margin-top: 3px;
}

.Header_headerButtonText__1tl9I {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--darkTextColorDi);
  font-weight: 400;
}

.Header_dropdownOne__2kQgZ {
  height: 130px;
  width: 170px;
  border-style: solid;
  position: absolute;
  padding: 5px;
}

.Header_csslogo__2NidG {
  font-size: 30px;
  font-weight: bold;
  background-color: var(--themeColor);
  color: white;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /*background-image: linear-gradient(to right, var(--themeColor), #d7e7fc);*/
}

.Header_styleDiv1__QXdpT {
  width: 10px;
  color: red;
  height: 4px;
  position: absolute;
  border-style: none;
  top: 21px;
  left: 10px;
  z-index: 9999;
  border-radius: 0px 5px 5px 0px;
  background: transparent;
  background-color: var(--themeColor);
}

.Header_styleDiv2__2WZ6R {
  width: 10px;
  color: red;
  height: 4px;
  position: absolute;
  border-style: none;
  top: 13px;
  left: 10px;
  z-index: 9999;
  border-radius: 0px 5px 5px 0px;
  background-color: var(--themeColor);
}


@media (max-width: 1300px) {
  .Header_topNavigationBar__axKlu {
    width: auto;
  }






  .Header_inner_topbar__3UBnv {
    margin: auto;
    width: 80%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Header_textEmail__1mS9H {
    font-size: 14px;
  }

  .Header_rightContainer__1d4DV {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: none;
    border-color: red;
    height: 100%;
    width: 100%;
    min-width: 100px;
  }


  .Header_firstTopContainer__3ccc- {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
  }
}



.Header_loginButton__pGizD{

  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  border-style: none;
  background-color: var(--lightThemeColor1);
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  width: 100px;


}


.Header_dropDownBarButton__jG9Lr{
 margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  border-style: none;
  background-color: var(--lightThemeColor1);
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  width: 100px;
  visibility: hidden;
}







.Header_registrationButton__1wRs0{
 margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  border-style: none;
  background-color: var(--lightThemeColor1);
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  width: 100px;

}







@media (max-width: 800px) {


 .Header_registrationButton__1wRs0{
 display: none;
 }
 
 .Header_dropDownBarButton__jG9Lr{
 visibility: visible;
 }


.Header_innerSocialMediaContainer__1dBrB{

   visibility: hidden;

  }

  .Header_headerButtonDiv__1Ts3n{
  margin-bottom: 10px;
  }



.Header_fbicon1__1NWLA{
visibility: hidden;
}

.Header_fbicon__Ol6I8{
visibility: hidden;
}

}

.Header_HomeText__kKiR3 {
  font-weight: bold;
  color: var(--themeColor);
}


.Header_logoImage__3Txb0{
width:40px;
height: 40px;

}



@media (max-width: 768px) {
  .Header_headerButtonDiv__1Ts3n{
    margin-left: 0;
  }
  .Header_topNavigationBar__axKlu{
    padding-left: 0;
  }
  .Header_navigationMenuMobile__3MX_j{
    margin-top: 20px;
  }
  }


.DropDownOne_dropdownOne__fwXHo{
width: 200px;
border-style: none;
position: absolute;
padding: 5px;
background-color: white;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
padding: 15px;
border-radius: 10px;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}



.DropDownOne_dropdownTwoDiv__1Q_Op{
width: 150px;
border-style: none;
position: absolute;
background-color: white;
display: flex;
flex-direction: column;
justify-content: flex-start;
visibility: hidden;
left: 50px;
padding: 20px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
border-radius: 10px;
}




.DropDownOne_dropdownOneDiv3__2a6zQ:hover .DropDownOne_dropdownTwoDiv__1Q_Op{

visibility:visible;	

}







.DropDownOne_dropDownTwoButton__FYxL5{

 border-style: none;
 background-color: white;
 font-size: 16px;
 padding: 10px;
 cursor: pointer;
 height: 40px;
 display: flex;
 justify-content: flex-start;
}


.DropDownOne_dropDownTwoButton__FYxL5:hover{

color: var(--themeColor);	
}



.Footer_footer__2Vjt9 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  background-color: var(--themeColor);
  padding-top: 30px;
  padding-bottom: 30px;

}

.Footer_mainFooterContainer__1PWNn {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, auto));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 0px;
  -webkit-column-gap: 0px;
          column-gap: 0px;

  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.Footer_cont44__38c6R {
  visibility: hidden;
}

@media (max-width: 1300px) {
  .Footer_mainFooterContainer__1PWNn {
    width: 80%;
  }
}

.Footer_csslogo__35LMF {
  font-size: 30px;
  font-weight: bold;
  background-color: var(--themeColor);
  color: white;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /*background-image: linear-gradient(to right, var(--themeColor), #d7e7fc);*/
}

.Footer_styleDiv1__31YEl {
  width: 10px;
  color: red;
  height: 4px;
  position: absolute;
  border-style: none;
  top: 21px;
  left: 10px;
  z-index: 9999;
  border-radius: 0px 5px 5px 0px;
  background: transparent;
  background-color: var(--themeColor);
}

.Footer_otherLink__32TVP {
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}

.Footer_block1__NO3vh {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Footer_logotextContainer__3OmHh {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.Footer_logopic__GzFFz {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer_websiteNametext__2BHZ7 {
  margin-left: 10px;
  font-size: 20px;
}

.Footer_websiteDetail__fY4xX {
  margin-top: 10px;
  color: var(--footerText);
  color: white;
  font-size: 14px;
  text-align: justify;
  width: 80%;
}

.Footer_block2__39Z-e {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
}

.Footer_cont1__2nD6g {
  margin-top: 16px;
  display: flex;
  justify-content: start;
  align-items: center;
  color: var(--footerText);
  font-size: 16px;
  background-color: transparent;
  border: none;
}

.Footer_cont1__2nD6g:hover {
  cursor: pointer;
}

.Footer_cont2__SrsQh {
  margin-top: 16px;
  display: flex;
  justify-content: s;
  align-items: center;
  color: var(--footerText);
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.Footer_cont1Icon__UDIqV {
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 50%;
}

.Footer_cont1Text__2FQCn {
  margin-left: 10px;
  color: white;
  font-size: 14px;
  text-align: left;
}
.Footer_address__1nB21{
  margin-bottom: 10px;
}
.Footer_header__9RHsb {
  margin-bottom: 5px;
}
.Footer_block3__3c9Fo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Footer_block4__24NQl {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Footer_cont4__noGNV {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Footer_cont4Icon__fw95c {
  height: 20px;
  width: 20px;
  background-color: #185fb8;
  background: linear-gradient(#dfe3e9, #ffffff);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--themeColor);
  padding: 10px;
  margin-right: 6px;
}

.Footer_cont4textContainer__3-Ij6 {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  color: white;
}

.Footer_cont4Title1__2rbOG {
  font-size: 14px;
}

.Footer_cont4Title2__OwpL2 {
  margin-top: 5px;
  font-size: 14px;
}

.Footer_horiLine__xAGLX {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: 1px;
  border-bottom: 1px solid #70a188;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Footer_bottomBar__byU4Z {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.Footer_text1__2woX0 {
  color: white;
}

.Footer_cmpDetail__ECJYf {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer_powerBy__WOLys {
  margin-right: 10px;
  color: white;
}

.Footer_footerLogoBtn__3imWB {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
}

.Footer_footerLogoBtn__3imWB:hover {
  cursor: pointer;
}

.Footer_logo__1moVx {
  height: 35px;
  width: 35px;
  background-color: #185fb8;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: white;
  font-size: 18px;
}
.Footer_websiteDetails__3kMsQ {
  margin-left: 10px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.Home_homeParent__2q6-Q {
  width: 100%;
}

.Home_topBar__2oT1w {
  width: 100%;
  height: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home_National__2rtmi {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

.Home_State__3TGXJ {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

.Home_newsBlock__2-M5K {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.Home_latest__EKOQ- {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}


.Home_explorebutton__1s5-g {
  background-color: var(--themeColor); 
  color: white;
  border: none;
  padding: 12px 25px; 
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s, -webkit-transform 0.3s;
  transition: background-color 0.3s, transform 0.3s;
  transition: background-color 0.3s, transform 0.3s, -webkit-transform 0.3s;
  text-decoration: none; 
  display: inline-block; 
}

.Home_explorebutton__1s5-g:hover {
  background-color: #0056b3;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.Home_dashoAppLink__2yZlF{
border-style: none;
background-color: white;
color: var(--themeColor);
cursor: pointer;
font-size:16px;

}


.Home_productLinkDiv__1vZrT{
width: 100%;
border-style: none;
display: flex;
justify-content: center;
}
.Home_ExamBoxSlide_img__d8kyu__32bvN{
  object-fit: cover;
}
.Home_productLinkDiv__1vZrT>img{
  object-fit: cover;
}



.Home_latestTitle__1xW46 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.Home_latestsubTitle__2m8AL {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.Home_panImageBkg__1K5F2 {
  background-color: #e7efeb;
  background-repeat: no-repeat;
  height: auto;
  background-image: url("https://images.unsplash.com/photo-1523192193543-6e7296d960e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}

.Home_newsBlock__2-M5K {
  width: 100%;
}

.Home_stateNewsBlock__2aW-7 {
  width: 100%;
}

.Home_mainExamTitle__2LnNH {
  width: 100%;
  margin-top: 50px;
  font-size: 40px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--deepDarkThemeTextColor);
}

.Home_HowitWorksContainer__L2ux1 {
  margin-top: 50px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Home_HowitworksTitle__21rYw {
  font-size: 40px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
}

.Home_helloTopContainer__2qPmU {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  border-style: none;
}

.Home_mainWhytoppersContainer__1ju6g {
  margin-top: 30px;
  margin-bottom: 20px;
  background-color: var(--chatCommentBkgColor);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.Home_whyToppersTitle__VqmF0 {
  font-size: 40px;
  font-weight: bold;
  background-color: var(--chatCommentBkgColor);
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
}

.Home_whyToppersContainer__1rytw {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  background-color: var(--chatCommentBkgColor);
}

.Home_ExamContainer__2uNv6 {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.Home_SolvingItChanllengesBlockTitle__21j0R {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  font-size: 40px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
}

.Home_bottombox__2Sjgw {
  margin-top: 50px;
  padding-bottom: 30px;
  padding-top: 50px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--chatCommentBkgColor);
  border-style: none;

  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
}

.Home_marginDivBottom__nDpvz {
  border-style: solid;
  width: 80%;
}

.Home_bTitle__2dasK {
  margin-top: 15px;
  font-size: 30px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
}

.Home_bdetails__Ek-ae {
  margin-top: 15px;
  font-size: 20px;
}

.Home_bbutton__gXl4F {
  margin-top: 25px;
  width: auto;
  height: 40px;
  border-radius: 4px;
  background-color: var(--themeColor);
  color: white;
  font-size: 16px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;

}

.Home_bbutton__gXl4F:hover {
  border: 1px solid var(--themeColor);
  background-color: white;
  cursor: pointer;
  color: var(--themeColor);
}

.Home_paarent__2vY46 {
  width: 350px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 20px;
  font-family: Roboto;
  border-style: none;
  cursor: pointer;
}

.Home_paarent__2vY46:hover {
 border-style: solid;
 border-width: 1px;
 border-color: var(--themeColor);
}

.Home_logo__yTfcW {
  margin-top: 30px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home_imagesExam__KH-TF {
  width: 80px;
  height: 80px;
}


.Home_imagesSAP__2-vO2{
width:80px;
height:50px;

}

.Home_title__2Z19u {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.Home_description__2SCwo {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  text-align: justify;
}


@media (max-width: 1300px) {
  .Home_latestTitle__1xW46 {
    font-size: 20px;
  }

  .Home_latestsubTitle__2m8AL {
    margin-top: 10px;
    font-size: 30px;
  }

  .Home_homeParent__2q6-Q {
    width: 98%;
  }

  .Home_mainExamTitle__2LnNH {
    width: 100%;
    text-align: center;
    font-size: 25px;
  }

  .Home_HowitworksTitle__21rYw {
    width: 100%;
    text-align: center;
    font-size: 25px;
  }

  .Home_HowitWorksContainer__L2ux1 {
  }

  .Home_paarent__2vY46 {
    width: 100%;
  }

  .Home_whyToppersTitle__VqmF0 {
    width: 100%;
    text-align: center;
    font-size: 25px;
  }

  .Home_whyToppersContainer__1rytw {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Home_bottombox__2Sjgw {
    width: 100%;
  }

  .Home_bTitle__2dasK {
    font-size: 22px;
    text-align: center;
  }
  .Home_bdetails__Ek-ae {
    font-size: 18px;
    text-align: justify;
  }
}

.Home_productLinkDiv__1vZrT{
  
  display: flex;
  align-items: center;
}


.Home_dasho__80Yq9 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.Home_titleText__2IPq7 {
  font-size: 40px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
  margin-bottom: 26px; 
  text-align: center;
}

.Home_dashoAppLink__2yZlF {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--themeColor);
  color: white; 
  font-size: 16px; 
  font-weight: bold;
  border-radius: 5px; 
  text-decoration: none;
  transition: background-color 0.3s ease, -webkit-transform 0.2s ease;
  transition: background-color 0.3s ease, transform 0.2s ease;
  transition: background-color 0.3s ease, transform 0.2s ease, -webkit-transform 0.2s ease; 
}

.Home_dashoAppLink__2yZlF:hover {
  background-color: var(--themeColor);
  color: white;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px); 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.Home_dashoAppLink__2yZlF:active {
  -webkit-transform: translateY(0);
          transform: translateY(0); 
  box-shadow: none; 
}

.Home_dasho__80Yq9 img {
  width: 100%;
  max-width: 1200px; 
  height: auto; 
  object-fit: cover;
  margin-bottom: 20px; 
}

.Home_dashoAppLink__2yZlF {
  border-style: none;
  background-color: white;
  color: var(--themeColor);
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
}

.Home_dashoAppLink__2yZlF:hover {
  color: var(--hoverThemeColor);
  /* text-decoration: underline; */
  color: white;
}

@media only screen and (max-width: 600px) {
  .Home_dasho__80Yq9 img {
    width: 100%; 
  }
}


.Home_dashoAppLink__2yZlF:hover {
  color: var(--hoverThemeColor);
  /* text-decoration: underline; */
  color: white;
}
@media only screen and (max-width: 600px) {
    .Home_newsBlock__2-M5K {
        grid-template-columns: repeat(auto-fit, minmax(150px, auto));
    }
    .Home_productLinkDiv__1vZrT {
        flex-direction: column;
        align-items: center;
    }


    .Home_titleText__2IPq7{
      font-size: 20px;
    }
    .Home_SolvingItChanllengesBlockTitle__21j0R{
      font-size: 20px;
      text-align: center;

    }
}



@media (max-width: 471px) {
.Home_homeParent__2q6-Q{
width: 100%;
}

.Home_IntroductionBlockParent__1lPyG{
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column-reverse;
}

.Home_bdetails__Ek-ae{
  width: 88%;
  padding: 20px;
}

}
.IntroductionBlock_MainIntroductionBlockParent__2HbTL {
  width: 100%;
  display: flex;
  background-color: var(--chatCommentBkgColor);
  background-color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.IntroductionBlock_IntroductionBlockParent__r_PRP {
  border-style: none;
  width: 1200px;
  height: 500px;
  display: flex;
  border: 1px solid lightgray;
  padding: 20px;
  border-radius: 10px;
}

.IntroductionBlock_leftBox__1v_fv {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  border-style: none;
}

.IntroductionBlock_rightBox__2TYZ2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.IntroductionBlock_img1__2HG3e {
  width: 250px;
  height: 350px;
  object-fit: fill;
  margin-right: 10px;
  margin-bottom: 100px;
}

.IntroductionBlock_img2__28r4x {
  object-fit: fill;

  width: 350px;
  height: 450px;
}



.IntroductionBlock_imgOneBig__2hawO{
  object-fit: cover; /* Ensures the image is cropped to fit its container */
  width: 100%; /* Image takes the full width of its container */
  max-width: 350px; /* Restrict maximum width */
  height: auto; /* Maintain aspect ratio */
  margin-right: 1rem; /* Add spacing for better aesthetics */
}





.IntroductionBlock_mainCard__1vclk {
  min-width: 350px;
  width: 100%;
  height: 350px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  border-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
}

.IntroductionBlock_mainheadings__2nsvY{
  font-size: 65px;
  font-weight: 800;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: var(--darkTextColorDi);
  border-style: none;
  width: 100%;
  text-align: center;
}

.IntroductionBlock_searchboxmaterial__2G5ss {
  margin-top: 20px;
  width: 90%;

  background-color: white;
  border-radius: 10px;
  height: 35px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-style: none;
}

.IntroductionBlock_searchbyexamEditBox__wRm0n {
  border: none;
  width: 80%;
  height: 30px;
  border-radius: 10px;
  margin-left: 10px;
}

.IntroductionBlock_searchbyexamEditBox__wRm0n:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border: none;
}

.IntroductionBlock_dropdownContainer__3mTr7 {
  width: 20%;
  height: 37px;
  background-color: var(--themeColor);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.IntroductionBlock_downArrow__3sp6W {
  color: white;
  height: 20px;
  width: 20px;
}

.IntroductionBlock_dropdownContainer__3mTr7:hover {
  cursor: pointer;
}

.IntroductionBlock_btnTitle__1v2AI {
  margin-top: 20px;
  width: 350px;
  color: white;
  background-color: var(--themeColor);
  border-radius: 4px;
  border: none;
  height: 50px;
  font-size: 16px;
  /* margin-left: 100px; */
  
}

.IntroductionBlock_BtnContainer__35-B2{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.IntroductionBlock_dropDownListitemmenu__1WTHE {
  width: 200px;
}

.IntroductionBlock_btnTitle__1v2AI:hover {
  cursor: pointer;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  background-color: white;
}

.IntroductionBlock_websiteContent__3ZCdY {
  width: 100%;
  display: flex;
  border-style: none;
  border-color: red;
}

.IntroductionBlock_detailContainer__1mz05 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-style: none;
  color: green;
  width: 50%;
  padding-top: 50px;
}

.IntroductionBlock_HomeImageContainer1__2oBpT {
  width: 50%;
  display: flex;
  justify-content: start;
  margin-top: 50px;
  border-style: none;
  border-color: cyan;
}

.IntroductionBlock_rightImage__1F8Z0 {
  border-style: none;
  color: orange;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 10px;
}

.IntroductionBlock_instructionInfo__RPlJn {
  border-style: none;
  color: black;
  font-size: 18px;
  text-align: left;
  width: 100%;
}

.IntroductionBlock_btnContainer__2kYC1 {
  margin-right: 40px;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 20px;
}

.IntroductionBlock_btn1__2mzxe {
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.IntroductionBlock_btn1__2mzxe:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.IntroductionBlock_btn2__1GkLO {
  margin-top: 16px;
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.IntroductionBlock_btn2__1GkLO:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.IntroductionBlock_btn3__3VQXe {
  margin-top: 16px;
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.IntroductionBlock_btn3__3VQXe:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.IntroductionBlock_btn4__fX6zS {
  font-weight: bold;
  margin-top: 16px;
  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.IntroductionBlock_btn4__fX6zS:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.IntroductionBlock_detailContainer1__1jkgI {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-style: none;
  width: 100%;
  border-color: orange;
}

.IntroductionBlock_detailContainer2__3VqP7 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-style: none;
  width: 100%;
  border-color: orange;
}

.IntroductionBlock_odisaMap__2O2Dk {
  width: 100%;
}

.IntroductionBlock_textHeading1__2FY2H {
  padding-top: 90px;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* margin-top: 100px;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase; */
  background-color: transparent;
  fill: var(--themeColor);
}

.IntroductionBlock_textHeading2__2LVnd {
  width: 100%;
  margin-top: 50px;
  font-weight: bold;
  color: white;
  line-height: 55px;
  font-size: 40px;
  border-radius: 10px;
  text-align: left;
  color: black;
}

.IntroductionBlock_textHeading3__20gHW {
  display: flex;
  justify-content: flex-start;
  border-style: none;
  width: 100%;
  border-color: red;
}

.IntroductionBlock_findTopperButton__SZUyB {
  width: 353px;
  height: 66px;
  border-radius: 5px;
  border-style: none;
  cursor: pointer;
  color: var(--themeColor);
  font-size: 20px;
}

.IntroductionBlock_findTopperButton__SZUyB:hover {
  color: white;
  background-color: var(--themeColor);
}

.IntroductionBlock_leftTopHeadingText__1c-8Y {
  text-align: left;
}

.IntroductionBlock_textDetails__1fWsO {
  visibility: hidden;
  margin-top: 30px;
  color: var(--darkText);
  font-size: 18px;
  text-align: justify;
  line-height: 34px;
  font-weight: bolder;
  background-color: #e7efeb;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media (max-width: 800px) {
  .IntroductionBlock_websiteContent__3ZCdY {
    flex-direction: column;
  }
  .IntroductionBlock_detailContainer__1mz05 {
    width: 100%;
  }

  .IntroductionBlock_HomeImageContainer1__2oBpT {
    width: 100%;
  }

  .IntroductionBlock_textHeading2__2LVnd {
    font-size: 20px;
  }

  .IntroductionBlock_imgOneBig__2hawO{
  width: 100%;
  margin: auto;
  display: block; 
  }

  .IntroductionBlock_img1__2HG3e{
  display: none;
  }

  .IntroductionBlock_img2__28r4x{
  display: none;
  }


  .IntroductionBlock_btnTitle__1v2AI{

  color: white;
  background-color: var(--themeColor);
  border-radius: 4px;
  border: none;
  height: 50px;
  font-size: 16px;
  margin: 0;
  }

}

.IntroductionBlock_subHead__31CyX{
font-weight: bold;
font-size: 20px;
color: grey;
border-style: none;
text-align: center;
margin-bottom: 20px;
}


@media (max-width: 1300px) {

.IntroductionBlock_IntroductionBlockParent__r_PRP {
width:80%;
}	
}


@media (max-width: 1000px) {

  .IntroductionBlock_MainIntroductionBlockParent__2HbTL{
    width: 98%;
    padding: 10px;
  }
  .IntroductionBlock_IntroductionBlockParent__r_PRP {
  width: 100%;
  height: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .IntroductionBlock_rightBox__2TYZ2 {
    width: 95%;
    display: flex;

  }

  .IntroductionBlock_img1__2HG3e{
    padding: 20px;
    width: 130px;
    height: 220px;

  }

  .IntroductionBlock_img2__28r4x{
  
    width: 130px;
    height: 220px;

  }

  .IntroductionBlock_leftBox__1v_fv{
    width: 100%;
    display: flex;
    justify-self: center;
    margin-bottom: 40px;
  }

  .IntroductionBlock_mainCard__1vclk{
width: 100%;
height: 100%;
min-width: 0;
  }

  .IntroductionBlock_mainheadings__2nsvY{
   font-size: 25px;
   text-align: center;
  }

  .IntroductionBlock_subHead__31CyX{
    margin-top: 10px;
    text-align: center;
  }

}


@media (max-width: 787px) {
.IntroductionBlock_imgOneBig__2hawO{
 
}
}

@media (max-width: 768px) { 
  .IntroductionBlock_imgOneBig__2hawO {
    max-width: 250px;  
  }
}

@media (max-width: 480px) {  
  .IntroductionBlock_imgOneBig__2hawO {
    max-width: 150px;  
  }

  .IntroductionBlock_btnTitle__1v2AI{
    width: 100%;
    height: 40px;
  }
  .IntroductionBlock_leftBox__1v_fv{
    margin-bottom: 10px;
  }
}





.OptionField_name_div__32_uY{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-style: none;
margin-top: 10px;
position: relative;
width: 100%;
border-width: 1px;
height: 35px;
}

.OptionField_input_field__sNSdO{
position: relative;
width: 100%;
height: 100%;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 10px 10px 10px 10px;
padding-left: 10px;
padding-right: 10px;
font-size: 16px;

}
.OptionField_input_field__sNSdO:focus {
    border-style: solid;
    border-width: 1px;
    outline: none !important;
    border-color: var(--themeColor);
    border-radius:10px 10px 10px 10px;
}






.CenterColumnLeftRightGrid_centerImageLeftRightGrid__3vOgo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CenterColumnLeftRightGrid_title__IJ0TB {
  margin-top: 40px;
  color: blue;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.CenterColumnLeftRightGrid_subTitle__1mWsx {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.CenterColumnLeftRightGrid_details__bAdbE {
  margin-top: 40px;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: var(--lightText);
}

.CenterColumnLeftRightGrid_serviceSection__1dfh9 {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.CenterColumnLeftRightGrid_leftSideContent__2VgLy {
  width: 100%;

}

.CenterColumnLeftRightGrid_features__1dNIB {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--themeColor);
  font-weight: bold;
}

/* .a1box {
  max-height: 2.4em;
  overflow: hidden;
  transition: max-height 0.3s ease; 
}

.a1box:hover {
  max-height: none; 
} */

.CenterColumnLeftRightGrid_serviceImage__1FLJy {
  height: 60px;
  width: 60px;
  background-color: white;
  visibility: hidden;
}

.CenterColumnLeftRightGrid_a1box__3r_tH {
  height: auto;
  margin: 12px;
  padding: 8px;
  border: 1px solid var(--cardBorderColor);
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  overflow: hidden;
}

.CenterColumnLeftRightGrid_serviceHeading1__3qd08 {
  font-size: 18px;
  font-weight: 500;
  font-weight: bold;
  color: #08203d;
}

.CenterColumnLeftRightGrid_serviceHeading2__TdJ_d {
  margin-top: 6px;
  font-size: 14px;
  color: var(--lightText);
  line-height: 23px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  transition: max-height 0.3s ease;
}

.CenterColumnLeftRightGrid_serviceHeading2__TdJ_d:hover {
  height: auto;
  max-height: none;
  cursor: pointer;
}


.CenterColumnLeftRightGrid_a1box__3r_tH:hover .CenterColumnLeftRightGrid_serviceHeading2__TdJ_d{
  -webkit-line-clamp: 8;
  transition: max-height 0.3s ease;
}

.CenterColumnLeftRightGrid_centerImage__I9U9D {
  margin-top: 35px;
  width: 100%;
  height: 620px;
  min-height: 620px;
  max-height: 620px;
  background-color: white;
  object-fit: cover;
  border-style: none;
  border-radius: 10px;
  display: flex;


}

.CenterColumnLeftRightGrid_rightSideContent__3Wwqw {
  width: 100%;

}

@media (max-width: 1300px) {
  .CenterColumnLeftRightGrid_title__IJ0TB {
    font-size: 20px;
  }

  .CenterColumnLeftRightGrid_subTitle__1mWsx {
    margin-top: 10px;
    font-size: 30px;
  }
  .CenterColumnLeftRightGrid_a1box__3r_tH {
    width: auto;
    padding: 10px;
    margin-top: 10px;
  }

  .CenterColumnLeftRightGrid_centerImage__I9U9D {
    width: 96%;
    display: flex;
    justify-self: center;
    align-self: center;
    
  }

  .CenterColumnLeftRightGrid_serviceSection__1dfh9 {
    margin-top: 20px;
    width: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-auto-rows: auto;
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }
}

.Testimonial_ourClientDetails__30Fm7 {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Testimonial_clientTitle__GbhQa {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.Testimonial_clientSubTitle__2ZGWY {
  margin-top: 20px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
  line-height: 55px;
  font-size: 40px;
}

.Testimonial_clientFeedbackContainer__3JIdY {
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;

  /* display: flex;
  overflow-x: scroll;
  white-space: nowrap; */
}

.Testimonial_aboutClinetBox__3Jpjh {
  width: auto;
  background-color: white;
  margin: 10px;
  padding: 20px;
  border-radius: 1px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.Testimonial_aboutClinetBox__3Jpjh:hover {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.Testimonial_clientImg__1nZob {
  margin-top: -50px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Testimonial_clientFeedback__KV150 {
  display: flex;
  justify-content: space-between;
}

.Testimonial_socialNetworkImage__25f4X {
  visibility: hidden;
  height: 30px;
  width: 30px;
  color: #00acee;
}

.Testimonial_clientDetails__1bdJL {
  font-size: 14px;
  margin-top: 10px;
  color: var(--lightText);
  line-height: 24px;
  text-align: justify;
}

.Testimonial_clientName__1W4U5 {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #08203d;
}

.Testimonial_clientStatus__1XuHk {
  margin-top: 10px;
  color: var(--themeColor);
  font-size: 14px;
}

@media (max-width: 1300px) {
  .Testimonial_TestimonialParent__3HHE0 {
   border-style: none;	  
  }

  .Testimonial_clientTitle__GbhQa {
    font-size: 20px;
    text-align: center;
  }

  .Testimonial_clientSubTitle__2ZGWY {
    margin-top: 10px;
    font-size: 30px;
    text-align: center;
  }

  .Testimonial_clientFeedbackContainer__3JIdY{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

}


.Testimonial_@media__28-tS (max-width: 600px) {

.Testimonial_clientFeedbackContainer__3JIdY{
  display: flex;
  overflow-y: scroll;
  overflow-x:hidden;
}


}



.Pricing_subscriptionDetails__7_aSD {
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Pricing_PlanTextTitle__1fGCM {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.Pricing_PlanTextsubTitle__2CVGx {
  margin-top: 20px;
  font-weight: bold;
  color: #08203D;
  line-height: 55px;
  font-size: 45px;
  
}

.Pricing_planContainer__21CgL {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Pricing_basicPlan__19cHP {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 8px;
}

.Pricing_basicPlanContent__15tMi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Pricing_pic__1OaKU {
  margin-top: 20px;
  height: 60px;
  width: 60px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColorLight);
  display: flex;
  justify-content: center;
  align-items: center;
}


.Pricing_rupees_icon__22ll4{
  height: 30px;
  width: 30px;
  color: var(--lightText);
}

.Pricing_planTitle__2PnLY {
  margin-top: 20px;
  font-size: 25px;
  color: var(--darkText);
  letter-spacing: 5px;
  font-weight: bold;
}

.Pricing_aboutPrice__2XCJj {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  align-items: end;
}

.Pricing_amount__3AJnu {
  font-size: 40px;
  font-weight: bold;
  color: var(--themeColor);
}

.Pricing_amountMonth__1Nndx {
  font-size: 20px;
  display: flex;
  align-items: end;
  font-weight: bold;
  color: var(--lightText);
}

.Pricing_horizontalLine__3kQAT {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  background-color: var(--darkText);
  border-bottom: 1px solid var(--cardBorderColor);
}

.Pricing_features__1ufAA {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.Pricing_checkIcon__1q-Vy {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: gray;
}

.Pricing_textplanDetails__3TTmt {
  margin-left: 10px;
  color: var(--lightText);
}

.Pricing_basicPlanBtn__tk6jM {
  height: 24px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
  color: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border-radius: 30px;
  margin-bottom: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid var(--themeColor);
}

.Pricing_standardPlan__FswLz {
  width: 33.33%;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.Pricing_unlimitedPlan__2zk0K {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 8px;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.FAQ_faqContent__2e4kQ {
  margin-top: 40px;
  width: 100%;
  display: grid;
  justify-content: flex-end;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding-bottom: 40px;
  padding-top: 30px;
}

.FAQ_faqdetailContainer__FChNN {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.FAQ_faqImages__3ff5j {
  margin-left: 20px;
  margin-right: 20px;
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio:  calc(2729 / 2674 * 100%);;
  background-color: var(--themeColor);
  border-radius: 10px;
  background-color: red;
  
}

.FAQ_faqtextHeading1__1JEk1 {
  margin-left: 20px;

  display: flex;
  justify-content: start;
  align-items: start;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.FAQ_qaContainer__FDX2L {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  background-color: var(--bgColor);
  border: 1px solid var(--cardBorderColor);
  border-radius: 10px;
}

.FAQ_questionsText__HT8VF {
  margin-left: 10px;
}

.FAQ_questionsAnsContainer__ivyn5 {
  display: flex;
  justify-content: start;
  align-items: center;
}
.FAQ_imgarrowContainer__2vfhC {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--themeColor);
}

.FAQ_ansText__1rlR- {
  margin-left: 20px;

  margin-top: 10px;
  color: var(--lightText);
}

.TeamBlock_ourTeam__2nxBy {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TeamBlock_ourTeamTitle__3C7sd {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.TeamBlock_ourTeamSubTitle__QYOb6 {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.TeamBlock_allTeamMemberdetail__bsYYj {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, auto));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.TeamBlock_ourTeamdetailContainer__1XBgH {
  width: auto;
  margin: 10px;
  padding: 10px;
  border: 1px solid var(--cardBorderColor);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.TeamBlock_developerPic1__io2p3 {
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;

  background-color: #207ef5;
}

.TeamBlock_developerPic2__2rsR5 {
  border-radius: 10px;

  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;

  background-color: #207ef5;
}

.TeamBlock_developerPic3__3-6hM {
  border-radius: 10px;

  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;

  /* aspect-ratio:calc(1600 * 1067 /100%); */
  background-color: #207ef5;
}

.TeamBlock_developerPic4__lk9r2 {
  border-radius: 10px;

  width: 100%;
  aspect-ratio: 5/5;
  object-fit: cover;
  background-color: #207ef5;
}

.TeamBlock_developerName__DMtxC {
  margin-top: 16px;
  font-size: 18px;
  font-weight: 500;
  color: var(--darkText);
}

.TeamBlock_developerRole__3cr5z {
  font-size: 16px;
  margin-top: 10px;
  color: var(--themeColor);
}

.TeamBlock_socialNetworking__1l49J {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.TeamBlock_pic1__2jgcf {
  height: 20px;
  width: 20px;
  background-color: #e3effe;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
  color: var(--themeColor);
  padding: 8px;
  border-style: none;
}

.TeamBlock_pic2__14lyr {
  margin-left: 16px;
  margin-right: 16px;
  height: 20px;
  width: 20px;
  padding: 8px;
  background-color: #e3effe;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
  color: var(--themeColor);
  border-style: none;
}

.TeamBlock_pic3__3kDYd {
  height: 20px;
  width: 20px;
  padding: 8px;
  background-color: #e3effe;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
  color: var(--themeColor);
  border-style: none;
}

@media (max-width: 1300px) {
  .TeamBlock_ourTeamTitle__3C7sd {
    font-size: 20px;
  }

  .TeamBlock_ourTeamSubTitle__QYOb6 {
    margin-top: 10px;
    font-size: 30px;
  }
}

.Apointment_bookAppointment__1VfsX {
  margin-top: 40px;
  display: flex;
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

  background-color: var(--cardBorderColorLight);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.Apointment_bookpic__-nNKg {
  width: 100%;
  background-color: white;

  image-resolution: from-image;
  height: 350px;
}

.Apointment_bookDetail__1MLjs {
  width: 100%;
  height: 350px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: start;
}

.Apointment_bookDetailContainer__1HDfb {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: center;
  padding-left: 30px;
}

.Apointment_bookTitle__2g33Y {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.Apointment_booksubTitle__3N40s {
  margin-top: 30px;
  width: 90%;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.Apointment_bookaptBtn__1XWl- {
  margin-top: 30px;
  padding: 10px;
  background-color: var(--themeColor);
  border-radius: 10px;
  color: white;
  border: none;
}
.Apointment_bookaptBtn__1XWl-:hover{
  cursor: pointer;

}

@media (max-width: 1300px) {
  .Apointment_bookTitle__2g33Y {
    margin-top: 10px;
    font-size: 20;
  }

  .Apointment_bookDetailContainer__1HDfb {
    padding-left: 20px;
  }
  .Apointment_booksubTitle__3N40s {
    margin-top: 20px;
    font-size: 24px;
    line-height: 35px;
  }

  .Apointment_textDetails__3-_cH {
    margin-top: 10px;
    font-size: 18px;
  }
}

.BlogAndNewsBlock_latest__3p3dt {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.BlogAndNewsBlock_latestTitle__1uGa3 {
  font-weight: bold;
  color: black;
  font-size:40px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.BlogAndNewsBlock_latestsubTitle__2ZxBa {
  margin-top: 5px;
  /* font-weight: bold; */
  color: #070707;
  line-height: 55px;
  font-size:20px;
}
.BlogAndNewsBlock_newsContainer__1Bnil {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, auto));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.BlogAndNewsBlock_singleContainer__3-a5o {
  margin: 10px;
  height: 500px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.BlogAndNewsBlock_newsImage__293Bt {
  width: 100%;
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  background-color: white;
}

.BlogAndNewsBlock_newsTitle__1b-16 {
  margin-top: 20px;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #08203d;
}

.BlogAndNewsBlock_newsDesc__29MgQ {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 15px;
  color: var(--lightText);
  text-align: justify;
  margin-right: 10px;
  line-height: 19px;
  height: 180px;
  max-height: 180px;
  min-height: 180px;
}

.BlogAndNewsBlock_readMoreBtn__1bwbl {
  margin-left: 10px;
  font-size: 16px;
  color: var(--lightText);
  margin-bottom: 20px;
  color: var(--themeColor);
  padding: 6px;
  border-radius: 2px;
}

@media (max-width: 1300px) {
  .BlogAndNewsBlock_latestTitle__1uGa3 {
    font-size: 20px;
  }

  .BlogAndNewsBlock_latestsubTitle__2ZxBa {
    margin-top: 10px;
    font-size: 30px;
  }
}


.SingleBlog_singleContainer__xTdk1 {
    margin: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  
  .SingleBlog_newsImage__3uSGB {
    width: 100%;
    height: 200px;
    max-height: 200px;
    min-height: 200px;
    background-color: white;
    object-fit: cover;
  }
  
  .SingleBlog_newsTitle__35Aw3 {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #08203d;
  }
  
  .SingleBlog_newsDesc__1k7mF {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 15px;
    color: var(--lightText);
    text-align: justify;
    margin-right: 10px;
    line-height: 19px;
    height: auto;
    /* max-height: 180px;
    min-height: 180px; */
  }
  
  .SingleBlog_readMoreBtn__1CDjO {
    margin-top: 20px;
    font-weight: bold;
    margin-left: 10px;
    font-size: 16px;
    color: var(--lightText);
    margin-bottom: 20px;
    color: var(--themeColor);
    padding: 6px;
    border-radius: 2px;
    border: none;
    background-color: transparent;
  }
  .SingleBlog_readMoreBtn__1CDjO:hover{
    cursor: pointer;
  }
  


  
  @media (max-width: 375px) {
.SingleBlog_newsImage__3uSGB{
  
  width: 100%;
  object-fit: fill;
}

  }

@media (max-width: 320px) {
.SingleBlog_singleContainer__xTdk1{
  margin-left: 0;
  margin-right: 20px;
}

.SingleBlog_newsImage__3uSGB{
  width: 100%;
  object-fit: fill;
  
  /* max-height: 150px; */
}
}
.LeftImageRightText_websiteContent2__2E-_p {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.LeftImageRightText_HomeImageContainer2__rLald {
  width: 100%;
  height: 400px;
  background-color: green;
  border-radius: 10px;
  object-fit: cover;

}

.LeftImageRightText_detailContainer2__3YUG- {
  width: 100%;
}

.LeftImageRightText_aboutHeading1__3iREq {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.LeftImageRightText_aboutHeading2__1-hWe {
  font-weight: bold;
  color: #08203d;
  line-height: 28px;
  font-size: 25px;
}

.LeftImageRightText_aboutDetails__29Jbh {
  margin-top: 20px;
  font-size: 18px;
  color: var(--lightText);
  line-height: 32px;
  text-align: justify;
}

@media (max-width: 1300px) {
  .LeftImageRightText_detailContainer2__3YUG- {
  }
  .LeftImageRightText_aboutHeading1__3iREq {
    font-size: 20;
  }
  .LeftImageRightText_aboutHeading2__1-hWe {
    margin-top: 10px;
    font-size: 30px;
    line-height: 40px;
  }

  .LeftImageRightText_aboutDetails__29Jbh {
    margin-top: 10px;
    font-size: 18px;
    line-height: 30px;
  }
}

.OneNewsBlock_parentNews__1NdPu {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.OneNewsBlock_latest__3ykIR {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.OneNewsBlock_latestTitle__gkyKG {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.OneNewsBlock_latestsubTitle__26aXd {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.OneNewsBlock_newsContainer__2uw0j {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OneNewsBlock_newsBlock1__3_r8q {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.OneNewsBlock_imgAndHeading__Jc9lx {
  width: 40%;
}

.OneNewsBlock_mainImg__ZZWLH {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.OneNewsBlock_mainTitle__2rM_B {
  color: var(--darkText);
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.OneNewsBlock_contentDetails__3CjEf {
  margin-left: 16px;
  width: 60%;
}

.OneNewsBlock_newsDetails__awXnW {
  margin-top: 20px;
  text-align: justify;
  line-height: 26px;
  font-size: 18px;
  color: var(--lightText);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
}

.OneNewsBlock_readMore__1hGoi {
  float: left;
  margin-top: 20px;
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: end;
  color: white;
  font-weight: 500;
  border: none;
  padding: 6px;
  background-color: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 12px;
}

.OneNewsBlock_readMore__1hGoi:hover {
  cursor: pointer;
}

/* .newsBlock2 {
  width: 100%;
  height: 210px;
  display: flex;
  margin-left: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
} */

@media (max-width: 1000px) {
  .OneNewsBlock_parentNews__1NdPu {
    width: 100%;
    margin: 0;
    font-size: 14px;
  }

  .OneNewsBlock_imgAndHeading__Jc9lx {
    width: 100%;

    height: 400px;
    object-fit: cover;
  }

  .OneNewsBlock_newsBlock1__3_r8q {
    display: flex;
    flex-direction: column;
  }

  .OneNewsBlock_contentDetails__3CjEf {
    width: 98%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
  }

  .OneNewsBlock_mainTitle__2rM_B {
    margin-top: 10px;
    width: 100%;
  }

  .OneNewsBlock_newsDetails__awXnW {
    margin-top: 10px;
    width: 100%;
  }
}

.Client_clientPage__vrJNk {
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

.Client_Heading__WPVHk {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
}

.Client_clientLogos__3UH4y {
    display: grid;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 30px;
    max-width: 520px;
    margin: 0 auto; 
    grid-template-columns: repeat(4,1fr);
    align-items: center;
    justify-content: center;
}
.Client_clientLogosContainer__14qj_{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 20px;
    gap: 20px;

}

.Client_clientItem__26yds {
    flex: 0 1 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.Client_clientItem__26yds:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.Client_clientLogo__3EEh7 {
    max-height: 80px;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.Client_clientLogo__3EEh7:hover {
    opacity: 1;
}

.Client_clientName__3wi_d {
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.Client_clientDescription__SHJ1X {
    text-align: center;
    max-width: 500px;
    margin: 0 auto 40px;
}

.Client_clientDescription__SHJ1X h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 10px;
}

.Client_clientDescription__SHJ1X p {
    color: #666;
    line-height: 1.6;
}

.Client_testimonials__2hkqR {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

.Client_testimonialCard__VTsK7 {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    position: relative;
    margin-bottom: 20px;
}

.Client_profileImage__2D_St {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    top: -25px;
    left: 20px;
    border: 3px solid #fff;
}

.Client_testimonialContent__2Otg8 {
    padding-top: 30px;
}

.Client_testimonialContent__2Otg8 h3 {
    font-size: 1.1em;
    margin-bottom: 5px;
    color: #333;
}

.Client_jobTitle__3U4L9 {
    color: #888;
    font-size: 0.9em;
    margin-bottom: 10px;
}

.Client_feedback__2haBT {
    color: #555;
    font-style: italic;
}

@media (max-width: 768px) {
    .Client_clientItem__26yds {
        width: 100px;
        height: 100px;
        background-color: none;
        border: none;
        border-radius: 0;
        box-shadow: none;
    }
    .Client_clientLogos__3UH4y{
        grid-template-columns: 1fr;
        display: flex;
        flex-direction: column;
        align-items: center;
    } 
    .Client_clientLogosContainer__14qj_ {
        grid-template-columns: 1fr;
        
    }
    .Client_testimonials__2hkqR {
        flex-direction: column;
        align-items: center;
    }
    
    .Client_clientDescription__SHJ1X {
        text-align: center;
    }
}

/* @media (max-width: 400px) {
    .clientPage {
        padding: 20px;
    }

    .clientLogo {
        max-height: 60px;
    }

    .clientName {
        font-size: 12px;
    }

    .clientDescription h2 {
        font-size: 1.5em;
    }

    .testimonialCard {
        width: 250px;
        padding: 15px;
    }

    .testimonialContent h3 {
        font-size: 1em;
    }

    .jobTitle {
        font-size: 0.8em;
    }

    .feedback {
        font-size: 0.9em;
    }
} */
@media (max-width: 400px) {
    .Client_clientPage__vrJNk {
        padding: 20px;
    }

    .Client_clientLogos__3UH4y {
        display: grid;
        grid-template-columns: 1fr; 
        justify-items: center; 
        grid-gap: 20px; 
        gap: 20px;
    }

    .Client_clientLogosContainer__14qj_ {
        grid-template-columns: 1fr; 
        justify-items: center;
    }

    .Client_clientLogo__3EEh7 {
        max-height: 60px;
    }

    .Client_clientName__3wi_d {
        font-size: 12px;
    }

    .Client_clientDescription__SHJ1X h2 {
        font-size: 1.5em;
    }

    .Client_testimonialCard__VTsK7 {
        width: 250px;
        padding: 15px;
    }

    .Client_testimonialContent__2Otg8 h3 {
        font-size: 1em;
    }

    .Client_jobTitle__3U4L9 {
        font-size: 0.8em;
    }

    .Client_feedback__2haBT {
        font-size: 0.9em;
    }
}


.FirstBlock_websiteContent2__3RdCZ {
    margin-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
  
  .FirstBlock_HomeImageContainer2__3-kOD {
    width: 100%;
    height: 400px;
    background-color: green;
    border-radius: 10px;
    object-fit: cover;
  
  }
  
  .FirstBlock_detailContainer2__3EFFv {
    width: 100%;
  }
  
  .FirstBlock_aboutHeading1__1UmVx {
    font-weight: bold;
    color: var(--themeColor);
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
  }
  
  .FirstBlock_aboutHeading2__1BeoP {
    font-weight: bold;
    color: #08203d;
    line-height: 28px;
    font-size: 25px;
  }
  
  .FirstBlock_aboutDetails__x0O7v {
    margin-top: 20px;
    font-size: 18px;
    color: var(--lightText);
    line-height: 32px;
    text-align: justify;
  }
  
  @media (max-width: 1300px) {
    .FirstBlock_detailContainer2__3EFFv {
    }
    .FirstBlock_aboutHeading1__1UmVx {
      font-size: 20;
    }
    .FirstBlock_aboutHeading2__1BeoP {
      margin-top: 10px;
      font-size: 30px;
      line-height: 40px;
    }
  
    .FirstBlock_aboutDetails__x0O7v {
      margin-top: 10px;
      font-size: 18px;
      line-height: 30px;
    }
  }
  
.SecondBlock_websiteContent2__3jXpv {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.SecondBlock_HomeImageContainer2__1CK4B {
  width: 100%;
  height: 400px;
  background-color: green;
  border-radius: 10px;
  object-fit: cover;
}

.SecondBlock_detailContainer2__1vPvJ {
  width: 100%;
}

.SecondBlock_aboutHeading1__2MPE7 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.SecondBlock_aboutHeading2__1Uyr3 {
  font-weight: bold;
  color: #08203d;
  line-height: 28px;
  font-size: 25px;
}

.SecondBlock_aboutDetails__IvPCm {
  margin-top: 20px;
  font-size: 18px;
  color: var(--lightText);
  line-height: 32px;
  text-align: justify;
}

@media (max-width: 1300px) {
  .SecondBlock_detailContainer2__1vPvJ {
  }
  .SecondBlock_aboutHeading1__2MPE7 {
    font-size: 20;
  }
  .SecondBlock_aboutHeading2__1Uyr3 {
    margin-top: 10px;
    font-size: 30px;
    line-height: 40px;
  }

  .SecondBlock_aboutDetails__IvPCm {
    margin-top: 10px;
    font-size: 18px;
    line-height: 30px;
  }
}

.Exambox_parentBox__1j2ug {
  width: 270px;

  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
}

.Exambox_parentBox__1j2ug:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.Exambox_logo__k0KiI {
  width: 100%;
  height: 150px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Exambox_imagesExam__Zzfoi {
  width: 100%;
  height: 100%;
}

.Exambox_title__3aj_t {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}

.Exambox_description__1vbR9 {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Exambox_ViewToppersBtn__2jA1y {
  margin-top: 20px;
  width: 100px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border-radius: 20px;
  border: none;
  margin-bottom: 20px;
}

.Exambox_ViewToppersBtn__2jA1y:hover {
  border: 1px solid var(--themeColor);
  background-color: white;
  cursor: pointer;
  color: var(--themeColor);
}

.ToppersCard_parentBox__2r0u4 {
  width: 270px;

  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
}

.ToppersCard_parentBox__2r0u4:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ToppersCard_logo__GeSuu {
  width: 100%;
  height: 250px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ToppersCard_imagesExam__1LOnI {
  width: 100%;
  height: 100%;
}

.ToppersCard_title__5QLin {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}

.ToppersCard_description1__3N2zZ {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ToppersCard_description2__1jqLi {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ToppersCard_ViewToppersBtn__2ZYqi {
  margin-top: 20px;
  width: 100px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border-radius: 20px;
  border: none;
  margin-bottom: 20px;
}

.ToppersCard_ViewToppersBtn__2ZYqi:hover {
  border: 1px solid var(--themeColor);
  background-color: white;
  cursor: pointer;
  color: var(--themeColor);
}

.WhyHelloToppersCard_paarent__19_Cp {
  width: 300px;

  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 20px;
}

.WhyHelloToppersCard_logo__t7bA8 {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WhyHelloToppersCard_title__3SjhC {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.WhyHelloToppersCard_description__3GYoi {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}

.HowitWorks_paarent__8umdA {
  width: 200px;

  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.HowitWorks_logo__3cW2O {
  margin-top: 30px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HowitWorks_imagesExam__5xEOL {
  width: 80px;
  height: 80px;
}

.HowitWorks_title__51Bgr {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.HowitWorks_description__vUD-X {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}

.ExamBoxSlide_slider__4-TB6 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: none;
  margin: auto;
}






.ExamBoxSlide_cards__1XH49 {
  width: 100%;
  margin-top: 30px;
  border-style: none;
  overflow: hidden;
  position: relative;
  direction: rtl;
}


.ExamBoxSlide_wrapper__15mvI {
  display: flex;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  border-style: none;
  border-color: red;
  width:200%;
}





.ExamBoxSlide_wrapperMobile__2ZZEP {
  display: flex;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  border-style: none;
  border-color: red;
  width:550%;
  visibility: hidden;
  height: 0px;
}




.ExamBoxSlide_dot__ZTHk5 {
  /* Styles for regular dots */
  width: 10px;
  height: 10px;
  background-color: gray;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  border-style: none;
}





@media (max-width: 600px) {
 .ExamBoxSlide_wrapper__15mvI {
   visibility: hidden;
   height:0px;
 }

 .ExamBoxSlide_dot__ZTHk5 {
  width: 30px;
  height: 20px;
  background-color: grey;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  border-style: none;
}

  .ExamBoxSlide_wrapperMobile__2ZZEP {
  
   visibility: visible;
   height: auto;
  }




}




.ExamBoxSlide_child__2eKQZ {
  width: 16.6%;
  box-sizing: border-box;
  border: 1px solid #000;
  border-style: none;
  font-size: 24px;
  display: inline-block;
}



.ExamBoxSlide_childMobile__2kF-s {
  width: 16.6%;
  box-sizing: border-box;
  border: 1px solid #000;
  border-style: none;
  font-size: 24px;
  display: inline-block;
}






.ExamBoxSlide_cardDiv__3e7z9{
border-style: none;
border-color: red;
}


.ExamBoxSlide_card__1IEAO {
  width: 95%;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
  border: 1px solid var(--cardBorderColor);
  margin: auto;
}

.ExamBoxSlide_card__1IEAO:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}







.ExamBoxSlide_img__d8kyu {

  width: 100%;
  aspect-ratio:1.7/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px 7px 0px 0px;
  object-fit: cover;
}

.ExamBoxSlide_title__2tY8g {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}

.ExamBoxSlide_description1__AeoWV {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ExamBoxSlide_description2__b4KdQ {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ExamBoxSlide_ViewToppersBtn__3gZlo {
  margin-top: 20px;
  width: 150px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border-radius: 20px;
  border: none;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.ExamBoxSlide_ViewToppersBtn__3gZlo:hover {
  border: 1px solid var(--themeColor);
  background-color: white;
  cursor: pointer;
  color: var(--themeColor);
}

.ExamBoxSlide_indicatorDots__4HxDi {
  margin-top: 40px;
  display: flex; /* Make the dots container a flex container */
  justify-content: center; /* Center the dots horizontally */
  
}


.ExamBoxSlide_activeDot__VrFZh {
  /* Styles for active dot */
  background-color: var(--themeColor);
}

.Examslidebox1_slider__2JTc6 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  
  .Examslidebox1_cards__-O2SY {
    width: 100%;
    height: auto;
    margin-top: 30px;
    overflow: hidden;
    position: relative;
  }
  
  .Examslidebox1_wrapper__1RAE5 {
    display: flex;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    width: 200%; 
  }
  
  .Examslidebox1_wrapperMobile__KteFw {
    display: flex;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    width: 550%; 
    visibility: hidden;
    height: 0;
  }
  
  .Examslidebox1_child__149jn {
    width: 16.6%;
    box-sizing: border-box;
  }
  
  .Examslidebox1_childMobile__2Bvsg {
    width: 16.6%;
    box-sizing: border-box;
  }
  
  .Examslidebox1_dot__3spz6 {
    width: 10px;
    height: 10px;
    background-color: gray;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .Examslidebox1_activeDot__1lVlQ {
    background-color: var(--themeColor);
  }
  
  @media (max-width: 600px) {
    .Examslidebox1_wrapper__1RAE5 {
      visibility: hidden;
      height: 0;
    }
  
    .Examslidebox1_dot__3spz6 {
      width: 30px;
      height: 20px;
      background-color: gray;
      border-radius: 5px;
    }
  
    .Examslidebox1_wrapperMobile__KteFw {
      visibility: visible;
      height: auto;
    }
  }

  .Examslidebox1_card__3h6Rs {
    width: 95%;
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
  .Examslidebox1_img__14aAL {
    width: 100%;
    height: 100%;
    object-fit: cover;  
    border-radius: 7px 7px 0 0;
  }
  
  @media (max-width: 768px) {
    .Examslidebox1_img__14aAL {
      height: 180px; 
    }
  }
  
  @media (max-width: 600px) {
    .Examslidebox1_img__14aAL {
      height: 150px; 
    }
  }
  
  .Examslidebox1_title__3DK8r {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  .Examslidebox1_description1__1B4Wr {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
  }
  
  .Examslidebox1_ViewToppersBtn__3czis {
    margin-top: 20px;
    width: 150px;
    height: 30px;
    background-color: var(--themeColor);
    color: white;
    border-radius: 20px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Examslidebox1_ViewToppersBtn__3czis:hover {
    background-color: white;
    border: 1px solid var(--themeColor);
    color: var(--themeColor);
  }
  
.oneexamcard1_card__Xkq61 {
    width: 100%; 
    max-width: 350px; 
    height: auto; 
    padding: 10px 0px;
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    justify-content: flex-start; 
    align-items: center;
    margin: 10px; 
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); 
    overflow: hidden; 

}

.oneexamcard1_img__37OHm {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    border-radius: 7px 7px 0 0;
    aspect-ratio: 1/2;
}

.oneexamcard1_description1__3PAWQ {
    margin: 10px 0; 
    font-size: 14px; 
    text-align: center;
    
}

.oneexamcard1_title__yjzjc {
   
    font-size: 16px; 
    font-weight: bold;
    text-align: center;
}

.oneexamcard1_ViewToppersBtn__2QKdf {
    
    width: 80%;
    height: 30px; 
    background-color: var(--themeColor);
    color: white;
    border-radius: 20px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; 
}

.oneexamcard1_ViewToppersBtn__2QKdf:hover {
    background-color: white;
    border: 1px solid var(--themeColor);
    color: var(--themeColor);
}

.ToppersCardSlide_slider__WSesh {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style:none;
  margin: auto;
}

.ToppersCardSlide_cards__2dxZv {
  width: 100%;
  margin-top: 30px;
  display: flex;
  background-color: white;
}

.ToppersCardSlide_card__bqbR9 {
  width: 30%;
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
  border: 1px solid var(--cardBorderColor);
}

.ToppersCardSlide_card__bqbR9:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.ToppersCardSlide_img__-8ocv {
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ToppersCardSlide_title__hGgm0 {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}

.ToppersCardSlide_description1__2Z6Aw {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ToppersCardSlide_description2__juJ6n {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ToppersCardSlide_ViewToppersBtn__w7NZn {
  margin-top: 20px;
  width: 150px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border-radius: 20px;
  border: none;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ToppersCardSlide_ViewToppersBtn__w7NZn:hover {
  border: 1px solid var(--themeColor);
  background-color: white;
  cursor: pointer;
  color: var(--themeColor);
}

.ToppersCardSlide_indicatorDots__2LEsp {
  margin-top: 40px;
  display: flex; /* Make the dots container a flex container */
  justify-content: center; /* Center the dots horizontally */
}

.ToppersCardSlide_dot__3HOW7 {
  /* Styles for regular dots */
  width: 10px;
  height: 10px;
  background-color: gray;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
}

.ToppersCardSlide_activeDot__3vAVl {
  /* Styles for active dot */
  background-color: black;
}

.Faqlist_faqContainer__3tQ32 {
  width: 100%;

  background-color: var(--cardBorderColorLight);
  background-color: var(--bodyBkgColor);
  border-radius: 10px;
  margin-top: 30px;
  padding: 20px;
}

.Faqlist_mainTitleContainer__3J2vE {
  margin-top: 30px;
  font-size: 30px;
  font-weight: bolder;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Faqlist_mainContainer__308Wo {
  margin-top: 30px;

  width: 100%;
  display: flex;
  flex-direction: column;
}

.Faqlist_questionsContainer__MHH8P {
  margin: 10px;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid var(--cardBorderColor);
}

.Faqlist_queContainer__tN7lv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}
.Faqlist_que1__2NZFf {
  font-size: 18px;
  font-weight: bold;
  color: black;
}

.Faqlist_arrow__3mUqg {
  width: 20px;
  height: 20px;
  background-color: var(--cardBorderColorLight);
  border-radius: 30px;
  padding: 6px;
}

.Faqlist_ans1__2k4sa {
  color: black;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
}

@media (max-width: 1000px) {
  .Faqlist_faqContainer__3tQ32 {
    width: 100%;
    padding: 0;
  }

  .Faqlist_mainTitleContainer__3J2vE {
    padding-top: 20px;
  }

  .Faqlist_mainContainer__308Wo {
    width: 100%;
  }
}

.SolvingItChanllengesBlock_rulesAndRegulationsParent__1f-Qg {
    margin-top: 40px;
    width: 100%;
  }
  
  .SolvingItChanllengesBlock_rulesAndRegulationsMain__1ZzYJ {
    width: 100%;
  }
  
  .SolvingItChanllengesBlock_innerDiv__2Qbic {
    width: 1200px;
    margin: auto;
  }
  
  .SolvingItChanllengesBlock_serchContainer__2fpgs {
    background-color: var(--bgColor);
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--cardBorderColor);
  }
  
  .SolvingItChanllengesBlock_serchbox__14pia {
    margin-left: 10px;
    width: 90%;
  
    background-color: white;
    border-radius: 8px;
    height: 28px;
    padding-left: 10px;
    font-size: 14px;
    border: none;
    border: 1px solid var(--cardBorderColorLight);
  }
  
  .SolvingItChanllengesBlock_serchbox__14pia:focus {
    border-style: solid;
    background-color: white;
    border-width: 2px;
    outline: none !important;
    border-color: var(--themeColor);
  }
  
  .SolvingItChanllengesBlock_btnContainer__1FZ8U {
    width: 100px;
    margin-left: 20px;
    height: 28px;
    background-color: var(--themeColor);
    border: none;
    border-radius: 8px;
    color: white;
    margin-right: 10px;
  }
  
  .SolvingItChanllengesBlock_btnContainer__1FZ8U:hover {
    cursor: pointer;
    background-color: white;
    color: var(--themeColor);
    border: 1px solid var(--themeColor);
  }
  
  .SolvingItChanllengesBlock_parentDiv__2A3fw {
    margin-top: 20px;
    display: flex;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(700px, auto));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
  
  .SolvingItChanllengesBlock_leftBox__zOc8F {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid var(--cardBorderColor);
  }
  
  .SolvingItChanllengesBlock_topHeadingSection__2Piyt {
    height: 40px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: var(--bgColor);
    border-bottom: 1px solid var(--cardBorderColor);
  }
  
  .SolvingItChanllengesBlock_iconContainer__1cnIl {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid var(--cardBorderColor);
  }
  
  .SolvingItChanllengesBlock_title__3Ca9x {
    width: 100%;
    margin-left: 10px;
    font-weight: bold;
    color: var(--darkText);
    text-align: center;
  }
  
  .SolvingItChanllengesBlock_rightBoxContainer__17swL {
  }
  
  .SolvingItChanllengesBlock_topBox__wej1y {
    width: 100%;
  
    border: 1px solid var(--cardBorderColor);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  .SolvingItChanllengesBlock_midBox__1pwcO {
    margin-top: 20px;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid var(--cardBorderColor);
  }
  
  .SolvingItChanllengesBlock_bottomBox__2i3k0 {
    margin-top: 20px;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid var(--cardBorderColor);
  }
  
  .SolvingItChanllengesBlock_mainContainer__21yON {
  }
  
  .SolvingItChanllengesBlock_mainIcon__1U_Pz {
  }
  
  .SolvingItChanllengesBlock_noticeIcon__15KBj {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .SolvingItChanllengesBlock_regIcon__144t8 {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .SolvingItChanllengesBlock_ruleIcon__3H3JJ {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .SolvingItChanllengesBlock_actIcon__3HnU- {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .SolvingItChanllengesBlock_rulesAndRegulationsParent__1f-Qg {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
  
  .SolvingItChanllengesBlock_reraData__2gvEF {
    margin-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
  
  .SolvingItChanllengesBlock_Block1__19wBQ {
    height: 500px;
    border: 1px solid var(--themeColor);
  }
  
  .SolvingItChanllengesBlock_Title__1AiDI {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--themeColor);
    background-color: var(--themeColor);
    font-weight: bold;
    color: white;
  }
  
  .SolvingItChanllengesBlock_mainContainer__21yON {
    padding-top: 10px;
    display: flex;
    padding-right: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--cardBorderColorLight);
  }
  
  .SolvingItChanllengesBlock_mainContainer__21yON:hover {
    background-color: var(--bgColor);
    cursor: pointer;
  }
  
  .SolvingItChanllengesBlock_nu__3rupH {
    display: flex;
    justify-content: center;
    width: 15%;
    font-size: 14px;
  }
  
  .SolvingItChanllengesBlock_details__2v2EP {
    margin-top: 10px;
    width: 100%;
    font-size: 13px;
    line-height: 24px;
    text-align: justify;
  }
  
  .SolvingItChanllengesBlock_Block2__1gNC3 {
    height: 500px;
    border: 1px solid var(--themeColor);
  }
  
  .SolvingItChanllengesBlock_Block3__2ggvd {
    height: 500px;
    border: 1px solid var(--themeColor);
  }
  
  .SolvingItChanllengesBlock_Block4__1-Tle {
    height: 500px;
    border: 1px solid var(--themeColor);
  }
  
  .SolvingItChanllengesBlock_threeCardContainer__36YRg {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    background-color: var(--chatCommentBkgColor);
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .SolvingItChanllengesBlock_topCard__1DXiB {
    width: 1200px;
    border-style: none;
    display: flex;
    margin: auto;
  }
  
  .SolvingItChanllengesBlock_topSectionJoinTopper__2KAgw {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .SolvingItChanllengesBlock_textDiv__FaPvZ {
    border-style: none;
  }
  
  .SolvingItChanllengesBlock_largeText__3aLib {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  
  .SolvingItChanllengesBlock_smallText__PjXaK {
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
  }
  
  .SolvingItChanllengesBlock_joinNowButton__2K9IC {
    height: 40px;
    width: 230px;
    border-radius: 30px;
    border-style: none;
    background-color: var(--themeColor);
    margin-top: 30px;
    cursor: pointer;
    font-family: roboto;
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  
  
  
  
  
  
  
  
  
  .SolvingItChanllengesBlock_joinNowButton__2K9IC:hover{
  
  color: var(--themeColor);
  background: white;
  border-style: solid;
  border-color: var(--themeColor);
  border-width: 1px;
  }
  
  
  
  .SolvingItChanllengesBlock_topRightSection__2nbls {
    width: 50%;
    object-fit: cover;
  }
  
  .SolvingItChanllengesBlock_girlIMageTop__1TmNt {
    width: 400px;
    height: 400px;
    object-fit: cover;
  }
  
  .SolvingItChanllengesBlock_card1__143ca {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 200px;
  }
  
  .SolvingItChanllengesBlock_card2__1UDqo {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 200px;

  }
  
  .SolvingItChanllengesBlock_card3__k8KlD {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 200px;

  }
  
  .SolvingItChanllengesBlock_contentContainer__1_B-J {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .SolvingItChanllengesBlock_img__3eTYn {
    width: 80px;
    height: 80px;
    /* border: 1px solid var(--cardBorderColorLight); */
    padding: 1px;
    /* background-color: var(--cardBorderColorLight); */
    /* border-radius: 40%; */
 
  }
  
  /* .card1:hover .img {
    transform: rotate(360deg);
  }
  
  .card2:hover .img {
    transform: rotate(360deg);
  }
  
  .card3:hover .img {
    transform: rotate(360deg);
  } */
  
  .SolvingItChanllengesBlock_title__3Ca9x {
    width: 100%;
    margin-top: 10px;
    font-size: 16px;
    color: var(--deepDarkThemeTextColor);
    font-weight: bold;
    line-height: 28px;
  }
  
  /* .details {
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  } */
  
  .SolvingItChanllengesBlock_inconstructionMessage__146Si {
    margin-top: 40px;
    color: red;
    font-size: 25px;
    border-style: solid;
    padding: 20px;
    border-radius: 10px;
  }
  
  .SolvingItChanllengesBlock_shapethefutureCard__3rdsa {
    margin-top: 30px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 20px;
    border-radius: 10px;
  }
  
  .SolvingItChanllengesBlock_shapetheFutureTitle__11uJj {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 30px;
    color: var(--deepDarkThemeTextColor);
  }
  
  .SolvingItChanllengesBlock_shapetheFutureButton__1IpfC {
    width: 100px;
    height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: var(--themeColor);
    color: white;
    border: none;
    border-radius: 6px;
  }
  
  .SolvingItChanllengesBlock_shapetheFutureButton__1IpfC:hover {
    cursor: pointer;
    color: var(--themeColor);
    border: 1px solid var(--themeColor);
    background-color: white;
  }
  
  .SolvingItChanllengesBlock_shapethefutureDetails__3oI6x {
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 26px;
    color: var(--deepDarkThemeTextColor);
    font-weight: 500;
    font-size: 18px;
  }
  
  .SolvingItChanllengesBlock_mainTitle1__2cmbJ {
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 30px;
    color: var(--deepDarkThemeTextColor);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .SolvingItChanllengesBlock_submainTitle__FmliF {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: oblique;
    color: var(--themeColor);
  }
  
  .SolvingItChanllengesBlock_details1__1875R {
    margin-top: 50px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 26px;
    color: var(--deepDarkThemeTextColor);
    font-weight: 500;
    font-size: 18px;
  }
  
  .SolvingItChanllengesBlock_details2__1vHCW {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 26px;
    color: var(--deepDarkThemeTextColor);
    font-weight: 500;
    font-size: 18px;
  }
  
  .SolvingItChanllengesBlock_mainTitle2__7v0-q {
    margin-top: 50px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 30px;
    color: var(--deepDarkThemeTextColor);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .SolvingItChanllengesBlock_joinToppersCard__3m-bj {
    width: 100%;
    background-color: var(--chatCommentBkgColor);
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 20px;
    margin-bottom: 20px;
    border-style: none;
    margin: auto;
  }
  
  .SolvingItChanllengesBlock_newContainer__pf_-f {
    margin-top: 50px;
    padding: 10px;
  }
  
  .SolvingItChanllengesBlock_mainheadingTitle__XRa52 {
    font-weight: bolder;
    font-size: 30px;
    color: black;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .SolvingItChanllengesBlock_mainHead1__2HXJf {
    margin-top: 15px;
    font-size: 17px;
    font-weight: bolder;
    color: var(--deepDarkThemeTextColor);
  }
  
  .SolvingItChanllengesBlock_mainDetail1__1FvHL {
    margin-top: 6px;
    font-size: 16px;
    color: var(--lightColor);
  }
  
  .SolvingItChanllengesBlock_howitworks__36Ju8 {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .SolvingItChanllengesBlock_howitworksTitle__3z73e {
    text-align: center;
    font-weight: bolder;
    font-size: 30px;
    color: black;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .SolvingItChanllengesBlock_lastContainer__2Ehw3 {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  
  .SolvingItChanllengesBlock_mainCircle__3hXVN {
    width: 50px;
    height: 50px;
    border: 1px solid var(--cardBorderColor);
    border-radius: 10%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .SolvingItChanllengesBlock_Acontaienr__kloj6 {
    width: 80%;
    border: 1px solid var(--cardBorderColor);
    border-radius: 10px;
    margin: 10px;
  }
  .SolvingItChanllengesBlock_titleA__tM5_8 {
    background-color: var(--cardBorderColorLight);
    height: 30px;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    right: 10px;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-left: 10px;
  }
  
  .SolvingItChanllengesBlock_desA__297TG {
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  
  @media (max-width: 1000px) {
    .SolvingItChanllengesBlock_joinToppersCard__3m-bj {
      width: 100%;
      padding: 0;
      margin: 0;
    }
  
    .SolvingItChanllengesBlock_topCard__1DXiB {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      margin: 0;
    }
  
    .SolvingItChanllengesBlock_submainTitle__FmliF{
      text-align: center;
    }
  
    .SolvingItChanllengesBlock_innerDiv__2Qbic {
      width: 98%;
      margin: 0;
    }
  
    .SolvingItChanllengesBlock_mainTitle1__2cmbJ {
      text-align: center;
    }
  
    .SolvingItChanllengesBlock_details1__1875R {
      text-align: justify;
      margin: 10px;
    }
  
    .SolvingItChanllengesBlock_details2__1vHCW {
      text-align: justify;
      margin: 10px;
    }
  
    .SolvingItChanllengesBlock_threeCardContainer__36YRg {
      margin: 10px;
    }
  
    .SolvingItChanllengesBlock_topSectionJoinTopper__2KAgw {
      width: 100%;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  
    .SolvingItChanllengesBlock_topRightSection__2nbls {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .SolvingItChanllengesBlock_shapethefutureCard__3rdsa {
      width: 100%;
    }
  
    .SolvingItChanllengesBlock_mainTitle2__7v0-q {
      text-align: center;
    }
  
    .SolvingItChanllengesBlock_howitworks__36Ju8 {
      margin: 0;
      padding: 0;
    }
  
    .SolvingItChanllengesBlock_lastContainer__2Ehw3 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
    }
  
    .SolvingItChanllengesBlock_Acontaienr__kloj6 {
    }
  
    .SolvingItChanllengesBlock_titleA__tM5_8 {
      height: 30px;
    }
    .SolvingItChanllengesBlock_desA__297TG {
      height: auto;
    }
  }
  
  .SolvingItChanllengesBlock_f_points_star__1OKFt {
    /* Modify size here: */
    --size: 30px;
    --half-size: calc(var(--size) / 2);
  
    position: relative;
    display: block;
    color: var(--themeColor);
    width: 0px;
    height: 0px;
    border-right: var(--half-size) solid transparent;
    border-bottom: calc(var(--half-size) * 0.7) solid #333;
    border-left: var(--half-size) solid transparent;
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
  }
  .SolvingItChanllengesBlock_f_points_star__1OKFt:before {
    border-bottom: calc(var(--half-size) * 0.8) solid #333;
    border-left: calc(var(--half-size) * 0.3) solid transparent;
    border-right: calc(var(--half-size) * 0.3) solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: calc(var(--half-size) * 0.45 * -1);
    left: calc(var(--half-size) * 0.65 * -1);
    display: block;
    content: "";
    -webkit-transform: rotate(-35deg);
            transform: rotate(-35deg);
  }
  .SolvingItChanllengesBlock_f_points_star__1OKFt:after {
    position: absolute;
    display: block;
    color: #333;
    top: calc(var(--half-size) * 0.03);
    left: calc(var(--half-size) * -1.05);
    width: 0px;
    height: 0px;
    border-right: var(--half-size) solid transparent;
    border-bottom: calc(var(--half-size) * 0.7) solid #333;
    border-left: var(--half-size) solid transparent;
    -webkit-transform: rotate(-70deg);
            transform: rotate(-70deg);
    content: "";
  }
  

.Digitalmarketingblock_marketingpage__18bfv {
    text-align: center;
    padding: 30px;
    font-family: Roboto;
    background-color: var(--chatCommentBkgColor);
    width: 100%;
    box-sizing: border-box; 
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.Digitalmarketingblock_title__2bEli {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333; 
}

.Digitalmarketingblock_subtitle__2urIF {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #555;
    line-height: 1.5; 
}

.Digitalmarketingblock_cardscontainer__pHXYO {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; 
    grid-gap: 20px; 
    gap: 20px;
    width: 100%; 
    max-width: 1200px; 
    margin: 0 auto; 
    padding: 0 10px; 
    box-sizing: border-box;
}

.Digitalmarketingblock_card__6wMEQ {
    background-color: #ffffff; 
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 100%; 
    max-width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s;
    transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
    overflow: hidden;
}

.Digitalmarketingblock_card__6wMEQ:hover {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
}

.Digitalmarketingblock_cardicon__2ocda {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.Digitalmarketingblock_cardimage__3mUp- {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 50%;
    background-color: #f0f0f0;
    padding: 10px;
}

.Digitalmarketingblock_cardtitle__3QlnA {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #222;
}

.Digitalmarketingblock_carddescription__n-1PV {
    font-size: 1rem;
    color: #666;
    line-height: 1.5; 
}

.Digitalmarketingblock_explorebutton__wyGx1 {
    background-color: var(--themeColor); 
    color: white;
    border: none;
    padding: 12px 25px; 
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s, -webkit-transform 0.3s;
    transition: background-color 0.3s, transform 0.3s;
    transition: background-color 0.3s, transform 0.3s, -webkit-transform 0.3s;
    text-decoration: none; 
    display: inline-block; 
}

.Digitalmarketingblock_explorebutton__wyGx1:hover {
    background-color: #0056b3;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

@media (max-width: 768px) {
    .Digitalmarketingblock_cardscontainer__pHXYO {
        flex-direction: column; 
        align-items: center;
    }

    .Digitalmarketingblock_card__6wMEQ {
        width: calc(100% - 40px); 
        margin-bottom: 20px; 
    }

    .Digitalmarketingblock_title__2bEli {
        font-size: 2rem; 
    }

    .Digitalmarketingblock_subtitle__2urIF {
        font-size: 1rem; 
    }

    .Digitalmarketingblock_explorebutton__wyGx1 {
        font-size: 1rem; 
        padding: 10px 20px; 
    }
}

/* Optional for better content centering */
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll on the entire page */
    box-sizing: border-box;
}

.Newblock_rulesAndRegulationsParent__3Vpps {
    margin-top: 40px;
    width: 100%;
    font-family: Roboto;
  }
  
  .Newblock_rulesAndRegulationsMain__16vrx {
    width: 100%;
  }
  
  .Newblock_innerDiv__26-pd {
    width: 1200px;
    margin: auto;
  }
  
  .Newblock_serchContainer__1RCqO {
    background-color: var(--bgColor);
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--cardBorderColor);
  }
  
  .Newblock_serchbox__1w5eS {
    margin-left: 10px;
    width: 90%;
  
    background-color: white;
    border-radius: 8px;
    height: 28px;
    padding-left: 10px;
    font-size: 14px;
    border: none;
    border: 1px solid var(--cardBorderColorLight);
  }
  
  .Newblock_serchbox__1w5eS:focus {
    border-style: solid;
    background-color: white;
    border-width: 2px;
    outline: none !important;
    border-color: var(--themeColor);
  }
  
  .Newblock_btnContainer__11L0D {
    width: 100px;
    margin-left: 20px;
    height: 28px;
    background-color: var(--themeColor);
    border: none;
    border-radius: 8px;
    color: white;
    margin-right: 10px;
  }
  
  .Newblock_btnContainer__11L0D:hover {
    cursor: pointer;
    background-color: white;
    color: var(--themeColor);
    border: 1px solid var(--themeColor);
  }
  
  .Newblock_parentDiv__2q4K3 {
    margin-top: 20px;
    display: flex;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(700px, auto));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
  
  .Newblock_leftBox__1o8YE {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid var(--cardBorderColor);
  }
  
  .Newblock_topHeadingSection__2WU9R {
    height: 40px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: var(--bgColor);
    border-bottom: 1px solid var(--cardBorderColor);
  }
  
  .Newblock_iconContainer__XeCVm {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid var(--cardBorderColor);
  }
  
  .Newblock_title__2Lw2D {
    width: 100%;
    margin-left: 10px;
    font-weight: bold;
    color: var(--darkText);
    text-align: center;
  }
  
  .Newblock_rightBoxContainer__169xD {
  }
  
  .Newblock_topBox__3yDPN {
    width: 100%;
  
    border: 1px solid var(--cardBorderColor);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  .Newblock_midBox__4gGGl {
    margin-top: 20px;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid var(--cardBorderColor);
  }
  
  .Newblock_bottomBox__1QVcw {
    margin-top: 20px;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid var(--cardBorderColor);
  }
  
  .Newblock_mainContainer__2oZ9N {
  }
  
  .Newblock_mainIcon__1rDrE {
  }
  
  .Newblock_noticeIcon__22PIR {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Newblock_regIcon__20QNg {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Newblock_ruleIcon__3Zvlq {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Newblock_actIcon__2c8hE {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Newblock_rulesAndRegulationsParent__3Vpps {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
  
  .Newblock_reraData__13buz {
    margin-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
  
  .Newblock_Block1__1yGhg {
    height: 500px;
    border: 1px solid var(--themeColor);
  }
  
  .Newblock_Title__262tG {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--themeColor);
    background-color: var(--themeColor);
    font-weight: bold;
    color: white;
  }
  
  .Newblock_mainContainer__2oZ9N {
    padding-top: 10px;
    display: flex;
    padding-right: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--cardBorderColorLight);
  }
  
  .Newblock_mainContainer__2oZ9N:hover {
    background-color: var(--bgColor);
    cursor: pointer;
  }
  
  .Newblock_nu__2xdIo {
    display: flex;
    justify-content: center;
    width: 15%;
    font-size: 14px;
  }
  
  .Newblock_details__2RyEc {
    margin-top: 10px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
  }
  
  .Newblock_Block2__2SZ02 {
    height: 500px;
    border: 1px solid var(--themeColor);
  }
  
  .Newblock_Block3__8dr6M {
    height: 500px;
    border: 1px solid var(--themeColor);
  }
  
  .Newblock_Block4__H03gx {
    height: 500px;
    border: 1px solid var(--themeColor);
  }
  
  .Newblock_threeCardContainer__1Q_a_ {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
  
  .Newblock_topCard__1PeUG {
    width: 1200px;
    border-style: none;
    display: flex;
    margin: auto;
  }
  
  .Newblock_topSectionJoinTopper__2uOCO {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .Newblock_textDiv__1iI4_ {
    border-style: none;
  }
  
  .Newblock_largeText__uhIZo {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  
  .Newblock_smallText__BhBrD {
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
  }
  
  .Newblock_joinNowButton__1X4ps {
    height: 40px;
    width: 230px;
    border-radius: 30px;
    border-style: none;
    background-color: var(--themeColor);
    margin-top: 30px;
    cursor: pointer;
    font-family: roboto;
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  
  
  
  
  
  
  
  
  
  .Newblock_joinNowButton__1X4ps:hover{
  
  color: var(--themeColor);
  background: white;
  border-style: solid;
  border-color: var(--themeColor);
  border-width: 1px;
  }
  
  
  
  .Newblock_topRightSection__2RnNJ {
    width: 50%;
    object-fit: cover;
  }
  
  .Newblock_girlIMageTop__3BKnA {
    width: 400px;
    height: 400px;
    object-fit: cover;
  }
  
  .Newblock_card1__1QVcV {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .Newblock_card2__3GIDg {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .Newblock_card3__2humv {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .Newblock_contentContainer__UAjti {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .Newblock_img__zZJf- {
    width: 60px;
    height: 60px;
 
  }

.Newblock_card4__1qsPY{
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


  /* .card1:hover .img {
    transform: rotate(360deg);
  }
  
  .card2:hover .img {
    transform: rotate(360deg);
  }
  
  .card3:hover .img {
    transform: rotate(360deg);
  } */
  
  .Newblock_title__2Lw2D {
    width: 80%;
    margin-top: 10px;
    font-size: 18px;
    color: var(--deepDarkThemeTextColor);
    font-weight: bold;
    line-height: 28px;
  }
  
  /* .details {
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  } */
  
  .Newblock_inconstructionMessage__2WOvy {
    margin-top: 40px;
    color: red;
    font-size: 25px;
    border-style: solid;
    padding: 20px;
    border-radius: 10px;
  }
  
  .Newblock_shapethefutureCard__1qdZ_ {
    margin-top: 30px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 20px;
    border-radius: 10px;
  }
  
  .Newblock_shapetheFutureTitle__kN_6h {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 30px;
    color: var(--deepDarkThemeTextColor);
  }
  
  .Newblock_shapetheFutureButton__2iPFq {
    width: 100px;
    height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: var(--themeColor);
    color: white;
    border: none;
    border-radius: 6px;
  }
  
  .Newblock_shapetheFutureButton__2iPFq:hover {
    cursor: pointer;
    color: var(--themeColor);
    border: 1px solid var(--themeColor);
    background-color: white;
  }
  
  .Newblock_shapethefutureDetails__2jTjb {
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 26px;
    color: var(--deepDarkThemeTextColor);
    font-weight: 500;
    font-size: 18px;
  }
  
  .Newblock_mainTitle1__1_boE {
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 30px;
    color: var(--deepDarkThemeTextColor);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Newblock_submainTitle__zpygQ {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: oblique;
    color: var(--themeColor);
  }
  
  .Newblock_details1__1mp85 {
    margin-top: 50px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 26px;
    color: var(--deepDarkThemeTextColor);
    font-weight: 500;
    font-size: 18px;
  }
  
  .Newblock_details2__25unF {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 26px;
    color: var(--deepDarkThemeTextColor);
    font-weight: 500;
    font-size: 18px;
  }
  
  .Newblock_mainTitle2__ndmqw {
    margin-top: 50px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 30px;
    color: var(--deepDarkThemeTextColor);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Newblock_joinToppersCard__nYref {
    width: 100%;
    background-color: var(--chatCommentBkgColor);
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 20px;
    margin-bottom: 20px;
    border-style: none;
    margin: auto;
  }
  
  .Newblock_newContainer__2f5XC {
    margin-top: 50px;
    padding: 10px;
  }
  
  .Newblock_mainheadingTitle__3lq7o {
    font-weight: bolder;
    font-size: 30px;
    color: black;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .Newblock_mainHead1__2kJK7 {
    margin-top: 15px;
    font-size: 17px;
    font-weight: bolder;
    color: var(--deepDarkThemeTextColor);
  }
  
  .Newblock_mainDetail1__V08O1 {
    margin-top: 6px;
    font-size: 16px;
    color: var(--lightColor);
  }
  
  .Newblock_howitworks__3GmRE {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .Newblock_howitworksTitle__3yrD1 {
    text-align: center;
    font-weight: bolder;
    font-size: 30px;
    color: black;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .Newblock_lastContainer__23iHy {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  
  .Newblock_mainCircle__25y6k {
    width: 50px;
    height: 50px;
    border: 1px solid var(--cardBorderColor);
    border-radius: 10%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Newblock_Acontaienr__1-WbT {
    width: 80%;
    border: 1px solid var(--cardBorderColor);
    border-radius: 10px;
    margin: 10px;
  }
  .Newblock_titleA__1rzGc {
    background-color: var(--cardBorderColorLight);
    height: 30px;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    right: 10px;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-left: 10px;
  }
  
  .Newblock_desA__1hAOm {
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  
  @media (max-width: 1000px) {
    .Newblock_joinToppersCard__nYref {
      width: 100%;
      padding: 0;
      margin: 0;
    }
  
    .Newblock_topCard__1PeUG {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      margin: 0;
    }
  
    .Newblock_submainTitle__zpygQ{
      text-align: center;
    }
  
    .Newblock_innerDiv__26-pd {
      width: 98%;
      margin: 0;
    }
  
    .Newblock_mainTitle1__1_boE {
      text-align: center;
    }
  
    .Newblock_details1__1mp85 {
      text-align: justify;
      margin: 10px;
    }
  
    .Newblock_details2__25unF {
      text-align: justify;
      margin: 10px;
    }
  
    .Newblock_threeCardContainer__1Q_a_ {
      margin: 10px;
    }
  
    .Newblock_topSectionJoinTopper__2uOCO {
      width: 100%;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  
    .Newblock_topRightSection__2RnNJ {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .Newblock_shapethefutureCard__1qdZ_ {
      width: 100%;
    }
  
    .Newblock_mainTitle2__ndmqw {
      text-align: center;
    }
  
    .Newblock_howitworks__3GmRE {
      margin: 0;
      padding: 0;
    }
  
    .Newblock_lastContainer__23iHy {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
    }
  
    .Newblock_Acontaienr__1-WbT {
    }
  
    .Newblock_titleA__1rzGc {
      height: 30px;
    }
    .Newblock_desA__1hAOm {
      height: auto;
    }
  }
  
  .Newblock_f_points_star__1pyzo {
    /* Modify size here: */
    --size: 30px;
    --half-size: calc(var(--size) / 2);
  
    position: relative;
    display: block;
    color: var(--themeColor);
    width: 0px;
    height: 0px;
    border-right: var(--half-size) solid transparent;
    border-bottom: calc(var(--half-size) * 0.7) solid #333;
    border-left: var(--half-size) solid transparent;
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
  }
  .Newblock_f_points_star__1pyzo:before {
    border-bottom: calc(var(--half-size) * 0.8) solid #333;
    border-left: calc(var(--half-size) * 0.3) solid transparent;
    border-right: calc(var(--half-size) * 0.3) solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: calc(var(--half-size) * 0.45 * -1);
    left: calc(var(--half-size) * 0.65 * -1);
    display: block;
    content: "";
    -webkit-transform: rotate(-35deg);
            transform: rotate(-35deg);
  }
  .Newblock_f_points_star__1pyzo:after {
    position: absolute;
    display: block;
    color: #333;
    top: calc(var(--half-size) * 0.03);
    left: calc(var(--half-size) * -1.05);
    width: 0px;
    height: 0px;
    border-right: var(--half-size) solid transparent;
    border-bottom: calc(var(--half-size) * 0.7) solid #333;
    border-left: var(--half-size) solid transparent;
    -webkit-transform: rotate(-70deg);
            transform: rotate(-70deg);
    content: "";
  }
  

.PricingBlock_app__13V0r {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.PricingBlock_text__30tS4 {
    text-align: center;
    margin-top: 20px;
    font-size: 38px;
    height: 50px;
}

.PricingBlock_text1__19P3d {
    font-size: 30px;
    font-weight: 700;
    color: #004aad;
    height: 25px;
}

.PricingBlock_text2__6Cdg3 {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 5px;
}

.PricingBlock_strong__1yBVT {
    font-size: 20px;
    text-align: center;
}

.PricingBlock_leftImg__3NMu0 {
    /* height: ; */
}

.PricingBlock_rightImg__2L_2d {
    height: 219px;
}

.PricingBlock_howItWorks__3p2t_ {
    height: auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.PricingBlock_mainBlock__1OQgn {
    display: flex;
    /* flex-direction: column; */
    height: 100%;
    grid-gap: 38px;
    gap: 38px;
    margin-bottom: 30px;
}

.PricingBlock_eduImg__3wIzb{
    max-width: 100%;
    max-height: 100%; 
    display: block; 
    margin: auto;
}


.PricingBlock_LineOne__10Ae4{
    font-size: 27px;
    font-weight: 900;
    margin-top: 25px;
}
.PricingBlock_LineTwo__3IuFs{
    font-size: 20px;
    margin-bottom: 25px;
}

.PricingBlock_headContainerSecond__1slwX{
    background-color: var(--themeColor);
    color: #fff;
    text-align: center;
    /* padding-top: 5px; */
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    
}
.PricingBlock_headthirdContainer__51NJg{
    background-color: var(--cardBackgroundColor);
}



.PricingBlock_howItWorksFirst__6PCSj ,.PricingBlock_howItWorksSecond__UMmfI ,.PricingBlock_howItWorksThird__1iV7q{
    display: flex;
    flex-direction: column;
    grid-gap: none;
    gap: none;
    /* border: 0.5px black; */
    border-radius: 15px;
    text-wrap: wrap;
    width: 33%;   
    overflow: hidden;
    background-color:var(--cardBackgroundColor);
    /* justify-content: center; */

}
.PricingBlock_divList__25H9h{
    font-size: 22px;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 40px;
    margin-bottom: 40px;    
    /* text-align: justify; */
}



.PricingBlock_listItem__3-6aJ{
    display: flex;
}

.PricingBlock_btnJoinNow__15N1G{
    background-color: #fff;
    border-style: none;
    color: var(--themeColor);
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 60px;
    cursor: pointer;
}
.PricingBlock_joinNow__1gFFB{
    text-align: center;
}

.PricingBlock_leftBlock__15WHG,
.PricingBlock_rightBlock__3K5tO {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48%;
}

.PricingBlock_leftBox__3656R,
.PricingBlock_rightBox__3XxDE {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding-top: 45px;
    padding-bottom: 25px;
    flex: 1 1;
    grid-gap: 35px;
    gap: 35px;
    justify-content: space-evenly;
    width: 100%;
    box-sizing: border-box;
    border: 0.1px black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: center;
    border-radius: 15px;
}

.PricingBlock_hds__1ulwt {
    font-size: 20px;
}

.PricingBlock_cnt__2wzQ- {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 400;
    padding-left: 45px;
    padding-right: 45px;
}

.PricingBlock_textContainer__3vjL4 {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}



/* Trending Live Classes */

.PricingBlock_heroContainer__1GN77 {
    /* display: flex; */
    width: 1200px;
    height: auto;
}


.PricingBlock_heroHeading__b17R8 {
    width: 100%;
    margin-bottom: 25px;
}

.PricingBlock_headCnt__f5_W2 {
    font-size: 31px;
    text-align: center;
    font-weight: 700;
}

.PricingBlock_mainTop__1AGdG {
    display: flex;
    justify-content: center;
    grid-gap: 43px;
    gap: 43px;
    align-items: center;
    height: auto;
}

.PricingBlock_mainBottom__3LbOk {
    display: flex;
    width: 100%;
    height: auto;
}

.PricingBlock_firstBox__Rqzqi,
.PricingBlock_secondBox__3XRKc,
.PricingBlock_thirdBox__rxSTs {
    width: 100%;
    /* border: 0.5px solid #004aad; */
    box-shadow: 0 6px 12px -2px rgba(50, 50, 93, .25), 0 3px 7px -3px rgba(0, 0, 0, .3);
    border: none;
    border-radius: 15px;
    overflow: hidden;
    flex: 1 1;
    height: auto;

}

.PricingBlock_imgAll__qlajw {
    width: 100%;
    aspect-ratio: 1.7/1;
    object-fit: fill;
}










.PricingBlock_leftBlock__15WHG .PricingBlock_textContainer__3vjL4 .PricingBlock_hds__1ulwt{
    color:rgb(57, 54, 54);
    font-weight: bold;
}

.PricingBlock_rightBlock__3K5tO .PricingBlock_textContainer__3vjL4 .PricingBlock_hds__1ulwt{
    font-weight: bold;
}


.PricingBlock_cnt__2wzQ-{
    color: rgb(107, 105, 105);
}


.PricingBlock_middleBlock__AZWqv{
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
   
}






.PricingBlock_info__1RvSc {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* gap: 60px; */
    background-color: #0056D2;


}

.PricingBlock_time__1HPKt,
.PricingBlock_faculty__1APQs {
    margin-top: 25px;
    margin-bottom: 30px;
    color: white;
    font-weight: 700;
}

.PricingBlock_span__13y0a{
    font-size: 22px;
    margin-bottom: 10px;
}
.PricingBlock_rs__3LjeW{
    margin-top: 20px;
    font-size: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.PricingBlock_dollar__1JTdE{
    font-size: 28px;
    margin-bottom: auto;
    margin-top: 6px;
}
.PricingBlock_enrollNow__3k6aC {
    width: 50%;
    border-radius: 5px;
    height: 40px;
    color: white;
    font-weight: 700;
    /* background-color: #004aad; */
    background-color: white;
    color: #004aad;
    border: 1px solid #004aad;
    cursor: pointer;
    margin-bottom: 30px;
    margin-left:30px;
}

.PricingBlock_enrollNow__3k6aC:hover{
background-color: #004aad;
color: white;
border: 1px solid white;
}

.PricingBlock_allCourses__1CDGF {
    font-size: 24px;
    color: #004aad;
    font-weight: 400;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.PricingBlock_mainBottom__3LbOk {
    display: flex;
    justify-content: space-around;
    /* gap: 170px; */
    color: black;
    font-weight: 700;
    font-size: 32px;
    width: 100%;
}

.PricingBlock_orgInfo__23bUc {
    margin-bottom: 40px;
}
.PricingBlock_div__3LfW1{
    margin-top: 20px;
    width: 82%;
    text-align:left;
    height: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.PricingBlock_innerDiv__2Uq0S{
    width: 100%;
    display: flex;
    grid-gap: 5px;
    gap: 5px;
}



/* start your journey block */

.PricingBlock_contentFirst__1u5kQ{
    font-size: 30px;
    font-weight: 700;
}
.PricingBlock_contentSecond__3RhS4{
    font-size: 25px;
}

.PricingBlock_blockThree__kIeX8{
    display: flex;
    width: 1200px;
    grid-gap: 200px;
    gap: 200px;
    
}

.PricingBlock_leftContent__2GnKv{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
}
.PricingBlock_FourImagesContainer__dK-T_{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 50px;
    gap: 50px;
}
.PricingBlock_child__1NUA-{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PricingBlock_topImgContainer__1Tbq4 {
    display: flex;
    grid-gap: 208px;
    gap: 208px;
    /* flex-direction: column-reverse; */
}
.PricingBlock_bottomImgContainer__3dZBr{
    display: flex;
    grid-gap: 119px;
    gap: 119px;
}

.PricingBlock_topImgLeftContainer__3rl46 ,.PricingBlock_topImgRightContainer__3lPF6{
    display: grid;
    grid-gap: 5px;
    gap: 5px;
}

.PricingBlock_getStarted__32rUM{
    background-color: var(--themeColor);
    color: #fff;
    font-size: 23px;
    width: 80%;
    height: 50px;
    cursor: pointer;
    border: 1px solid #0b3772;
    border-radius: 8px;
}

.PricingBlock_sessions__1V90w ,.PricingBlock_liveClass__2wEC- ,.PricingBlock_subjects__V8xHu ,.PricingBlock_educators__1XGVn {
    font-size: 22px;
}

.PricingBlock_sessions__1V90w ,.PricingBlock_liveClass__2wEC-{
    /* margin-top: 3px; */
}

.PricingBlock_firstInFourImg__1m92n {
    margin-left: 64px;
}

.PricingBlock_educators__1XGVn{
    margin-left: 25px;
}

.PricingBlock_subjects__V8xHu{
    /* margin-left: -21px; */
}

.PricingBlock_educators__1XGVn ,.PricingBlock_subjects__V8xHu ,.PricingBlock_liveClass__2wEC- ,.PricingBlock_sessions__1V90w{
    text-align: center;
}


.PricingBlock_thirdInFourImg__1Ywq1 ,.PricingBlock_fourthInFourImg__2crnq{
    display: grid;
    justify-content: center;
    align-items: center;
}






/* App Store */

.PricingBlock_appStoreContainer__28c01{
    display: flex;
    margin-top: 60px;
    width: 1200px;
    margin-bottom: 50px;
}

.PricingBlock_headLineAppstore__1konr{
    font-size: 30px;
    font-weight: 700;
    margin-top: 100px;
    margin-bottom: 25px;
}
.PricingBlock_storeImgDiv__3tvGP{
    display: flex;
}

.PricingBlock_contentAppStore__2Qhxz{
    font-size: 25px;
}

.PricingBlock_imgAppStore__M3AKz{
    /* width: 70%; */
}
.PricingBlock_infoAppStore___17wE{
    width: 50%;
    margin-top: -64px;
}

.PricingBlock_androidImg__GZt1i{
    width: 100%;
}
.PricingBlock_appleStoreImgContainer__3NOAg{
    width: 200px;
    margin-right: 20px;
}
.PricingBlock_appleStoreImg__cVZG1 {
    width: 100%;
    aspect-ratio: 2.8/1;
    margin-top: 35px;
    margin-right: 20px;
}
.PricingBlock_playStoreImgContainer__2zlhq{
    width: 200px;
    margin-right: 20px;
}
.PricingBlock_playStoreImg__1vUiH{
    width: 100%;
    aspect-ratio: 2.8/1;
    margin-top: 34px;
    margin-right: 20px;
}













.PricingBlock_first__3Zu4W{
    height: 100px;
}





/* Fourth-block */
.PricingBlock_fourthContainer__1GNHI {
    background-color: #0C4Dc4;
    width: 100vw;
    height: auto;
    text-align: center;
}

.PricingBlock_heading__1b1Zt {
    font-family: 'Roboto', sans-serif;
    font-size: 35px;
    font-weight: 300;
    color: white;
}

.PricingBlock_join__1cGon {
    height: 60px;
    width: 207px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 10px;
}

.PricingBlock_cardContainer__1rtGi{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.PricingBlock_bottomContainer__qbZek{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

.PricingBlock_servicesContainer__3McIB{
    display: flex;
    justify-content: center;
    align-items: center;
}

.PricingBlock_powerContainer__3dXn2{
    font-size: 20px;
    color: white;
    margin-right: 40px;
}

.PricingBlock_servicesContainer__3McIB button{
    border: none;
    height: 30px;
    width: 30px;
    font-size: 15px;
    border-radius: 5px;
    margin-right: 10px;
    font-weight: bold;
}

.PricingBlock_servicesContainer__3McIB .PricingBlock_diracaiContainer__31zU8{
    color: white;
    font-weight: bolder;
    font-size: 18px;
}

.PricingBlock_c__2L0HU{
    width: 120px;
}




/* Media queries */
@media (max-width: 1299px) {

    .PricingBlock_howItWorks__3p2t_,
    .PricingBlock_heroContainer__1GN77,
    .PricingBlock_blockThree__kIeX8,
    .PricingBlock_appStoreContainer__28c01 {
        width: 80%;
    }
}

@media (min-width: 1300px) {

    .PricingBlock_howItWorks__3p2t_,
    .PricingBlock_heroContainer__1GN77,
    .PricingBlock_blockThree__kIeX8 ,
    .PricingBlock_appleStoreContainer__2YHI_{
        width: 1200px;
        max-width: none;
        /* Remove max-width for screens 1300px or more */
        margin: 0 auto;
    }
}


@media(max-width: 1024px) {
    .PricingBlock_leftBox__3656R {
        grid-gap: 30px;
        gap: 30px;
    }

    .PricingBlock_heading__1b1Zt {
        margin-left: 20px;
        margin-right: 20px;
    }

    .PricingBlock_mainTop__1AGdG {
        flex-direction: column;

    }

    .PricingBlock_firstBox__Rqzqi,
    .PricingBlock_secondBox__3XRKc,
    .PricingBlock_thirdBox__rxSTs {
        /* width: 70%; */
    }
}

@media (max-width: 600px) {
    .PricingBlock_mainBlock__1OQgn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .PricingBlock_howItWorksFirst__6PCSj , .PricingBlock_howItWorksSecond__UMmfI , .PricingBlock_howItWorksThird__1iV7q{
        width: 90%;
        max-width: 550px;
    }
    .PricingBlock_howItWorksFirst__6PCSj img , .PricingBlock_howItWorksSecond__UMmfI img , .PricingBlock_howItWorksThird__1iV7q img{
        width: 600px;
    }

    .PricingBlock_text__30tS4 {
        margin-bottom: 20px;
    }

    .PricingBlock_heading__1b1Zt {
        font-size: 31px;
        margin-left: 25px;
        margin-right: 25px;
    }

    .PricingBlock_leftBlock__15WHG,
    .PricingBlock_rightBlock__3K5tO {
        width: 100%;
    }

    .PricingBlock_orgInfo__23bUc {
        font-size: 25px;

    }
    
    .PricingBlock_blockThree__kIeX8{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .PricingBlock_topImgContainer__1Tbq4 {
        grid-gap: 0px;
        gap: 0px;
        width: 100%;
        justify-content: center !important;
    }
    .PricingBlock_bottomImgContainer__3dZBr{
        grid-gap: 0px;
        gap: 0px;
        width: 100%;
        justify-content: space-around;
    }
    .PricingBlock_FourImagesContainer__dK-T_{
        width: 100% !important;
        grid-gap: 30px;
        gap: 30px;
    }
    
    .PricingBlock_c__2L0HU{
        width: 70px;
    }
    .PricingBlock_blockThree__kIeX8 .PricingBlock_leftContent__2GnKv{
        width: 90%;
    }

    .PricingBlock_blockThree__kIeX8 .PricingBlock_leftContent__2GnKv .PricingBlock_content__2Hugn{
        width: 100%;
    }

    .PricingBlock_blockThree__kIeX8 .PricingBlock_leftContent__2GnKv .PricingBlock_content__2Hugn button{
        width: 100%;
    }

    .PricingBlock_appStoreContainer__28c01{
        flex-direction: column-reverse;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .PricingBlock_appStoreContainer__28c01 .PricingBlock_imgAppStore__M3AKz{
        width: 90%;
    }
    .PricingBlock_appStoreContainer__28c01 .PricingBlock_infoAppStore___17wE{
        width: 90%;
    }
    .PricingBlock_appStoreContainer__28c01 ,.PricingBlock_infoAppStore___17wE .PricingBlock_storeImgDiv__3tvGP{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .PricingBlock_playStoreImgContainer__2zlhq, .PricingBlock_appleStoreImgContainer__3NOAg{
        margin-right: 0px;
    }
    .PricingBlock_sessions__1V90w ,.PricingBlock_liveClass__2wEC- ,.PricingBlock_subjects__V8xHu ,.PricingBlock_educators__1XGVn {
        font-size: 19px;
    }
    .PricingBlock_divList__25H9h{
        font-size: 18px;
    }
    
}


@media (max-width: 1200px) {
    .PricingBlock_mainBlock__1OQgn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .PricingBlock_howItWorksFirst__6PCSj , .PricingBlock_howItWorksSecond__UMmfI , .PricingBlock_howItWorksThird__1iV7q{
        width: 90%;
        max-width: 550px;
    }
    .PricingBlock_howItWorksFirst__6PCSj img , .PricingBlock_howItWorksSecond__UMmfI img , .PricingBlock_howItWorksThird__1iV7q img{
        width: 600px;
    }

    .PricingBlock_text__30tS4 {
        margin-bottom: 20px;
    }

    .PricingBlock_heading__1b1Zt {
        font-size: 31px;
        margin-left: 25px;
        margin-right: 25px;
    }

    .PricingBlock_leftBlock__15WHG,
    .PricingBlock_rightBlock__3K5tO {
        width: 100%;
    }

    .PricingBlock_orgInfo__23bUc {
        font-size: 25px;

    }
    
    .PricingBlock_blockThree__kIeX8{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .PricingBlock_topImgContainer__1Tbq4 {
        grid-gap: 0px;
        gap: 0px;
        width: 100%;
        justify-content: space-around;
    }
    .PricingBlock_bottomImgContainer__3dZBr{
        grid-gap: 0px;
        gap: 0px;
        width: 100%;
        justify-content: space-around;
    }

    .PricingBlock_blockThree__kIeX8 .PricingBlock_leftContent__2GnKv{
        width: 90%;
    }

    .PricingBlock_blockThree__kIeX8 .PricingBlock_leftContent__2GnKv .PricingBlock_content__2Hugn{
        width: 100%;
    }

    .PricingBlock_blockThree__kIeX8 .PricingBlock_leftContent__2GnKv .PricingBlock_content__2Hugn button{
        width: 100%;
    }

    .PricingBlock_appStoreContainer__28c01{
        flex-direction: column-reverse;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .PricingBlock_appStoreContainer__28c01 .PricingBlock_imgAppStore__M3AKz{
        width: 90%;
    }
    .PricingBlock_appStoreContainer__28c01 .PricingBlock_infoAppStore___17wE{
        width: 90%;
    }
    .PricingBlock_appStoreContainer__28c01 ,.PricingBlock_infoAppStore___17wE .PricingBlock_storeImgDiv__3tvGP{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .PricingBlock_playStoreImgContainer__2zlhq, .PricingBlock_appleStoreImgContainer__3NOAg{
        margin-right: 0px;
    }   
    .PricingBlock_thirdInFourImg__1Ywq1{
        margin-left: 54px;
    } 

    
    .PricingBlock_topImgContainer__1Tbq4 {
        width: 100%;
        grid-gap: 130px;
        gap: 130px;
        justify-content: center;

    }
    
    
    .PricingBlock_bottomImgContainer__3dZBr{
        justify-content: center;
        grid-gap: 90px;
        gap: 90px;
    }
    .PricingBlock_FourImagesContainer__dK-T_{
        grid-gap: 60px;
        gap: 60px;
    }
    .PricingBlock_blockThree__kIeX8{
        grid-gap: 60px;
        gap: 60px;
    }
}


@media (max-width :425px) {
    .PricingBlock_text__30tS4 {
        font-size: 30px;
    }

    .PricingBlock_heading__1b1Zt {
        font-size: 28px;
    }

    .PricingBlock_mainBottom__3LbOk {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .PricingBlock_orgInfo__23bUc {
        margin-bottom: 5px;
    }
    .PricingBlock_sessions__1V90w ,.PricingBlock_liveClass__2wEC- ,.PricingBlock_subjects__V8xHu ,.PricingBlock_educators__1XGVn {
        font-size: 15px;
    }
    
}



@media (max-width :850px) {

    .PricingBlock_heading__1b1Zt {
        font-size: 30px;

    }

    .PricingBlock_text2__6Cdg3 {
        font-size: 25px;
    }

    .PricingBlock_orgInfo__23bUc {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 1300px) and (min-width: 1000px) {
    .PricingBlock_middleBlock__AZWqv{
        height: 350px;
    }
  }
  

.About_about__24WKg {
  display: flex;
  flex-direction: column;
  font-size: 30px;
}

/* Top Title Section */
.About_topTitle__mcUmp {
  width: 100%;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  text-align: start;
  font-size: 15px;
  color: var(--darkText);
  background-color: var(--chatCommentBkgColor);
}

/* Responsive Grid Layout */
.About_aboutbox__1xHEc,
.About_aboutbox2__3BPtM,
.About_whyChooseUsContainer__23ryh,
.About_process__2VpLX {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 30px;
  gap: 30px;
}

/* Left & Right Sections */
.About_leftBox__1z4ge,
.About_leftBox1__1Qgm9,
.About_RightBox__3DPnl,
.About_RightBox1__1NH1I {
  width: 100%;
}

/* Office Image */
.About_OfficeImageContainer__2rzsn {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

/* Text Sections */
.About_p1__384r6, .About_p2__35mqI, .About_p3__3TiKN {
  font-size: 16px;
  color: var(--lightText);
  line-height: 1.5;
  text-align: justify;
  margin-top: 10px;
}

/* Buttons */
.About_getintouchParentDiv__3wna2,
.About_buttonProcess__1BSQ4,
.About_lastSectionBtn__12ivK {
  margin-top: 20px;
  border-radius: 6px;
  font-size: 16px;
  padding: 8px 16px;
  color: var(--themeColorText);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--themeColorText);
  cursor: pointer;
  transition: background 0.3s ease;
}

.About_getintouchParentDiv__3wna2:hover,
.About_buttonProcess__1BSQ4:hover,
.About_lastSectionBtn__12ivK:hover {
  background: var(--themeColorText);
  color: white;
}

/* Centered Sections */
.About_whyChooseUsTitle__3FkNr {
  width: 100%;
  margin-top: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  color: var(--darkText);
}

.About_whyChooseUsDescription__1NAPq {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  color: var(--lightText);
}

/* Process Section */
.About_processBox1__2vSPF {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.About_processTitle__1gQZj {
  font-size: 28px;
  font-weight: bold;
  color: var(--darkText);
}

.About_processDesc__Bz_C8 {
  margin-top: 10px;
  font-size: 16px;
  color: var(--lightText);
  text-align: justify;
  line-height: 24px;
}

/* Process Cards */
.About_processCard__2U9mo {
  margin-top: 20px;
  border: 1px solid var(--cardBorderColorLight);
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  grid-gap: 15px;
  gap: 15px;
}

.About_cardIcon__25Kkd {
  height: 50px;
  width: 50px;
  background-color: transparent;
  border-radius: 50%;
}

/* Process Image */
.About_processImage__1EeiX {
  margin-top: 50px;
  background-color: azure;
  width: 100%;
  aspect-ratio: 1.3/1;
  border-radius: 10px;
}

/* Last Section */
.About_aboutLastSection__1bzOZ {
  width: 100%;
  margin-top: 100px;
  background-color: var(--cardBorderColorLight);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding-bottom: 30px;
}

.About_lastSectionTitle1__1U2ra,
.About_lastSectionTitle2__1K-mq {
  margin-top: 40px;
  font-size: 26px;
  font-weight: bold;
  color: var(--darkText);
  text-align: center;
}

.About_lastSectiondesc1__1Hgma {
  width: 50%;
  margin-top: 40px;
  font-size: 16px;
  color: var(--lightText);
  text-align: center;
}

/* Top Block Section */
.About_topBlockAbout__3sf1i {
  height: 300px;
  background-color: var(--chatCommentBkgColor);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .About_aboutbox__1xHEc, .About_aboutbox2__3BPtM, .About_process__2VpLX {
    grid-template-columns: 1fr;
  }

  .About_processCard__2U9mo {
    flex-direction: column;
    text-align: center;
  }

  .About_processContent__157Vw {
    align-items: center;
  }

  .About_OfficeImageContainer__2rzsn {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .About_whyChooseUsTitle__3FkNr {
    font-size: 24px;
  }

  .About_processTitle__1gQZj {
    font-size: 24px;
  }

  .About_processDesc__Bz_C8 {
    font-size: 14px;
  }

  .About_lastSectionTitle1__1U2ra, .About_lastSectionTitle2__1K-mq {
    font-size: 24px;
  }

  .About_lastSectiondesc1__1Hgma {
    width: 80%;
    font-size: 14px;
  }

  .About_topBlockAbout__3sf1i {
    height: 250px;
  }
}

@media (max-width: 480px) {
  .About_whyChooseUsTitle__3FkNr {
    font-size: 20px;
  }

  .About_processTitle__1gQZj {
    font-size: 20px;
  }

  .About_lastSectionTitle1__1U2ra, .About_lastSectionTitle2__1K-mq {
    font-size: 20px;
  }

  .About_lastSectiondesc1__1Hgma {
    width: 90%;
    font-size: 14px;
  }

  .About_OfficeImageContainer__2rzsn {
    height: 250px;
  }

  .About_topBlockAbout__3sf1i {
    height: 200px;
  }
}

.LeftImageRightText_websiteContent2__3ECgz {
    margin-top: 50px;
    width: 100%;
    display: flex;
  }
  
  .LeftImageRightText_HomeImageContainer2__zFxAw {
    width: 50%;
    background-color: green;
  }
  
  .LeftImageRightText_detailContainer2__3o6yt {
    margin-left: 30px;
    width: 50%;
  }
  
  .LeftImageRightText_aboutHeading1__1a8NZ {
    font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
  }
  
  .LeftImageRightText_aboutHeading2__3JI19 {
    margin-top: 20px;
    font-weight: bold;
    color: #08203D;
    line-height: 55px;
    font-size: 45px;
  }
  
  .LeftImageRightText_aboutDetails__1K4Ec {
    margin-top: 20px;
    font-size: 18px;
    color: var(--lightText);
    line-height: 32px;
    text-align: justify;
  }



.LeftImageRightText_aboutTitle__32kjP{
display: flex;
justify-content:center;
color: var(--themeColor);
font-weight: bold;

}




/* Parent Container */
.TeamBlock_teamBlockParent__drVaY {
  width: 100%;
  background-color: #f9f9f9;
}

/* About Section */
.TeamBlock_topBlockAbout__1o-om {
  text-align: start;
  padding: 50px 5%;
  background-color: var(--chatCommentBkgColor);
  border-radius: 12px;
}

.TeamBlock_topBlockAbout__1o-om h1 {
  font-size: 32px;
  color: var(--darkText);
}

.TeamBlock_topBlockAbout__1o-om p {
  font-size: 18px;
  color: gray;
  max-width: 800px;
  margin: auto;
}

/* Team Section */
.TeamBlock_parentOurTeam__3RL6o {
  text-align: center;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 50px 5%;
}

.TeamBlock_ourTeamTitle__25isg {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.TeamBlock_ourTeamSubTitle__2WfDR {
  margin-top: 40px;
  font-weight: bold;
  color: #08203d;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

/* Team Grid - Ensures 4 cards per row on large screens */
.TeamBlock_teamGrid__2DyaT {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  grid-gap: 30px;
  gap: 30px;
  max-width: 1200px;
  margin: auto;
}

/* Highlight the important leader */
.TeamBlock_highlightedLeader__30HPI {
  margin-left: 280px;
  
  color: white;
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
}

.TeamBlock_highlightedLeader__30HPI h3,
.TeamBlock_highlightedLeader__30HPI p {
  color: white;
}

.TeamBlock_highlightedLeader__30HPI:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}


/* Center small sections (2 or 3 members) */
.TeamBlock_teamGrid__2DyaT.TeamBlock_centered__2pWr_ {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 300px;
  max-width: 800px;
  grid-gap: 30px;
  gap: 30px;
}

/* Team Member Card */
.TeamBlock_teamMemberCard__1nHyF {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  width: 250px;  /* Ensures cards are aligned in the center */
}

.TeamBlock_teamMemberCard__1nHyF:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

/* Team Member Image - Crop from top & bottom */
.TeamBlock_teamMemberCard__1nHyF img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center ;  
  border-radius: 12px;
}

.TeamBlock_teamMemberCard__1nHyF h3 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.TeamBlock_teamMemberCard__1nHyF p {
  font-size: 14px;
  color: gray;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .TeamBlock_teamGrid__2DyaT {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
}

@media (max-width: 768px) {
  .TeamBlock_teamGrid__2DyaT {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .TeamBlock_teamGrid__2DyaT {
    grid-template-columns: 1fr;
  }
  .TeamBlock_highlightedLeader__30HPI {
    margin-left: 0px;
    
    color: white;
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
  }
}

/* Modal Background Overlay */
.TeammemberModal_modalOverlay__3rb4D {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Prevents content from sticking to edges */
    z-index: 1000;
    overflow-y: auto; /* Allows scrolling if content overflows */
}

/* Modal Box */
.TeammemberModal_modalContent__12sl3 {
    height:90dvh;
    background: white;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 500px;
    width: 100%;
    position: relative;
    overflow-y: auto;
    -webkit-animation: TeammemberModal_fadeIn__3He31 0.3s ease-in-out;
            animation: TeammemberModal_fadeIn__3He31 0.3s ease-in-out;
}

/* Close Button */
.TeammemberModal_closeButton__YyPgK {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    background: rgba(0, 0, 0, 0.2);
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;
}

.TeammemberModal_closeButton__YyPgK:hover {
    background: rgba(0, 0, 0, 0.5);
}

/* Profile Image */
.TeammemberModal_modalImage__Ltg1X {
    width: 100%;
    min-height: 300px;
    object-fit: cover;
    object-position: top;
    border-radius: 12px;
    margin-bottom: 15px;
}

/* Role Styling */
.TeammemberModal_modalRole__1yytl {
    color: var(--themeColor);
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px;
}

/* Description */
.TeammemberModal_modalDescription__Y_Mxe {
    font-size: 14px;
    font-weight: 500;
    color: #444;
    text-align: justify;
    margin-top: 10px;
    line-height: 1.6;
}

/* Fade-in Animation */
@-webkit-keyframes TeammemberModal_fadeIn__3He31 {
    from {
        opacity: 0;
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@keyframes TeammemberModal_fadeIn__3He31 {
    from {
        opacity: 0;
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 600px) {
    .TeammemberModal_modalOverlay__3rb4D {
    padding: 8px;}
    .TeammemberModal_modalContent__12sl3 {
        max-width: 80%;
        padding: 8px;
        max-height: 90vh; 
        overflow-y: auto; 
    }

    .TeammemberModal_modalImage__Ltg1X {
        max-height: 200px;
    }

    .TeammemberModal_modalRole__1yytl {
        font-size: 16px;
    }

    .TeammemberModal_modalDescription__Y_Mxe {
        font-size: 13px;
    }
}
.About_about__39owL {
  display: flex;
  flex-direction: column;
  font-size: 30px;
}

.About_topTitle__3piKp {
  width: 100%;
  line-height: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 15px;
  color: var(--darkText);
  background-color: var(--chatCommentBkgColor);
}

.About_aboutbox__2djAL {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.About_aboutbox2__3YwHA {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.About_leftBox__3QdLp {
  width: 100%;
}

.About_OfficeImageContainer__1vfw3 {
  width: 100%;
  border-radius: 10px;
}

.About_RightBox__3plcG {
  width: 100%;
}

.About_p1__1FENl {
  font-size: 16px;
  color: var(--lightText);
  line-height: 1.5;
  text-align: justify;
}

.About_p2__OiuNY {
  margin-top: 10px;
  font-size: 16px;
  color: var(--lightText);
  line-height: 1.5;
  text-align: justify;
}

.About_p3__X5aCj {
  margin-top: 10px;
  font-size: 16px;
  color: var(--lightText);
  line-height: 1.5;
  text-align: justify;
}

.About_getintouchParentDiv__qMmiO {
  margin-top: 20px;
  border-radius: 6px;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: var(--themeColorText);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--themeColorText);
}

.About_RightBox1__I-Crn {
  width: 100%;
}

.About_leftBox1__3BcqL {
  width: 100%;
}

.About_whyChooseUsTitle__2OvB- {
  width: 100%;
  margin-top: 100px;

  line-height: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  color: var(--darkText);
}

.About_whyChooseUsDescription__3Suj7 {
  width: 100%;
  margin-top: 20px;

  line-height: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: var(--lightText);
}

.About_whyChooseUsContainer__s53Nv {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.About_block1st__3tL2n {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.About_box1__EPI04 {
  margin-top: 30px;
  background-color: var(--cardBorderColorLight);
  padding: 16px;
  border-radius: 10px;
}

.About_boxImg__1olzH {
  margin-top: -30px;
  height: 40px;
  width: 40px;
  padding: 6px;
  background-color: white;
  border: 2px solid var(--cardBorderColorLight);
  border-radius: 10px;
}

.About_boxTitle__3tz97 {
  margin-top: 10px;
  color: var(--darkText);
  font-size: 16px;
}

.About_boxDesc__1QJU3 {
  margin-top: 10px;
  color: var(--lightText);
  font-size: 16px;
}

.About_block2st__CzIcP {
  width: 100%;
}

.About_process__EiRU4 {
  margin-top: 40px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.About_processBox1__1N6-6 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.About_processTitle__2Qwz8 {
  font-size: 30px;
  font-weight: bold;
  color: var(--darkText);
}

.About_processDesc__29YkW {
  width: 100%;
  margin-top: 20px;
  color: var(--lightText);
  line-height: 24px;
  text-align: justify;
}

.About_buttonProcess__2nXse {
  margin-top: 20px;
  border-radius: 6px;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: var(--themeColorText);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--themeColorText);
}

.About_processImage__12owM {
  margin-top: 50px;
  background-color: azure;
  width: 100%;
  aspect-ratio:1.3/1;
  border-radius: 10px;
  /* aspect-ratio: calc(387 / 600 * 100%); */

}

.About_processBox2__3w6Rb {
  width: 100%;
}

.About_processCard__SD3kM {
  margin-top: 20px;
  border: 1px solid var(--cardBorderColorLight);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
}

.About_cardIcon__1x6OW {
  height: 50px;
  width: 50px;
  max-width: 50px;
  max-height: 50px;
  background-color: transparent;
  border-radius: 20%;
}

.About_processContent__2KWYb {
  width: 90%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.About_title__3vgjk {
  font-size: 18px;
  color: var(--darkText);
  font-weight: bold;
}

.About_processDesc__29YkW {
  margin-top: 10px;
  font-size: 16px;
  color: var(--lightText);
}

.About_aboutLastSection__aYT1G {
  width: 100%;
  margin-top: 100px;
  background-color: var(--cardBorderColorLight);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  border-radius: 5px;
  padding-bottom: 30px;
}

.About_lastSectionTitle1__4FB1E {
  margin-top: 40px;
  font-size: 30px;
  font-weight: bold;
  color: var(--darkText);
}

.About_lastSectionTitle2__2veoy {
  margin-top: 10px;
  font-size: 30px;
  font-weight: bold;
  color: var(--darkText);
}

.About_lastSectiondesc1__2FERL {
  width: 50%;
  margin-top: 40px;
  font-size: 16px;
  color: var(--lightText);
  text-align: center;
}

.About_lastSectionBtn__GRYjy {
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 6px;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: var(--themeColorText);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--themeColorText);
}

.About_topBlockAbout__1zFHH{
height: 300px;
background-color: var(--chatCommentBkgColor);
display: flex;
align-items: center;

}




.LeftImageRightText_websiteContent2__3FlDI {
    margin-top: 50px;
    width: 100%;
    display: flex;
  }
  
  .LeftImageRightText_HomeImageContainer2__2Dbpy {
    width: 50%;
    background-color: green;
  }
  
  .LeftImageRightText_detailContainer2__2BDNN {
    margin-left: 30px;
    width: 50%;
  }
  
  .LeftImageRightText_aboutHeading1__NB55I {
    font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
  }
  
  .LeftImageRightText_aboutHeading2__3GYnV {
    margin-top: 20px;
    font-weight: bold;
    color: #08203D;
    line-height: 55px;
    font-size: 45px;
  }
  
  .LeftImageRightText_aboutDetails__3oms2 {
    margin-top: 20px;
    font-size: 18px;
    color: var(--lightText);
    line-height: 32px;
    text-align: justify;
  }



.LeftImageRightText_aboutTitle__3tnGr{
display: flex;
justify-content:center;
color: var(--themeColor);
font-weight: bold;

}




.TeamBlock_ourTeam__3_gBG {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TeamBlock_ourTeamTitle__3UEXH {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.TeamBlock_ourTeamSubTitle__2LZJn {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.TeamBlock_allTeamMemberdetail__1tC4U {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, auto));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.TeamBlock_ourTeamdetailContainer__2hvBx {
  width: auto;
  margin: 10px;
  padding: 10px;
  border: 1px solid var(--cardBorderColor);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.TeamBlock_developerPic1__1zViA {
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;

  background-color: #207ef5;
}

.TeamBlock_developerPic2__2kknq {
  border-radius: 10px;

  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;

  background-color: #207ef5;
}

.TeamBlock_developerPic3__1Fvzx {
  border-radius: 10px;

  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;

  /* aspect-ratio:calc(1600 * 1067 /100%); */
  background-color: #207ef5;
}

.TeamBlock_developerPic4__tWpMn {
  border-radius: 10px;

  width: 100%;
  aspect-ratio: 5/5;
  object-fit: cover;
  background-color: #207ef5;
}

.TeamBlock_developerName__3RlCa {
  margin-top: 16px;
  font-size: 18px;
  font-weight: 500;
  color: var(--darkText);
}

.TeamBlock_developerRole__2hcyH {
  font-size: 16px;
  margin-top: 10px;
  color: var(--themeColor);
}

.TeamBlock_socialNetworking__2jvc3 {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.TeamBlock_pic1__3r37Q {
  height: 20px;
  width: 20px;
  background-color: #e3effe;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
  color: var(--themeColor);
  padding: 8px;
  border-style: none;
}

.TeamBlock_pic2__R_oZJ {
  margin-left: 16px;
  margin-right: 16px;
  height: 20px;
  width: 20px;
  padding: 8px;
  background-color: #e3effe;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
  color: var(--themeColor);
  border-style: none;
}

.TeamBlock_pic3__2NhPx {
  height: 20px;
  width: 20px;
  padding: 8px;
  background-color: #e3effe;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
  color: var(--themeColor);
  border-style: none;
}

@media (max-width: 1300px) {
  .TeamBlock_ourTeamTitle__3UEXH {
    font-size: 20px;
  }

  .TeamBlock_ourTeamSubTitle__2LZJn {
    margin-top: 10px;
    font-size: 30px;
  }
}


.App_profileParent__26cwM {
  width: 100%;
}

.App_mainContainer__2aTkT {
  max-width: 1000px;
  padding: 20px;
  background-color: white;
  margin: auto; 
}

.App_videoSection__2Gsv0 {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 10px;
}

.App_mainImg__2eE_h {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.App_profileCardDetails__1UyHT {
  margin-top: 20px;
  width: 100%;
  display: flex;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.App_leftSide__2R6dI {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App_rightSide__2D8Pe {
  width: 100%;
}

.App_profilePic__32rJo {
  width: 120px;
  height: 120px;
  border: 10px;
  margin: 20px;
}

.App_mainprofilePic__kQQFs {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 20px;
}

.App_bookbtn__Xp-b1 {
  width: 120px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.App_bookbtn__Xp-b1:hover {
  border: 1px solid var(--themeColor);
  cursor: pointer;
  background-color: white;
  color: var(--themeColor);
}

.App_topContainer__GI-FY {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App_NameContainer__3cPtj {
  font-size: 30px;
  font-weight: bold;
}
.App_rattingContainer__2vyOl {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App_rattingIcon__3IETS {
  height: 30px;
  width: 30px;
  color: sandybrown;
}

.App_ratingNumber__dgDn- {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
  color: var(--greyTextColor);
}

.App_HeartBtn__xiKpt {
  height: 30px;
  width: 30px;
  color: var(--greyTextColor);
}

.App_midContainer__2z4H9 {
  margin: 20px;
  display: flex;
  align-items: center;
}
.App_location__2PQDZ {
  height: 30px;
  width: 30px;
  color: var(--greyTextColor);
}

.App_locationName__CXSKA {
  margin-left: 10px;
  color: var(--deepDarkThemeTextColor);
}

.App_bottomContainer__2kESA {
  margin: 20px;
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.App_teachesContainer__3DSaH {
  display: flex;
  align-items: center;
  padding-top: 8px;
  margin-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: var(--cardBorderColorLight);
  border-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.App_icon1__1f7OW {
  height: 30px;
  width: 30px;
  border-radius: 10px;
}

.App_detailsContainer__2-X7C {
  margin-left: 10px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.App_heading__3zU16 {
  font-weight: bold;
}

.App_headingDetails__2Tp-R {
  margin-top: 6px;
}

.App_languageContainer__3cCx7 {
  display: flex;
  align-items: center;
  margin: 20px;
}

.App_languageTitle__hR-UA {
  font-size: 18px;
  font-weight: bold;
}

.App_lang__WNHbi {
  margin-left: 10px;
}

.App_aboutmeContainer__12-Bo {
  margin: 20px;
}

.App_aboutmeTitle__35_mI {
  font-size: 30px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
}

.App_helloTitle__24n83 {
  margin-top: 20px;
}

.App_detailsaboutme__1h42F {
  margin-top: 30px;
  line-height: 26px;
}

.App_readmore__1Z2lF {
  margin-top: 30px;
  font-weight: bold;
  text-decoration: underline;
  color: var(--themeColor);
}

.App_ratingContainer__1Byty {
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.App_finalRating__2asSs {
  font-size: 30px;
  font-weight: bold;
}

.App_stars__kFQPW {
  margin-top: 10px;
  display: flex;
  width: 200px;
  justify-content: space-between;
}

.App_r1__a6zUa {
  height: 30px;
  width: 30px;
  color: sandybrown;
}

.App_r2__3a810 {
  height: 30px;
  width: 30px;
  color: sandybrown;
}

.App_r3__2tonM {
  height: 30px;
  width: 30px;
  color: sandybrown;
}

.App_r4__1TEsb {
  height: 30px;
  width: 30px;
  color: sandybrown;
}

.App_r5__26Q32 {
  height: 30px;
  width: 30px;
  color: sandybrown;
}

.App_rateCards__kjiGm {
  margin-top: 50px;
}

.App_star5Container__3xPV2 {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.App_stars5all__2rYN0 {
  width: 200px;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
}

.App_star4Container__2kfMG {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.App_star4__2T7rV {
}

.App_stars4all__2aElY {
  width: 160px;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
}

.App_star3Container__1otjo {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.App_star4__2T7rV {
}

.App_stars3all__1L_LI {
  width: 120px;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
}

.App_star2Container__1Y_gc {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.App_star4__2T7rV {
}

.App_stars2all__1B9jF {
  width: 80px;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
}

.App_star1Container__21Xne {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.App_star4__2T7rV {
}

.App_stars1all__2xrdo {
  width: 40px;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
}

.App_starNu__3Ws9v {
  margin-left: 10px;
  font-weight: bold;
}


@media (max-width: 1000px) {
.App_profileParent__26cwM{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;


}

.App_mainContainer__2aTkT{
  width: 98%;
  padding: 0px;
}

.App_profileCardDetails__1UyHT{
  display: flex;
  flex-direction: column;
}

/* .topContainer{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.ratingContainer{
  margin-top: 10px;

}
.HeartBtn{
  margin-top: 10px;

}

.bottomContainer{
  display: flex;
  flex-direction: column;
} */

}


.Ratting_ratingContainer__2-1qv {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
  
  .Ratting_finalRating__14PIG {
    font-size: 30px;
    font-weight: bold;
  }
  
  .Ratting_stars__2LEjz {
    margin-left: 20px;
    display: flex;
    width: 200px;
    justify-content: space-between;
  }
  
  .Ratting_r1__pHCv_ {
    height: 30px;
    width: 30px;
    color: sandybrown;
  }
  
  .Ratting_r2__26qP7 {
    height: 30px;
    width: 30px;
    color: sandybrown;
  }
  
  .Ratting_r3__1NQPF {
    height: 30px;
    width: 30px;
    color: sandybrown;
  }
  
  .Ratting_r4__1TMVP {
    height: 30px;
    width: 30px;
    color: sandybrown;
  }
  
  .Ratting_r5__3djeL {
    height: 30px;
    width: 30px;
    color: sandybrown;
  }
  
  .Ratting_rateCards__1GQZB {
    margin-top: 50px;
  }
  
  .Ratting_star5Container__1ImBa {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .Ratting_stars5all__3VzW_ {
    width: 200px;
    margin-left: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
  }
  
  .Ratting_star4Container__3CHNZ {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .Ratting_star4__3UBbr {
  }
  
  .Ratting_stars4all__3Xnzp {
    width: 160px;
    margin-left: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
  }
  
  .Ratting_star3Container__3S9jw {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .Ratting_star4__3UBbr {
  }
  
  .Ratting_stars3all__1Y5OW {
    width: 120px;
    margin-left: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
  }
  
  .Ratting_star2Container__3AH8F {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .Ratting_star4__3UBbr {
  }
  
  .Ratting_stars2all__8PsFi {
    width: 80px;
    margin-left: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
  }
  
  .Ratting_star1Container__2iKWS {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .Ratting_star4__3UBbr {
  }
  
  .Ratting_stars1all__2tqKZ {
    width: 40px;
    margin-left: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
  }
  
  .Ratting_starNu__38Obw {
    margin-left: 10px;
    font-weight: bold;
  }

  .Ratting_TotalRating__3ubpA{
    margin-left: 20px;
    font-size: 20px;
    color: black;
  }

.ProfileCard_profileCardDetails__3b7Yh {
    margin-top: 20px;
    width: 100%;
    display: flex;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  
  .ProfileCard_leftSide__ndzSd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .ProfileCard_rightSide__H-6o5 {
    width: 100%;
  }
  
  .ProfileCard_profilePic__1_F33 {
    width: 120px;
    height: 120px;
    border: 10px;
    margin: 20px;
  }
  
  .ProfileCard_mainprofilePic__iSnpI {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 20px;
  }
  
  .ProfileCard_bookbtn__3IOk5 {
    width: 120px;
    height: 30px;
    background-color: var(--themeColor);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
  
  .ProfileCard_bookbtn__3IOk5:hover {
    border: 1px solid var(--themeColor);
    cursor: pointer;
    background-color: white;
    color: var(--themeColor);
  }
 
  .ProfileCard_bookbtn1__2G6ai{
  
    width: 120px;
    height: 30px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-weight: bold;

  }





  .ProfileCard_topContainer__3sHAM {
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .ProfileCard_NameContainer__3toy_ {
    font-size: 30px;
    font-weight: bold;
  }
  .ProfileCard_rattingContainer__3-VhU {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ProfileCard_rattingIcon__2WYZ8 {
    height: 30px;
    width: 30px;
    color: sandybrown;
  }
  
  .ProfileCard_ratingNumber__32tuM {
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
    color: var(--greyTextColor);
  }
  
  .ProfileCard_HeartBtn__3HpIv {
    height: 30px;
    width: 30px;
    color: var(--greyTextColor);
  }
  
  .ProfileCard_midContainer__3d3fh {
    margin: 20px;
    display: flex;
    align-items: center;
  }
  .ProfileCard_location__1oZ56 {
    height: 30px;
    width: 30px;
    color: var(--greyTextColor);
  }
  
  .ProfileCard_locationName__3C6eU {
    margin-left: 10px;
    color: var(--themeColor);
  }
  
  .ProfileCard_bottomContainer__xGJ2i {
    margin: 20px;
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
  
  .ProfileCard_teachesContainer__40_4- {
    display: flex;
    align-items: center;
    padding-top: 8px;
    margin-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: var(--cardBorderColorLight);
    border-radius: 10px;
    border: 1px solid var(--cardBorderColor);
    border-style: none;
  }
  
  .ProfileCard_icon1__H3A4Y {
    height: 30px;
    width: 30px;
    border-radius: 10px;
  }
  
  .ProfileCard_detailsContainer__3Orpd {
    margin-left: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  
  .ProfileCard_heading__2Dsg0 {
   font-weight: bold;
  }
  
  .ProfileCard_headingDetails__2JirF {
    margin-top: 6px;
  }
  
  .ProfileCard_languageContainer__22RiH {
    display: flex;
    align-items: center;
    margin: 20px;
  }
  
  .ProfileCard_languageTitle__1JrJ_ {
    font-size: 18px;
    font-weight: bold;
  }
  
  .ProfileCard_lang__2Fvnk {
    margin-left: 10px;
  }


@media (max-width: 1000px) {
  .ProfileCard_profileParent__u_tts{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
  
  
  }
  
  .ProfileCard_mainContainer__vIXrI{
    width: 98%;
    padding: 0px;
  }
  
  .ProfileCard_profileCardDetails__3b7Yh{
    display: flex;
    flex-direction: column;
  }
  
  /* .topContainer{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  
  .ratingContainer{
    margin-top: 10px;
  
  }
  .HeartBtn{
    margin-top: 10px;
  
  }
  
  .bottomContainer{
    display: flex;
    flex-direction: column;
  } */
  
  }

.Slot_slotsContainer__3CG5C {
  margin-top: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: white;
  border-style: none;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  padding-top: 50px;
  padding-left: 30px;  
  border-radius: 10px;
}

.Slot_nextPreButtonsDiv__3r6q8{

height: 50px;
display: flex;
align-items: center;
}


.Slot_navButton__3g-aL{

border-style:none;
padding: 5px;
color: var(--themeColor);
background-color: var(--chatCommentBkgColor);
width: 100px;
cursor: pointer;
border-radius: 5px;
height: 35px;
}
.Slot_titleMessageBooking__3tBxP{
height: 50px;

}


.Slot_dateText__zC6ZK{
margin: 20px;
font-weight: bold;
border-style: solid;
border-width: 1px;
border-color: var(--chatCommentBkgColor);
border-radius: 5px;
height: 35px;
display: flex;
align-items: center;
width: 150px;
justify-content: center;
}





@media only screen and (max-width: 600px) {

.Slot_navButton__3g-aL{
font-size: 12px;
width: 70px;
height: 35px;	
}

.Slot_dateText__zC6ZK{
height: 35px;
border-style: solid;
}



}










.OneSlot_oneSlot__3u4ad{

width: 300px;
height: 60px;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
display: flex;
align-items: center;
justify-content: space-between;
border-radius:10px;
margin-top:10px;
border-color: var(--themeColor);
background-color:var(--chatCommentBkgColor);
cursor: pointer;
margin-top: 20px;
font-size: 20px;
display: flex;
justify-content: center;
border-color: #b4cdfa;
}


.OneSlot_oneSlot__3u4ad:hover{

background-color: var(--themeColor);
color: white;
}


.OneSlot_oneSlot__3u4ad:hover .OneSlot_timeDiv__YUH8L{
color: white;

}


.OneSlot_bookNowButton__ZwCW9{
width: 30%;
min-width: 120px;
border-radius: 0px 10px 10px 0px;
border-style: solid;
background-color: var(--themeColor);
color: white;
cursor: pointer;
border-color: var(--themeColor);
}


.OneSlot_timeDiv__YUH8L{

display: flex;
align-items: center;
padding-left: 10px;
color: var(--themeColor);
font-weight: bold;
}


.OneSlot_timeSpan__2q1Yd{


}


.OneSlot_loginDiv__3Iqy1{
position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.OneSlot_closeLoginButton__1_2rE{
border-radius: 5px;
border-style: solid;
width: 120px;
height: 70px;
background-color: white;
cursor: pointer;
border-width: 1px;
}



@media only screen and (max-width: 600px) {

.OneSlot_oneSlot__3u4ad{

height:40px;
width: 250px;
font-size: 17px;
}


}












.BookingForm_bookingForm__1ZJSn{
position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1);
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;

}

.BookingForm_formBox__30t3P{
margin-top: 5%;
width: 350px;
height:400px;
background-color: white;
border-radius: 10px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
justify-content: center;
}


.BookingForm_editmobileNuBox__3SXAe {
  background-color: var(--bodyBkgColor);
  border-radius: 5px;
  /* background-color: #f0f2f5; */
  height: 28px;
  width: 90%;
  padding-left: 10px;
  border: 1px solid var(--cardBorderColorLight);
  font-size: 14px;
  margin: auto;
  border-color: grey;
}

.BookingForm_editmobileNuBox__3SXAe:focus {
  padding-left: 8px;
  padding-right: 8px;
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
  background-color: white;
}

.BookingForm_formContentBox__3nsHP{

margin-top: 50px;
padding: 30px;
}


.BookingForm_textInfoForm__3yLLC{
height: 30px;
}


.BookingForm_fieldtype__5Ked4{
border-style: none;
display: flex;
flex-direction: column;
margin-top: 20px;
border-radius: 5px;
padding: 10px;
background-color:pink;
}


.BookingForm_bookSlotButton__1E7PQ{
width: 100%;
background-color: var(--themeColor);
border-style: none;
border-radius: 5px;
color: white;
margin: auto;
height: 35px;
cursor: pointer;
margin-top: 30px;
}

.BookingForm_bookSlotButton__1E7PQ:hover{


}


.BookingForm_closeButton__jQ5cc{

}

.BookingForm_closeButtonDiv__3sBJf{
width: 100%;
display: flex;
justify-content: flex-end;
}


.BookingForm_loginToMeetingLinkButton__21uGW{
margin-top:20px;
border-style: none;
height:35px;
width: 100%;
border-radius: 5px;
color: var(--themeColor);
cursor: pointer;
}

.BlogAndNewsBlock_latest__1k8fC {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  
}

.BlogAndNewsBlock_latestTitle__2elTT {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.BlogAndNewsBlock_latestsubTitle__2Hh_8 {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.BlogAndNewsBlock_newsContainer__D8UAw {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BlogAndNewsBlock_singleContainer__34Qir {
  margin: 10px;
  height: 500px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.BlogAndNewsBlock_newsImage__3fP48 {
  width: 100%;
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  background-color: white;
}

.BlogAndNewsBlock_newsTitle__Bz8U2 {
  margin-top: 20px;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #08203d;
}

.BlogAndNewsBlock_newsDesc__2cl5M {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 15px;
  color: var(--lightText);
  text-align: justify;
  margin-right: 10px;
  line-height: 19px;
  height: 180px;
  max-height: 180px;
  min-height: 180px;
}

.BlogAndNewsBlock_readMoreBtn__2OmK7 {
  margin-left: 10px;
  font-size: 16px;
  color: var(--lightText);
  margin-bottom: 20px;
  color: var(--themeColor);
  padding: 6px;
  border-radius: 2px;
}



.OneFriend_oneFriend__1qxur{


width: 150px;
height: 190px;
border-style: solid;
display: flex;
align-items: center;
flex-direction: column;
background-color: white;
padding: 30px;
border-color: var(--cardBorderColor);
border-width: 1px;
border-radius: var(--cardBorderRadius);
}


.OneFriend_friendImage__2S223{

width:150px;
height:150px; 
margin: auto;
border-radius: var(--cardBorderRadius);
}

.OneFriend_InfoBox__1L6_T{
height: 40px;
color: grey;
border-style: none;
}

.News_parentContainer__oaz8b {
  display: flex;
  flex-direction: column;
  border-style: none;
}

.News_topPic__27B6F {
  width: 100%;
  height: 300px;
  object-fit: cover;
  background-color: var(--chatCommentBkgColor);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-style: none;
}

.News_topContentDiv__3kHNL{
width: 1200px;
border-style: none;
margin: auto;
}

.News_Heading1s__3Djwv {
  font-weight: bolder;
  font-size: 30px;
  margin-left: 40px;
}

.News_subheading1s__3f2xO {
  margin-top: 20px;
  font-size: 18px;
  color: var(--deepDarkThemeTextColor);
  margin-left: 40px;
}

.News_editbtnContainer__3FESF {
  margin-left: 40px;
  margin-top: 30px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.News_emailEditBox__2auuS {
  width: 250px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--themeColor);
}

.News_emailEditBox__2auuS:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.News_subBtn__4yZVS {
  font-weight: bolder;
  margin-left: 20px;
  width: 250px;
  height: 30px;
  font-size: 14px;
  background-color: var(--themeColor);
  border: none;
  color: white;
  border-radius: 8px;
}

.News_subBtn__4yZVS:hover {
  cursor: pointer;
}

.News_latest__2pe7B {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.News_latestTitle__1jOmD {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.News_latestsubTitle__ziEt_ {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.News_newsContainer__1Lg27 {
  width: 1200px;
  display: flex;
  flex-direction: column;
  margin: auto;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  row-gap: 10px;
  column-gap: 10px; */
}

.News_natitonalNewsBlock__jvYkK {
  width: 100%;
}

.News_stateNewsBlock__1PP1b {
  width: 100%;
}

.News_National__1kFmi {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

.News_State__1zXIL {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

@media (max-width: 1300px) {
.News_topContentDiv__3kHNL{
width: 90%;
}

.News_newsContainer__1Lg27{
width: 90%;
}

}


@media (max-width: 1000px) {
  .News_parentContainer__oaz8b {
    width: 100%;
  }

  .News_topPic__27B6F {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin: 0;
  }

  .News_Heading1s__3Djwv {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 20px;
    margin-left: 0;
  }

  .News_subheading1s__3f2xO {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 20px;
    margin-left: 0;
  }

  .News_editbtnContainer__3FESF {
    width: 100%;
    margin: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 0;
  }

  .News_subBtn__4yZVS {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
  }
}


.Digitalmarketing_container__olPYr {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 0 15px;
}
.Digitalmarketing_banner__287Ub{
  display: grid;
  justify-content: center;
 
  align-items: center;
 
}

.Digitalmarketing_bannerimg__11Hnc{
  width: 100%;
  display: flex;
  justify-content: center;
}
.Digitalmarketing_bannerhead__2XruQ{
  font-weight: bolder;
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
}

.Digitalmarketing_bannersubhead__1rCaZ{
  text-align: justify;
  margin-left: 100px;
  margin-right: 100px;
 
}

.Digitalmarketing_header__2AKhM {
  text-align: center;
  padding: 50px 0;
  background: #f8f9fa;
}

.Digitalmarketing_para__26oEN{
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.Digitalmarketing_title__21S4A {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--themeColor);
}

.Digitalmarketing_subtitle__2CeHn {
  font-size: 1.25rem;
  margin-top: 10px;
  color: #7f8c8d;
}

.Digitalmarketing_section__3V1aO {
  margin: 50px 0;
}

.Digitalmarketing_sectionTitle__PXhiW {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #34495e;
}

.Digitalmarketing_aboutContent__1kx6g {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.Digitalmarketing_aboutImage__24ggy {
  max-width: 100%;
  border-radius: 10px;
  width: 300px;
}

.Digitalmarketing_text__3WZk4 {
  flex: 1 1;
}

.Digitalmarketing_cards__16qRy, .Digitalmarketing_serviceList__2zCtl {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.Digitalmarketing_card__1XI3I {
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: calc(33.33% - 20px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.Digitalmarketing_card__1XI3I img, .Digitalmarketing_service__kfqAM img {
  max-width: 20%;
  height: auto;
  border-radius: 10px;
}

.Digitalmarketing_cardTitle__1vOCk {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 10px 0;
  color: #2c3e50;
}

.Digitalmarketing_cardText__18sTz, .Digitalmarketing_packageText__2bxYP {
  font-size: 0.95rem;
  color: #7f8c8d;
  margin-top: 10px;
  text-align: left;
}



/* Our Services Section */
.Digitalmarketing_serviceList__2zCtl {
  display: flex;
  flex-wrap: nowrap;
  grid-gap: 15px;
  gap: 15px;
  overflow-x: auto;
}

.Digitalmarketing_service__kfqAM {
  flex: 1 1 auto;
  min-width: 300px;
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
}

.Digitalmarketing_service__kfqAM img {
  max-width: 50px;
  height: auto;
}

.Digitalmarketing_serviceTitle__2s2aW {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 10px 0;
  color: #2c3e50;
}

.Digitalmarketing_serviceText__2bUNe {
  font-size: 0.95rem;
  color: #7f8c8d;
  margin-top: 10px;
  text-align: left;
}



.Digitalmarketing_package__2IiJz {
  margin-top: 20px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  
  
}
.Digitalmarketing_packagebox__6z--B{
  display: grid;
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 10px;
  max-width: 300px;
  color: black
}

.Digitalmarketing_packageTitle__2C_g0 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000000;
  text-align: center;
}

.Digitalmarketing_addonText__1wf-s, .Digitalmarketing_footerText__1q4Ia {
  text-align: center;
  color: #434242;
  font-size: 1rem;
}

.Digitalmarketing_footer__3VXNQ {
  padding: 30px 15px;
  text-align: center;
  /* background: #000000; */
  color: #000000;
}

.Digitalmarketing_footer__3VXNQ a {
  color: #ecf0f1;
  text-decoration: none;
}

.Digitalmarketing_footer__3VXNQ a:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .Digitalmarketing_card__1XI3I, .Digitalmarketing_service__kfqAM {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .Digitalmarketing_aboutContent__1kx6g {
    flex-direction: column;
    text-align: center;
  }

  .Digitalmarketing_card__1XI3I, .Digitalmarketing_service__kfqAM {
    width: 100%;
  }

  .Digitalmarketing_title__21S4A {
    font-size: 2rem;
  }

  .Digitalmarketing_subtitle__2CeHn {
    font-size: 1rem;
  }

  .Digitalmarketing_sectionTitle__PXhiW {
    font-size: 1.75rem;
  }
}

@media (max-width: 480px) {
  .Digitalmarketing_title__21S4A {
    font-size: 1.75rem;
  }

  .Digitalmarketing_subtitle__2CeHn {
    font-size: 0.9rem;
  }

  .Digitalmarketing_sectionTitle__PXhiW {
    font-size: 1.5rem;
  }

  .Digitalmarketing_footerText__1q4Ia {
    font-size: 0.9rem;
  }

  .Digitalmarketing_addonText__1wf-s {
    font-size: 0.9rem;
  }
}

/* General App Styles */
.Packagecard_app__2Ls_V {
    text-align: center;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
  }
  
  .Packagecard_pagetitle__ngMDJ {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 40px;
  }
  
  .Packagecard_sectiontitle__3-ncb {
    font-size: 1.8rem;
    font-weight: bold;
    color: #34495e;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  
  /* Package Container */
  .Packagecard_packagecontainer__1zzOK {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
  }
  
  /* Package Card */
  .Packagecard_packagecard__QKHfS {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 20px;
    width: 300px;
    height: 400px; /* Fixed height for consistency */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex; /* Use Flexbox for layout */
    flex-direction: column; /* Stack elements vertically */
    justify-content: space-between; /* Distribute space between elements */
    transition: box-shadow 0.2s ease, -webkit-transform 0.2s ease;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    transition: transform 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
  }
  
  .Packagecard_packagecard__QKHfS:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .Packagecard_packagetitle__pCutf {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #2c3e50;
  }
  
  .Packagecard_packageprice__19lwo {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #e74c3c;
  }
  
  .Packagecard_packagefeatures__3olGU {
    text-align: left;
    color: #7f8c8d;
    margin-bottom: 20px;
    flex-grow: 1; 
    overflow-y: auto; 
  }
  
  .Packagecard_featureitem__2ppAv {
    font-size: 0.95rem;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  
  .Packagecard_featureicon__3Jjg2 {
    color: var(--themeColor);
    font-weight: bold;
    margin-right: 8px;
  }
  
  /* Get Started Button */
  .Packagecard_getstartedbtn__3JPpN {
    background: var(--themeColor);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.2s ease;
    align-self: center; /* Center the button horizontally */
  }
  
  .Packagecard_getstartedbtn__3JPpN:hover {
    background: #2980b9;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .Packagecard_packagecontainer__1zzOK {
      flex-direction: column;
      align-items: center;
    }
  
    .Packagecard_packagecard__QKHfS {
      width: 90%;
    }
  }
  

.Whychooseus_whychooseus__1XJBz {
    font-family: 'Arial', sans-serif;
    margin: 0 auto;
    padding: 20px;
    
    width: 100%;
    color: #333;
    line-height: 1.6;
    background-color: #f4f4f9;
    
  }
  
  .Whychooseus_headertitle__VTu5l {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 40px;
    background-color: #f4f4f9;
  }
  
  .Whychooseus_highlight__pmPnd {
    color: var(--themeColor);
  }
  
  .Whychooseus_features__37UaB {
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 40px;
    background-color: #f4f4f9;
    padding: 10px;
    
  }
  
  .Whychooseus_featurecard__25T4f {
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    flex: 1 1;
    max-width: 300px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .Whychooseus_featurecard__25T4f .Whychooseus_icon__5CJhb {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .Whychooseus_featurecard__25T4f h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .Whychooseus_featurecard__25T4f p {
    font-size: 0.9rem;
    color: #666;
  }
  
  .Whychooseus_ctasection__3R9YM {
    background: #003366;
    color: #fff;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
  }
  
  .Whychooseus_ctacontent__3FDcW h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .Whychooseus_ctacontent__3FDcW p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .Whychooseus_ctabutton__SnE35 {
    background: #00c853;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .Whychooseus_ctabutton__SnE35:hover {
    background: #00b341;
  }

  @media (max-width: 768px) {

    .Whychooseus_features__37UaB {
        display: grid;
      flex-direction: column; /* Stack the feature cards */
      grid-gap: 10px;
      gap: 10px;
    }
  
    .Whychooseus_featurecard__25T4f {
      padding: 10px;
    }
  
    .Whychooseus_headertitle__VTu5l {
      font-size: 1.2rem;
    }
  
    .Whychooseus_ctacontent__3FDcW h3 {
      font-size: 1rem;
    }
  
    .Whychooseus_ctacontent__3FDcW p {
      font-size: 0.8rem;
    }
  
    .Whychooseus_ctabutton__SnE35 {
      font-size: 0.8rem;
      padding: 6px 12px;
    }
  }
  
  @media (max-width: 400px) {
    .Whychooseus_features__37UaB {
        display: grid;
      flex-direction: column; /* Stack the feature cards */
      grid-gap: 10px;
      gap: 10px;
    }
  
    .Whychooseus_featurecard__25T4f {
      padding: 10px;
    }
  
    .Whychooseus_headertitle__VTu5l {
      font-size: 1.2rem;
    }
  
    .Whychooseus_ctacontent__3FDcW h3 {
      font-size: 1rem;
    }
  
    .Whychooseus_ctacontent__3FDcW p {
      font-size: 0.8rem;
    }
  
    .Whychooseus_ctabutton__SnE35 {
      font-size: 0.8rem;
      padding: 6px 12px;
    }
  }
  
.Getintouch_getintouch__2GyzW {
  font-family: 'Arial', sans-serif;
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  text-align: center;
  color: #333;
  line-height: 1.6;
}

.Getintouch_title__wur0t {
  font-size: 2rem;
  color: #003366;
  margin-bottom: 20px;
}

.Getintouch_subtitle__3-G98 {
  font-size: 1.5rem;
  color: var(--themeColor);
  margin-bottom: 30px;
}

.Getintouch_ctatext__yojgZ {
  font-size: 1.2rem;
  color: #666;
  margin-top: 20px;
}

.Getintouch_ctalink__2jkTc {
  color: white;
  text-decoration: none;
  font-weight: bold;
  background-color: var(--themeColor);
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.Getintouch_ctalink__2jkTc:hover {
  -webkit-text-decoration:wavy;
          text-decoration:wavy;
}

@media (max-width: 600px) {
  .Getintouch_title__wur0t {
      font-size: 1.8rem;
  }

  .Getintouch_subtitle__3-G98 {
      font-size: 1.3rem;
  }

  .Getintouch_ctatext__yojgZ {
      font-size: 1rem;
  }
}

.Ourservice_services__284uf {
    font-family: 'Arial', sans-serif;
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    color: #333;
  }
  
  .Ourservice_servicestitle__LXu2c {
    font-size: 2.5rem;
    color: #000;
    margin-bottom: 40px;
  }
  .Ourservice_serviceicon__1XIIX img {
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  margin-bottom: 15px;
}

  .Ourservice_servicesgrid__1ORzg {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }
  
  .Ourservice_servicecard__JqoKI {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s;
    transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
  }
  
  .Ourservice_servicecard__JqoKI:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .Ourservice_serviceicon__1XIIX {
    font-size: 2.5rem;
    color: #007acc;
    margin-bottom: 15px;
  }
  
  .Ourservice_servicetitle__3qkoi {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .Ourservice_servicedescription__3yYym {
    font-size: 1rem;
    color: #666;
    text-align: left;
    padding: 15px;
    line-height: 1.6;
  }
  
  .Ourservice_servicepoint__3-2RX {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .Ourservice_pointbullet__2m41b {
    width: 8px;
    height: 8px;
    background-color: #007acc;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  @media (max-width: 768px) {
    .Ourservice_servicestitle__LXu2c {
      font-size: 2rem;
    }
  
    .Ourservice_servicecard__JqoKI {
      padding: 15px;
    }
  
    .Ourservice_servicetitle__3qkoi {
      font-size: 1.2rem;
    }
  
    .Ourservice_servicedescription__3yYym {
      font-size: 0.9rem;
    }
  }
  
/* File: src/components/AddonServices.module.css */

.AddonServices_addonservices__9B245 {
    font-family: 'Arial', sans-serif;
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    color: #333;
    /* background-color: #f9f9f9; */
  }
  
  .AddonServices_title__3QEQh {
    font-size: 2.5rem;
    color: #000;
    margin-bottom: 10px;
  }
  
  .AddonServices_subtitle__2jiNM {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 30px;
  }
  
  .AddonServices_servicesgrid__wJj6Q {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }
  
  .AddonServices_servicecard__2qeF5 {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s;
    transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
  }
  
  .AddonServices_servicecard__2qeF5:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .AddonServices_servicetitle__3DIAN {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .AddonServices_servicedescription__23WwD {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .AddonServices_title__3QEQh {
      font-size: 2rem;
    }
  
    .AddonServices_servicecard__2qeF5 {
      padding: 15px;
    }
  
    .AddonServices_servicetitle__3DIAN {
      font-size: 1.2rem;
    }
  
    .AddonServices_servicedescription__23WwD {
      font-size: 0.9rem;
    }
  }
  
.Testimonial_testimonials__3bDGZ {
    font-family: 'Arial', sans-serif;
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    color: #333;
  }
  
  .Testimonial_title__1hSJj {
    font-size: 2.5rem;
    color: #000;
    margin-bottom: 40px;
  }
  
  .Testimonial_testimonialGrid__2DKKy {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }
  
  .Testimonial_testimonialCard__2rUNn {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: box-shadow 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s;
    transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
  }
  
  .Testimonial_testimonialCard__2rUNn:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .Testimonial_stars__2wrv6 {
    font-size: 1.2rem;
    color: #f4c430;
    margin-bottom: 10px;
  }
  
  .Testimonial_text__3iRzU {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  .Testimonial_profile__2DT5D {
    margin-right: 15px;
  }
  
  .Testimonial_profileImage__1DR7T {
    width: 50px; /* Adjust the size of the image */
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  .Testimonial_author__1SVrB {
    font-size: 1rem;
    color: #333;
    font-weight: bold;
    text-align: right;
  }
  
  @media (max-width: 768px) {
    .Testimonial_title__1hSJj {
      font-size: 2rem;
    }
  
    .Testimonial_testimonialCard__2rUNn {
      padding: 15px;
    }
  
    .Testimonial_text__3iRzU {
      font-size: 0.9rem;
    }
  
    .Testimonial_author__1SVrB {
      font-size: 0.9rem;
    }
  }
  
.Blogpage_blogcontainer__fx6JW {
  font-family: 'Arial', sans-serif;
  color: #333;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.Blogpage_blogheader__3528Y {
  text-align: center;
  margin-bottom: 40px;
}

.Blogpage_blogheader__3528Y h1 {
  font-size: 2.5rem;
  color: #000;
}

.Blogpage_blogheader__3528Y p {
  font-size: 1.2rem;
  color: #555;
}

.Blogpage_blogposts__1AIil {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  gap: 20px;
}

.Blogpage_blogpost__NapWp {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
}

.Blogpage_blogpost__NapWp:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.Blogpage_blogimage__16DPC {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.Blogpage_blogcontent__ppIlF {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Blogpage_blogtitle__1NqoE {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.Blogpage_blogdate__2fAf- {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 15px;
}

.Blogpage_blogexcerpt__2kBoq, .Blogpage_blogdetails__3PKXx {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: auto;
}

.Blogpage_readmoreContainer__3KB-w {
  margin-top: 20px;
  text-align: right;
}

.Blogpage_readmoreButton__1r-SA {
  background-color: #007bff;
  background-color: var(--themecolor, #007bff);
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.Blogpage_readmoreButton__1r-SA:hover {
  background-color: #0056b3;
}

/* Detailed Section Styles */
.Blogpage_detailsSection__ujhkB {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  -webkit-animation: Blogpage_fadeIn__1joIW 0.3s ease-in-out;
          animation: Blogpage_fadeIn__1joIW 0.3s ease-in-out;
}

.Blogpage_detailsImage__3yW22 {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 8px;
}

.Blogpage_detailsTitle__3dWGq {
  font-size: 2rem;
  color: #000;
  margin-bottom: 15px;
}

.Blogpage_detailsDate__3Dtai {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 20px;
}

.Blogpage_detailsContent__1Cqzv {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #555;
}

.Blogpage_backButton__2oiuk {
  background-color: #3c36f4;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 20px;
  transition: background-color 0.3s;
}

.Blogpage_backButton__2oiuk:hover {
  background-color: #2f55d3;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .Blogpage_blogposts__1AIil {
    grid-template-columns: 1fr;
  }

  .Blogpage_detailsImage__3yW22 {
    height: 00px;
  }
}

/* Keyframes for Fade-in Effect */
@-webkit-keyframes Blogpage_fadeIn__1joIW {
  from {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes Blogpage_fadeIn__1joIW {
  from {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}


.Blogpage_additionalImages__QuAqb {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin: 20px 0;
  flex-wrap: wrap;
}

.Blogpage_additionalImage__1w5fp {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
}

.Blogpage_videoContainer__AF1xd {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.Blogpage_video__28MWN {
  width: 100%;
  max-width: 560px;
  height: 315px;
  border-radius: 8px;
}

.Blogpage_paragraphImage__3GGQz {
  width: 300px;
  height: 300px;
  margin-bottom: 10px;
}

.Blogpage_detailParagraph__1kIr_ {
  margin-bottom: 20px;
}

.Blogpage_detailsContent__1Cqzv p {
  text-align: justify;
}
.ContactUs_contactUsParent__3qW_D {
  width: 100%;
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.ContactUs_content__3gbin {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.ContactUs_heading1__34Z6- {
  color: var(--darkText);
  font-size: 22px;
  font-weight: bold;
  width: 50%;
  line-height: 26px;
}

.ContactUs_addressContainer__3hy_O {
  display: flex;
  align-items:stretch;
  

}
.ContactUs_addressContainermain__158rN{
  display: flex;
  align-items:stretch;
  margin-top: 20px;
}

.ContactUs_addressIcon__GfdSE {
  border: 1px solid var(--themeColor);
  height: 20px;
  width: 20px;
  border-radius: 20px;
  padding: 4px;
  color: var(--themeColor);
  margin-top: -5px;
}

.ContactUs_addressHead__9JbhG {
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
  /* border: 1px solid red; */

}
.ContactUs_addressHeadbr__2NgY0{
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
  /* border: 1px solid red; */
  width: 160px;
}

.ContactUs_addressDetails__3Mi9i {
  margin-left: 10px;
}
.ContactUs_addressDetails1__omWq1 {
  margin-left: 10px;
  /* border: 1px solid red; */
  flex-wrap: wrap;
  width: 400px;
}
.ContactUs_addressAbroad__3M9EZ{
  margin-top: 60px;
}

.ContactUs_phoneContainer__1V9lG {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.ContactUs_phoneIcon__37nmh {
  border: 1px solid var(--themeColor);
  height: 20px;
  width: 20px;
  border-radius: 20px;
  padding: 4px;
  color: var(--themeColor);
}

.ContactUs_phoneIcon1__oW9Xx{
  display: none;
  border: 1px solid var(--themeColor);
  height: 20px;
  width: 20px;
  border-radius: 20px;
  padding: 4px;
  color: var(--themeColor);
}

.ContactUs_phoneHead__vl9Ij {
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
}

.ContactUs_phoneDetails__1xhUv {
  margin-left: 10px;
}

.ContactUs_emailContainer__1qT0I {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.ContactUs_emailIcon__2hl0A {
  border: 1px solid var(--themeColor);
  height: 20px;
  width: 20px;
  border-radius: 20px;
  padding: 4px;
  color: var(--themeColor);
}


.ContactUs_emailIcon1__l-HPp {
  display: none;
  border: 1px solid var(--themeColor);
  height: 20px;
  width: 20px;
  border-radius: 20px;
  padding: 4px;
  color: var(--themeColor);
}
.ContactUs_emailHead__oeyha {
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
}

.ContactUs_emailDetails__3w0XT {
  margin-left: 10px;
}

.ContactUs_SocialMediaTitle__14SMA {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.ContactUs_mainContainerIcon__2SKo5 {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.ContactUs_icon1Container__170Y4 {
  height: 30px;
  width: 30px;
}

.ContactUs_icon2Container__3NPMF {
  margin-left: 16px;
  height: 30px;
  width: 30px;
}

.ContactUs_icon3Container__Q-hM8 {
  margin-left: 16px;

  height: 30px;
  width: 30px;
}

.ContactUs_icon4Container__3STa4 {
  margin-left: 16px;

  height: 30px;
  width: 30px;
}

.ContactUs_BusinessDetails__3F9ES {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.ContactUs_details1__2mlXf {
  margin-top: 10px;
  color: var(--lightText);
  font-size: 16px;
}

.ContactUs_bContainer1__2aFLn {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.ContactUs_bContainer2__2jwI2 {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.ContactUs_dayIcon__1t7mb {
  height: 20px;
  width: 20px;
  padding: 4px;
  color: var(--themeColor);
}

.ContactUs_dayHead__3ev_b {
  margin-left: 10px;
  font-weight: bold;
}

.ContactUs_daytimeDetails__1SGvC {
  margin-left: 10px;
}

.ContactUs_details2__iKSLy {
  margin-top: 20px;
}

.ContactUs_getinTouchTitle__3u6Lx {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.ContactUs_getintouchdetails3__2i4ZA {
  margin-top: 10px;
  line-height: 24px;
}

.ContactUs_headingDetails1__23dXA {
  width: 80%;
  margin-top: 20px;
  font-size: 15px;
  color: var(--lightText);
  line-height: 22px;
  text-align: justify;
}

.ContactUs_heading2__KB0oU {
  margin-top: 20px;
  color: var(--darkText);
  font-size: 16px;
  font-weight: bold;
}

.ContactUs_OfficeImageContainer__3XWAx {
  margin-top: 20px;
  width: 80%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.ContactUs_bottomDetailsContainer__3hkeF {
  margin-top: 20px;
  width: 100%;
  display: flex;
}

.ContactUs_firstContainer__2Vn-N {
  width: 50%;
}

.ContactUs_firstTitle__hUOYC {
  font-size: 16px;
  color: var(--darkText);
  font-weight: 500;
  visibility: hidden;
}

.ContactUs_subFIrstContainer__GhnQ9 {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.ContactUs_sub_FIrstContainer__3v_wm {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.ContactUs_locationIcon__1Wvhz {
  border: 1px solid var(--cardBorderColor);
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  color: var(--lightText);
  display: flex;
  justify-content: start;
  align-items: start;
  background-color: var(--cardBorderColorLight);
  padding: 8px;
  border-radius: 50%;
}

.ContactUs_locationIcon2__342JH {
  border: 1px solid var(--cardBorderColor);
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  color: var(--lightText);
  display: flex;
  justify-content: start;
  align-items: start;
  background-color: var(--cardBorderColorLight);
  padding: 8px;
  border-radius: 50%;
}

.ContactUs_firstAddressText__1eaXK {
  margin-right: 10px;
  margin-left: 6px;
  font-size: 14px;
  color: var(--lightText);
  line-height: 20px;
}

.ContactUs_secContainer__1ZEv8 {
  width: 100%;
}

.ContactUs_form__2s7QD {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  justify-self: center;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.ContactUs_formContainer__3l8Of {
  width: 90%;
}
 
.ContactUs_headingText__3B6j9 {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.ContactUs_horizontalLine__3mjWp {
  margin-top: 14px;
  margin-bottom: 6px;
  border-bottom: 1px solid var(--cardBorderColor);
}

.ContactUs_topContainer__2Z_df {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ContactUs_firstContainer__2Vn-N {
  width: 50%;
}

.ContactUs_firstNameText__2j3Rq {
  margin-top: 20px;
  color: var(--lightText);
}

.ContactUs_firstNameEditBox__2j1YQ {
  width: 90%;
  margin-right: 5px;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_firstNameEditBox__2j1YQ:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_secContainer__1ZEv8 {
  width: 50%;
}
.ContactUs_secContainer2__jyeTj {
  width: 50%;
  visibility: hidden;
}

.ContactUs_lastNameText__1ZKg6 {
  margin-left: 10px;
  margin-top: 20px;
  color: var(--lightText);
}
.ContactUs_lastNameEditBox__2fNaa {
  width: 90%;
  margin-left: 10px;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_lastNameEditBox__2fNaa:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_emailContainer__1qT0I {
  margin-top: 20px;
  width: auto;
}

.ContactUs_textEmail__1Wdrq {
  margin-top: 10px;
  color: var(--lightText);
}

.ContactUs_emailEditBox__C1Hop {
  width: 97%;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_emailEditBox__C1Hop:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_companyContainer__2Ypal {
  margin-top: 20px;
  width: auto;
}

.ContactUs_textCompany__1P4FE {
  margin-top: 10px;
  color: var(--lightText);
}

.ContactUs_companyEditBox__10gvQ {
  width: 97%;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_companyEditBox__10gvQ:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_phoneContainer__1V9lG {
  margin-top: 20px;
  width: auto;
}

.ContactUs_textphone__3V_0h {
  margin-top: 10px;
  color: var(--lightText);
}

.ContactUs_phoneEditBox__12urW {
  width: 97%;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_phoneEditBox__12urW:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_messageContainer__GP5tj {
  margin-top: 20px;
  width: 97%;
}

.ContactUs_textMessage__rrVKi {
  margin-top: 10px;
  color: var(--lightText);
}

.ContactUs_addressIcon1__VuGQ6{
  display: none;
}

.ContactUs_messageEditBox__2Wuto {
  width: 100%;
  height: 100px;
  text-align: start;
  margin-top: 4px;
  background-color: white;
  padding-top: 0;
  padding-left: 0;
  line-height: 1em;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
  display: flex;
  justify-content: start;
  align-items: start;
}

.ContactUs_messageEditBox__2Wuto:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_sendBtnContainer__5gmHj {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
}

.ContactUs_sendBtn__30XlK {
  margin-top: 40px;
  width: 60%;
  height: 40px;
  background-color: var(--themeColor);
  border-radius: 8px;
  color: white;
  font-size: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ContactUs_sendBtn__30XlK:hover {
  cursor: pointer;
}

.ContactUs_horizontalBorder__1Faf- {
  margin-top: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--cardBorderColor);
}

.ContactUs_t1__Plpv- {
  margin-top: 20px;
  font-size: 18px;
  color: var(--darkText);
  font-weight: bolder;
}

.ContactUs_d1__95dgX {
  margin-top: 10px;
  font-size: 16px;
  color: var(--lightText);
}

.ContactUs_d2__182r7 {
  margin-top: 20px;
  font-size: 18px;
  color: var(--darkText);
  font-weight: bold;
}

@media (max-width: 1300px) {
  .ContactUs_contactUsParent__3qW_D {
    width: 98%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ContactUs_OfficeImageContainer__3XWAx {
    width: 96%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ContactUs_form__2s7QD {
    margin-right: 10px;

    width: 96%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}


@media (max-width: 729px) {
.ContactUs_addressContainer__3hy_O{
display: flex;
flex-direction: column;
}

.ContactUs_addressDetails1__omWq1{
  width: auto;
  margin-left: 3px;
  margin-top: 10px;
}

.ContactUs_addressDetails__3Mi9i{
  margin-left: 3px;
  margin-top: 10px;

}

.ContactUs_addressAbroad__3M9EZ{
  margin-top: 25px;
}

.ContactUs_addressIcon__GfdSE{
  display: none;
}

.ContactUs_addressHeadbr__2NgY0{
  width: 100%;
  display: flex;
  grid-gap: 10px
  ;
  gap: 10px
  ;
  margin-left: 0;

}

.ContactUs_addressHead__9JbhG{
  width: 100%;
  display: flex;
  grid-gap: 10px
  ;
  gap: 10px
  ;
  margin-left: 0;
}

.ContactUs_addressIcon1__VuGQ6 {
  border: 1px solid var(--themeColor);
  height: 20px;
  width: 20px;
  border-radius: 20px;
  padding: 4px;
  color: var(--themeColor);
  margin-top: -5px;
  display: flex;
}

.ContactUs_emailIcon__2hl0A ,.ContactUs_phoneIcon__37nmh{
  display: none;
}

.ContactUs_phoneHead__vl9Ij ,.ContactUs_emailHead__oeyha{
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-left: 0;
  /* flex-direction: column; */
}

.ContactUs_emailIcon1__l-HPp ,.ContactUs_phoneIcon1__oW9Xx{
  display: flex;
}
.ContactUs_addressContainermain__158rN{
  flex-direction: column;
  width: 100%;
}

.ContactUs_topContainer__2Z_df{
  flex-direction: column;
  width: 100%;
}

.ContactUs_emailContainer__1qT0I ,.ContactUs_phoneContainer__1V9lG{
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.ContactUs_firstContainer__2Vn-N ,.ContactUs_secContainer__1ZEv8{
  width: 100%;
}
.ContactUs_lastNameText__1ZKg6 ,.ContactUs_lastNameEditBox__2fNaa{
  margin-left: 0;
}
.ContactUs_firstNameEditBox__2j1YQ ,.ContactUs_lastNameEditBox__2fNaa{
  width: 97%;
}
.ContactUs_messageEditBox__2Wuto{
  width: 98%;
}
}


@media (max-width: 375px) {
  .ContactUs_firstNameEditBox__2j1YQ ,.ContactUs_lastNameEditBox__2fNaa{
    width: 94%;
  }

  .ContactUs_emailEditBox__C1Hop{
    width: 94%;
  }

  .ContactUs_phoneEditBox__12urW{
    width: 94%;
  }
  .ContactUs_messageEditBox__2Wuto{
    width: 94%;
  }
}
.Notices_notices__19fhP {
}

.Notices_searchbar__3iWob {
  margin-top: 100px;
  margin-bottom: 34px;
  width: 700px;
  border: 1px solid var(--cardBorderColor);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Notices_serchIcon__37jII {
  margin-left: 10px;
  color: var(--lightText);
}

.Notices_searchInput__1rPS7 {
  width: 94%;
  margin-right: 5px;
  background-color: white;
  height: 30px;
  font-size: 14px;
  font-size: 18px;
  border: none;
}

@media (max-width: 1300px) {
  .Notices_searchbar__3iWob {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.Notices_mainContainer__2iz2n {
  margin-top: 20px;
  width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.Notices_leftContainer__PTNWI {
}

.Notices_title__3Zeij {
  font-weight: bold;
  color: black;

  margin-left: 10px;
}

.Notices_topBar__2LoWX {
  width: 100%;
  height: 35px;
  background-color: var(--cardBorderColorLight);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  font-size: 18px;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid var(--cardBorderColor);
}

.Notices_mainImg__1P3Hg {
  width: 100%;
}

.Notices_pic__2h72B {
  width: 100%;
  height: 100%;
}

.Notices_docBox__2Fk33 {
  height: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.Notices_law__2VLnK {
  margin: 10px;
  line-height: 30px;
}

.Notices_uploadDocument__1qovi {
  margin-top: 10px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border: 1px solid var(--themeColor);
  border-radius: 6px;
}

.Notices_rightContainer__3se8q {
}

.Notices_changesContainer__2Eo7C {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Notices_circleContainer__1k7vz {
  width: 130px;
  height: 130px;
  margin-top: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: var(--themeColor);
  border: 2px solid var(--themeColor);
}

.Notices_changesTitle__23nxY {
  font-weight: bolder;
  font-size: 22px;
}

.Notices_arrowIcon__1yZpG {
  margin-top: 10px;
  color: var(--themeColor);
  height: 30px;
  width: 30px;
}

.Notices_mainChangesDetailsContainer__3qGn_ {
  margin-top: 50px;
  width: 45%;
  text-align: justify;
  height: auto;
  padding: 10px;
  line-height: 30px;
  border: 1px solid var(--themeColor);
  border-radius: 10px;
}


@media (max-width: 1300px) {

  .Notices_mainContainer__2iz2n{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Notices_leftContainer__PTNWI{
    width: 97%;
  }

  .Notices_rightContainer__3se8q{
    margin-top: 40px;
    width: 97%;
  }

  .Notices_mainChangesDetailsContainer__3qGn_{
    width: 85%;
  }
}
.OneNotice_oneNotice__dHsoH {
  height: auto;
  margin-top: 16px;
  padding: 20px;
  border-radius: 16px;
  display: flex;
  border-bottom: 1px solid var(--cardBorderColorLight);
}

.OneNotice_noticeBg__2WF_T {
  height: 30px;
  width: 30px;
  max-width: 30px;
  max-width: 30px;
  background-color: var(--cardBorderColorLight);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--cardBorderColor);
}

.OneNotice_noticeNumber__2fkl3 {
  color: var(--darkText);
}

.OneNotice_contentContainer__3gRi1 {
  margin-left: 20px;
  width: 80%;
}

.OneNotice_titleDiv__3v7QQ {
  font-size: 20px;
  font-weight: bold;
  color: var(--darkText);
}

.OneNotice_contentDiv__3x3Xs {
  margin-top: 10px;
  color: var(--lightText);
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  margin-bottom: 16px;
}

@media (max-width: 1300px) {
  .OneNotice_oneNotice__dHsoH {
    padding: 0px;
  }

}
.JoinAsTopper_rulesAndRegulationsParent__2QWLt {
  margin-top: 40px;
  width: 100%;
}

.JoinAsTopper_rulesAndRegulationsMain__3kQJS {
  width: 100%;
}

.JoinAsTopper_innerDiv__29KtL {
  width: 1200px;
  margin: auto;
}

.JoinAsTopper_tabContainer__17wUj {
  display: flex;
  justify-content: center;
  grid-gap: 16px;
  gap: 16px;
  margin-top: 20px;
}

.JoinAsTopper_tabButton__3pURi {
  padding: 10px 20px;
  border: none;
  background: #e0e0e0;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.3s ease;
}

.JoinAsTopper_tabButton__3pURi:hover {
  background: #95a9eb;
}

.JoinAsTopper_activeTab__13SBt {
  background: #007bff;
  color: white;
  font-weight: bold;
}

.JoinAsTopper_innerDiv__29KtL {
  margin-top: 20px;
  padding: 20px;
}

/* Responsive */
@media (max-width: 480px) {
  .JoinAsTopper_innerDiv__29KtL {
    width: 90%;
    margin: auto;
    padding: 0px;
  }
}


@media (max-width: 768px) {
  .JoinAsTopper_tabContainer__17wUj {
    flex-direction: column;
    align-items: center;
  }

  .JoinAsTopper_tabButton__3pURi {
    width: 100%;
    text-align: center;
  }
}


.JoinAsTopper_serchContainer__1QzB4 {
  background-color: var(--bgColor);
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
}

.JoinAsTopper_serchbox__2F0Dh {
  margin-left: 10px;
  width: 90%;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: none;
  border: 1px solid var(--cardBorderColorLight);
}

.JoinAsTopper_serchbox__2F0Dh:focus {
  border-style: solid;
  background-color: white;
  border-width: 2px;
  outline: none !important;
  border-color: var(--themeColor);
}

.JoinAsTopper_btnContainer__ztpLv {
  width: 100px;
  margin-left: 20px;
  height: 28px;
  background-color: var(--themeColor);
  border: none;
  border-radius: 8px;
  color: white;
  margin-right: 10px;
}

.JoinAsTopper_btnContainer__ztpLv:hover {
  cursor: pointer;
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
}

.JoinAsTopper_parentDiv__1mrqd {
  margin-top: 20px;
  display: flex;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, auto));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.JoinAsTopper_leftBox__35KqE {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.JoinAsTopper_topHeadingSection__3JxH1 {
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: var(--bgColor);
  border-bottom: 1px solid var(--cardBorderColor);
}

.JoinAsTopper_iconContainer__2FXI7 {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
}

.JoinAsTopper_title__3KzsD {
  width: 100%;
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
  text-align: center;
}

.JoinAsTopper_rightBoxContainer__2RLuA {
}

.JoinAsTopper_topBox__F2Mr1 {
  width: 100%;

  border: 1px solid var(--cardBorderColor);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.JoinAsTopper_midBox__3wVPS {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.JoinAsTopper_bottomBox__2NPRW {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.JoinAsTopper_mainContainer__2FUzX {
}

.JoinAsTopper_mainIcon__3u2hx {
}

.JoinAsTopper_noticeIcon__3EllN {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.JoinAsTopper_regIcon__12Y0m {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.JoinAsTopper_ruleIcon__1Haj9 {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.JoinAsTopper_actIcon__3iUB3 {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.JoinAsTopper_rulesAndRegulationsParent__2QWLt {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.JoinAsTopper_reraData__23HfS {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.JoinAsTopper_Block1__1cACV {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.JoinAsTopper_Title__2ijsL {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--themeColor);
  background-color: var(--themeColor);
  font-weight: bold;
  color: white;
}

.JoinAsTopper_mainContainer__2FUzX {
  padding-top: 10px;
  display: flex;
  padding-right: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--cardBorderColorLight);
}

.JoinAsTopper_mainContainer__2FUzX:hover {
  background-color: var(--bgColor);
  cursor: pointer;
}

.JoinAsTopper_nu__3WvV- {
  display: flex;
  justify-content: center;
  width: 15%;
  font-size: 14px;
}

.JoinAsTopper_details__EmmVj {
  margin-top: 10px;
  width: 100%;
  font-size: 15px;
  line-height: 24px;
  text-align: justify;
}

.JoinAsTopper_Block2__Z6Eq8 {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.JoinAsTopper_Block3__3q_AC {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.JoinAsTopper_Block4__17T-8 {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.JoinAsTopper_threeCardContainer__3tdNj {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.JoinAsTopper_topCard__1hzpw {
  width: 1200px;
  border-style: none;
  display: flex;
  margin: auto;
}

.JoinAsTopper_topSectionJoinTopper__2clR1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.JoinAsTopper_textDiv__1HvSZ {
  border-style: none;
}

.JoinAsTopper_largeText__nz4RW {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.JoinAsTopper_smallText__hmyjK {
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
}

.JoinAsTopper_joinNowButton__2x3zQ {
  height: 40px;
  width: 230px;
  border-radius: 30px;
  border-style: none;
  background-color: var(--themeColor);
  margin-top: 30px;
  cursor: pointer;
  font-family: roboto;
  color: white;
  font-size: 16px;
  font-weight: bold;
}









.JoinAsTopper_joinNowButton__2x3zQ:hover{

color: var(--themeColor);
background: white;
border-style: solid;
border-color: var(--themeColor);
border-width: 1px;
}



.JoinAsTopper_topRightSection__2VcxF {
  width: 50%;
  object-fit: cover;
}

.JoinAsTopper_girlIMageTop__2ydlr {
  width: 400px;
  height: 400px;
  object-fit: cover;
}

.JoinAsTopper_card1__3I36k {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.JoinAsTopper_card2__5OvaV {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.JoinAsTopper_card3__226Zf {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.JoinAsTopper_contentContainer__3VaXK {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.JoinAsTopper_img__1s_vZ {
  width: 60px;
  height: 60px;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s; /* Add a smooth transition for the rotation effect */
  /* Initial state (not hovered) */
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.JoinAsTopper_card1__3I36k:hover .JoinAsTopper_img__1s_vZ {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.JoinAsTopper_card2__5OvaV:hover .JoinAsTopper_img__1s_vZ {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.JoinAsTopper_card3__226Zf:hover .JoinAsTopper_img__1s_vZ {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.JoinAsTopper_title__3KzsD {
  width: 80%;
  margin-top: 10px;
  font-size: 22px;
  color: var(--deepDarkThemeTextColor);
  font-weight: bold;
  line-height: 28px;
}

/* .details {
  margin-top: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
} */

.JoinAsTopper_inconstructionMessage__wdzQG {
  margin-top: 40px;
  color: red;
  font-size: 25px;
  border-style: solid;
  padding: 20px;
  border-radius: 10px;
}

.JoinAsTopper_shapethefutureCard__3n3zr {
  margin-top: 30px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 20px;
  border-radius: 10px;
}

.JoinAsTopper_shapetheFutureTitle__vipLw {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 30px;
  color: var(--deepDarkThemeTextColor);
}

.JoinAsTopper_shapetheFutureButton__1MC9f {
  width: 100px;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: var(--themeColor);
  color: white;
  border: none;
  border-radius: 6px;
}

.JoinAsTopper_shapetheFutureButton__1MC9f:hover {
  cursor: pointer;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  background-color: white;
}

.JoinAsTopper_shapethefutureDetails__2aqGU {
  width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 26px;
  color: var(--deepDarkThemeTextColor);
  font-weight: 500;
  font-size: 18px;
}

.JoinAsTopper_mainTitle1__3-WMC {
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 30px;
  color: var(--deepDarkThemeTextColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.JoinAsTopper_submainTitle__2j4Q3 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: oblique;
  color: var(--themeColor);
}

.JoinAsTopper_details1__w74Qu {
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 26px;
  color: var(--deepDarkThemeTextColor);
  font-weight: 500;
  font-size: 18px;
}

.JoinAsTopper_details2__2nLXt {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 26px;
  color: var(--deepDarkThemeTextColor);
  font-weight: 500;
  font-size: 18px;
}

.JoinAsTopper_mainTitle2__K32cS {
  margin-top: 50px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 30px;
  color: var(--deepDarkThemeTextColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.JoinAsTopper_joinToppersCard__czb21 {
  width: 100%;
  background-color: var(--chatCommentBkgColor);
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 20px;
  border-style: none;
  margin: auto;
}

.JoinAsTopper_newContainer__1q3t8 {
  margin-top: 50px;
  padding: 10px;
}

.JoinAsTopper_mainheadingTitle__3EVTy {
  font-weight: bolder;
  font-size: 30px;
  color: black;
  margin-bottom: 30px;
  text-align: center;
}

.JoinAsTopper_mainHead1__2h0wT {
  margin-top: 15px;
  font-size: 17px;
  font-weight: bolder;
  color: var(--deepDarkThemeTextColor);
}

.JoinAsTopper_mainDetail1__3JjAz {
  margin-top: 6px;
  font-size: 16px;
  color: var(--lightColor);
}

.JoinAsTopper_howitworks__s-ef2 {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.JoinAsTopper_howitworksTitle__2sCNS {
  text-align: center;
  font-weight: bolder;
  font-size: 30px;
  color: black;
  margin-bottom: 30px;
  text-align: center;
}

.JoinAsTopper_lastContainer__1AFlr {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.JoinAsTopper_mainCircle__1dWk1 {
  width: 50px;
  height: 50px;
  border: 1px solid var(--cardBorderColor);
  border-radius: 10%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.JoinAsTopper_Acontaienr__2gH5M {
  width: 80%;
  border: 1px solid var(--cardBorderColor);
  border-radius: 10px;
  margin: 10px;
}
.JoinAsTopper_titleA__LpEzl {
  background-color: var(--cardBorderColorLight);
  height: 30px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  right: 10px;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-left: 10px;
}

.JoinAsTopper_desA__3JPJA {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

@media (max-width: 1000px) {
  .JoinAsTopper_joinToppersCard__czb21 {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .JoinAsTopper_topCard__1hzpw {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .JoinAsTopper_submainTitle__2j4Q3{
    text-align: center;
  }

  .JoinAsTopper_innerDiv__29KtL {
    width: 98%;
    margin: 0;
  }

  .JoinAsTopper_mainTitle1__3-WMC {
    text-align: center;
  }

  .JoinAsTopper_details1__w74Qu {
    text-align: justify;
    margin: 10px;
  }

  .JoinAsTopper_details2__2nLXt {
    text-align: justify;
    margin: 10px;
  }

  .JoinAsTopper_threeCardContainer__3tdNj {
    margin: 10px;
  }

  .JoinAsTopper_topSectionJoinTopper__2clR1 {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .JoinAsTopper_topRightSection__2VcxF {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .JoinAsTopper_shapethefutureCard__3n3zr {
    width: 100%;
  }

  .JoinAsTopper_mainTitle2__K32cS {
    text-align: center;
  }

  .JoinAsTopper_howitworks__s-ef2 {
    margin: 0;
    padding: 0;
  }

  .JoinAsTopper_lastContainer__1AFlr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .JoinAsTopper_Acontaienr__2gH5M {
  }

  .JoinAsTopper_titleA__LpEzl {
    height: 30px;
  }
  .JoinAsTopper_desA__3JPJA {
    height: auto;
  }
}

.JoinAsTopper_f_points_star__3i5Vn {
  /* Modify size here: */
  --size: 30px;
  --half-size: calc(var(--size) / 2);

  position: relative;
  display: block;
  color: var(--themeColor);
  width: 0px;
  height: 0px;
  border-right: var(--half-size) solid transparent;
  border-bottom: calc(var(--half-size) * 0.7) solid #333;
  border-left: var(--half-size) solid transparent;
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
}
.JoinAsTopper_f_points_star__3i5Vn:before {
  border-bottom: calc(var(--half-size) * 0.8) solid #333;
  border-left: calc(var(--half-size) * 0.3) solid transparent;
  border-right: calc(var(--half-size) * 0.3) solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: calc(var(--half-size) * 0.45 * -1);
  left: calc(var(--half-size) * 0.65 * -1);
  display: block;
  content: "";
  -webkit-transform: rotate(-35deg);
          transform: rotate(-35deg);
}
.JoinAsTopper_f_points_star__3i5Vn:after {
  position: absolute;
  display: block;
  color: #333;
  top: calc(var(--half-size) * 0.03);
  left: calc(var(--half-size) * -1.05);
  width: 0px;
  height: 0px;
  border-right: var(--half-size) solid transparent;
  border-bottom: calc(var(--half-size) * 0.7) solid #333;
  border-left: var(--half-size) solid transparent;
  -webkit-transform: rotate(-70deg);
          transform: rotate(-70deg);
  content: "";
}

.customServices_mainContainer__5J-sK {
  
  padding: 20px;
  max-width: 1200px;
  text-align: center;
}

.customServices_title__26K2o {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.customServices_serviceList__37U0O {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customServices_serviceItem__3N0iG {
  display: flex;
  align-items: center;
  background: #fff;
  margin: 20px 0;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  width: 85%;
  max-width: 900px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.customServices_serviceItem__3N0iG::before {
  content: "";
  position: absolute;
  top: -30px;
  left: -30px;
  width: 180px;
  height: 180px;
  background: rgba(0, 68, 255, 0.3);
  border-radius: 50%;
  z-index: 0;
}

.customServices_serviceItem__3N0iG::after {
  content: "";
  position: absolute;
  bottom: -40px;
  right: -40px;
  width: 200px;
  height: 200px;
  background: rgba(0, 153, 255, 0.6);
  border-radius: 50%;
  z-index: 0;
}

.customServices_serviceItem__3N0iG:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.customServices_serviceImage__210K_ {
  width: 30%;
  height: auto;
  border-radius: 15px;
  margin-right: 25px;
  object-fit: cover;
  position: relative;
  z-index: 1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.customServices_serviceContent__1T_hb {
  text-align: left;
  position: relative;
  z-index: 1;
  flex-grow: 1;
}

.customServices_serviceTitle__2xhJR {
  font-size: 24px;
  font-weight: bold;
  color: #444;
}

.customServices_serviceDescription__3_KIG {
  font-size: 16px;
  font-weight: 500;
  color: #2c2b2b;
  margin-bottom: 10px;
}

.customServices_featureList__3kYXl {
  list-style-type: none;
  padding-left: 0;
  margin-top: 5px;
}

.customServices_featureItem__3YTsw {
  font-size: 16px;
  color: #2c2b2b;
  padding-left: 15px;
  position: relative;
}

.customServices_featureItem__3YTsw::before {
  content: "•";
  color: #007bff;
  position: absolute;
  left: 0;
  font-size: 16px;
}

@media (max-width: 768px) {
  .customServices_serviceItem__3N0iG {
    -webkit-margin-start: 32px;
            margin-inline-start: 32px;
    -webkit-margin-end: 32px;
            margin-inline-end: 32px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px;
    width: 95%;
  }

  .customServices_serviceImage__210K_ {
    width: 40%;
    margin-bottom: 15px;
    margin-right: 0;
  }

  .customServices_serviceContent__1T_hb {
    text-align: center;
  }

  .customServices_serviceTitle__2xhJR {
    font-size: 22px;
  }

  .customServices_serviceDescription__3_KIG {
    font-size: 14px;
  }

  .customServices_featureItem__3YTsw {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .customServices_serviceItem__3N0iG {
    width: 90%;
    padding: 0px;
  }

  .customServices_serviceImage__210K_ {
    width: 50%;
  }

  .customServices_serviceTitle__2xhJR {
    font-size: 20px;
  }

  .customServices_serviceDescription__3_KIG {
    font-size: 13px;
  }

  .customServices_featureItem__3YTsw {
    font-size: 12px;
  }
}

.JoinAsTopperTopSection_parent__n--uJ {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}


.JoinAsTopperTopSection_t1__2SUCL {
  width: 60%;
  font-size: 18px;
  line-height: 22px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.JoinAsTopperTopSection_imagestop__2G_sU {

  width: 100%;
  height: 300px;
  background-color: var(--chatCommentBkgColor);
  display: flex;
  align-items: center;
}



.JoinAsTopperTopSection_innerDiv__2NW4z{

width: 1200px;
margin: auto;
}

.JoinAsTopperTopSection_detailsText__3dCZ3 {
  width: 60%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
}


@media (max-width: 480px) {
  .JoinAsTopperTopSection_t1__2SUCL,
  .JoinAsTopperTopSection_detailsText__3dCZ3 {
    width: 100%;
    font-size: 14px;
    text-align: center;
  }

  .JoinAsTopperTopSection_imagestop__2G_sU {
    height: 200px;
  }


}



/* Project Container */
.Projects_projectsContainer__3vRzf {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  background-color: #eef4ff;
  padding: 20px;
  border-radius: 10px;
 
}


/* Accordion Item */
.Projects_accordionItem__1yO97 {
  border: 1px solid #ccc;
  margin-bottom: 15px;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.Projects_accordionItem__1yO97:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15);
}

/* Accordion Header */
.Projects_accordionHeader__2V6sB {
  background: #4679fc;
  color: white;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px 5px 0 0;
}

.Projects_accordionHeader__2V6sB:hover {
  background: #1d4ed8;
}

/* Accordion Arrow */
.Projects_accordionArrow__2blG3 {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.Projects_accordionArrow__2blG3.Projects_open__2gFMj {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

/* Accordion Content */
.Projects_accordionContent__1Oz-o {
  max-height: 0;
  overflow: hidden;
  padding: 0 20px;
  transition: max-height 0.4s ease-out, padding 0.4s ease-out;
  background: white;
}

.Projects_accordionContent__1Oz-o.Projects_active__1JOQ0 {
  max-height: 600px;
  padding: 20px;
  overflow-y: auto;
}

/* Image Card */
.Projects_projectImageCard__1__RE {
  background: white;
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  margin-bottom: 15px;
  overflow: hidden;
}

.Projects_projectImageCard__1__RE:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

/* Project Image */
.Projects_projectImage__2Oyzr {
  width: 100%;
  max-height: auto;
  object-fit: cover;
  /* aspect-ratio:  1/3 ; */
  border-radius: 8px;
}

/* Project Status */
.Projects_projectStatus__2YuCp {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 6px 12px;
  border-radius: 20px;
  margin-top: 10px;
  text-align: center;
}

/* Dynamic status colors */
.Projects_status-ongoing__1MG0C {
  background: rgba(255, 165, 0, 0.2);
  color: #ff8c00;
  border: 1px solid #ff8c00;
}

.Projects_status-completed__2QHFu {
  background: rgba(34, 197, 94, 0.2);
  color: #22c55e;
  border: 1px solid #22c55e;
}

/* Project Content */
.Projects_projectContent__1vzO6 {
  font-size: 16px;
  font-weight: 400;
  color: #333;
  margin: 15px 0;
  line-height: 1.5;
  text-align: justify;
  padding: 0 10px; /* Added padding to prevent overflow */
}

/* Features List */
.Projects_projectFeatures__2AaHd {
  list-style-type: none;
  padding-left: 0;
  color: #555;
  font-size: 14px;
  margin-top: 10px;
}

.Projects_projectFeatures__2AaHd li {
  padding: 8px 0;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.Projects_projectFeatures__2AaHd li::before {
  content: "✔";
  color: #2a66ff;
  font-weight: bold;
  margin-right: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .Projects_projectsContainer__3vRzf {
      width: 95%;
      padding: 15px;
  }

  .Projects_accordionHeader__2V6sB {
      font-size: 16px;
      padding: 12px;
  }

  .Projects_accordionContent__1Oz-o {
      padding: 0 15px;
  }

  .Projects_accordionContent__1Oz-o.Projects_active__1JOQ0 {
      padding: 15px;
  }

  .Projects_projectImage__2Oyzr {
      max-height: 200px;
  }

  .Projects_projectStatus__2YuCp,
  .Projects_projectContent__1vzO6 {
      font-size: 14px;
  }

  .Projects_projectFeatures__2AaHd {
      font-size: 13px;
  }
}

@media (max-width: 480px) {

  .Projects_sectionTitle__2MwsT{
    padding:8px;
  }
  .Projects_projectsContainer__3vRzf {
      width: 90%;
      padding: 8px;
   
  }

  .Projects_accordionHeader__2V6sB {
      font-size: 14px;
      padding: 10px;
  }

  .Projects_accordionContent__1Oz-o {
      padding: 0 10px;
  }

  .Projects_accordionContent__1Oz-o.Projects_active__1JOQ0 {
      padding: 10px;
  }

  .Projects_projectImage__2Oyzr {
      max-height: 150px;
  }

  .Projects_projectStatus__2YuCp,
  .Projects_projectContent__1vzO6 {
      font-size: 13px;
      padding: 0 8px;
  }

  .Projects_projectFeatures__2AaHd {
      font-size: 12px;
  }
}

.OneProject_mainContainer__3MFZw {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 20px;
}

.OneProject_oneProject__13_GJ {
  margin: 16px;
}

.OneProject_imageProject__3SzZH {
  width: 100%;
  height: 300px;
  border-radius: 20px;
}

.OneProject_projectName__psoaG {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.OneProject_discription__1_RHt {
  margin-top: 10px;
  font-size: 16px;
  line-height: 22px;
  color: var(--lightText);
}

.OneProject_locationContainer__3ScqM {
  margin-top: 10px;

  display: flex;
}

.OneProject_locationTitle__2myoZ {
  color: var(--lightText);
  width: 100px;
}

.OneProject_location__1Whz0 {
  color: var(--darkText);
  font-weight: bold;
}

.OneProject_launchDateContainer__3qFYJ {
  margin-top: 10px;

  display: flex;
}

.OneProject_launchTitle__3zLEm {
  color: var(--lightText);
  width: 100px;
}

.OneProject_launchDate__21Mdl {
  font-weight: bold;
}

.OneProject_statusContainer__3Gk1O {
  margin-top: 10px;

  display: flex;
}

.OneProject_statusTitle__3KGyt {
  color: var(--lightText);
  width: 100px;
}

.OneProject_status__1UID0 {
  font-weight: bold;
  color: var(--darkText);
}

.OneProject_reraContainer__3qDIx {
  margin-top: 10px;

  display: flex;
}

.OneProject_rereTitle__IKtEn {
  color: var(--lightText);
  width: 100px;
}

.OneProject_rera__1WMx0 {
  font-weight: bold;
  color: var(--darkText);
}

.OneProject_bottomContnet__3uryA {
  margin-top: 10px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.OneProject_noOfUnitsContainer__VyEwi {
  display: flex;
}

.OneProject_noofUnitsTitle__3Hscu {
  color: var(--lightText);
  width: 100px;
}

.OneProject_no_fu_units__2PwNj {
  font-weight: bold;
  color: var(--darkText);
}

.OneProject_btnRedirect__2UYQZ {
  color: blue;
  background-color: transparent;
  border: none;
  font-size: 16px;
  padding-right: 10px;
}

.OneProject_btnRedirect__2UYQZ:hover {
  cursor: pointer;
}

.Projects_projectMain__10ciX{

    margin-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}


@media (max-width: 1300px) {
 
    .Projects_projectMain__10ciX{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

    }
  }
.TermsofUse_parent__LGANS {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.TermsofUse_mainContainer__1b0Kj {
  width: 80%;
  background-color: white;
}

.TermsofUse_titleheading__3UF5P {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.TermsofUse_subtitle__3ba-g {
  margin-top: 20px;
  line-height: 20px;
}

.TermsofUse_heading1__2gtv- {
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
}

.TermsofUse_block1__1eyQ4 {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.TermsofUse_dot__St1P6 {
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
  display: flex;
  align-items: start;
  justify-content: start;
}

.TermsofUse_dot2__qForN {
  margin-top: 8px;
  display: flex;
  justify-self: start;
  align-self: flex-start;
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
}

.TermsofUse_title1___n-CA {
  width: -webkit-max-content;
  width: max-content;

  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.TermsofUse_details1__KY_VJ {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
}
.TermsofUse_title2__1hc42 {
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

.CookiesPolicy_parent__3SyQB {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:50px;
}
.CookiesPolicy_mainContainer__1uKJV {
  width: 80%;
  background-color: white;
}

.CookiesPolicy_titleheading__dugKk {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.CookiesPolicy_subtitle__24C1X {
  margin-top: 20px;
  line-height: 20px;
}

.CookiesPolicy_heading1__AiNin {
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
}

.CookiesPolicy_block1__3VfQM {
  margin-top: 10px;
  display: flex;
}

.CookiesPolicy_dot__34DWs {
    margin-top: 6px;
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CookiesPolicy_dot2__1JsgX {
  margin-top: 8px;
  display: flex;
  justify-self: start;
  align-self: flex-start;
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
}

.CookiesPolicy_title1__3x3xl {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  margin-left: 10px;
  display: flex;

  font-weight: bold;
}

.CookiesPolicy_details1__tj4M3 {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
}
.CookiesPolicy_title2__1OfCx {
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

.Disclaimer_parent__3faMX {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.Disclaimer_mainContainer__3fgmW {
  width: 80%;
  background-color: white;
}

.Disclaimer_titleheading__1nn5Z {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.Disclaimer_subtitle__gg6D0 {
  margin-top: 20px;
  line-height: 20px;
}

.Disclaimer_heading1__fIxQv {
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
}

.Disclaimer_block1__1YAHg {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.Disclaimer_dot__22oOO {
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
  display: flex;
  align-items: start;
  justify-content: start;
}


.Disclaimer_dot2__X78A5 {
    margin-top: 8px;
    display: flex;
    justify-self: start;
    align-self: flex-start;
    margin-left: 10px;
    height: 5px;
    width: 5px;
    min-height: 5px;
    min-width: 5px;
    background-color: black;
 

  }





.Disclaimer_title1__1RE6H {
  width: -webkit-max-content;
  width: max-content;

  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.Disclaimer_details1__1LSie {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
}
.Disclaimer_title2__1wOn6 {
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
}

.Privacypolicy_parent__2Ypdq {
    width: 100%;
     display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  .Privacypolicy_mainContainer__9Awy- {
    width: 80%;
    background-color: white;
  }
  
  .Privacypolicy_titleheading__1-cpS{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .Privacypolicy_subtitle__2YemX{
    margin-top: 20px;
    line-height: 20px;
  
  }
  
  .Privacypolicy_heading1__3eoJd{
    margin-top: 20px;
    font-weight: bold;
    font-size: 16px;
  }
  
  .Privacypolicy_block1__31MLY{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  
  .Privacypolicy_dot__2H0no{
    margin-left: 10px;
    height: 5px;
    width: 5px;
    min-height: 5px;
    min-width: 5px;
    background-color: black;
    
  }
  
  .Privacypolicy_title1__5yVDf{
    width: -webkit-max-content;
    width: max-content;
  
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  
  .Privacypolicy_details1__2r_ba{
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
  .Privacypolicy_title2__22za9{
    margin-top: 30px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
  }

.SuccessStoryDetails_parent__3ASGD {
  margin-top: 20px;
  display: flex;
  width: 100%;
}

.SuccessStoryDetails_leftContainer__pH_ly {
  width: 60%;
  margin: auto;
  margin-bottom: 50px;
}

.SuccessStoryDetails_imgAndHeading__NEGUF {
  object-fit: cover;
}

.SuccessStoryDetails_mainImg__1kVyC {
  width: 720px;

  object-fit: cover;
}
.SuccessStoryDetails_title__22AWU {
  margin-top: 20px;
  line-height: 26px;
  font-size: 20px;
  font-weight: bold;
}

.SuccessStoryDetails_details__1dhaU {
  margin-top: 20px;
  text-align: justify;
  line-height: 26px;
  font-size: 16px;
}

.SuccessStoryDetails_rightContainer__27SNT {
  width: 40%;
  display: flex;
  justify-content: center;
}

.SuccessStoryDetails_boxContainer__3vkX5 {
  width: 80%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 1px solid gray;
}

.SuccessStoryDetails_recentPost__QHjhN {
  margin-top: 10px;
  color: black;
  font-weight: bold;
}

.SuccessStoryDetails_recentPost1__2jwWG {
  margin-top: 20px;
}

.SuccessStoryDetails_horiBorder__1s-NI {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%;
  border-bottom: 1px solid gray;
}

.SuccessStoryDetails_recentPost2__1XPT0 {
}

.SuccessStoryDetails_container1__euuWR {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.SuccessStoryDetails_iconCal__1cqkk {
  width: 30px;
  height: 30px;
  background-color: gainsboro;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--greyTextColor);
}
.SuccessStoryDetails_date__2j_Vx {
  margin-left: 10px;
  font-weight: bold;
}

@media (max-width: 1000px) {
  .SuccessStoryDetails_parent__3ASGD {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .SuccessStoryDetails_leftContainer__pH_ly {
    width: 100%;
  }

  .SuccessStoryDetails_mainImg__1kVyC {
    margin-top: 20px;
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .SuccessStoryDetails_rightContainer__27SNT {
    visibility: hidden;
  }
}

.HelpCenter_parent__2_NQ_ {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.HelpCenter_mainContainer__2bAd7 {
  width: 80%;
  background-color: white;
}

.HelpCenter_titleheading__1psS9 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.HelpCenter_subtitle__CTCtq {
  margin-top: 20px;
  line-height: 20px;
}

.HelpCenter_heading1__2HwBQ {
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
}

.HelpCenter_heading2__14AcQ {
  margin-top: 20px;
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
}

.HelpCenter_block1__30jHy {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.HelpCenter_dot__1x-9F {
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
  display: flex;
  align-items: start;
  justify-content: start;
}

.HelpCenter_dot2__11Zvd {
  margin-top: 8px;
  display: flex;
  justify-self: start;
  align-self: flex-start;
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
}

.HelpCenter_title1__dgUca {
  width: -webkit-max-content;
  width: max-content;

  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.HelpCenter_details1__L1h04 {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
}
.HelpCenter_title2__3hrV2 {
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

.ReraAct_rulesAndRegulationsParent__cqwZu {
  margin-top: 40px;

  width: 100%;
}

.ReraAct_serchContainer__2cFoy {
  background-color: var(--bgColor);
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_serchbox__2lIF9 {
  margin-left: 10px;
  width: 90%;

  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: none;
  border: 1px solid var(--cardBorderColorLight);
}

.ReraAct_serchbox__2lIF9:focus {
  border-style: solid;
  background-color: white;
  border-width: 2px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ReraAct_btnContainer__3cKy0 {
  width: 100px;
  margin-left: 20px;
  height: 28px;
  background-color: var(--themeColor);
  border: none;
  border-radius: 8px;
  color: white;
  margin-right: 10px;
}

.ReraAct_btnContainer__3cKy0:hover {
  cursor: pointer;
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
}

.ReraAct_parentDiv__2pD1M {
  margin-top: 20px;
  display: flex;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(480px, auto));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.ReraAct_leftBox__1wVcz {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_topHeadingSection__1fFO0 {
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: var(--bgColor);
  border-bottom: 1px solid var(--cardBorderColor);
}

.ReraAct_iconContainer__2f9NP {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_title__1CeZP {
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
}

.ReraAct_rightBoxContainer__1pXKW {
}

.ReraAct_topBox__1eAYk {
  width: 100%;

  border: 1px solid var(--cardBorderColor);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.ReraAct_midBox__1YZw5 {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_bottomBox__1kBa5 {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_mainContainer__2keBT {
}

.ReraAct_mainIcon__2tTOF {
}

.ReraAct_noticeIcon__1iQRK {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReraAct_regIcon__3269M {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReraAct_ruleIcon__GqxHL {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReraAct_actIcon__RRUQh {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SingleReraAct_parent__15Xg7 {
  align-items: center;
  border-bottom: 1px solid var(--cardBorderColor);
}
.SingleReraAct_parent__15Xg7:hover{
  background-color: var(--cardBorderColorLight);
}
.SingleReraAct_parent__15Xg7:hover .SingleReraAct_downloadBtnContainer__2miX-{
  background-color: white;
}

.SingleReraAct_detailsContainer__24HyT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.SingleReraAct_rightSide__eLZjm {
  display: flex;
  justify-content: start;
  align-items: center;
}

.SingleReraAct_nu__3_3HK {
  width: 20px;
  font-size: 16px;
  font-weight: bold;
}

.SingleReraAct_title__2T6A1 {
  margin-left: 20px;
  width: 80%;
  color: var(--darkText);
  font-weight: bold;
}
.SingleReraAct_details__2vgyy {
  width: 50%;
  color: var(--lightText);
  line-height: 28px;
}

.SingleReraAct_downloadBtnContainer__2miX- {
  border: 1px solid var(--cardBorderColor);
  background-color: var(--cardBorderColorLight);
  width: 30px;
  height: 30px;
  min-height: 30px;
  min-width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SingleReraAct_downloadBtnContainer__2miX- :hover{
 cursor: pointer;
}

.SingleReraAct_downloadIcon__2ZSOI {
  height: 20px;
  width: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}





.EATechData_plcDataDiv__10SQN{


height: 100vh;
width: 100vw;



}


.EATechData_plcTitleDiv__1fXeK{
width: 100%;
height: 70px;
display: flex;
justify-content: center;
align-items: center;
font-size: 30px;
font-weight: bold;
background-color: lightgrey;
}


.EATechData_TableDiv__SLh4M{

border-style: solid;
width: 90%;
min-height: 100px;
margin: auto;
border-color: lightgrey;
border-width: 1px;
margin-top:10px;
}


.EATechData_oneRow__312qw{
width:90%;
height: 50px;
background-color: lightgrey;
display: flex;
margin: auto;
}




.EATechData_oneRow1__1LN9T{
width:90%;
height: 50px;
background-color: white;
display: flex;
margin: auto;
border-style: solid;
border-color: lightgrey;
border-width: 1px;
}





.EATechData_oneRowValue__1E8HY{
width:100%;
height: 50px;
background-color: white;
display: flex;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
}




.EATechData_leftDiv__2AN3-{

width: 33%;	
padding-left: 20px;
align-items: center;
height: 100%;
display: flex;
}

.EATechData_middleDiv__1bV_4{
width: 33%;
padding-left: 20px;
align-items: center;
height: 100%;
display: flex;


}


.EATechData_rightDiv__2K1yN{
height: 100%;
width: 33%;
display: flex;
align-items: center;

}







.Gallery_Container__YWlU9 {
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    margin: 10px;
  }
  
  .Gallery_mainImage__16kqo {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 8px;
    margin-top: 10px;
    padding: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    border: 2px solid lightgray;
  }
  
  .Gallery_gallery__6GwdO {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .Gallery_galleryitem__1g-Av {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease, -webkit-transform 0.3s ease;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transition: transform 0.3s ease, box-shadow 0.3s ease, -webkit-transform 0.3s ease;
    position: relative;
    padding: 10px;
  }
  
  .Gallery_galleryitem__1g-Av img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }
  
  .Gallery_galleryitem__1g-Av:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .Gallery_middleRow__1haIK {
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
  }
  
  .Gallery_middleRow__1haIK img {
    width: calc(50% - 10px);
    height: 100%;
    object-fit: cover;
  }
  
  .Gallery_circularRow__3LhDi {
    display: flex;
    width: 100%;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 20px;
    /* border:1px solid lightgray; */
    border-radius: 10px;
    padding: 10px;
    /* background-color: rgb(224, 225, 226); */
  }
  
  .Gallery_circularFrame__31ADI {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease, -webkit-transform 0.3s ease;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transition: transform 0.3s ease, box-shadow 0.3s ease, -webkit-transform 0.3s ease;
  }
  
  .Gallery_circularFrame__31ADI img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .Gallery_circularFrame__31ADI:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  

  


@media (max-width: 471px) {
  .Gallery_circularRow__3LhDi{
flex-direction: column;
align-items: center;
  }

   .Gallery_circularFrame__31ADI img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .Gallery_gallery__6GwdO {
    grid-template-columns: 0.5fr; 
  }
  .Gallery_middleRow__1haIK{
    grid-column: span 2; 
    display: flex;
    flex-direction: column;  
    justify-content: flex-start;  
    grid-gap: 20px;  
    gap: 20px;  
  
  }
  .Gallery_middleRow__1haIK img {
    width: 100%;
    height: auto;
    object-fit: contain; 
  }
  
  .Gallery_mainImage__16kqo {
  height: auto;
    width: 100%;
  }
  
  .Gallery_Container__YWlU9{
  margin-left: 0;
  padding-left: 0;
  }
  .Gallery_gallery__6GwdO{
    padding-right: 0;
  }
  }
.footer {
  /* background-color: #ffffff;
  padding: 40px 20px;
  width: 100%; */
display: flex;
 flex-direction: column;
      width: 100%;
      /* max-width: 1200px; */
      margin: auto;
      background-color: var(--themeColor);
      /* margin-top: 30px; */
      color: #ffffff;
}

.footer-inner{
  display: flex;
 flex-direction: column;
      width: 100%;
      max-width: 1200px;
      margin: auto;
      background-color: var(--themeColor);
      /* margin-top: 30px; */
      color: #ffffff;
}
.footer-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
  width: 100%;
}

.footer-logo-container{
margin-top: 10px;
width: 25%;
}

.footer-logo .logo-text-png {
  /* background-image: url(./logo-text-png.png); */
  background-position: center;
  background-size: cover;
  height: 49px;
  width: 189px;
  margin: auto;
}

.footer-subscription .insights-text {
  /* color: #333333; */
  color: #ffffff;
  font-family: "Red Hat Display-Regular", Helvetica, sans-serif;
  font-size: 24px;
  text-align: center;
}

.subscription-form {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.email-input {
  width: 70%;
  padding: 8px;
  border: 1px solid #bbbbbb;
  border-radius: 4px;
}

.send-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}

.social-media-icons {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.social-icon {
  border: 1px solid #bbbbbb;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}

.contact-section {
  text-align: left;
  width: 20%;

}

.contact-section2{
  text-align: left;
  width: 27%;
}

.cont1Text{ 
  display: flex;
  flex-direction: column;
  grid-gap: 25px
  ;
  gap: 25px
  ;
}

.strong2{
  margin-bottom: 7px;
}


.cont4 {
  margin-top: 16px;
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
  align-items: center;
  cursor: pointer;
}

.cont4Icon {
  height: 20px;
  width: 20px;
  background-color: #185fb8;
  background: linear-gradient(#dfe3e9, #ffffff);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--themeColor);
  padding: 10px;
  margin-right: 6px;
}


.header{
  margin-bottom: 7px;
  /* color: darkgray; */
}
.l{
  margin-bottom: 4px;
}

.contact-heading {
  /* color: #333333; */
  color: #ffffff;
  font-family: "Inter-Regular", Helvetica, sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
}

.contact-item {
  /* color: #111317; */
  color: #ffffff;
cursor: pointer;
  font-family: "Inter-Regular", Helvetica, sans-serif;
  font-size: 14px;
}

.links-section .footer-links {
  list-style: none;
  padding: 0;
  text-align: center;
}

.footer-link {
  color: #111317;
  font-size: 14px;
  margin: 5px 0;
  cursor: pointer;
}

.footer-bottom {
  border-top: 1px solid #bbbbbb;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 20px;
}

.copyright {
  font-size: 12px;
  /* color: #7e8695; */
  color: #ffffff;
}

.footer-policies {
  display: flex;
  justify-content: center;
  grid-gap: 55px;
  gap: 55px;
  margin-top: 5px;
}

.policy-link {
  font-size: 12px;
  /* color: #111317; */
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.contact-section-container{
  display: flex;
  grid-gap: 30px
  ;
  gap: 30px
  ;
  width: 72%;
  justify-content: flex-end;
  /* flex-direction: column; */
}

.footer-bottom{
  display: flex;
  width: 100%;
  justify-content: space-between;
}




.logoCSS {
  display: flex;
  margin-top: 30px;
  }
  
  .companyName{
  font-size:35px;
  font-weight: bold;
  /* color: var(--darkTextColorDi); */
  color: #ffffff;
  /* margin-left: 10px; */
  font-size: 35px;
    font-weight: 800;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
   }
  

  .insights-text{
    margin-top: 25px;
    /* margin-bottom: 25px; */
    display: flex; 
    flex-direction: column;
    margin-bottom: 30px;
  }

  .span{
    display: flex;
  margin-top: 3px
  ;
  font-size: 28px;
  }



  @media (max-width: 1300px) {
    .footer-inner {
      width: 80%;
    }
  }


  @media (max-width: 740px) {
    .footer-policies{
      flex-direction: column;
      grid-gap: 7px
      ;
      gap: 7px
      ;
    }

    .footer-bottom{
      flex-direction: column;
    }

    .policy-link{
      justify-content: center;
    }
    .contact-section-container{
      flex-direction: column;
      grid-gap: 0
      ;
      gap: 0
      ;
    }
    .contact-heading{
      margin-top: 20px;
    }

    .contact-section{
      width: 100%;
    }
    .contact-section2{
      width: 100%;
    }

    .footer-container{
flex-direction: column;
    }
    .footer-logo-container{
      width: 100%;
    }
  }
.ServicesDetails_parentNews__2E3Hw {
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1200px;
  background: #f9f9f9; /* Light gray background */
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.ServicesDetails_imgAndHeading__2G720 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #e7f3ff; /* Light blue background */
  border-radius: 8px;
}

.ServicesDetails_mainImg__rvAXK {
  width: 180px;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.ServicesDetails_contentDetails__3jCKj {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 20px;
}

.ServicesDetails_mainTitle__2LLiR {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.ServicesDetails_newsDetails__2i4Cf {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  max-width: 800px;
  text-align: justify;
}

.ServicesDetails_featureList__248lx {
  margin-top: 20px;
  padding: 0;
  list-style: none;
}

.ServicesDetails_featureItem__3d_-L {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #444;
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 5px 0;
  width: 90%;
  max-width: 600px;
}

.ServicesDetails_featureItem__3d_-L::before {
  content: "✔"; /* Checkmark icon */
  color: green;
  font-weight: bold;
  margin-right: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .ServicesDetails_parentNews__2E3Hw {
    width: 95%;
    padding: 16px;
  }

  .ServicesDetails_mainImg__rvAXK {
    width: 150px;
  }

  .ServicesDetails_mainTitle__2LLiR {
    font-size: 24px;
  }

  .ServicesDetails_newsDetails__2i4Cf {
    font-size: 16px;
    text-align: left;
  }

  .ServicesDetails_featureItem__3d_-L {
    font-size: 14px;
    padding: 8px;
    text-align: left;
  }
}

