.name_div{

display: flex;
flex-direction: column;
width:80%;
align-items: center;
justify-content: center;
margin: 10px;
}

.name_txt{
width: 100%;
position: relative;
background-color:white;
font-size: 23px;
color: grey;
font-weight: thin;
}

.name_inputDiv{
width: 100%;
}

.input_field  {
position: relative;
width: 100%;
height: 30px;
font-size:20px;
box-sizing: border-box;
}



.input_field:focus {
    border-style: none;   	
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}









.input_field_date  {
position: relative;
width: 100%;
max-width:300px;
height: 30px;
font-size:20px;
box-sizing: border-box;
}


.input_field_date:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}






.inputText_field  {
position: relative;
width: 100%;
height: 200px;
font-size:20px;
box-sizing: border-box;
}



.inputText_field:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}











