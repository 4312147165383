

/* Project Container */
.projectsContainer {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  background-color: #eef4ff;
  padding: 20px;
  border-radius: 10px;
 
}


/* Accordion Item */
.accordionItem {
  border: 1px solid #ccc;
  margin-bottom: 15px;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.accordionItem:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15);
}

/* Accordion Header */
.accordionHeader {
  background: #4679fc;
  color: white;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px 5px 0 0;
}

.accordionHeader:hover {
  background: #1d4ed8;
}

/* Accordion Arrow */
.accordionArrow {
  transition: transform 0.3s ease;
}

.accordionArrow.open {
  transform: rotate(180deg);
}

/* Accordion Content */
.accordionContent {
  max-height: 0;
  overflow: hidden;
  padding: 0 20px;
  transition: max-height 0.4s ease-out, padding 0.4s ease-out;
  background: white;
}

.accordionContent.active {
  max-height: 600px;
  padding: 20px;
  overflow-y: auto;
}

/* Image Card */
.projectImageCard {
  background: white;
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin-bottom: 15px;
  overflow: hidden;
}

.projectImageCard:hover {
  transform: scale(1.02);
}

/* Project Image */
.projectImage {
  width: 100%;
  max-height: auto;
  object-fit: cover;
  /* aspect-ratio:  1/3 ; */
  border-radius: 8px;
}

/* Project Status */
.projectStatus {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 6px 12px;
  border-radius: 20px;
  margin-top: 10px;
  text-align: center;
}

/* Dynamic status colors */
.status-ongoing {
  background: rgba(255, 165, 0, 0.2);
  color: #ff8c00;
  border: 1px solid #ff8c00;
}

.status-completed {
  background: rgba(34, 197, 94, 0.2);
  color: #22c55e;
  border: 1px solid #22c55e;
}

/* Project Content */
.projectContent {
  font-size: 16px;
  font-weight: 400;
  color: #333;
  margin: 15px 0;
  line-height: 1.5;
  text-align: justify;
  padding: 0 10px; /* Added padding to prevent overflow */
}

/* Features List */
.projectFeatures {
  list-style-type: none;
  padding-left: 0;
  color: #555;
  font-size: 14px;
  margin-top: 10px;
}

.projectFeatures li {
  padding: 8px 0;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.projectFeatures li::before {
  content: "✔";
  color: #2a66ff;
  font-weight: bold;
  margin-right: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .projectsContainer {
      width: 95%;
      padding: 15px;
  }

  .accordionHeader {
      font-size: 16px;
      padding: 12px;
  }

  .accordionContent {
      padding: 0 15px;
  }

  .accordionContent.active {
      padding: 15px;
  }

  .projectImage {
      max-height: 200px;
  }

  .projectStatus,
  .projectContent {
      font-size: 14px;
  }

  .projectFeatures {
      font-size: 13px;
  }
}

@media (max-width: 480px) {

  .sectionTitle{
    padding:8px;
  }
  .projectsContainer {
      width: 90%;
      padding: 8px;
   
  }

  .accordionHeader {
      font-size: 14px;
      padding: 10px;
  }

  .accordionContent {
      padding: 0 10px;
  }

  .accordionContent.active {
      padding: 10px;
  }

  .projectImage {
      max-height: 150px;
  }

  .projectStatus,
  .projectContent {
      font-size: 13px;
      padding: 0 8px;
  }

  .projectFeatures {
      font-size: 12px;
  }
}
