.footer {
  /* background-color: #ffffff;
  padding: 40px 20px;
  width: 100%; */
display: flex;
 flex-direction: column;
      width: 100%;
      /* max-width: 1200px; */
      margin: auto;
      background-color: var(--themeColor);
      /* margin-top: 30px; */
      color: #ffffff;
}

.footer-inner{
  display: flex;
 flex-direction: column;
      width: 100%;
      max-width: 1200px;
      margin: auto;
      background-color: var(--themeColor);
      /* margin-top: 30px; */
      color: #ffffff;
}
.footer-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
  width: 100%;
}

.footer-logo-container{
margin-top: 10px;
width: 25%;
}

.footer-logo .logo-text-png {
  /* background-image: url(./logo-text-png.png); */
  background-position: center;
  background-size: cover;
  height: 49px;
  width: 189px;
  margin: auto;
}

.footer-subscription .insights-text {
  /* color: #333333; */
  color: #ffffff;
  font-family: "Red Hat Display-Regular", Helvetica, sans-serif;
  font-size: 24px;
  text-align: center;
}

.subscription-form {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.email-input {
  width: 70%;
  padding: 8px;
  border: 1px solid #bbbbbb;
  border-radius: 4px;
}

.send-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}

.social-media-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-icon {
  border: 1px solid #bbbbbb;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}

.contact-section {
  text-align: left;
  width: 20%;

}

.contact-section2{
  text-align: left;
  width: 27%;
}

.cont1Text{ 
  display: flex;
  flex-direction: column;
  gap: 25px
  ;
}

.strong2{
  margin-bottom: 7px;
}


.cont4 {
  margin-top: 16px;
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
  align-items: center;
  cursor: pointer;
}

.cont4Icon {
  height: 20px;
  width: 20px;
  background-color: #185fb8;
  background: linear-gradient(#dfe3e9, #ffffff);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--themeColor);
  padding: 10px;
  margin-right: 6px;
}


.header{
  margin-bottom: 7px;
  /* color: darkgray; */
}
.l{
  margin-bottom: 4px;
}

.contact-heading {
  /* color: #333333; */
  color: #ffffff;
  font-family: "Inter-Regular", Helvetica, sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
}

.contact-item {
  /* color: #111317; */
  color: #ffffff;
cursor: pointer;
  font-family: "Inter-Regular", Helvetica, sans-serif;
  font-size: 14px;
}

.links-section .footer-links {
  list-style: none;
  padding: 0;
  text-align: center;
}

.footer-link {
  color: #111317;
  font-size: 14px;
  margin: 5px 0;
  cursor: pointer;
}

.footer-bottom {
  border-top: 1px solid #bbbbbb;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 20px;
}

.copyright {
  font-size: 12px;
  /* color: #7e8695; */
  color: #ffffff;
}

.footer-policies {
  display: flex;
  justify-content: center;
  gap: 55px;
  margin-top: 5px;
}

.policy-link {
  font-size: 12px;
  /* color: #111317; */
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.contact-section-container{
  display: flex;
  gap: 30px
  ;
  width: 72%;
  justify-content: flex-end;
  /* flex-direction: column; */
}

.footer-bottom{
  display: flex;
  width: 100%;
  justify-content: space-between;
}




.logoCSS {
  display: flex;
  margin-top: 30px;
  }
  
  .companyName{
  font-size:35px;
  font-weight: bold;
  /* color: var(--darkTextColorDi); */
  color: #ffffff;
  /* margin-left: 10px; */
  font-size: 35px;
    font-weight: 800;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
   }
  

  .insights-text{
    margin-top: 25px;
    /* margin-bottom: 25px; */
    display: flex; 
    flex-direction: column;
    margin-bottom: 30px;
  }

  .span{
    display: flex;
  margin-top: 3px
  ;
  font-size: 28px;
  }



  @media (max-width: 1300px) {
    .footer-inner {
      width: 80%;
    }
  }


  @media (max-width: 740px) {
    .footer-policies{
      flex-direction: column;
      gap: 7px
      ;
    }

    .footer-bottom{
      flex-direction: column;
    }

    .policy-link{
      justify-content: center;
    }
    .contact-section-container{
      flex-direction: column;
      gap: 0
      ;
    }
    .contact-heading{
      margin-top: 20px;
    }

    .contact-section{
      width: 100%;
    }
    .contact-section2{
      width: 100%;
    }

    .footer-container{
flex-direction: column;
    }
    .footer-logo-container{
      width: 100%;
    }
  }