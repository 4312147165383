.about {
  display: flex;
  flex-direction: column;
  font-size: 30px;
}

/* Top Title Section */
.topTitle {
  width: 100%;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  text-align: start;
  font-size: 15px;
  color: var(--darkText);
  background-color: var(--chatCommentBkgColor);
}

/* Responsive Grid Layout */
.aboutbox,
.aboutbox2,
.whyChooseUsContainer,
.process {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: auto;
  gap: 30px;
}

/* Left & Right Sections */
.leftBox,
.leftBox1,
.RightBox,
.RightBox1 {
  width: 100%;
}

/* Office Image */
.OfficeImageContainer {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

/* Text Sections */
.p1, .p2, .p3 {
  font-size: 16px;
  color: var(--lightText);
  line-height: 1.5;
  text-align: justify;
  margin-top: 10px;
}

/* Buttons */
.getintouchParentDiv,
.buttonProcess,
.lastSectionBtn {
  margin-top: 20px;
  border-radius: 6px;
  font-size: 16px;
  padding: 8px 16px;
  color: var(--themeColorText);
  width: fit-content;
  border: 1px solid var(--themeColorText);
  cursor: pointer;
  transition: background 0.3s ease;
}

.getintouchParentDiv:hover,
.buttonProcess:hover,
.lastSectionBtn:hover {
  background: var(--themeColorText);
  color: white;
}

/* Centered Sections */
.whyChooseUsTitle {
  width: 100%;
  margin-top: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  color: var(--darkText);
}

.whyChooseUsDescription {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  color: var(--lightText);
}

/* Process Section */
.processBox1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.processTitle {
  font-size: 28px;
  font-weight: bold;
  color: var(--darkText);
}

.processDesc {
  margin-top: 10px;
  font-size: 16px;
  color: var(--lightText);
  text-align: justify;
  line-height: 24px;
}

/* Process Cards */
.processCard {
  margin-top: 20px;
  border: 1px solid var(--cardBorderColorLight);
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  gap: 15px;
}

.cardIcon {
  height: 50px;
  width: 50px;
  background-color: transparent;
  border-radius: 50%;
}

/* Process Image */
.processImage {
  margin-top: 50px;
  background-color: azure;
  width: 100%;
  aspect-ratio: 1.3/1;
  border-radius: 10px;
}

/* Last Section */
.aboutLastSection {
  width: 100%;
  margin-top: 100px;
  background-color: var(--cardBorderColorLight);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding-bottom: 30px;
}

.lastSectionTitle1,
.lastSectionTitle2 {
  margin-top: 40px;
  font-size: 26px;
  font-weight: bold;
  color: var(--darkText);
  text-align: center;
}

.lastSectiondesc1 {
  width: 50%;
  margin-top: 40px;
  font-size: 16px;
  color: var(--lightText);
  text-align: center;
}

/* Top Block Section */
.topBlockAbout {
  height: 300px;
  background-color: var(--chatCommentBkgColor);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .aboutbox, .aboutbox2, .process {
    grid-template-columns: 1fr;
  }

  .processCard {
    flex-direction: column;
    text-align: center;
  }

  .processContent {
    align-items: center;
  }

  .OfficeImageContainer {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .whyChooseUsTitle {
    font-size: 24px;
  }

  .processTitle {
    font-size: 24px;
  }

  .processDesc {
    font-size: 14px;
  }

  .lastSectionTitle1, .lastSectionTitle2 {
    font-size: 24px;
  }

  .lastSectiondesc1 {
    width: 80%;
    font-size: 14px;
  }

  .topBlockAbout {
    height: 250px;
  }
}

@media (max-width: 480px) {
  .whyChooseUsTitle {
    font-size: 20px;
  }

  .processTitle {
    font-size: 20px;
  }

  .lastSectionTitle1, .lastSectionTitle2 {
    font-size: 20px;
  }

  .lastSectiondesc1 {
    width: 90%;
    font-size: 14px;
  }

  .OfficeImageContainer {
    height: 250px;
  }

  .topBlockAbout {
    height: 200px;
  }
}
