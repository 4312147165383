.slotsContainer {
  margin-top: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: white;
  border-style: none;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  padding-top: 50px;
  padding-left: 30px;  
  border-radius: 10px;
}

.nextPreButtonsDiv{

height: 50px;
display: flex;
align-items: center;
}


.navButton{

border-style:none;
padding: 5px;
color: var(--themeColor);
background-color: var(--chatCommentBkgColor);
width: 100px;
cursor: pointer;
border-radius: 5px;
height: 35px;
}
.titleMessageBooking{
height: 50px;

}


.dateText{
margin: 20px;
font-weight: bold;
border-style: solid;
border-width: 1px;
border-color: var(--chatCommentBkgColor);
border-radius: 5px;
height: 35px;
display: flex;
align-items: center;
width: 150px;
justify-content: center;
}





@media only screen and (max-width: 600px) {

.navButton{
font-size: 12px;
width: 70px;
height: 35px;	
}

.dateText{
height: 35px;
border-style: solid;
}



}







