.clientPage {
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

.Heading {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
}

.clientLogos {
    display: grid;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
    max-width: 520px;
    margin: 0 auto; 
    grid-template-columns: repeat(4,1fr);
    align-items: center;
    justify-content: center;
}
.clientLogosContainer{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 20px;

}

.clientItem {
    flex: 0 1 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.clientItem:hover {
    transform: scale(1.05);
}

.clientLogo {
    max-height: 80px;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.clientLogo:hover {
    opacity: 1;
}

.clientName {
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.clientDescription {
    text-align: center;
    max-width: 500px;
    margin: 0 auto 40px;
}

.clientDescription h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 10px;
}

.clientDescription p {
    color: #666;
    line-height: 1.6;
}

.testimonials {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

.testimonialCard {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    position: relative;
    margin-bottom: 20px;
}

.profileImage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    top: -25px;
    left: 20px;
    border: 3px solid #fff;
}

.testimonialContent {
    padding-top: 30px;
}

.testimonialContent h3 {
    font-size: 1.1em;
    margin-bottom: 5px;
    color: #333;
}

.jobTitle {
    color: #888;
    font-size: 0.9em;
    margin-bottom: 10px;
}

.feedback {
    color: #555;
    font-style: italic;
}

@media (max-width: 768px) {
    .clientItem {
        width: 100px;
        height: 100px;
        background-color: none;
        border: none;
        border-radius: 0;
        box-shadow: none;
    }
    .clientLogos{
        grid-template-columns: 1fr;
        display: flex;
        flex-direction: column;
        align-items: center;
    } 
    .clientLogosContainer {
        grid-template-columns: 1fr;
        
    }
    .testimonials {
        flex-direction: column;
        align-items: center;
    }
    
    .clientDescription {
        text-align: center;
    }
}

/* @media (max-width: 400px) {
    .clientPage {
        padding: 20px;
    }

    .clientLogo {
        max-height: 60px;
    }

    .clientName {
        font-size: 12px;
    }

    .clientDescription h2 {
        font-size: 1.5em;
    }

    .testimonialCard {
        width: 250px;
        padding: 15px;
    }

    .testimonialContent h3 {
        font-size: 1em;
    }

    .jobTitle {
        font-size: 0.8em;
    }

    .feedback {
        font-size: 0.9em;
    }
} */
@media (max-width: 400px) {
    .clientPage {
        padding: 20px;
    }

    .clientLogos {
        display: grid;
        grid-template-columns: 1fr; 
        justify-items: center; 
        gap: 20px;
    }

    .clientLogosContainer {
        grid-template-columns: 1fr; 
        justify-items: center;
    }

    .clientLogo {
        max-height: 60px;
    }

    .clientName {
        font-size: 12px;
    }

    .clientDescription h2 {
        font-size: 1.5em;
    }

    .testimonialCard {
        width: 250px;
        padding: 15px;
    }

    .testimonialContent h3 {
        font-size: 1em;
    }

    .jobTitle {
        font-size: 0.8em;
    }

    .feedback {
        font-size: 0.9em;
    }
}

