.parent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}


.t1 {
  width: 60%;
  font-size: 18px;
  line-height: 22px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagestop {

  width: 100%;
  height: 300px;
  background-color: var(--chatCommentBkgColor);
  display: flex;
  align-items: center;
}



.innerDiv{

width: 1200px;
margin: auto;
}

.detailsText {
  width: 60%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
}


@media (max-width: 480px) {
  .t1,
  .detailsText {
    width: 100%;
    font-size: 14px;
    text-align: center;
  }

  .imagestop {
    height: 200px;
  }


}
